import React from 'react';
import { Formik, Form } from 'formik';
import Button from '../../audi-ui-components/Button';
import FormField from '../../components/FormField';
import Media from '../../components/Media';
import { validate, defaultSchema } from '../../lib/validation';
import { obfuscatePhone } from './helpers';
import { request } from '../../lib/apiRequestWrapper';
import { PATH_FINANCE, FIELD_DATEPICKER } from '../../constants';
import moment from 'moment';
import Crumb from '../../components/Crumb';

class RequestRegNum extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isRequestValid: false,
      isVerifyValid: false,
      step: 1,
      requestData: {},
      isLoading: false,
      errorMessage: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRequestSubmit = (values, formikBag) => {
    this.setState({isLoading: true, errorMessage: null, errorItems: {}});
    let dob = moment(values.dateOfBirth).format('DDMMYY');
    let _data = Object.assign({}, values, {dateOfBirth: dob});
    request(
      `${process.env.RAZZLE_API}/1/vwfs/registration/recover/create`,
      {
        method: "POST",
        body: JSON.stringify(_data),
      }
    ).then((response) => {
      this.setState({isLoading: false, requestData: _data, step: 2});
    }).catch((error) => {
      console.error(error);
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
      }
      this.setState({isLoading: false, errorMessage: error.message});
    });
  }

  handleVerifySubmit = (values, formikBag) => {
    this.setState({isLoading: true, errorMessage: null});
    let _data = Object.assign({}, values, this.state.requestData);
    request(
      `${process.env.RAZZLE_API}/1/vwfs/registration/recover/confirm`,
      {
        method: "POST",
        body: JSON.stringify(_data),
      }
    ).then((response) => {
      this.setState({isLoading: false});
      this.props.history.push(PATH_FINANCE + '/register?regnum=requested');
    }).catch((error) => {
      console.error(error);
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
      }
      this.setState({isLoading: false, errorMessage: error.message});
    });
  }

  handleChange = (fieldName) => {
    if (this.state.errorItems.hasOwnProperty(fieldName)) {
      let _items = Object.assign({}, this.state.errorItems);
      delete _items[fieldName];
      this.setState({errorItems: _items});
    }
  }

  validateRequest = (values) => {
    let schema = {
      firstName: {...defaultSchema.requiredField},
      lastName: {...defaultSchema.requiredField},
      dateOfBirth: {...defaultSchema.requiredField},
      mobilePhone: {
        ...defaultSchema.requiredField,
        ...defaultSchema.mobile
      }
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }

  validateVerify = (values) => {
    let schema = {
      verificationCode: {...defaultSchema.requiredField}
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }

  render() {
    return (
      <div className="page-wrapper">
        <header className="finance__header mb-7">
          <Media type="image" url={`${process.env.PUBLIC_PATH}assets/1920x1080_InlineMediaGallery_AQ8_D_181008.jpg`} x="50%" y="50%" altText="Hero Image" />
          <div className="content-wrapper">
            <Crumb />
            <div className="finance__header-inner"><p className="aui-headline-3 indent py-7">Request a new registration code</p></div>
          </div>
        </header>

        <div className="content-wrapper pb-7">

          {this.state.step === 1 && <>
            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-small-6">
                <p className="mb-7">
                  If you have lost your registration code you can request a new one by completing the form below.
                  To ensure your details are secure, we will send your registration code to your registered mobile number via text message.
                  If you have any queries please contact our Customer Service Team.
                </p>
              </div>
              <div className="col-small-1"></div>
              <div className="col-small-3">
                <p className="mb-3">
                  <b>Monday - Friday 9:00am to 5:00pm (AEST)</b><br />
                  General Enquiries: <b style={{whiteSpace:'nowrap'}}>1300 734 567</b><br />
                  Online Technical Support: <b style={{whiteSpace:'nowrap'}}>1300 017 163</b><br />
                  Calling from overseas: <b style={{whiteSpace:'nowrap'}}>(+61 2) 9695 6311</b>
                </p>
              </div>
            </div>

            <hr className="indent my-7" />

            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-small-6">
                <div className="finance__loader-wrapper">
                  <Formik validate={this.validateRequest} onSubmit={this.handleRequestSubmit} initialValues={{firstName: "", lastName: "", dateOfBirth: "", mobilePhone: ""}}>
                    {(formikBag) => (
                      <Form className="">
                        <FormField name="firstName" label="First name" description="Please enter your first name as it appears on your contract" formikBag={formikBag} />
                        <FormField name="lastName" label="Last name" description="Please enter your last name as it appears on your contract" formikBag={formikBag} />
                        <FormField name="dateOfBirth" fieldType={FIELD_DATEPICKER} label="Date of birth (dd/mm/yyyy)" formikBag={formikBag} />
                        <FormField name="mobilePhone" label="Mobile number" description="The mobile number you registered when applying for your finance can be found on your contract." formikBag={formikBag} />
                        {this.state.errorMessage && <p className="mb-3 aui-color-text-red">{this.state.errorMessage}</p>}
                        <Button label="Send verification code" type="submit" buttonType="primary" />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>}

          {this.state.step === 2 && <>
            <h2 className="aui-headline-4 mb-3">One last thing!</h2>
            <p className="mb-3">
              You are one step closer to being able to access your Audi Financial Services account.
              Simply enter the verification code sent to {this.state.requestData.hasOwnProperty('mobilePhone') && obfuscatePhone(this.state.requestData.mobilePhone)}.
              Please allow up to 2 minutes to receive the verification code.
            </p>
            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-small-6">
                <div className="finance__loader-wrapper">
                  <Formik validate={this.validateVerify} onSubmit={this.handleVerifySubmit} initialValues={{verificationCode: ""}}>
                    {(formikBag) => (
                      <Form className="">
                        <FormField name="verificationCode" label="Verification code" formikBag={formikBag} />
                        {this.state.errorMessage && <p className="mb-3 aui-color-text-red">{this.state.errorMessage}</p>}
                        <Button label="Submit verification code" type="submit" buttonType="primary" />
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </>}
        </div>
      </div>
    );
  }
}

export default RequestRegNum;
