import React, { Component } from 'react';

function WordMark(props) {

  if (props.name === "AudiMagazine") {
    return (
      <svg className="wordmark audi-magazine" preserveAspectRatio="xMinYMid meet" viewBox="0 0 213 30" width="213" height="30">
        <g>
          <path d="M97,24v-8.8c0-0.5,0-1,0-1.5c0-0.5,0-1,0-1.5c-0.4,0.5-0.7,1-1.1,1.5c-0.4,0.5-0.7,1-1.1,1.5l-4,5.7h-2.6l-4-5.7 c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c-0.2-0.3-0.4-0.5-0.5-0.8c0,0.5,0,1,0,1.5 c0,0.5,0,1,0,1.5V24h-3.6V7.3h4.1l5.4,7.7c0.3,0.4,0.6,0.8,0.8,1.2s0.6,0.8,0.8,1.2c0.3-0.4,0.6-0.8,0.9-1.2 c0.3-0.4,0.6-0.8,0.9-1.2l5.4-7.7h3.9V24H97z"/>
  	      <path d="M114.6,24l-0.2-1.2c-0.3,0.2-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.3,0.3s-1,0.1-1.5,0.1 c-0.8,0-1.5-0.1-2.2-0.2c-0.6-0.2-1.2-0.4-1.6-0.7c-0.4-0.3-0.8-0.7-1-1.2s-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3 c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.2-0.7c0.5-0.2,1-0.4,1.5-0.5c0.6-0.1,1.2-0.2,1.8-0.3s1.4-0.1,2.1-0.1c0.3,0,0.6,0,0.9,0 c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0v0c0-0.4-0.1-0.8-0.2-1.1s-0.4-0.5-0.7-0.7s-0.7-0.3-1.2-0.4c-0.5-0.1-1-0.1-1.6-0.1 c-0.5,0-1,0-1.4,0.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.2,0.3s-0.7,0.2-1.1,0.4v-2.6c0.3-0.2,0.7-0.3,1.1-0.5 c0.4-0.1,0.8-0.3,1.3-0.4c0.5-0.1,0.9-0.2,1.4-0.2c0.5-0.1,1-0.1,1.6-0.1c1.1,0,2.1,0.1,2.9,0.3c0.9,0.2,1.6,0.5,2.2,0.9 c0.6,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.2V24H114.6z M114.2,19h-1.9c-0.8,0-1.5,0-2,0.1c-0.6,0.1-1,0.1-1.4,0.3 c-0.4,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.2,0.4-0.2,0.7c0,0.2,0,0.4,0.1,0.5s0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.7,0.3 c0.3,0.1,0.6,0.1,1.1,0.1c0.4,0,0.9,0,1.2-0.1s0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.8-0.4V19z"/>
  	      <path d="M132.5,22.4c-0.3,0.2-0.6,0.4-1,0.6s-0.7,0.3-1.2,0.5s-0.8,0.2-1.3,0.3c-0.4,0.1-0.9,0.1-1.4,0.1c-1.1,0-2-0.1-2.8-0.4 c-0.8-0.3-1.5-0.7-2.1-1.2c-0.6-0.5-1-1.2-1.3-1.9c-0.3-0.8-0.5-1.6-0.5-2.5c0-0.9,0.2-1.8,0.5-2.5s0.8-1.4,1.4-1.9 s1.3-0.9,2.2-1.2s1.9-0.4,3-0.4c0.5,0,1,0,1.4,0.1s0.9,0.2,1.3,0.3c0.4,0.1,0.8,0.3,1.2,0.4c0.4,0.2,0.7,0.4,0.9,0.6l0.4-1h2.9 v11.1c0,1.1-0.2,2-0.5,2.7c-0.4,0.8-0.9,1.4-1.6,1.9s-1.5,0.9-2.5,1.1c-1,0.2-2.1,0.3-3.4,0.3c-0.6,0-1.2,0-1.7,0 c-0.5,0-1-0.1-1.5-0.1c-0.5-0.1-0.9-0.1-1.3-0.2c-0.4-0.1-0.8-0.2-1.2-0.3v-2.9c0.3,0.1,0.7,0.2,1.1,0.3c0.4,0.1,0.8,0.2,1.2,0.2 c0.4,0.1,0.9,0.1,1.4,0.1c0.5,0,1,0.1,1.5,0.1c0.8,0,1.6-0.1,2.2-0.2c0.6-0.1,1.1-0.3,1.5-0.5c0.4-0.2,0.7-0.5,0.9-0.9 c0.2-0.4,0.3-0.8,0.3-1.4V22.4z M132.5,15.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.9-0.3c-0.3-0.1-0.7-0.1-1-0.2 s-0.8-0.1-1.2-0.1c-0.6,0-1.1,0.1-1.6,0.2s-0.9,0.3-1.2,0.6c-0.3,0.3-0.6,0.6-0.7,1c-0.2,0.4-0.3,0.9-0.3,1.4s0.1,1,0.3,1.4 c0.2,0.4,0.4,0.7,0.7,1c0.3,0.3,0.7,0.5,1.2,0.6c0.5,0.1,1,0.2,1.6,0.2c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.1,1-0.2 c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.9-0.4V15.5z"/>
  	      <path d="M150,24l-0.2-1.2c-0.3,0.2-0.6,0.5-1,0.7c-0.4,0.2-0.8,0.4-1.2,0.5c-0.4,0.1-0.9,0.2-1.4,0.3c-0.5,0.1-1,0.1-1.5,0.1 c-0.8,0-1.5-0.1-2.2-0.2c-0.6-0.2-1.2-0.4-1.6-0.7c-0.4-0.3-0.8-0.7-1-1.2s-0.4-1-0.4-1.6c0-0.5,0.1-0.9,0.3-1.3 c0.2-0.4,0.4-0.7,0.7-1c0.3-0.3,0.7-0.5,1.2-0.7c0.5-0.2,1-0.4,1.5-0.5c0.6-0.1,1.2-0.2,1.8-0.3c0.7-0.1,1.4-0.1,2.1-0.1 c0.3,0,0.6,0,0.9,0c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.7,0v0c0-0.4-0.1-0.8-0.2-1.1s-0.4-0.5-0.7-0.7s-0.7-0.3-1.2-0.4 c-0.5-0.1-1-0.1-1.6-0.1c-0.5,0-1,0-1.4,0.1c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.2-1.2,0.3c-0.4,0.1-0.7,0.2-1.1,0.4v-2.6 c0.3-0.2,0.7-0.3,1.1-0.5c0.4-0.1,0.8-0.3,1.3-0.4c0.5-0.1,0.9-0.2,1.4-0.2s1-0.1,1.6-0.1c1.1,0,2.1,0.1,2.9,0.3s1.6,0.5,2.2,0.9 c0.6,0.4,1,0.9,1.3,1.5c0.3,0.6,0.5,1.4,0.5,2.2V24H150z M149.6,19h-1.9c-0.8,0-1.5,0-2,0.1c-0.6,0.1-1,0.1-1.4,0.3 c-0.4,0.1-0.6,0.3-0.8,0.5c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.2,0,0.4,0.1,0.5c0.1,0.2,0.2,0.3,0.4,0.5c0.2,0.1,0.4,0.2,0.7,0.3 c0.3,0.1,0.6,0.1,1.1,0.1c0.4,0,0.9,0,1.2-0.1c0.4-0.1,0.7-0.1,1.1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.5-0.3,0.8-0.4V19z"/>
  	      <path d="M156.1,24V22l7.8-7.2h-7.6v-2.6h12.7v2l-7.7,7.2h8V24H156.1z"/>
  	      <path d="M172.7,10.4V7.3h3.6v3.2H172.7z M172.7,24V12.1h3.6V24H172.7z"/>
          <path d="M191.6,24v-6.5c0-0.5-0.1-0.9-0.2-1.3c-0.1-0.4-0.3-0.7-0.6-0.9c-0.2-0.2-0.6-0.4-1-0.5s-0.9-0.2-1.4-0.2 c-0.4,0-0.8,0-1.2,0.1c-0.4,0.1-0.7,0.1-1.1,0.2c-0.3,0.1-0.6,0.2-0.9,0.3s-0.6,0.2-0.8,0.4V24h-3.6V12.1h3.2l0.3,1.4 c0.3-0.3,0.6-0.5,1-0.7c0.4-0.2,0.8-0.4,1.2-0.5c0.4-0.1,0.9-0.3,1.3-0.3s1-0.1,1.5-0.1c0.5,0,1,0,1.5,0.1c0.5,0.1,1,0.2,1.4,0.3 c0.4,0.2,0.8,0.4,1.2,0.6c0.4,0.3,0.7,0.6,1,1s0.5,0.9,0.6,1.4c0.1,0.5,0.2,1.2,0.2,1.9v7H191.6z"/>
  	      <path d="M211.9,23.3c-0.3,0.2-0.7,0.3-1.2,0.5c-0.5,0.1-0.9,0.3-1.5,0.3c-0.5,0.1-1.1,0.2-1.6,0.2c-0.6,0.1-1.1,0.1-1.6,0.1 c-0.8,0-1.6-0.1-2.3-0.2s-1.3-0.3-1.9-0.6c-0.6-0.3-1.1-0.6-1.5-1s-0.8-0.8-1-1.3c-0.3-0.5-0.5-1-0.6-1.5c-0.1-0.5-0.2-1.1-0.2-1.7 c0-0.6,0.1-1.1,0.2-1.6c0.1-0.5,0.3-1,0.6-1.5s0.6-0.9,1-1.3c0.4-0.4,0.9-0.7,1.4-1c0.5-0.3,1.1-0.5,1.8-0.7s1.4-0.2,2.3-0.2 c0.8,0,1.6,0.1,2.3,0.2c0.7,0.1,1.3,0.3,1.8,0.6c0.5,0.3,1,0.6,1.4,1c0.4,0.4,0.7,0.8,1,1.2c0.3,0.5,0.4,0.9,0.6,1.5 c0.1,0.5,0.2,1.1,0.2,1.6c0,0.1,0,0.2,0,0.3s0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.2h-10.8c0,0.4,0.2,0.8,0.3,1.1 c0.2,0.3,0.5,0.6,0.8,0.8s0.8,0.4,1.3,0.5c0.5,0.1,1.1,0.2,1.9,0.2c0.5,0,1,0,1.5-0.1c0.5,0,1-0.1,1.5-0.2c0.5-0.1,0.9-0.2,1.3-0.3 c0.4-0.1,0.8-0.2,1.1-0.4V23.3z M209.2,16.8c-0.1-0.4-0.2-0.8-0.4-1.1c-0.2-0.3-0.5-0.6-0.8-0.8s-0.7-0.3-1.1-0.4 c-0.4-0.1-0.8-0.1-1.3-0.1s-0.9,0-1.3,0.1c-0.4,0.1-0.7,0.2-1,0.4c-0.3,0.2-0.6,0.5-0.8,0.8c-0.2,0.3-0.3,0.7-0.4,1.1H209.2z"/>
        </g>
        <g>
          <path style={{fill:'#f50537'}} d="M18.7,24l-1.8-3.5H7.3L5.5,24H1.4l8.8-16.8h3.9L22.9,24H18.7z M13.4,13.5c-0.2-0.4-0.4-0.9-0.7-1.3 c-0.2-0.4-0.4-0.9-0.7-1.3c-0.2,0.4-0.4,0.9-0.7,1.3c-0.2,0.4-0.4,0.9-0.7,1.3l-2.1,4.1h6.8L13.4,13.5z"/>
          <path style={{fill:'#f50537'}} d="M35.7,24l-0.2-1.3c-0.3,0.2-0.6,0.5-0.9,0.7c-0.4,0.2-0.7,0.4-1.2,0.5c-0.4,0.1-0.9,0.3-1.3,0.3 c-0.5,0.1-0.9,0.1-1.4,0.1c-0.5,0-1,0-1.5-0.1c-0.5-0.1-0.9-0.2-1.4-0.3s-0.8-0.4-1.2-0.6s-0.7-0.6-0.9-1s-0.5-0.8-0.6-1.4 c-0.1-0.5-0.2-1.2-0.2-1.9v-7h3.6v6.5c0,0.5,0.1,0.9,0.2,1.3s0.3,0.7,0.6,0.9c0.2,0.2,0.6,0.4,1,0.5c0.4,0.1,0.9,0.2,1.4,0.2 c0.4,0,0.7,0,1.1-0.1c0.3,0,0.6-0.1,1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.8-0.4v-8.5h3.6V24H35.7z"/>
          <path style={{fill:'#f50537'}} d="M54.4,24l-0.2-1.3c-0.3,0.3-0.6,0.5-1,0.7s-0.8,0.4-1.2,0.5s-0.9,0.3-1.4,0.3 c-0.5,0.1-1,0.1-1.5,0.1c-1.1,0-2-0.1-2.8-0.4c-0.8-0.3-1.5-0.7-2.1-1.3c-0.6-0.5-1-1.2-1.3-2c-0.3-0.8-0.5-1.7-0.5-2.6 c0-1,0.2-1.9,0.5-2.6s0.8-1.4,1.4-2s1.3-1,2.2-1.2s1.9-0.4,3-0.4c0.4,0,0.9,0,1.3,0.1c0.4,0.1,0.8,0.1,1.2,0.2 c0.4,0.1,0.7,0.2,1.1,0.4s0.6,0.3,0.9,0.5V7.3h3.6V24H54.4z M54,15.5c-0.3-0.2-0.6-0.3-0.9-0.4c-0.3-0.1-0.6-0.2-0.9-0.3 s-0.7-0.1-1-0.2c-0.4,0-0.8-0.1-1.2-0.1c-0.6,0-1.2,0.1-1.6,0.2c-0.5,0.2-0.9,0.4-1.2,0.7s-0.6,0.7-0.7,1.1 c-0.2,0.4-0.2,0.9-0.2,1.5c0,0.6,0.1,1.1,0.2,1.5c0.2,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.5,1.2,0.7c0.5,0.2,1,0.2,1.6,0.2 c0.4,0,0.8,0,1.2-0.1c0.4,0,0.7-0.1,1-0.2c0.3-0.1,0.6-0.2,0.9-0.3c0.3-0.1,0.6-0.3,0.9-0.4V15.5z"/>
          <path style={{fill:'#f50537'}} d="M62.1,10.4V7.3h3.6v3.2H62.1z M62.1,24V12.1h3.6V24H62.1z"/>
        </g>
      </svg>
    );
  }

  if (props.name === "ADE") {
    return (
      <svg className="wordmark ade" preserveAspectRatio="xMinYMid meet" width="525.66px" height="86.49px" viewBox="0 0 525.66 86.49">
        <g>
          <path d="M73.03,66.42c0-6.99,4.69-11.21,12.67-11.21c3.05,0,5.98,0.71,7.98,2.01V46.88h4.14v30.27h-3.47l-0.34-1.89 c-2.05,1.39-5.27,2.38-8.57,2.38C77.88,77.66,73.03,73.56,73.03,66.42z M93.68,72.35V60.51c-2.05-1.3-4.55-2.09-7.4-2.09 c-5.81,0-8.99,2.68-8.99,7.99c0,5.3,3.18,8.02,8.99,8.02C89.13,74.44,91.64,73.64,93.68,72.35z"/>
          <path d="M107.45,55.71h3.72l0.33,3.89c1.47-2.25,3.93-4.31,8.07-4.31c1.55,0,2.88,0.21,3.81,0.54v3.68 c-1.21-0.33-2.72-0.5-4.31-0.5c-4.48,0-6.57,2.63-7.49,4.17v13.97h-4.14V55.71z"/>
          <path d="M129.28,46.88h4.14v4.51h-4.14V46.88z M129.28,55.71h4.14v21.45h-4.14V55.71z"/>
          <path d="M138.74,55.71h4.6l8.41,17.1l8.32-17.1h4.52l-11,21.45h-3.85L138.74,55.71z"/>
          <path d="M169.9,46.88h4.14v4.51h-4.14V46.88z M169.9,55.71h4.14v21.45h-4.14V55.71z"/>
          <path d="M183.67,55.71h3.46l0.34,2.13c2.13-1.55,5.61-2.63,9.41-2.63c5.39,0,10.04,1.88,10.04,8.23v13.72h-4.14V64.15 c0-4.26-2.3-5.68-6.77-5.68c-3.22,0-6.27,1.04-8.19,2.25v16.44h-4.14V55.71z"/>
          <path d="M216,84.89v-3.51c2.3,0.97,5.4,1.71,9.75,1.71c5.6,0,9.03-1.54,9.03-6.98v-1.3c-2.08,1.3-5.14,2.17-8.24,2.17 c-7.56,0-12.42-3.85-12.42-10.92c0-6.89,4.69-10.86,12.67-10.86c3.26,0,6.36,0.83,8.37,2.25l0.29-1.75h3.47v20.74 c0,7.19-5.02,10.05-12.92,10.05C221.69,86.49,218,85.78,216,84.89z M234.77,71.67V60.51c-2.05-1.3-4.56-2.09-7.4-2.09 c-5.81,0-8.99,2.47-8.99,7.65c0,5.19,3.18,7.7,8.99,7.7C230.22,73.77,232.73,72.97,234.77,71.67z"/>
          <path d="M282.75,72.4v3.29c-2.42,1.13-5.44,1.96-9.74,1.96c-8.45,0-12.59-4.85-12.59-11.54c0-6.15,4.35-10.91,11.96-10.91 c7.49,0,11.83,4.38,11.83,10.45c0,0.54-0.04,1.25-0.08,1.63h-19.58c0.3,4.26,3.14,7.03,8.91,7.03 C277.61,74.32,280.24,73.4,282.75,72.4z M264.6,64.11h15.55c-0.37-3.39-3.55-5.73-7.78-5.73C268.15,58.38,264.93,60.72,264.6,64.11 z"/>
          <path d="M297.52,66.24L287.4,55.71h5.35l7.28,7.9l7.36-7.9h5.14l-10.03,10.5l10.58,10.95h-5.36l-7.74-8.32l-7.78,8.32h-5.14 L297.52,66.24z"/>
          <path d="M318.65,55.71h3.46l0.3,1.88c2.09-1.38,5.27-2.38,8.62-2.38c7.58,0,12.43,4.05,12.43,11.21c0,6.98-4.69,11.24-12.68,11.24 c-3.09,0-6.02-0.71-7.98-2.01v10.33h-4.14V55.71z M339.18,66.42c0-5.32-3.17-8.04-8.99-8.04c-2.84,0-5.35,0.8-7.4,2.09v11.84 c2.05,1.3,4.56,2.1,7.4,2.1C336,74.41,339.18,71.72,339.18,66.42z"/>
          <path d="M371.48,72.4v3.29c-2.43,1.13-5.45,1.96-9.75,1.96c-8.44,0-12.58-4.85-12.58-11.54c0-6.15,4.34-10.91,11.96-10.91 c7.49,0,11.83,4.38,11.83,10.45c0,0.54-0.04,1.25-0.08,1.63h-19.57c0.29,4.26,3.14,7.03,8.9,7.03 C366.33,74.32,368.96,73.4,371.48,72.4z M353.32,64.11h15.56c-0.38-3.39-3.56-5.73-7.78-5.73 C356.87,58.38,353.66,60.72,353.32,64.11z"/>
          <path d="M380.34,55.71h3.72l0.33,3.89c1.47-2.25,3.94-4.31,8.07-4.31c1.55,0,2.89,0.21,3.82,0.54v3.68 c-1.22-0.33-2.73-0.5-4.32-0.5c-4.48,0-6.57,2.63-7.48,4.17v13.97h-4.14V55.71z"/>
          <path d="M402.18,46.88h4.14v4.51h-4.14V46.88z M402.18,55.71h4.14v21.45h-4.14V55.71z"/>
          <path d="M436.31,72.4v3.29c-2.43,1.13-5.44,1.96-9.75,1.96c-8.44,0-12.59-4.85-12.59-11.54c0-6.15,4.35-10.91,11.95-10.91 c7.5,0,11.84,4.38,11.84,10.45c0,0.54-0.04,1.25-0.08,1.63h-19.57c0.29,4.26,3.14,7.03,8.9,7.03 C431.16,74.32,433.79,73.4,436.31,72.4z M418.15,64.11h15.57c-0.38-3.39-3.56-5.73-7.79-5.73 C421.71,58.38,418.49,60.72,418.15,64.11z"/>
          <path d="M445.17,55.71h3.47l0.33,2.13c2.14-1.55,5.61-2.63,9.41-2.63c5.4,0,10.04,1.88,10.04,8.23v13.72h-4.14V64.15 c0-4.26-2.29-5.68-6.77-5.68c-3.22,0-6.28,1.04-8.2,2.25v16.44h-4.15V55.71z"/>
          <path d="M475.63,66.42c0-6.4,4.14-11.21,12.8-11.21c2.88,0,5.85,0.54,7.65,1.33v3.38c-1.87-0.75-4.68-1.38-7.73-1.38 c-5.98,0-8.45,3.17-8.45,7.87c0,5.14,3.23,7.9,8.2,7.9c3.81,0,6.48-0.92,8.33-1.75v3.46c-2.05,0.89-4.43,1.63-8.74,1.63 C480.11,77.66,475.63,73.19,475.63,66.42z"/>
          <path d="M524.19,72.4v3.29c-2.42,1.13-5.43,1.96-9.74,1.96c-8.45,0-12.59-4.85-12.59-11.54c0-6.15,4.35-10.91,11.96-10.91 c7.49,0,11.84,4.38,11.84,10.45c0,0.54-0.04,1.25-0.09,1.63H506c0.29,4.26,3.14,7.03,8.91,7.03 C519.04,74.32,521.68,73.4,524.19,72.4z M506.04,64.11h15.56c-0.38-3.39-3.56-5.73-7.78-5.73 C509.6,58.38,506.38,60.72,506.04,64.11z"/>
        </g>
        <g>
          <path style={{fill:'#f50537'}} d="M86.16,0.96h7.03l15.89,30.29h-7.57l-3.26-6.27H80.97l-3.22,6.27h-7.49L86.16,0.96z M83.49,19.75 h12.29L89.63,7.5L83.49,19.75z"/>
          <path style={{fill:'#f50537'}} d="M132,29.12c-1.96,1.55-5.47,2.72-8.74,2.81c-5.57,0-10.58-1.84-10.58-9.5V9.8h6.57v11.8 c0,3.6,1.63,5.31,5.6,5.31c2.76,0,4.64-0.71,6.74-1.8V9.8h6.57v21.45h-5.73L132,29.12z"/>
          <path style={{fill:'#f50537'}} d="M144.51,20.51c0-7.07,4.43-11.37,12.55-11.37c3.17,0,6.31,0.79,8.32,2.09V0.96h6.57v30.29h-5.73 l-0.46-2.34c-2,1.8-5.52,3.02-9.03,3.02C148.98,31.92,144.51,27.65,144.51,20.51z M165.38,25.1v-9.12 c-2.26-1.16-4.26-1.75-7.36-1.75c-4.43,0-6.82,2.26-6.82,6.28c0,4.01,2.39,6.35,6.82,6.35C161.12,26.85,163.12,26.28,165.38,25.1z" />
          <path style={{fill:'#f50537'}} d="M180.14,0.96h6.57v5.69h-6.57V0.96z M180.14,9.8h6.57v21.45h-6.57V9.8z"/>
        </g>
        <polygon style={{fill:'#f50537'}} points="21.48,0 0,40.42 43.36,40.42 64.85,0 21.48,0 "/>
      </svg>
    );
  }

  if (props.name === "AudiConnectPlus") {
    return (<span className="aui-headline-4 wordmark">Audi Connect <i className="aui-color-text-red">plus</i></span>);
  }

  if (props.name === "myAudi") {
    return (
      <svg className="wordmark myaudi" preserveAspectRatio="xMinYMid meet" viewBox="0 0 328 30">
        <path style={{fill:'#f50537'}} d="M137.8,6.8h5v16.1h-5V6.8z M137.8,0.3h5v4.3h-5V0.3z M126.6,0.3V8c-1.5-0.9-3.8-1.6-6.2-1.6 c-6.2,0-9.6,3.3-9.6,8.5c0,5.4,3.4,8.6,9.2,8.6c2.8,0,5.3-0.9,6.8-2.3l0.4,1.8h4.3V0.3L126.6,0.3z M126.6,18.3 c-1.6,0.9-3.2,1.3-5.5,1.3c-3.3,0-5.1-1.7-5.1-4.7s1.8-4.7,5.1-4.7c2.3,0,3.9,0.4,5.5,1.3C126.6,11.5,126.6,18.3,126.6,18.3z M101.5,21.2c-1.5,1.3-3.9,2.2-6.6,2.2c-4.2,0-8-1.5-8-7.1V7h5v8.9c0,2.8,1.2,3.9,4.2,3.9c1.8,0,3.5-0.5,5.1-1.3V6.9h5v16.2h-4.3 L101.5,21.2L101.5,21.2z M72.3,0.3h-5.3L55.1,23.1h5.6l2.3-4.7h13l2.5,4.7h5.6L72.3,0.3z M65,14.4l4.6-9.2l4.6,9.2H65z"/>
        <path d="M36.6,30c-0.5,0-1.2,0-1.6-0.3v-2.5c0.5,0.1,1,0.1,1.6,0.1c2.9,0,4.8-1.2,6.3-4.1l0.4-0.8L35.1,6.8h3.4l6.3,12.9l6.3-12.9 h3.4l-8.4,16.7C44,27.5,41.6,30,36.6,30z M28.9,13.2c0-3.2-1.7-4.3-5.1-4.3c-2.5,0-5,0.8-6.3,1.8c0.2,0.6,0.2,1.3,0.3,2V23h-3.1 v-9.8c0-3.2-1.7-4.3-5.1-4.3c-2.3,0-4.7,0.8-6.2,1.7v12.3H0.2V6.8h2.6l0.3,1.6c1.6-1.2,4.2-2,7.1-2c2.6,0,5.1,0.6,6.4,2.5 c1.6-1.3,4.6-2.5,7.9-2.5c4.1,0,7.6,1.5,7.6,6.2v10.4h-3.2V13.2z"/>
        <path d="M167.1,0h3.7l11.3,22.6h-3.6l-2.8-5.6h-13.6l-2.8,5.6h-3.5L167.1,0z M163.2,14.4h11.3L168.9,3L163.2,14.4z"/>
        <path d="M200.3,21.3c-1.6,1-3.9,1.8-6.6,1.8c-4,0-7.7-1.1-7.7-6V6.6h3.1v9.9c0,3,1.9,4.1,5.2,4.1c2,0.1,4-0.5,5.7-1.5V6.6h3.1v16 h-2.6L200.3,21.3z"/>
        <path d="M209.3,21.8v-2.4c2.1,0.7,4.3,1,6.5,1.1c3.8,0,5.6-0.8,5.6-2.3c0-1.6-1.7-1.9-4.7-2.5c-4.9-0.9-7.2-1.9-7.2-4.9 c0-2.8,2.4-4.7,8-4.7c2.6,0,4.7,0.5,6.1,1.2v2.4c-1.9-0.8-4-1.1-6.1-1.1c-3.5,0-4.8,0.7-4.8,2.2c0,1.6,1.7,1.8,4.7,2.4 c4.9,0.9,7.2,1.9,7.2,4.9c0,2.8-3,4.8-8.8,4.8C213.3,23,210.7,22.5,209.3,21.8z"/>
        <path d="M228.1,6.6h3.6l0.4-4h2.6v4h7.7V9h-7.7v8.5c0,2.2,1.1,2.9,3.7,2.9c1.3,0,2.6-0.2,3.8-0.7v2.4c-1.5,0.5-3.1,0.8-4.7,0.8 c-3.9,0-5.8-1.4-5.8-5V9h-3.5V6.6z"/>
        <path d="M248.2,6.6h2.8l0.3,2.9c1.1-1.7,2.9-3.2,6-3.2c1,0,1.9,0.1,2.8,0.4v2.8c-1.1-0.3-2.1-0.4-3.2-0.4c-3.3,0-4.9,2-5.6,3.1v10.4 h-3.1V6.6z"/>
        <path d="M277.8,21.2c-1.6,1.1-4,1.8-6.8,1.8c-4,0-7.1-1.6-7.1-4.8c0-3.2,2.8-5,9.8-5c1.2,0,2.6,0.1,3.8,0.1v-0.6 c0-2.6-1.6-4.1-5.3-4.1c-3.2,0-4.9,0.4-6.8,1.2V7.5c1.7-0.8,3.8-1.3,7.1-1.3c4.8,0,8.1,2,8.1,6.2v10.3H278L277.8,21.2z M277.5,19.1 v-3.6h-3.1c-5.3,0-7.4,0.8-7.4,2.6c0,1.7,1.3,2.6,4.5,2.6C274,20.6,276.2,20,277.5,19.1z"/>
        <path d="M287.6,18.9V0h3.1v18.5c0,1.3,0.3,1.9,2,1.9c1,0,2-0.1,3-0.3v2.4c-1.2,0.3-2.5,0.5-3.7,0.5C288.7,23,287.6,21.6,287.6,18.9z"/>
        <path d="M301.1,0h3.1v3.4h-3.1V0z M301.1,6.6h3.1v16h-3.1V6.6z"/>
        <path d="M324.7,21.2c-1.6,1.1-4,1.8-6.8,1.8c-4,0-7.1-1.6-7.1-4.8c0-3.2,2.8-5,9.8-5c1.2,0,2.6,0.1,3.8,0.1v-0.6 c0-2.6-1.6-4.1-5.3-4.1c-3.2,0-4.9,0.4-6.8,1.2V7.5c1.7-0.8,3.8-1.3,7.1-1.3c4.8,0,8.1,2,8.1,6.2v10.3H325L324.7,21.2z M324.5,19.1 v-3.6h-3.1c-5.3,0-7.4,0.8-7.4,2.6c0,1.7,1.3,2.6,4.5,2.6C321,20.6,323.1,20,324.5,19.1L324.5,19.1z"/>
      </svg>
    );
  }

  return null;

}

export default WordMark;
