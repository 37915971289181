import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { gtmPush } from '../../../lib/gtm';
import { PATH_FAQ, PATH_LOGIN } from '../../../constants';

import Button from '../../../audi-ui-components/Button';
import Select from '../../../audi-ui-components/Select';
import CalendarEvents from '../../../audi-ui-components/icons/CalendarEvents';
import Time from '../../../audi-ui-components/icons/Time';
import Geofence from '../../../audi-ui-components/icons/Geofence';
import User from '../../../audi-ui-components/icons/User';

import { getEventPeriodString, getSessionTimeString, getVenueJsx } from '../helpers';

const EventDetailAside = ({
    scrollTo,
    event,
    isLoyaltyEligible,
    isAuthed,
    selectedSessionIdx,
    onSessionSelect,
    location
}) => {

    const {
        name: eventName,
        sessionCount,
        sessions,
        venue,
        price,
        rebate,
        guestPrice,
        canBookMessage,
        pricePrefix,
        hasBooked,
        isOnWaitlist,
        booking,
        waitlist,
        type
    } = event;

    const canBook = sessions[selectedSessionIdx].canBook;
    const canWaitlist = sessions[selectedSessionIdx].canWaitlist;

    const isVirtualEvent = (event.type === 'Virtual');

    const sessionPeriod = event &&
        selectedSessionIdx !== undefined &&
        getEventPeriodString(
            sessions[selectedSessionIdx].eventStartDate,
            sessions[selectedSessionIdx].eventEndDate
        );

    const selectedSessionTime = event &&
        selectedSessionIdx !== undefined &&
        getSessionTimeString(
            sessions[selectedSessionIdx].eventStartDate,
            sessions[selectedSessionIdx].eventEndDate
        );

    const bookingSessionPeriod = event &&
        booking &&
        getEventPeriodString(
            booking.session.eventStartDate,
            booking.session.eventEndDate
        );

    const bookingSessionTime = event &&
        booking &&
        getSessionTimeString(
            booking.session.eventStartDate,
            booking.session.eventEndDate
        );

    const waitlistSessionPeriod = event &&
        waitlist && waitlist.session &&
        getEventPeriodString(
            waitlist.session.eventStartDate,
            waitlist.session.eventEndDate
        );

    const waitlistSessionTime = event &&
        waitlist && waitlist.session &&
        getSessionTimeString(
            waitlist.session.eventStartDate,
            waitlist.session.eventEndDate
        );

    let title;
    if (event) {
        if (event.type.toLowerCase() === 'virtual') {
            title = <>Virtual Event:<br />{eventName}</>;
        } else {
            title = <>{eventName}</>;
        }
    }

    return (
        <>
            {event && <aside className="">
                <h1 className="aui-headline-3 mb-7">
                    <b>{title}</b>
                </h1>
                <div className="my-5">
                    <div className="row mb-4">
                        <div className="col-auto">
                            <CalendarEvents small className="mr-3" />
                        </div>
                        <div className="col">
                            {!hasBooked && !isOnWaitlist && sessionCount > 1 &&
                                <Select
                                    label="Choose date"
                                    name="choose-date"
                                    value={selectedSessionIdx}
                                    options={sessions.map((session, i) => {
                                        let sessionDate = getEventPeriodString(session.eventStartDate, session.eventEndDate);
                                        return { label: sessionDate, value: i };
                                    })}
                                    floatingLabel={true}
                                    onChange={(v, e) => { onSessionSelect(v); }}
                                    className="mb-3"
                                    style={{marginTop: "-1rem"}}
                                />
                            }
                            {!hasBooked && !isOnWaitlist && sessionCount === 1 && <span className="date">{sessionPeriod}</span>}
                            {!hasBooked && isOnWaitlist && <span className="date">{waitlistSessionPeriod}</span>}
                            {hasBooked && <span className="date">{bookingSessionPeriod}</span>}
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col-auto">
                            <Time small className="mr-3" />
                        </div>
                        <div className="col">
                            <span className="time">
                                {hasBooked ? bookingSessionTime : isOnWaitlist ? waitlistSessionTime : selectedSessionTime}
                            </span>
                        </div>
                    </div>
                    {!isVirtualEvent &&
                        <div className="row mb-4">
                            <div className="col-auto">
                                <Geofence small className="mr-3" />
                            </div>
                            <div className="col">
                                {getVenueJsx(venue, 'span', 'location')}
                            </div>
                        </div>
                    }
                    {!hasBooked && !isOnWaitlist && isLoyaltyEligible && canBook && (
                        <div className="row mb-4">
                            <div className="col-auto">
                                <User small className="mr-3 aui-color-text-red" />
                            </div>
                            <div className="col">
                                <span className="aui-color-text-red">
                                    {sessions[selectedSessionIdx].spotsAvailable + ' spaces available'}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
                <hr />
                {!hasBooked && (!isOnWaitlist || canBook) && isLoyaltyEligible && (canBook || canWaitlist) && <>
                    <table className="price table mt-5 mb-6">
                        <tbody>
                            <tr>
                                <td>Original price</td>
                                <td className="aui-headline-4">{pricePrefix || `$`}{price.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Less loyalty rebate</td>
                                <td className="aui-headline-4">-{pricePrefix || `$`}{rebate.toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td></td><td><hr /></td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Price</b>
                                </td>
                                <td className="aui-headline-4">
                                    <b>{pricePrefix || `$`}{(price - rebate).toFixed(2)}</b>
                                </td>
                            </tr>
                            {(typeof guestPrice !== "undefined" && guestPrice > 0 && event.allowsGuest) && <><tr>
                                <td></td><td><hr /></td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Guest ticket</b>
                                </td>
                                <td className="aui-headline-4">
                                    <b>{pricePrefix || `$`}{guestPrice.toFixed(2)}</b>
                                </td>
                            </tr></>}
                        </tbody>
                    </table>
                    {!sessions[selectedSessionIdx].openForRegistration &&
                        <p>{sessions[selectedSessionIdx].canBookMessage}</p>
                    }
                    {canBook &&
                        <Button label="Attend Event"
                            buttonType="primary"
                            isStretched onClick={scrollTo}
                            disabled={sessions[selectedSessionIdx].spotsAvailable < 1}
                        />
                    }
                    {!canBook && canWaitlist &&
                        <Button label="Join Waitlist"
                            buttonType="primary"
                            isStretched onClick={() => { scrollTo(); gtmPush("AudiExperience", "eventJoinWaitlistClick", eventName); }}
                            disabled={sessions[selectedSessionIdx].waitlistSpotsAvailable < 1}
                        />
                    }
                </>}

                {(!isAuthed || (!canBook && !canWaitlist)) && canBookMessage}

                {hasBooked && booking &&
                    <div className="attendees">
                        <p><b>Attendees:</b></p>
                        {booking.attendees.map(guest => <p key={guest.firstName + guest.lastName}>
                            {guest.isGuest ? 'Guest: ' : 'Member: '} {guest.firstName} {guest.lastName}
                        </p>)}
                    </div>
                }

                {hasBooked &&
                    <Button
                        buttonType="primary"
                        label="Modify attendance"
                        onClick={scrollTo}
                        isStretched
                    />
                }

                {!hasBooked && isOnWaitlist && !canBook &&
                    <Button
                        buttonType="primary"
                        label="Modify waitlist"
                        onClick={() => { scrollTo(); gtmPush("AudiExperience", "eventModifyWaitlistClick", eventName); }}
                        isStretched
                    />
                }

                {!isLoyaltyEligible && isAuthed && <Link to={PATH_FAQ + "/audi-experience"} className="aui-button aui-button--primary aui-button--stretched">
                    <span className="aui-button__text">View Eligibility</span>
                </Link>}

                {!isAuthed && <Link to={{ pathname: PATH_LOGIN, state: { from: location, selectedSessionIdx: selectedSessionIdx } }} className="aui-button aui-button--primary aui-button--stretched">
                    <span className="aui-button__text">Login to register</span>
                </Link>}
            </aside>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        event: state.experience.currentEvent,
        isLoading: state.profile.isLoading,
        isAuthed: state.profile.isAuthed
    };
};

export default connect(mapStateToProps)(EventDetailAside);
