import React from 'react';
import {connect} from 'react-redux';
import EditableSection from '../../../components/EditableSection';
import LocationEditSection from '../../Finance/components/LocationEditSection';
import MobileVerificationPopup from '../../Finance/components/MobileVerificationPopup';
import {
  updateVWFSProfile,
  submitVWFSMobileVerification
} from '../../../redux/Profile';
import { defaultSchema } from '../../../lib/validation';

const mapStateToProps = state => {
  return {
    isAuthed: state.profile.isAuthed,
    isLoadingProfile: state.profile.isLoading,
    vwfsProfile: state.profile.vwfsData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateVWFSProfile: (formData, cb, errCb) => {dispatch(updateVWFSProfile(formData, cb, errCb));},
    submitVWFSMobileVerification: (formData, cb, errCb) => {dispatch(submitVWFSMobileVerification(formData, cb, errCb));},
  }
}

class ProfileForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      verifyModal: null,
    }
  }

  handleUpdateFinancePhone = (formData, successCallback, errorCallback) => {
    const {submitVWFSMobileVerification} =  this.props;
    this.props.updateVWFSProfile(
      formData,
      (response) => {
        if (successCallback) { successCallback(response); }
      },
      (error) => {
        if (error.status === 499 || error.status === '499') {
          this.setState({
            verifyModal: <MobileVerificationPopup
              formData={formData}
              verify={submitVWFSMobileVerification}
              onCancel={() => { errorCallback({message: 'Verification was cancelled.'}); }}
              close={() => { this.setState({verifyModal: null}); }}
              successCallback={successCallback}
              errorCallback={errorCallback} />
          });
        } else {
          console.error(error);
          if (errorCallback) { errorCallback(error); }
        }
      }
    );
  }

  render() {
    const {vwfsProfile} = this.props;
    if (!vwfsProfile) {
      return (
        <div className={this.props.className}>
        </div>
      )
    }
    return (
      <div className={this.props.className}>
        <p className="aui-headline-4 mb-5">{vwfsProfile.title} {vwfsProfile.firstName} {vwfsProfile.middleName} {vwfsProfile.lastName}</p>
        <div className="mb-7">
          <EditableSection title="Email"
            isEditable
            loadingBgColour={this.props.loadingBgColour}
            fields={[
              {label: 'Email', name: 'email', v: vwfsProfile.email.email, hideLabel: true}
            ]}
            schema={{
              email: {
                ...defaultSchema.requiredField,
                ...defaultSchema.email
              }
            }}
            onSubmit={this.props.updateVWFSProfile}
            closeOnSuccess
            theme={this.props.theme}
          />
        </div>
        <div className="mb-7">
          <EditableSection title="Phone"
            isEditable
            loadingBgColour={this.props.loadingBgColour}
            fields={[
              {label: 'Mobile phone', name: 'mobilePhone', v: vwfsProfile.contact.mobilePhone},
              {label: 'Home phone', name: 'homePhone', v: vwfsProfile.contact.homePhone},
              {label: 'Business phone', name: 'businessPhone', v: vwfsProfile.contact.businessPhone}
            ]}
            schema={{
              mobilePhone: {
                ...defaultSchema.requiredField,
                ...defaultSchema.mobile
              },
              homePhone: {...defaultSchema.phone},
              businessPhone: {...defaultSchema.phone}
            }}
            onSubmit={this.handleUpdateFinancePhone}
            closeOnSuccess
            theme={this.props.theme}
          />
        </div>
        {vwfsProfile.location && vwfsProfile.location.map(location => (
          <div className="mb-7" key={`${location.identifierId}_${location.identifierType}`}>
            <LocationEditSection
              isEditable
              loadingBgColour={this.props.loadingBgColour}
              location={location}
              onSubmit={this.props.updateVWFSProfile}
              closeOnSuccess
              customerNumber={vwfsProfile.identifierId}
              theme={this.props.theme}
            />
          </div>
        ))}

        {this.state.verifyModal && this.state.verifyModal}

      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
