import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Switch extends React.Component {

  render() {
    const {leftLabel, rightLabel, theme} = this.props;
    let singleLabel = false;
    if (leftLabel && !rightLabel) { singleLabel = leftLabel; }
    if (!leftLabel && rightLabel) { singleLabel = rightLabel; }
    return(
      <label style={this.props.style} className={classnames(
        "aui-switch",
        `${this.props.className}`,
        {
          "aui-switch--text-left": leftLabel && !rightLabel,
          "aui-switch--equal": leftLabel && rightLabel,
          "is-disabled": this.props.disabled,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        <input type="checkbox" name={this.props.name} className="aui-switch__input" disabled={this.props.disabled} checked={this.props.value} onChange={(e) => { this.props.onChange(!this.props.value, e); }} />
        {singleLabel && <span className="aui-switch__text">{singleLabel}</span>}
        {leftLabel && rightLabel && <span className="aui-switch__text aui-switch__text--left">{leftLabel}</span>}
        {leftLabel && rightLabel && <span className="aui-switch__text aui-switch__text--right">{rightLabel}</span>}
        <span className="aui-switch__track" aria-hidden="true">
          <span className="aui-switch__thumb"></span>
        </span>
      </label>
    );
  }

}

Switch.propTypes = {
  value: PropTypes.bool,
  leftLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  rightLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_switch-themes.scss
};

Switch.defaultProps = {
  value: false,
  style: {},
  className: ''
}

export default Switch;
