import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M11.5,16 L11.5,18 M11.5,8 L11.5,14" strokeLinejoin="round"/>
          <path d="M21.5 20.5L1.5 20.5 11.5 2.5z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M23.5,33 L23.5,37 M23.5,18 L23.5,29" strokeLinejoin="round"/>
        <path d="M2.5 42.5L23.5 4.5 44.5 42.5z"/>
      </g>
    </svg>
  );
};
