import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadEvents, loadEventsCarousel, resetFilters } from '../../redux/Experience';
import Masonry from 'react-masonry-component';
import _debounce from 'lodash/debounce';
import _intersection from 'lodash/intersection';
import HeroSection from '../../components/HeroSection';
import Crumb from '../../components/Crumb';
import ExperienceFilter from './components/ExperienceFilter';
import MasonryItem from './components/MasonryItem';
import ListItem from './components/ListItem';
import LoadingOverlay from '../../components/LoadingOverlay';
import Button from '../../audi-ui-components/Button';
import { getEventPeriodString } from './helpers';
import { PATH_MY_BOOKINGS, PATH_REGISTER, BREAKPOINTS, AE_ITEMS_PER_PAGE } from '../../constants';

// const ryiEvent = {
//   id: "ryi",
//   type: "ryi",
//   thumbnail: `${process.env.PUBLIC_PATH}assets/ryi-tile.jpg`,
//   name: "Model Education event with Audi Australia Product Experts",
//   sessions: []
// }

const mapStateToProps = state => {
  return {
    list: state.experience.list,
    listCount: state.experience.listCount,
    listCarousel: state.experience.listCarousel || [],
    showPast: state.experience.showPast,
    isLoading: state.experience.isLoading,
    isGridLayout: state.experience.isGridLayout,
    filterType: state.experience.filterType,
    filterTypeLength: state.experience.filterType.length, // triggers rerender
    filterLocation: state.experience.filterLocation,
    filterInterest: state.experience.filterInterest,
    filterInterestLength: state.experience.filterInterest.length, // triggers rerender
    loyalty: state.profile.data ? (state.profile.data.isLoyaltyMember && state.profile.data.isLoyaltyEligible) : false,
    profileError: state.profile.error,
    isAuthed: state.profile.isAuthed
  };
};
const mapDispatchToProps = dispatch => {
  return {
    loadEvents: (skip) => {
      dispatch(loadEvents(skip));
    },
    loadEventsCarousel: () => {
      dispatch(loadEventsCarousel());
    },
    resetFilters: () => {
      dispatch(resetFilters());
    }
  };
};

class ExperienceHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        windowWidth: 0,
        currentEventsToShow: AE_ITEMS_PER_PAGE
    };
    this._debouncedResize = _debounce(this.handleResize, 500);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadEvents();
    this.props.loadEventsCarousel();
    window.addEventListener("resize", this._debouncedResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._debouncedResize);
  }

  componentDidUpdate(prevProps) {
      if (prevProps.isAuthed !== this.props.isAuthed || prevProps.profileError !== this.props.profileError) {
          this.props.loadEvents();
          this.props.loadEventsCarousel();
      }

      //scroll to new item
      if (prevProps.list) {
          if (prevProps.list.length < this.props.list.length) {
            var item = this.props.list[prevProps.list.length];
            if (this.refs['itemListRef_' + item.id]) {
                var rect = this.refs['itemListRef_' + item.id].getBoundingClientRect();
                var top = rect.top + window.pageYOffset;
                window.scroll(0, top-100);
            }
            if (this.refs['itemGridRef_' + item.id]) {
                var rect = this.refs['itemGridRef_' + item.id].firstElementChild.getBoundingClientRect();
                var top = rect.top + window.pageYOffset;
                window.scroll(0, top - 80);
            }
          }
      }
  }

  handleResize = () => {
    this.setState({windowWidth: window.innerWidth});
  }

  eventMatchesFilters = (event) => {
    const {filterType, filterLocation, filterInterest} = this.props;
    // type
    var typeMatch = filterType.length === 3; // true if all types are selected
    if (!typeMatch) {
      if (filterType.includes("event") && (!event.isPromo && !event.isOffer)) {
        typeMatch = true;
      }
      if (filterType.includes("offer") && (event.isPromo || event.isOffer)) {
        typeMatch = true;
      }
      if (filterType.includes("virtual") && (event.type.toLowerCase() === "virtual")) {
        typeMatch = true;
      }
    }
    if (!typeMatch) {
      return false;
    }
    // location
    var locMatch = filterLocation === "all" || (event.isPromo || event.isOffer) || event.type.toLowerCase() === "virtual";
    if (!locMatch && event.venue) {
      if (filterLocation === "international" && event.venue.isInternational) {
        locMatch = true;
      } else {
        if (filterLocation === event.venue.state) {
          locMatch = true;
        }
      }
    }
    if (!locMatch) {
      return false;
    }
    // interests
    var interestsMatch = true;
    if (event.interests && event.interests.length) {
      interestsMatch = _intersection(event.interests, filterInterest).length > 0;
    }
    if (typeMatch && locMatch && interestsMatch) {
      return true;
    }
    return false;
  }

  getCarouselContent = () => {
    const {listCarousel} = this.props;
    if (listCarousel && listCarousel.length > 0) {
      let slides = [];
      for (let i=0; i<listCarousel.length; i++) {
        if (listCarousel[i].heroImage) {
          let item = listCarousel[i];
          slides.push({
            id: item.id,
            type: 'image',
            url: decodeURIComponent(item.heroImage.url),
            children: (<div className="overlay">
              <div className="px-5 px-small-7">
                <div className="row">
                  <div className="col-medium-1" />
                  <div className="col-medium-4">
                    <p className="aui-headline-6 pt-small-7 mb-3"><small>{getEventPeriodString(item.eventStartDate, item.eventEndDate)}</small></p>
                    <p className="aui-headline-3 headline-margin mb-small-6"><b>{item.name}</b></p>
                  </div>
                </div>
              </div>
            </div>)
          });
        }
      }
      return slides;
    } else {
      // fallback image
      return [{
        id: 'fallback1',
        type: 'image',
        url: `${process.env.PUBLIC_PATH}assets/0O8A6390_websize.jpg`,
      }, {
        id: 'fallback2',
        type: 'image',
        url: `${process.env.PUBLIC_PATH}assets/0O8A6401_websize.jpg`,
      }];
    }
  }

  render() {
    const forceListView = this.state.windowWidth <= BREAKPOINTS.medium;
    const {list, listCount, showPast} = this.props;
    const {currentEventsToShow} = this.state;
    var filteredList = showPast ? [] : [];
    var showLoadMorePast = false;
    var showLoadMoreCurrent = false;
    if (list && list.length) {
      for (let i=0; i<list.length; i++) {
        if (this.eventMatchesFilters(list[i])) {
          filteredList.push(list[i]);
        }
      }
      if (showPast && listCount > list.length) {
        showLoadMorePast = true;
      }
      if (!showPast && filteredList.length > currentEventsToShow) {
        showLoadMoreCurrent = true;
        filteredList = filteredList.slice(0, currentEventsToShow);
      }
    }
    return (
      <div className="experience page-wrapper">

        <HeroSection imgWide gradient content={this.getCarouselContent()}>
          {this.props.listCarousel.length === 0 && <h1 className="aui-headline-3 headline-margin mb-small-6 pt-small-7">Events and Offers</h1>}
        </HeroSection>
        
        <div className="content-wrapper pb-7">
          
          {this.props.isAuthed && <div className="my-bookings-link px-5 py-5 py-xsmall-0">
            <Link to={PATH_MY_BOOKINGS} className="aui-button aui-button--primary">
              <span className="aui-button__text">My Bookings</span>
            </Link>
          </div>}

          <Crumb className="mb-6" />

          {this.props.isAuthed && <div className="mb-6">
            Audi Experience is best when you do just that – experience it. Simply use the filters below to browse what’s on offer and find an Audi Experience that’s perfect for you.
          </div>}

          {!this.props.isAuthed && <div className="mb-6">
            <p className="mb-3">
              Audi Experience delivers access to a whole world of experiences and offers that are as diverse as your interests.
              So, whether it’s sport, or culture or that you’re simply obsessed with food and wine,
              use the filters below to navigate around the ever-changing world that is, Audi Experience.
            </p>
            <p className="mb-6">If you’re <a href="https://cdn01.audiproduction.com.au/cms/media/1843/audi-experience-tcs__v81.pdf" target="_blank" rel="noopener noreferrer">eligible</a>, we invite you to register now.</p>
            <Link to={PATH_REGISTER} className="aui-button aui-button--primary">Register now</Link>

          </div>}

          <div className="position-relative">

            {this.props.isLoading &&
              <LoadingOverlay type={filteredList.length > 0 ? "overlay" : "placeholder"}>Loading Experiences</LoadingOverlay>
            }

            {this.props.list && this.props.list.length &&
              <ExperienceFilter showViewOptions={!forceListView} />
            }

            {filteredList.length > 0 && <>
              {this.props.isGridLayout && !forceListView && (
                <Masonry
                  className="experience-masonry"
                  disableImagesLoaded={true}
                  options={{
                    itemSelector: '.experience-item',
                    columnWidth: '.grid-sizer',
                  }}
                >
                  <div className="grid-sizer"></div>
                  {
                    filteredList.map((event, index) => {
                        return (
                           <div key={`${event.id}_${index}`} ref={'itemGridRef_' + (event.id)} className={'itemGridRef_' + (event.id)}>
                              <MasonryItem {...event} isAuthed={this.props.isAuthed} />
                          </div>
                      );
                  })}
                </Masonry>
              )}

              {(forceListView || !this.props.isGridLayout) && (
                <div className="experience-list">
                  {filteredList.map((event, index) => {
                      return (
                      <div key={event.id} ref={'itemListRef_' + (event.id)} className={'itemListRef_' + (event.id)}>
                          <ListItem {...event} isAuthed={this.props.isAuthed} loyalty={this.props.loyalty} />
                      </div>
                    );
                  })}
                </div>
              )}
            </>}

            {!this.props.isLoading && filteredList.length === 0 && <div className="text-center py-7">
              <p className="mb-7 aui-color-text-red">No experiences found.</p>
              <p className="mb-7">
                <Button buttonType="primary" label="Reset Filters" onClick={this.props.resetFilters} />
              </p>
            </div>}

            {showLoadMoreCurrent &&
              <div className="text-center pt-5">
                  <Button buttonType="primary" label="Load More" onClick={() => { this.setState({currentEventsToShow: currentEventsToShow + AE_ITEMS_PER_PAGE}); }} />
              </div>
            }
            {showLoadMorePast &&
              <div className="text-center pt-5">
                  <Button buttonType="primary" label="Load More" onClick={() => { this.props.loadEvents(list.length); }} />
              </div>
            }

          </div>

        </div>
      </div>
     );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceHome);
