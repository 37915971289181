import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M5.5,21.5 L5.5,14.5 C5.5,13.9 5.9,13.5 6.5,13.5 L17.5,13.5 C18.1,13.5 18.5,13.9 18.5,14.5 L18.5,21.5 L5.5,21.5 Z"/>
          <path d="M10.5,3.5 L13.5,3.5 C14.6,3.5 15.5,4.4 15.5,5.5 L15.5,8.5 C15.5,9.6 14.6,10.5 13.5,10.5 L10.5,10.5 C9.4,10.5 8.5,9.6 8.5,8.5 L8.5,5.5 C8.5,4.4 9.4,3.5 10.5,3.5 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M11.5,40.5 L11.5,27.5 C11.5,25.8 12.8,24.5 14.5,24.5 L33.5,24.5 C35.2,24.5 36.5,25.8 36.5,27.5 L36.5,40.5 L11.5,40.5 Z"/>
        <path d="M20.5,7.5 L27.5,7.5 C29.2,7.5 30.5,8.8 30.5,10.5 L30.5,17.5 C30.5,19.2 29.2,20.5 27.5,20.5 L20.5,20.5 C18.8,20.5 17.5,19.2 17.5,17.5 L17.5,10.5 C17.5,8.8 18.8,7.5 20.5,7.5 Z"/>
      </g>
    </svg>
  );
};
