import React from 'react';
import moment from 'moment';
import Button from '../../../audi-ui-components/Button';
import IconCalendarEvents from '../../../audi-ui-components/icons/CalendarEvents';
import { gtmPush } from '../../../lib/gtm';
import { AUDI_SERVICE_PLAN_TERMS } from '../../../constants';

const ServicePlan = ({ servicePlan, modelName, warrantyStartDate, setActiveModal }) => {

    //work out what plans we have data for and ensure that data is as expected

    const hasPlan = (
        'plan' in servicePlan &&
        'planStatus' in servicePlan &&
        'planStartDate' in servicePlan &&
        'planEndDate' in servicePlan
    );

    const hasTopUpPlan = (
        'topUpPlan' in servicePlan &&
        'topUpPlanType' in servicePlan &&
        'topUpPlanStatus' in servicePlan &&
        'topUpPlanStartDate' in servicePlan &&
        'topUpPlanEndDate' in servicePlan
    );

    const today = moment();

    //check if plans have started/ended

    const planStarted = hasPlan ? today.isAfter(servicePlan.planStartDate) : false;
    const planEnded = hasPlan ? today.isAfter(servicePlan.planEndDate) : false;

    const topUpPlanStarted = hasTopUpPlan ? today.isAfter(servicePlan.topUpPlanStartDate) : false;
    const topUpPlanEnded = hasTopUpPlan ? today.isAfter(servicePlan.topUpPlanEndDate) : false;


    //used to convert from plan name provided in data to name for customer display

    const fixPlanName = (name) => {
        switch (name) {
            case 'Retail Service Plan':
                return ('Audi Genuine Care Service Plan');
                break;
            case 'Free Service Plan':
                return ('Complimentary Service Plan');
                break;
            case 'Corporate Service Plan':
                return ('Corporate Service Plan');
                break;
            case 'Top-up Service Plan':
                return ('Audi Genuine Care Top-up Service Plan');
                break;
            default:
                return name;
        }
    }

    var canPurchasePlan = moment(warrantyStartDate).add(15, "M").isAfter(today);

    return (
        <div className="aui-color-gray90 pl-medium-3 d-flex flex-column" style={{ height: '100%' }}>

            <div className="row align-items-center pt-7">
                <div className="col-12 col-small-2 d-flex justify-content-center justify-content-small-end px-2 py-4">
                    <IconCalendarEvents />
                </div>
                <div className="col-10 offset-1 col-small-8 offset-small-0">
                    <h2 className="aui-headline-3">
                        <b>Audi Service Plan</b>
                    </h2>
                </div>
            </div>

            <div className="row pt-3 pb-7">
                <div className="col-1 col-small-2"></div>
                <div className="col-10 col-small-8">

                    {(!hasPlan && !hasTopUpPlan && canPurchasePlan) && <>
                        <p className="mb-3">Your {modelName} is not currently covered by a service plan and you may be eligible to purchase one.</p>
                        <p>
                            <Button label="Click here to find out more" buttonType="primary" onClick={() => { setActiveModal('buy-service-plan'); gtmPush("myAudi", "buyServicePlan", "vehicle"); }} />
                        </p>
                    </>}

                    {(!hasPlan && !hasTopUpPlan && !canPurchasePlan) && <>
                        <p>
                            This car is no longer eligible for a service plan.
                        </p>
                    </>}

                    {hasPlan && <>
                        <p className="mb-5">This plan covers all scheduled servicing to ensure your Audi performs at its peak.</p>
                        <p className="aui-headline-5">Service Plan Type</p>
                        <p className="mb-3">{fixPlanName(servicePlan.plan)}</p>
                        {servicePlan.planType && <>
                            <p className="aui-headline-5">Duration</p>
                            <p className="mb-3">{servicePlan.planType} (whichever comes first)</p>
                        </>}
                        {!planEnded && !planStarted &&
                            <p>
                                Your plan starts on {moment(servicePlan.planStartDate).format('MMM Do YYYY')} and will end on {moment(servicePlan.planEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                        {!planEnded && planStarted &&
                            <p>
                                Your plan started on {moment(servicePlan.planStartDate).format('MMM Do YYYY')} and will end on {moment(servicePlan.planEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                        {planEnded &&
                            <p className="aui-color-text-red">
                                Your plan ended on {moment(servicePlan.planEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                        {!hasTopUpPlan && <>
                            <p className="mt-5 mb-3">
                                You may be eligible to purchase a Top-up Service Plan
                                {servicePlan.planType.indexOf("3Years") > -1 && <>{` `}which will increase your current 3 Year Service Plan to a 5 Year Service Plan</>}.
                            </p>
                            <p><Button label="Click here to find out more" buttonType="primary" onClick={() => { setActiveModal('buy-service-plan'); gtmPush("myAudi", "buyServicePlan", "vehicle"); }} /></p>
                        </>}
                    </>}

                    {hasTopUpPlan && <>
                        <hr className="aui-color-gray80 my-5" />
                        <p className="aui-headline-5 mt-5">Service Plan Type</p>
                        <p className="mb-3">{fixPlanName(servicePlan.topUpPlan)}</p>
                        {servicePlan.topUpPlanType && <>
                            <p className="aui-headline-5">Duration</p>
                            <p className="mb-3">{servicePlan.topUpPlanType} (whichever comes first)</p>
                        </>}
                        {!topUpPlanEnded && !topUpPlanStarted &&
                            <p>
                                Your plan starts on {moment(servicePlan.topUpPlanStartDate).format('MMM Do YYYY')} and will end on {moment(servicePlan.topUpPlanEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                        {!topUpPlanEnded && topUpPlanStarted &&
                            <p>
                                Your plan started on {moment(servicePlan.topUpPlanStartDate).format('MMM Do YYYY')} and will end on {moment(servicePlan.topUpPlanEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                        {topUpPlanEnded &&
                            <p className="aui-color-text-red">
                                Your plan ended on {moment(servicePlan.topUpPlanEndDate).format('MMM Do YYYY')}.
                            </p>
                        }
                    </>}

                    {((hasPlan && !planEnded) || (hasTopUpPlan && !topUpPlanEnded) || canPurchasePlan) && <>
                        <p className="disclaimer pt-7">
                            Disclaimer: Audi Service Interval is every 15,000km or 12 Months (whichever occurs first) from the Warranty Start Date.
                        </p>
                        <p>
                            <Button
                                label="Terms &amp; Conditions"
                                buttonType="text"
                                href={AUDI_SERVICE_PLAN_TERMS}
                                target="_blank" rel="noopener noreferrer"
                                className="disclaimer"
                            />
                        </p>
                    </>}

                </div>
            </div>

        </div>
    );
};

export default ServicePlan;
