import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {connect} from 'react-redux';

import Contract from './Contract';
import ContractList from './ContractList';
import FinanceRegister from './FinanceRegister';
import FinanceRequestRegNum from './FinanceRequestRegNum';

import {
  PATH_FINANCE
} from '../../constants';

const mapStateToProps = state => {
  return {
    isLoadingProfile: state.profile.loading,
    vwfsId: state.profile.data.vwfsId || null
  };
}

class Finance extends React.Component {
  render() {
    const {vwfsId, isLoadingProfile} = this.props;
    const isRegistered = vwfsId || isLoadingProfile;
    return (
      <Switch>
        <Route exact path={PATH_FINANCE}>{isRegistered ? <ContractList /> : <Redirect to={`${PATH_FINANCE}/register`} />}</Route>
        <Route path={`${PATH_FINANCE}/register`} render={(routeProps) => isRegistered ? <Redirect to={PATH_FINANCE} /> : <FinanceRegister {...routeProps} />} />
        <Route path={`${PATH_FINANCE}/request-registration-code`} render={(routeProps) => isRegistered ? <Redirect to={PATH_FINANCE} /> : <FinanceRequestRegNum {...routeProps} />} />
        <Route path={`${PATH_FINANCE}/:contractId/:activeTab?`} render={(routeProps) => isRegistered ? <Contract {...routeProps} /> : <Redirect to={`${PATH_FINANCE}/register`} />} />
      </Switch>
    );
  }
}

export default connect(mapStateToProps)(Finance);
