import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Tooltip from './Tooltip';
import LoadingOverlay from './LoadingOverlay';
import { Formik, Form } from 'formik';
import { validate } from '../lib/validation';
import IconEdit from '../audi-ui-components/icons/Edit';
import IconCancel from '../audi-ui-components/icons/Cancel';
import Button from '../audi-ui-components/Button';
import FormField from './FormField';

class EditableSection extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    subheading: PropTypes.bool,
    isEditable: PropTypes.bool,
    fields: PropTypes.array,
    hiddenFields: PropTypes.object,
    schema: PropTypes.object, // validation
    className: PropTypes.string,
    style: PropTypes.object,
    validation: PropTypes.func,
    onSubmit: PropTypes.func, // should take form data, success callback, error callback
    closeOnSuccess: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    isEditable: false,
    fields: [],
    hiddenFields: {},
    className: '',
    style: {},
    subheading: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isLoading: false,
      errorMessage: null,
    };
  }

  handleToggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleSubmit = (data, formikBag) => {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ isLoading: true });
      var _data = Object.assign({}, data, this.props.hiddenFields);
      this.props.onSubmit(
        _data,
        response => {
          this.setState({ isLoading: false, errorMessage: null });
          if (this.props.closeOnSuccess) {
            this.handleToggleEdit();
          }
        },
        error => {
          if (error.body && error.body.modelState) {
            formikBag.setErrors(error.body.modelState);
          }
          this.setState({ isLoading: false, errorMessage: error.message });
        }
      );
    }
  };

  validateForm = (values) => {
    if (this.props.schema) {
      return validate(values, this.props.schema, {format: "firstError", fullMessages: false});
    }
    return {};
  }

  render() {
    const {
      title,
      isEditable,
      fields,
      className,
      style,
      subheading,
      theme
    } = this.props;
    const { isEditing, errorMessage } = this.state;
    var initialValues = {};
    if (isEditable) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].name && fields[i].v) {
          initialValues[fields[i].name] = fields[i].v;
        }
      }
    }
    return (
      <div
        className={classnames('editable', className, {
          editing: isEditing,
          'not-editing': !isEditing,
        })}
        style={style}>
        <header className="d-flex justify-content-between">
          <h2
            className={classnames(
              { 'aui-headline-3': !subheading, 'aui-headline-4': subheading },
              'title'
            )}>
            <b>
              {title ? (
                title
              ) : (
                <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />
              )}
            </b>
          </h2>
          {isEditable && !isEditing && (
            <button
              className={classnames("aui-button edit-btn edit", {[`aui-theme-${theme}`]: theme})}
              onClick={this.handleToggleEdit}
            >
              <IconEdit small /> <span className="edit-btn__text">Edit</span>
            </button>
          )}
          {isEditable && isEditing && (
            <button className={classnames("aui-button edit-btn cancel", {[`aui-theme-${theme}`]: theme})} onClick={this.handleToggleEdit}>
              <IconCancel small /> <span className="edit-btn__text">Cancel</span>
            </button>
          )}
        </header>

        {isEditing && (
          <div className="fields editing" style={{ position: 'relative' }}>
            {this.state.isLoading && <LoadingOverlay bgColour={this.props.loadingBgColour} />}
            <Formik
              validate={this.validateForm}
              onSubmit={this.handleSubmit}
              initialValues={initialValues}>
              {formikBag => (
                <Form>
                  {fields.map((field, index) => {
                    if (field.subtitle) {
                      return (
                        <h6 key={index} className="aui-headline-6 subtitle">
                          {field.subtitle}
                        </h6>
                      );
                    } else if (field.helptext) {
                      return (
                        <div key={index} className="field helptext">
                          {field.helptext}
                        </div>
                      );
                    } else if (field.name && field.name !== null) {
                      return (
                        <FormField
                          key={field.name}
                          {...field}
                          formikBag={formikBag}
                          theme={this.props.theme || null}
                        />
                      );
                    }
                    return null;
                  })}
                  <p className="submit">
                    <Button
                      label="Save changes"
                      buttonType="primary"
                      type="submit"
                      theme={this.props.theme || null}
                    />
                  </p>
                  {errorMessage && (
                    <p className="finance__error">{errorMessage}</p>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        )}

        {!isEditing && (
          <div className="fields not-editing">
            {fields.map((field, index) => {
              if (field.subtitle) {
                return (
                  <h6 key={index} className="aui-headline-6 subtitle">
                    {field.subtitle}
                  </h6>
                );
              } else if (field.name && field.name !== null) {
                let val =
                  typeof field.formatDisplay === 'function'
                    ? field.formatDisplay(field.v)
                    : field.v;
                return (
                  <div
                    key={field.name}
                    className={classnames('field', {
                      'hide-label': field.hideLabel,
                    })}>
                    <label>
                      {field.label} {field.tooltip && <Tooltip tip={field.tooltip} />}
                    </label>

                    <span className="value">{val}</span>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    );
  }
}

export default EditableSection;
