import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { PATH_EXPERIENCE, PATH_VEHICLES, PATH_FINANCE } from '../constants';

//I display a simple breadcrumb based on the current path
//I include special handling for various quirks of this system.
const Crumb = ({ vehicleByVin, currentEventName, className, links }) => {

    if (!links) {
        var path = useLocation().pathname;
        if (path.charAt(0) === "/") {
            path = path.substring(1);
        }
        var pathSplit = path.split("/");

        if (!pathSplit || !pathSplit.length) {
            return null;
        }

        links = [];

        if (pathSplit[0] === PATH_EXPERIENCE.substring(1)) {
            if (currentEventName) {
                links.push({to: PATH_EXPERIENCE, label: "Audi Experience"});
                links.push({label: currentEventName});
            } else {
                links.push({label: "Audi Experience"});
            }
        }

        else if (pathSplit[0] === PATH_VEHICLES.substring(1)) {
            const vins = vehicleByVin ? Object.keys(vehicleByVin) : [];
            if (pathSplit.length > 1 && vins.length > 4) {
                links.push({to: PATH_VEHICLES, label: "Vehicles"});
            } else {
                links.push({label: "Vehicles"});
            }
            if (pathSplit.length > 1) {
                if (pathSplit[1] === "register") {
                    links.push({label: "Add a vehicle"});
                } else if (vins.indexOf(pathSplit[1]) > -1) {
                    let vehicle = vehicleByVin[pathSplit[1]];
                    links.push({label: `${vehicle.modelYear} ${vehicle.modelNameSD}`});
                }
            }
        }

        else if (pathSplit[0] === PATH_FINANCE.substring(1)) {
            if (pathSplit.length === 1 || pathSplit[1] === "register" || pathSplit[1] === "request-registration-code") {
                links.push({label: "Finance"});
                if (pathSplit[1] === "register") {
                    links.push({label: "Register"});
                }
                else if (pathSplit[1] === "request-registration-code") {
                    links.push({label: "Request a new registration code"});
                }
            } else {
                // contract page
                links.push({label: "Finance", to: PATH_FINANCE});
                links.push({label: pathSplit[1]});
            }
        }

        else {
            for (let i = 0; i < pathSplit.length; i++) {
                var label = pathSplit[i].replace(/-/g, ' ');
                var link = {label: label};
                if (i < pathSplit.length -1) {
                    var path = "/";
                    for (let j = 1; j <= i; j++) {
                        path += (pathSplit[j] + '/');
                    }
                    link.to = path;
                }
                links.push(link);
            }
        }
    }

    return (
        <div className={classnames("breadcrumbs", className)}>
            <Link to="/" className="crumb">Home</Link>
            {links.map((item, i) => {
                if (item.to) {
                    return (<Link key={`crumb${i}`} to={item.to} className="crumb">{item.label}</Link>);
                }
                return (<span key={`crumb${i}`} className="crumb">{item.label}</span>);
            })}
        </div>
    );
};

const mapStateToProps = state => {
    return {
        vehicleByVin: state.vehicles.vehicleByVin,
        currentEventName: state.experience.currentEvent ? state.experience.currentEvent.name : ""
    };
};

export default connect(mapStateToProps)(Crumb);
