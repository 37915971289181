import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import { validate } from '../../../lib/validation';
import { loginSchema } from '../schema';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';

import {
  PATH_LOGIN,
  PATH_FORGOT_PASS
} from '../../../constants';

class RequestConfirmationEmailForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      isSubmitting: false,
      submitStatus: null,
      serverError: false
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true, submitStatus: null, serverError: false});
    request(
      `${process.env.RAZZLE_API}/1/account/email/request`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then((response) => {
      this.setState({step: 2, submitStatus: 200, isSubmitting: false});
      gtmPush("myAudi", "emailConfirmationRequested", values.email);
    }).catch((error) => {
      console.log(error);
      if (error.status === 403) {
        // email already confirmed
        this.setState({step: 2, submitStatus: error.status, isSubmitting: false});
      } else {
        // some other error
        let newState = {submitStatus: error.status, isSubmitting: false};
        if (error.body && error.body.modelState) {
          formikBag.setErrors(error.body.modelState);
        } else if (error.body && error.body.message) {
          newState.serverError = error.body.message;
        }
        this.setState(newState);
      }
    });
  }

  validateForm = (values) => {
    var schema = {
      email: loginSchema['email']
    };
    return validate(values, schema, {format: "firstError", fullMessages: false})
  }

  render() {
    const { step, submitStatus } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues || {}}>
        {(formikBag) => (
          <Form>

            {this.state.isSubmitting && <LoadingOverlay />}

            {step === 1 && <>
              <p className="aui-headline-4 mb-3"><b>Request email confirmation</b></p>
              <FormField name="email" label="Email" onChange={this.props.onChangeEmail} formikBag={formikBag} />
              <Button label="Submit" type="submit" buttonType="primary" />
              {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
            </>}

            {step === 2 && submitStatus === 200 && <>
              <p className="aui-headline-4 mb-3"><b>Your request has been sent</b></p>
              <p>
                We've sent an email to {formikBag.values.email}.
                Please check your inbox and follow the instructions to complete your registration.
              </p>
            </>}

            {step === 2 && submitStatus === 403 && <>
              <p className="aui-headline-4 mb-3"><b>Your email has already been confimed</b></p>
              <p className="mb-3">You have previously confirmed your email address</p>
              <p className="mb-5">
                <Button label="Login" buttonType="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }} />
              </p>
              <p className="mb-3">Have you forgotten your password?</p>
              <p>
                <Button label="Reset your password" buttonType="secondary" onClick={() => { this.props.goTo(PATH_FORGOT_PASS, 1); }} />
              </p>
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

export default RequestConfirmationEmailForm;
