import React from 'react';
import EditableSection from '../../../components/EditableSection';
import Button from '../../../audi-ui-components/Button';
import Modal from '../../../audi-ui-components/Modal';
import IconDocuments from '../../../audi-ui-components/icons/Documents';
import IconDocumentPricelistDollar from '../../../audi-ui-components/icons/DocumentPricelistDollar';
import { gtmPush } from '../../../lib/gtm';
import {
  formatDollar,
  formatDate,
  formatRegoNumber,
  formatDayOfMonth,
} from '../helpers';

class TabDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bpayModal: false,
    };
  }

  render() {
    const { contract, totalPaid } = this.props;
    const isContractLoaded = contract.hasOwnProperty('startDate');
    var summaryFields = [];
    if (isContractLoaded) {
      summaryFields = [
        {
          label: 'Total paid',
          name: 'totalPaid',
          v: totalPaid,
          formatDisplay: formatDollar,
        },
        {
          label: 'Balance',
          name: 'currentBalance',
          v: contract.currentBalance,
          formatDisplay: formatDollar,
          tooltip: <span>Note: This balance is not your payout amount.</span>,
        },
        {
          label: 'Start date',
          name: 'startDate',
          v: contract.startDate,
          formatDisplay: formatDate,
        },
        {
          label: 'Contract maturity date',
          name: 'maturityDate',
          v: contract.maturityDate,
          formatDisplay: formatDate,
        },
        {
          label: 'Balloon value',
          name: 'residualAmount',
          v: contract.residualAmount,
          formatDisplay: formatDollar,
          tooltip: 'This is the final amount due at the end of your term as agreed in your Audi Choice contract.',
        },
        {
          label: 'Status',
          name: 'contractStatusDesc',
          v: contract.contractStatusDesc,
        },
        {
          label: 'Payment method',
          name: 'paymentMethod',
          v: contract.payments && contract.payments.paymentMethod ? contract.payments.paymentMethod : null,
        },
      ];
      if (contract.isGfv === 'Y') {
        summaryFields.splice(6, 0, {
          label: 'Guaranteed Future Value',
          name: 'gfv',
          v: contract.residualAmount,
          formatDisplay: formatDollar,
          tooltip: 'The guaranteed minimum amount your Audi will be worth at the end of your Choice contract.',
        });
        summaryFields.splice(7, 0, {
          label: 'KM limit per year',
          name: 'gfvDistancePerYear',
          v: contract.assets && contract.assets.length ? contract.assets[0].gfvDistancePerYear : null,
          tooltip: 'The maximum anual KM allowance you agreed upon when you signed your contract. Every KM over the allowance is charged at 0.66 cents per KM',
        });
      }
    }
    return (
      <article className="finance__tab-dashboard aui-color-gray90 pt-6">
        <div className="container-fluid px-small-0">
          <div className="row">
            <div className="col-medium-6">
              <div className="row">
                <div className="col-xsmall-2 py-0 py-xsmall-7 pr-4 text-center text-xsmall-right">
                  <IconDocuments />
                </div>
                <div className="col-12 col-xsmall-8 px-6 px-xsmall-0">
                  <div className="summary py-7 mb-7">
                    <EditableSection title="Summary" fields={summaryFields} />
                  </div>

                  <div className="mb-7">
                    <EditableSection
                      title="Registration number"
                      isEditable={this.props.userRole === 'P'}
                      loadingBgColour="gray90"
                      closeOnSuccess
                      fields={[
                        {
                          name: 'registrationNumber',
                          v: contract.assets && contract.assets.length
                            ? contract.assets[0].registrationNumber
                            : '',
                          hideLabel: true,
                          formatValue: formatRegoNumber,
                        },
                        {
                          helptext: 'Updating the registration number will take up to 24 hours to process.',
                        },
                      ]}
                      schema={{
                        registrationNumber: {
                          presence: {
                            message: "Please enter your registration number",
                            allowEmpty: false
                          }
                        }
                      }}
                      onSubmit={this.props.updateContractData}
                    />
                  </div>
                </div>
                <div className="col-xsmall-2"></div>
              </div>
            </div>
            <div className="col-medium-6 pt-6 p-xsmall-0">
              <div className="row">
                <div className="col-xsmall-2 py-0 py-xsmall-7 pr-4 text-center text-xsmall-right">
                  <IconDocumentPricelistDollar />
                </div>
                <div className="col-12 col-xsmall-8 px-6 px-xsmall-0 py-7 ">
                  <div className="mb-7">
                    <EditableSection
                      title="Repayments"
                      fields={[
                        {
                          label: 'Next repayment',
                          name: 'nextPaymentAmount',
                          v: contract.payments && contract.payments.nextPaymentAmount
                            ? contract.payments.nextPaymentAmount
                            : null,
                          formatDisplay: formatDollar,
                        },
                        {
                          label: 'Date due',
                          name: 'nextPaymentDate',
                          v: contract.payments && contract.payments.nextPaymentDate
                            ? formatDate(contract.payments.nextPaymentDate)
                            : null,
                        },
                        {
                          label: 'Payment date',
                          name: 'paymentDay',
                          v: contract.payments && contract.payments.paymentDay
                            ? contract.payments.paymentDay
                            : null,
                          formatDisplay: formatDayOfMonth,
                        },
                      ]}
                    />
                  </div>

                  {contract.payments && contract.payments.paymentMethod &&
                    contract.payments.paymentMethod.toLowerCase() === 'direct debit' && (
                      <div className="mb-7">
                        <EditableSection
                          title="Direct debit account"
                          subheading
                          isEditable={this.props.userRole === 'P'}
                          loadingBgColour="gray90"
                          fields={[
                            {
                              label: 'BSB',
                              name: 'bsb',
                              v: contract.directdebit && contract.directdebit.bsb
                                ? contract.directdebit.bsb
                                : '',
                            },
                            {
                              label: 'Account number',
                              name: 'accountNumber',
                              v: '',
                              formatDisplay: () => {
                                return contract.directdebit && contract.directdebit.accountNumber
                                  ? contract.directdebit.accountNumber
                                  : '';
                              },
                            },
                          ]}
                          schema={{
                            bsb: {
                              presence: {
                                message: "Please enter your BSB",
                                allowEmpty: false
                              }
                            },
                            accountNumber: {
                              presence: {
                                message: "Please enter your account number",
                                allowEmpty: false
                              }
                            }
                          }}
                          onSubmit={this.props.updateContractData}
                          closeOnSuccess
                        />
                      </div>
                    )}

                  {contract.bpay && (
                    <div className="mb-7">
                      <h5 className="aui-headline-4 mb-5">
                        <b>Make a payment</b>
                      </h5>
                      <p className="mb-3">
                        Please select 'Make a payment' to obtain the Biller Code
                        and Reference Number for a BPAY payment.
                      </p>
                      <p className="mb-3">
                        Once your payment has been registered by our system,
                        your account details will be updated and shown in your
                        myAudi Australia account in the 'Finance' tab.
                      </p>
                      <p className="mb-3">
                        <small>
                          If you'd like to change your patment method to BPay
                          please call customer service
                        </small>
                      </p>
                      {contract.isGfv === 'Y' && (
                        <p className="aui-color-text-red mb-3">
                          <b>Important note:</b>
                          <br />
                          Please note that if you make additional payments which
                          reduce your Guaranteed Future Value (GFV) amount at
                          the end of the term,  your GFV return option will be
                          void.
                        </p>
                      )}
                      <p>
                        <Button
                          label="Make a payment"
                          buttonType="primary"
                          onClick={() => {
                            this.setState({ bpayModal: true });
                            gtmPush("myAudiFinance", "makeAPaymentClick", "finance");
                          }}
                        />
                      </p>
                    </div>
                  )}

                  <p className="mb-7 px-3 px-small-0">
                    If you{`'`}d like to change your payment method{` `}
                    {contract.payments && contract.payments.paymentMethod && contract.payments.paymentMethod.toLowerCase() === 'direct debit' && <span>to BPay </span>}
                    {contract.payments && contract.payments.paymentMethod && contract.payments.paymentMethod.toLowerCase() === 'bpay' && <span>to direct debit </span>}
                    please call customer service.
                  </p>
                </div>
                <div className="col-xsmall-2"></div>
              </div>
            </div>
          </div>
        </div>

        <Modal
          isActive={this.state.bpayModal}
          closeButton
          close={() => {
            this.setState({ bpayModal: false });
          }}
          modalStyle="layer">
          <h4 className="aui-headline-4 mb-5">Make a payment by BPAY</h4>
          <p className="mb-3" style={{ fontSize: '110%', lineHeight: '2em' }}>
            Biller code: <b>{contract.bpay && contract.bpay.billerCode}</b>
            <br />
            Ref: <b>{contract.bpay && contract.bpay.customerRef}</b>
          </p>
          <p className="mb-3">
            Selecting BPAY incurs a $4.50 fee. Contact your bank, credit union
            or building society to make your payment from your account.
          </p>
          <p className="mb-3">
            Please contact Audi Financial Services Customer Service on 1300 734
            567 for direct debit options.
          </p>
        </Modal>
      </article>
    );
  }
}

export default TabDashboard;
