import React from 'react';
import _find from 'lodash/find';
import { PATH_EVENT } from '../../constants';
import { withRouter } from 'react-router';
import LoadingOverlay from '../../components/LoadingOverlay';

import { connect } from 'react-redux';
import { loadEventByWaitlistId } from '../../redux/Experience';
const mapStateToProps = state => {
    return {
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEventByWaitlistId: (id, cb, errCb) => { dispatch(loadEventByWaitlistId(id, cb, errCb)); }
    };
};

class Waitlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    componentDidMount() {
        // console.log("componentDidMount");
        const {match} = this.props;
        this.props.loadEventByWaitlistId(
            match.params.waitlistNumber,
            (eventId) => {
                this.props.history.push(`${PATH_EVENT}/${eventId}?utm_source=crm&utm_medium=sms&utm_campaign=AE-Waitlist`);
            },
            (error) => {
                this.setState({error: "Tickets for this event are no longer available."});
            }
        );
    }

    render() {
        return (
            <div className="page-wrapper">
              <div className="content-wrapper">
                {!this.state.error && <LoadingOverlay type="placeholder">Loading event</LoadingOverlay>}
                {this.state.error && <div className="py-7 aui-color-text-red text-center">{this.state.error}</div>}
              </div>
            </div>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Waitlist));
