import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M18.5,18.5 L23.5,18.5 L23.5,23.5 L18.5,23.5 L18.5,18.5 Z M9.5,18.5 L14.5,18.5 L14.5,23.5 L9.5,23.5 L9.5,18.5 Z M0.5,18.5 L5.5,18.5 L5.5,23.5 L0.5,23.5 L0.5,18.5 Z M18.5,9.5 L23.5,9.5 L23.5,14.5 L18.5,14.5 L18.5,9.5 Z M9.5,9.5 L14.5,9.5 L14.5,14.5 L9.5,14.5 L9.5,9.5 Z M0.5,9.5 L5.5,9.5 L5.5,14.5 L0.5,14.5 L0.5,9.5 Z M18.5,0.5 L23.5,0.5 L23.5,5.5 L18.5,5.5 L18.5,0.5 Z M9.5,0.5 L14.5,0.5 L14.5,5.5 L9.5,5.5 L9.5,0.5 Z M0.5,0.5 L5.5,0.5 L5.5,5.5 L0.5,5.5 L0.5,0.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M3.5,3.5 L12.5,3.5 L12.5,12.5 L3.5,12.5 L3.5,3.5 Z M19.5,3.5 L28.5,3.5 L28.5,12.5 L19.5,12.5 L19.5,3.5 Z M35.5,3.5 L44.5,3.5 L44.5,12.5 L35.5,12.5 L35.5,3.5 Z M3.5,19.5 L12.5,19.5 L12.5,28.5 L3.5,28.5 L3.5,19.5 Z M19.5,19.5 L28.5,19.5 L28.5,28.5 L19.5,28.5 L19.5,19.5 Z M35.5,19.5 L44.5,19.5 L44.5,28.5 L35.5,28.5 L35.5,19.5 Z M3.5,35.5 L12.5,35.5 L12.5,44.5 L3.5,44.5 L3.5,35.5 Z M19.5,35.5 L28.5,35.5 L28.5,44.5 L19.5,44.5 L19.5,35.5 Z M35.5,35.5 L44.5,35.5 L44.5,44.5 L35.5,44.5 L35.5,35.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
  );
};
