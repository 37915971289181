import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M14.8,10.5 C15.6,8.2 18.2,6.9 20.5,7.8 C22.3,8.4 23.5,10.1 23.5,12 C23.5,14.5 21.5,16.5 19,16.5 C17.1,16.5 15.4,15.3 14.8,13.5 L13.5,13.5 L11.6,14.6 L9.7,13.3 L7.7,14.6 L5.6,13.3 L3.7,14.6 L2.8,14.6 L0.8,12.5 L2.9,10.6 L14.8,10.6 L14.8,10.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="40.5" cy="23.5" r="1"/>
        <path d="M27.1,26.5 C28.8,31.8 34.4,34.7 39.6,33 C44.9,31.3 47.8,25.7 46.1,20.5 C44.4,15.2 38.8,12.3 33.6,14 C30.5,15 28.1,17.4 27.1,20.5 L4.6,20.5 L0.6,24.5 L4.3,28.5 L6.4,28.5 L9.5,26.5 L13.6,28.5 L17.6,26.5 L22.1,28.5 L25.7,26.5 L27.1,26.5 Z"/>
      </g>
    </svg>
  );
};
