import React from 'react';
import classnames from 'classnames';
import Button from '../../../../audi-ui-components/Button';
import CalendarEvents from '../../../../audi-ui-components/icons/CalendarEvents';
import { getVenueString } from '../../helpers';
import isMobile from '../../../../lib/isMobile';
import { gtmPush } from '../../../../lib/gtm';
import moment from 'moment';

// functionality copied from https://github.com/jasonsalzman/react-add-to-calendar/blob/master/src/
// uses html from audi-ui-components/NavDropdown (mostly)

const calculateDuration = (start, end) => {
  let difference = moment(end).diff(moment(start));
  let duration = moment.duration(difference);
  return (
    Math.floor(duration.asHours()) + moment.utc(difference).format(":mm")
  );
}

class AddToCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isActive: false,
      isMobile: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
    this.setState({isMobile: isMobile()});
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if (this._element && !this._element.contains(e.target)) {
      this.setState({isActive: false});
    }
  }

  // build the url
  buildUrl = (type) => {
    const {event, selectedSession} = this.props;
    let calendarUrl = "";
    let venueString = getVenueString(event.venue);
    let start = moment(selectedSession.eventStartDate).format('YYYYMMDDTHHmmss');
    let end = moment(selectedSession.eventEndDate).format('YYYYMMDDTHHmmss');

    switch (type) {
      case "google":
        calendarUrl = "https://calendar.google.com/calendar/render";
        calendarUrl += "?action=TEMPLATE";
        calendarUrl += "&dates=" + start;
        calendarUrl += "/" + end;
        calendarUrl += "&location=" + encodeURIComponent(venueString);
        calendarUrl += "&text=" + encodeURIComponent(event.name);
        // calendarUrl += "&details=" + encodeURIComponent(event.description);
        break;

      case "yahoo":
        // yahoo doesn't utilize endTime so we need to calulate duration
        let duration = calculateDuration(selectedSession.eventStartDate, selectedSession.eventEndDate);
        calendarUrl = "https://calendar.yahoo.com/?v=60&view=d&type=20";
        calendarUrl += "&title=" + encodeURIComponent(event.name);
        calendarUrl += "&st=" + start;
        calendarUrl += "&dur=" + duration;
        // calendarUrl += "&desc=" + "";
        calendarUrl += "&in_loc=" + encodeURIComponent(venueString);
        break;

      case "outlookcom":
        calendarUrl = "https://outlook.live.com/owa/?rru=addevent";
        calendarUrl += "&startdt=" + start;
        calendarUrl += "&enddt=" + end;
        calendarUrl += "&subject=" + encodeURIComponent(event.name);
        calendarUrl += "&location=" + encodeURIComponent(venueString);
        // calendarUrl += "&body=" + encodeURIComponent(event.description);
        calendarUrl += "&allday=false";
        calendarUrl += "&uid=" + event.id;
        calendarUrl += "&path=/calendar/view/Month";
        break;

      default:
        calendarUrl = [
          "BEGIN:VCALENDAR",
          "VERSION:2.0",
          "BEGIN:VEVENT",
          "URL:" + document.URL,
          'METHOD:PUBLISH',
          "DTSTART:" + start,
          "DTEND:" + end,
          "SUMMARY:" + event.name,
          "DESCRIPTION:",
          "LOCATION:" + venueString,
          "END:VEVENT",
          "END:VCALENDAR"
        ].join("\n");

        if (this.state.isMobile) {
          calendarUrl = encodeURI(
            "data:text/calendar;charset=utf8," + calendarUrl
          );
        }
    }

    return calendarUrl;
  }

  onActionClick = (action, actionLabel) => {
    let url = this.buildUrl(action);
    gtmPush("AudiExperience", "eventAddToCalendar", actionLabel);
    // console.log(url);
    this.setState({isActive: false});
    if (
      !this.state.isMobile &&
      (url.indexOf("data") === 0 || url.indexOf("BEGIN") === 0)
    ) {
      let filename = "audi_experience.ics";
      let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });
      // many browsers do not properly support downloading data URIs
      // (even with "download" attribute in use) so this solution
      // ensures the event will download cross-browser
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(url, "_blank");
    }
  }

  render() {
    const {event, theme} = this.props;
    const {isActive} = this.state;
    const actions = [
      {label: "Google", type: "google"},
      {label: "Yahoo", type: "yahoo"},
      {label: "Outlook.com", type: "outlookcom"},
      {label: "all other calendars", type: null}
    ];
    return(
      <div ref={(el) => this._element = el} style={{display: "inline-block"}} className={classnames("aui-nav aui-nav--dropdown aui-nav--button", `${this.props.className}`, {[`aui-theme-${theme}`]: theme, 'is-active': isActive})}>
        <div className="aui-nav__panel">
          <ul className="aui-nav__items">
            {actions.map((action, index) => {
              let _style = {transitionDelay: `${.05 * index}s`, WebkitTransitionDelay: `${.05 * index}s`};
              return (
                <li key={`ni_${index}`} className="aui-nav__item" style={_style}>
                  <a className="aui-nav__action" onClick={(e) => { this.onActionClick(action.type, action.label); e.preventDefault(); }}>
                    {action.label}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <Button label="Add to calendar" icon={<CalendarEvents small />} buttonType="primary" theme={this.props.theme} onClick={() => { this.setState({isActive: !isActive}); }} />
      </div>
    );
  }
};

export default AddToCalendar;
