import React from 'react';
import PropTypes from 'prop-types';
import { obfuscatePhone } from '../helpers';
import Button from '../../../audi-ui-components/Button';
import Modal from '../../../audi-ui-components/Modal';
import TextField from '../../../audi-ui-components/TextField';
import LoadingOverlay from '../../../components/LoadingOverlay';

class MobileVerificationPopup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      otp: ""
    };
  }

  handleCancel = () => {
    if (typeof this.props.onCancel === 'function') {
      this.props.onCancel();
    }
    this.props.close();
  }

  handleSubmit = () => {
    const { close, successCallback, errorCallback } = this.props;
    const { otp } = this.state;
    var data = Object.assign({}, this.props.formData, {otp});
    this.setState({loading: true});
    this.props.verify(
      data,
      () => { successCallback(); close(); } ,
      (error) => {
        if (errorCallback) {
          errorCallback(error);
        } else {
          this.setState({loading: false, error});
        }
      }
    );
  }

  render() {
    return (
      <Modal isActive={true} close={this.props.close} modalStyle="layer">
        <h4 className="aui-headline-4 mb-3">Verify your mobile number</h4>
        <p className="mb-3">Please enter the 6 digit code that we just sent to {obfuscatePhone(this.props.formData.mobilePhone)}</p>
        {this.state.loading && <LoadingOverlay />}
        <div className="mb-3">
          <TextField name="otp" label="Verification code" value={this.state.otp} onChange={(v) => { this.setState({otp: v}); }} />
        </div>
        {this.state.error && this.state.error.message && <p className="aui-color-text-red mb-3">{this.state.error.message}</p>}
        <p style={{textAlign:'right'}}>
          <Button buttonType="secondary" label="Cancel mobile verification" onClick={this.handleCancel} />
          <Button buttonType="primary" label="Verify number" onClick={this.handleSubmit} />
        </p>
      </Modal>
    );
  }
}

MobileVerificationPopup.propTypes = {
  close: PropTypes.func,
  formData: PropTypes.object,
  verify: PropTypes.func,
  successCallback: PropTypes.func,
  errorCallback: PropTypes.func,
  onCancel: PropTypes.func
}

export default MobileVerificationPopup;
