import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { validate } from '../../../../lib/validation';
import { request } from '../../../../lib/apiRequestWrapper';
import { updateGuest } from '../../../../redux/Experience';
import IconForward from '../../../../audi-ui-components/icons/Forward';
import Button from '../../../../audi-ui-components/Button';
import FormField from '../../../../components/FormField';


/**
 * Step 1 of ModifyGuest
 */
const FormModifyGuest = ({ event, updateGuest, editBookingSteps, setEditBookingStep }) => {

  let booking = event && event.booking;
  let guest = booking && booking.attendees.find(guest => guest.isGuest);
  let index = booking && booking.attendees.findIndex(guest => guest.isGuest);

  let initialValues = {
    guest: true,
    id: guest && guest.id,
    firstName: (guest && guest.firstName) || null,
    lastName: (guest && guest.lastName) || null,
    email: (guest && guest.email) || null,
    isGuest: true
  }

  const onSubmitHandler = async (values, formikBag) => {

    // NOTE:: No payment requried when Modifying an attendee.
    try {
      let response = await request(
        `${process.env.RAZZLE_API}/1/experience/attendee/${guest.id}/update`,
        {
          method: "PATCH",
          body: JSON.stringify(values),
        }
      );
    } catch (error) {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setErrors(errors);
      }
      return;
    }

    // Slot the new values into state, to avoid refetching the whole event/booking.
    // TODO:: Is it safe to assume this index is correct, perhaps move to redux action
    updateGuest(index, values);

    setEditBookingStep(null);
    return;
  }

  const validateForm = (values) => {
    let validation = validate(values, {
      firstName: {
        presence: {
          message: "Please enter your guest's first name",
          allowEmpty: false
        },
      },
      lastName: {
        presence: {
          message: "Please enter your guest's last name",
          allowEmpty: false
        },
      },
      email: {
        presence: {
          message: "Please enter an email address for your guest",
          allowEmpty: false
        },
      },
    }, { format: "firstError", fullMessages: true });

    return validation;
  }

  return (
    event && <div className="">
      <h2 className="aui-headline-3 mt-4 mb-7"><b>Modify guest details</b></h2>
      <Formik
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={onSubmitHandler} >
        {formikBag => (
          <Form>
            <div className="row">
              <div className="col-small-6">
                <FormField name="firstName" label="Guest first name" formikBag={formikBag} type="text" />
              </div>
              <div className="col-small-6">
                <FormField name="lastName" label="Guest last name" formikBag={formikBag} type="text" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormField name="email" label="Guest email address" formikBag={formikBag} type="email" />
              </div>
            </div>
            <div className="row mt-7">
              <div className="col-auto mb-4">
                <Button label="Save"
                  buttonType="primary"
                  icon={<IconForward small />}
                  iconPosition="right"
                  type="submit"
                  disabled={formikBag.isSubmitting} />
              </div>
              <div className="col-auto mb-4">
                <Button label="Cancel"
                  buttonType="secondary"
                  onClick={() => { setEditBookingStep(null); }} />
              </div>
              <div className="col-12 col-xsmall text-xsmall-right">
                <Button
                  buttonType="secondary"
                  label="Remove guest"
                  type="button"
                  onClick={() => { setEditBookingStep(editBookingSteps.rmGuest); }}
                  disabled={formikBag.isSubmitting} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateGuest: (index, values) => {
      dispatch(updateGuest(index, values));
    },
  };
};

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormModifyGuest);
