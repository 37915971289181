import React from 'react';
import CollapsibleSection from '../../../components/CollapsibleSection';
import Button from '../../../audi-ui-components/Button';
import IconDownload from '../../../audi-ui-components/icons/Download';
import IconSystemHelp from '../../../audi-ui-components/icons/SystemHelp';
import IconVoiceCall3 from '../../../audi-ui-components/icons/VoiceCall3';
import { gtmPush } from '../../../lib/gtm';

class TabContact extends React.Component {
  render() {
    return (
      <article className="finance__tab-contact aui-color-gray90 pt-6">
        <div className="container-fluid px-small-0">
          <div className="row py-7">
            <div className="col-medium-6">
              <div className="row">
                <div className="col-xsmall-2 pr-4 text-center text-xsmall-right">
                  <IconVoiceCall3 />
                </div>
                <div className="col-12 col-xsmall-8 px-6 p-xsmall-0 ">
                  <h2 className="aui-headline-3 mb-6">
                    <b>Contact Us</b>
                  </h2>
                  <p className="mb-3">
                    If you need to get in contact with us, there are a number of
                    ways we can help.
                  </p>
                  <p className="mb-3">
                    <b>Monday - Friday, 9am - 5pm</b>
                    <br />
                    General Enquiries: <b>1300 734 567</b>
                    <br />
                    Portal Phone Support: <b>1300 017 163</b>
                    <br />
                    Calling from overseas <b>(+61 2) 9695 6311</b>
                  </p>
                  <p className="mb-3">
                    <b>You can email us:</b>
                    <br />
                    If your enquiry is general in nature:{' '}
                    <a
                      className="aui-textlink"
                      href="mailto:customer_service@vwfs.com.au">
                      customer_service@vwfs.com.au
                    </a>
                    <br />
                    If your enquiry is in relation to an overdue account:{' '}
                    <a
                      className="aui-textlink"
                      href="mailto:collections@vwfs.com.au">
                      collections@vwfs.com.au
                    </a>
                  </p>
                  <p className="mb-7">
                    <b>You can write to us:</b>
                    <br />
                    Audi Financial Services Australia
                    <br />
                    Locked Bag 4002
                    <br />
                    Chullora NSW 2190
                  </p>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Financial Assistance
                      </p>
                    }>
                    <p className="mb-3">
                      We understand that life can take unexpected turns and
                      anyone can experience financial hardship, due to unforseen
                      circumstances. We are commited to assisting you with
                      finding a suitable solution to manage your repayments,
                      whilst you get back on your feet.
                    </p>
                    <p className="mb-3">
                      <b>What is Financial Hardship?</b>
                      <br />
                      Financial hardship is when a customer is willing and has
                      the intention to pay, but due to a change in their
                      circumstances, is now unable to meet their repayments or
                      existing financial obligations. With formal hardship
                      assistance, their financial situation will be restored.
                    </p>
                    <p className="mb-3">
                      <b>
                        Reasons which may qualify you for Financial Hardship
                      </b>
                      <br />
                      Common events contributing to financial difficulty may
                      include:
                    </p>
                    <ul className="mb-3">
                      <li>Changes in income and/or expenses</li>
                      <li>
                        Changes in employment (such as reduced hours, loss of
                        job, reduced pay)
                      </li>
                      <li>
                        Significant life events such as (illness, injury,
                        relationship break down, death or disablity)
                      </li>
                      <li>Natural disaster</li>
                      <li>
                        Legal matters such as court actions, judgement or court
                        orders
                      </li>
                      <li>
                        Company failure or some other event such as insolvency
                        or administration
                      </li>
                    </ul>
                    <p className="mb-3">
                      Please{' '}
                      <a
                        className="aui-textlink"
                        href="https://www.vwfs.com.au/Media/VWFSCorp/Media/VWFSA-Financial-Hardship-FAQs.pdf"
                        target="_blank"
                        rel="noopener noreferrer">
                        click here
                      </a>{' '}
                      for more information relating to Hardship Assistance.
                    </p>
                    <p className="aui-headline-5 mb-3 mt-7">How to Apply</p>
                    <p className="mb-3">
                      In order to assess your eligibility for financial
                      assistance, we require you to complete the Financial
                      Hardship Application Form.
                    </p>
                    <p className="mb-3">
                      Please provide us with a signed copy within 21 days, via
                      one of the following:
                    </p>
                    <p className="mb-3">
                      <b>Email:</b>
                      <br />
                      <a
                        className="aui-textlink"
                        href="mailto:hardship@vwfs.com.au">
                        hardship@vwfs.com.au
                      </a>
                    </p>
                    <p className="mb-3">
                      <b>Post:</b>
                      <br />
                      Volkswagen Financial Services Australia
                      <br />
                      Locked Bag 4002
                      <br />
                      Chullora NSW 2190
                    </p>
                    <p className="mb-3">
                      <b>Fax:</b>
                      <br />
                      (02) 9695 6399
                      <br />
                      Attention: Hardship
                    </p>
                    <p className="mb-3">
                      <br />
                      <Button
                        buttonType="primary"
                        onClick={() => { gtmPush("myAudiFinance", "downloadHardshipForm", "finance"); }}
                        href={`/pdf/013_20181026.03.02.Hardship_Application.pdf`}
                        target="_blank"
                        rel="noopener noreferrer"
                        label="Download Hardship form"
                        icon={<IconDownload small />}
                      />
                    </p>
                    <p className="aui-headline-5 mb-3 mt-7">
                      What Happens Next?
                    </p>
                    <p className="mb-3">
                      Once your application has been completed and returned to
                      us, your circumstances will then be assessed to see what
                      arrangements or options may be suitable for you.
                    </p>
                    <p className="mb-3">
                      We will notify you of the outcome within 21 days. Should
                      we require additional information, one of our Hardship
                      specialists will be in contact with you to discuss the
                      matter further.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">Feedback</p>
                    }>
                    <p className="mb-3">
                      We pride ourselves on providing exceptional service and
                      great products to all our customers.
                    </p>
                    <p className="mb-3">
                      We believe that our consumers have the right to efficient,
                      honest, and fair treatment in their dealings with us. We
                      welcome your feedback, so we can continue to improve our
                      services.
                    </p>
                    <p className="mb-3">
                      <b>To provide feedback or make a complaint</b>
                    </p>
                    <p className="mb-3">
                      Your first point of contact for raising a complaint and
                      feedback is with our Customer Resolutions Team. They will
                      endeavour to address your concerns and resolve your
                      dispute.
                    </p>
                    <p className="mb-3">
                      You can contact our Customer Resolutions Team using one of
                      the following options:
                    </p>
                    <p className="mb-3">
                      <b>You can email us:</b>
                      <br />
                      Please email,{' '}
                      <a
                        className="aui-textlink"
                        href="mailto:feedback@vwfs.com.au">
                        feedback@vwfs.com.au
                      </a>
                    </p>
                    <p className="mb-3">
                      <b>You can call us:</b>
                      <br />
                      Monday – Friday, 9am – 5pm
                      <br />
                      Customer Service: 1300 734 567
                      <br />
                      Calling from overseas (+61 2) 9695 6311
                    </p>
                    <p className="mb-3">
                      <b>You can write to us:</b>
                      <br />
                      Volkswagen Financial Services Australia
                      <br />
                      Locked Bag 4002
                      <br />
                      Chullora NSW 2190
                      <br />
                    </p>
                    <p className="mb-3">
                      <b>Seeking additional help</b>
                    </p>
                    <p className="mb-3">
                      In the unlikely event that you{`'`}re not satisfied or
                      disagree with our decision or suggested resolution, you
                      may refer your complaint to Australian Financial
                      Complaints Authority (AFCA), an external dispute
                      resolution scheme of which we are a member. Their contact
                      details are:
                    </p>
                    <p className="mb-3">
                      M:{' '}
                      <b>
                        Australian Financial Complaints Authority GPO box 3,
                        Melbourne VIC 3001
                      </b>
                      <br />
                      P: 1800 931 678 (Free call)
                      <br />
                      E:{' '}
                      <a
                        className="aui-textlink"
                        href="mailto:info@afca.org.au">
                        info@afca.org.au
                      </a>
                      <br />
                      W:{' '}
                      <a
                        className="aui-textlink"
                        href="http://www.afca.org.au"
                        target="_blank"
                        rel="noopener noreferrer">
                        www.afca.org.au
                      </a>
                    </p>
                    <p className="mb-3">
                      <b>Are you hearing impaired?</b>
                    </p>
                    <p className="mb-3">
                      If you are hearing impaired, we can assist you in seeking
                      assistance to relay your conversation.
                    </p>
                    <p className="mb-3">
                      If English is not your first language and you do not feel
                      comfortable using English to do business with us, we may
                      be able to offer you the services of one of our
                      multilingual employees.
                    </p>
                    <p className="mb-3">
                      Alternatively, we can arrange to speak to a translator who
                      can be provided by the Department of Immigration and
                      Multicultural and Indigenous Affairs for example. There is
                      a fee for this government service. Please refer to the
                      Translating and Interpreting Service (TIS) section of the
                      Department of Immigration and Border Control website at{' '}
                      <a
                        className="aui-textlink"
                        href="http://www.immi.gov.au"
                        target="_blank"
                        rel="noopener noreferrer">
                        www.immi.gov.au
                      </a>
                      .
                    </p>
                  </CollapsibleSection>
                </div>
                <div className="col-xsmall-2"></div>
              </div>
            </div>

            <div className="col-medium-6">
              <div className="row mt-6 mt-xsmall-0">
                <div className="col-xsmall-2 pr-4 text-center text-xsmall-right">
                  <IconSystemHelp />
                </div>
                <div className="col-12 col-xsmall-8 px-6 p-xsmall-0">
                  <h2 className="aui-headline-3 mb-6">
                    <b>Frequently Asked Questions</b>
                  </h2>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        How do I make extra repayments towards my loan?
                      </p>
                    }>
                    <p>
                      You can make extra repayments via online transfer, Bpay or
                      branch deposit. Please be advised a $4.50 non-direct debit
                      fee is payable for the above options. To access Bpay
                      details please select the relevant vehicle from the
                      dashboard, and then select 'Make a Payment', the relevant
                      Bpay details will display.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Can I sell my car whilst it is under finance?
                      </p>
                    }>
                    <p>
                      We hold security over all vehicles financed with us. You
                      may negotiate with a purchaser to sell your car, however
                      you are obligated to pay your loan in full upon the sale
                      of your financed vehicle.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Once my loan is finalised, when is the PPSR removed?
                      </p>
                    }>
                    <p>
                      The PPSR/Financial interest will be removed exactly 7 days
                      after (and not prior to) we receive the full payout amount
                      to finalise your loan. This is to allow time for the
                      payment to clear.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Can I change my Direct Debit amount?
                      </p>
                    }>
                    <p>
                      Provided your payments are not less than the minimum
                      monthly repayment, you can change the amount. You can also
                      choose to make your payments weekly or fortnightly. You
                      can make these changes by emailing our Customer Service
                      Team at{' '}
                      <a
                        className="aui-textlink"
                        href="mailto:customer_service@vwfs.com.au">
                        customer_service@vwfs.com.au
                      </a>
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        How can I obtain a copy of my loan statement?
                      </p>
                    }>
                    <p>
                      You can obtain a copy of your loan statement under the
                      'Statements' tab. Simply select the relevant contract and
                      download the statement as a PDF.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Can I arrange my statement to be sent to me
                        automatically?
                      </p>
                    }>
                    <p>
                      Unfortunately statements are not sent automatically.
                      However you can access all financial year statements under
                      the 'Statements' tab.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        How can I authorise a third party to have access to my
                        loan account?
                      </p>
                    }>
                    <p className="mb-3">
                      You may authorise a person, over 18 years of age to obtain
                      information about your loan account. To authorise a third
                      party please complete, sign and return the Third Party
                      Consent Form found under the 'Enquiries &amp; requests'
                      tab to{' '}
                      <a
                        className="aui-textlink"
                        href="mailto:customer_service@vwfs.com.au">
                        customer_service@vwfs.com.au
                      </a>
                      .
                    </p>
                    <p>
                      Please allow 1-2 business days for this information to be
                      updated. Please note that this authority will enable the
                      authorised third party access to all information and to
                      make changes on your loan account.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        How do I change the account my payments are debited
                        from?
                      </p>
                    }>
                    <p>
                      You can update your banking details under the ‘Finance’
                      tab where your contract information is displayed within
                      the 'Direct debit account' section.
                    </p>
                  </CollapsibleSection>

                  <CollapsibleSection
                    toggleOnClickVisible
                    visibleContent={
                      <p className="aui-headline-5 mb-3">
                        Can I transfer my finance to another person?
                      </p>
                    }>
                    <p>
                      We are unable to offer a transfer of finance. If you are
                      considering changing the vehicles ownership to another
                      party, the loan will need to be paid out in full.
                    </p>
                  </CollapsibleSection>
                </div>
                <div className="col-xsmall-2"></div>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

export default TabContact;
