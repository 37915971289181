import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M18.7,8.6 C18.7,4.6 15.4,1.3 11.5,1.3 C7.6,1.3 4.3,4.6 4.3,8.6 C4.3,12.6 11.5,22.7 11.5,22.7 C11.5,22.7 18.7,12.6 18.7,8.6 Z"/>
          <path d="M7.5,7 L7.5,10.5 C7.5,11.1 7.9,11.5 8.4,11.5 L15.5,11.5 L15.5,7"/>
          <path d="M17 8L11.5 4 6 8"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M37.5,17.5 C37.5,9.8 31.2,3.5 23.5,3.5 C15.8,3.5 9.5,9.8 9.5,17.5 C9.5,25.2 23.5,44.5 23.5,44.5 C23.5,44.5 37.5,25.2 37.5,17.5 Z"/>
        <path d="M16.5,14 L16.5,20.5 C16.5,21.6 17.4,22.5 18.5,22.5 L30.5,22.5 L30.5,14"/>
        <path d="M33 16L23.5 8.5 14 16"/>
      </g>
    </svg>
  );
};
