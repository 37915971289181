import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import NavBar from '../../audi-ui-components/NavBar';
import NavDropdown from '../../audi-ui-components/NavDropdown';
import LoadingOverlay from '../../components/LoadingOverlay';
import CollapsibleSection from '../../components/CollapsibleSection';
import { loadFaq } from '../../redux/Content';
import { PATH_FAQ } from '../../constants';
import HeroSection from '../../components/HeroSection';
import Crumb from '../../components/Crumb';
import { Helmet } from "react-helmet";

const mapStateToProps = state => {
    return {
        faq: state.content.faq || null,
        isLoading: state.content.isLoading,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadFAQ: () => {
            dispatch(loadFaq());
        },
    };
};

class Faq extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.props.faq || this.props.faq.questions.length === 0) {
            this.props.loadFAQ();
        } else {
            const { match } = this.props;
            if (!match.params.category) {
                this.handleCategoryClick(0);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            (!prevProps.faq || prevProps.faq.questions.length === 0) &&
            this.props.faq &&
            this.props.faq.questions.length > 0
        ) {
            let idx = this.getActiveCategoryIndex();
            this.handleCategoryClick(idx);
        }
    }

    handleCategoryClick = index => {
        let c = this.props.faq.categories[index].slug;
        this.props.history.push(`${PATH_FAQ}/${c}`);
    };

    getActiveCategoryIndex = () => {
        const { faq, match } = this.props;
        const activeCategory = match.params.category;
        if (faq && faq.categories && activeCategory) {
            for (let i = 0; i < faq.categories.length; i++) {
                if (faq.categories[i].slug === activeCategory) {
                    return i;
                }
            }
        }
        return 0;
    };

    render() {
        const { faq, isLoading, match } = this.props;
        const activeCategory = match.params.category;

        let faqContent = <LoadingOverlay type="placeholder" />;
        if (!isLoading && faq && faq.categories && faq.categories.length > 1) {
            faqContent = (
                <>
                    <div className="faq--nav-d d-none d-small-block">
                        <NavBar
                            actions={faq.categories}
                            activeIndex={this.getActiveCategoryIndex()}
                            onActionClick={this.handleCategoryClick}
                            navType="tab"
                        />
                    </div>
                    <div className="faq--nav-m d-small-none px-5">
                        <NavDropdown
                            actions={faq.categories}
                            activeIndex={this.getActiveCategoryIndex()}
                            onActionClick={this.handleCategoryClick}
                            className=" mt-4 mb-6"
                        />
                    </div>
                </>
            );
            let category = faq.categories.filter((v, i) => {
                return v.slug === activeCategory
            });
        }

        return (
            <div className="page-wrapper faq">
              <div className="content-wrapper">
                <HeroSection
                    content={[{ type: "image", url: `${process.env.PUBLIC_PATH}assets/1920x1080_AA6_L_181005.jpg`, x: "50%", y: "50%" }]}
                    className="mb-2"
                    imgWide
                    outdent
                >
                    <h1 className="aui-headline-3 headline-margin mb-small-6 pt-small-7 aui-color-text-light">
                        <b>Frequently Asked Questions</b>
                    </h1>
                </HeroSection>

                <Crumb className="mb-6" />

                <div>
                    {faqContent}
                </div>
                <section className="faq-category py-7 aui-color-gray95">
                    <div className="content-wrapper">

                        {faq && faq.questions && faq.questions.map((q, index) => {
                            if (q.category === activeCategory) {
                                return (
                                    <article key={`q_${q.id}`} className="faq--item">
                                        <CollapsibleSection
                                            visibleContent={
                                                <h3 className="aui-headline-5 pt-7 pb-6 faq--question">
                                                    <b>{q.question}</b>
                                                </h3>
                                            }
                                            toggleOnClickVisible>
                                            <div
                                                className="faq--answer cms-content pb-7"
                                                dangerouslySetInnerHTML={{
                                                    __html: q.answer,
                                                }}></div>
                                        </CollapsibleSection>
                                    </article>
                                );
                            }
                            return null;
                        })}
                    </div>
                </section>
              </div>
              <Helmet>
                  <title>FAQ | myAudi Australia</title>
                  {faq && faq.seoMetaDescription && <meta name="description" content={faq.seoMetaDescription} />}
              </Helmet>
            </div>
        );
    }
}

Faq.propTypes = {
    faq: PropTypes.object,
    fetchFAQ: PropTypes.func,
    isFetchingFAQ: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
