import {request} from '../lib/apiRequestWrapper';
import cookie from '../lib/cookie';

const SET_IS_LOADING_LIST = 'FINANCE/SET_IS_LOADING_LIST';
const SET_CONTRACT_LIST = 'FINANCE/SET_CONTRACT_LIST';
const SET_API_DOWN = 'FINANCE/SET_API_DOWN';

const defaultState = {
  financeApiIsDown: false,

  contractList: [],
  isLoadingContractList: false,

  contactInfo: {
    // this was going to be fetched from an API, but that will probably never happen
    cst: {
      subHeading1: "Customer Service Team",
      contactPhone1: "1300 734 567",
      contactEmail1: "customer_service@vwfs.com.au",
      contactAddress1: "Locked Bag 4002 Chullora NSW 2190",
      contactAdditional1: "Mon-Fri (9am-5pm)"
    },
    collections: {
      subHeading1: "Overdue Accounts",
      subHeading2: "Collections Team",
      contactPhone1: "1300 734 567 (Option 1)",
      contactEmail1: "collections@vwfs.com.au",
      contactAddress1: "Locked Bag 4002 Chullora NSW 2190 (Attn: Collections Team)"
    },
    hardship: {
      subHeading1: "Hardship Assistance",
      contactPhone1: "1300 734 567 (Option 1)",
      contactEmail1: "hardship@vwfs.com.au",
      contactAddress1: "Locked Bag 4002 Chullora NSW 2190 (Attn: Hardship Team)"
    },
    idr: {
      subHeading1: "For Internal Dispute Resolution (IDR)",
      contactEmail1: "feedback@vwfs.com.au"
    },
    cio: {
      subHeading1: "For Internal Dispute Resolution (IDR)",
      subHeading2: "You may refer your complaint to the Credit and Investments Ombudsman, an external dispute resolution scheme of which we are a member. Their contact details are: ",
      contactName1: "Credit and Investments Ombudsman",
      contactPhone1: "(02) 9263 8440",
      contactEmail1: "info@cio.org",
      contactAddress1: "P O Box A252, Sydney South NSW 1235",
      contactAdditional1: "Opening Hours: Mon-Fri (9am-5pm)",
      contactName2: "1800 138 422 (Free Call)",
      contactPhone2: "(02) 9263 8400",
      contactWeb2: "www.cio.org.au"
    }
  }
};

export function fetchContracts() {
  return (dispatch, getState) => {
    dispatch({type: SET_IS_LOADING_LIST, payload: true});
    dispatch({type: SET_API_DOWN, payload: false});
    return request(
      `${process.env.RAZZLE_API}/1/vwfs/customer/contracts`,
      {}
    ).then((data) => {
      dispatch({type: SET_CONTRACT_LIST, payload: data});
      dispatch({type: SET_IS_LOADING_LIST, payload: false});
    }).catch((error) => {
      if (error.status >= 500) {
        dispatch({type: SET_API_DOWN, payload: true});
      } else {
        console.error(error);
        // dispatch(flashMessageAdd(error.message, 'error', false));
      }
      dispatch({type: SET_IS_LOADING_LIST, payload: false});
    });
  };
}

export function financeReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_IS_LOADING_LIST:
    return Object.assign({}, state, {
      isLoadingContractList: action.payload,
    });

    case SET_CONTRACT_LIST:
    return Object.assign({}, state, {
      contractList: action.payload,
    });

    case SET_API_DOWN:
    return Object.assign({}, state, {
      financeApiIsDown: action.payload
    });

    default:
    return state;
  }
}
