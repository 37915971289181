const profileSchema = {
  firstName: {
    presence: {
      message: "Please enter your name",
      allowEmpty: false
    }
  },
  lastName: {
    presence: {
      message: "Please enter your name",
      allowEmpty: false
    }
  },
  email: {
    presence: {
      message: "Email address is required",
      allowEmpty: false
    },
    email: {message: "Please enter a valid email address"}
  },
  mobile: {
    isMobileNumber: true
  }
};

const homeAddressSchema = {
  homeAddress: {
    presence: {
      message: "Please enter your address",
      allowEmpty: false
    }
  },
  homeSuburb: {
    presence: {
      message: "Please enter your suburb",
      allowEmpty: false
    }
  },
  homeState: {
    presence: {
      message: "Please select your state",
      allowEmpty: false
    }
  },
  homePostcode: {
    presence: {
      message: "Please enter a postcode",
      allowEmpty: false
    },
    format: {
      pattern: /^\d{4}$/,
      message: "Please enter a valid postcode"
    }
  }
};

const passwordSchema = {
    oldPassword: {
        presence: {
            message: "Please enter your current password",
            allowEmpty: false
        }
    },
    newPassword: {
        presence: {
            message: "Please enter your new password",
            allowEmpty: false
        },
        isPassword: {
            message: "Please enter a password with at least 10 characters that contains at least 1 number" //TODO: how to pull the message from the isPassword validator?
        },
        equality: {
            attribute: "oldPassword",
            message: "Your new password should be different to your current one",
            comparator: function (v1, v2) {
                if (v1 == '' || v2 == '')
                {
                    return true;
                }
                return JSON.stringify(v1) !== JSON.stringify(v2);
            }
        }
    },
    confirmPassword: {
        presence: {
            message: "Please confirm your new password",
            allowEmpty: false
        },
        equality: {
            attribute: "newPassword",
            message: "New Password and Repeat New Password should be the same"
        }
    }
};

const deactivateSchema = {
    password: {
        presence: {
            message: "Please enter your password",
            allowEmpty: false
        }
    }
};

export {
  profileSchema,
  homeAddressSchema,
  passwordSchema,
  deactivateSchema
};
