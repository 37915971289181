import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import Button from '../../audi-ui-components/Button';
import Modal from '../../audi-ui-components/Modal';
import IconLogin from '../../audi-ui-components/icons/Login';
import IconUser from '../../audi-ui-components/icons/User';
import IconDirectMail from '../../audi-ui-components/icons/DirectMail';
import IconPoiHome from '../../audi-ui-components/icons/PoiHome';
import IconCalendar from '../../audi-ui-components/icons/CalendarEvents';
import IconSelect from '../../audi-ui-components/icons/Select';
import NavBar from '../../audi-ui-components/NavBar';
import LoadingOverlay from '../../components/LoadingOverlay';
import Crumb from '../../components/Crumb';
import moment from 'moment';

import ProfileForm from './forms/ProfileForm';
import HomeAddressForm from './forms/HomeAddressForm';
import PreferenceForm from './forms/PreferenceForm';
import PreferredDealerForm from './forms/PreferredDealerForm';
import PreferredServiceDealerForm from './forms/PreferredServiceDealerForm';
import FinanceProfileForm from './forms/FinanceProfileForm';
import FinancePreferenceForm from './forms/FinancePreferenceForm';
import PasswordForm from './forms/PasswordForm';
import DeactivateForm from './forms/DeactivateForm';
import MFAEnableForm from './forms/MFAEnableForm';
import MFADisableForm from './forms/MFADisableForm';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        isLoadingProfile: state.profile.isLoading,
        profile: state.profile.data,
        vwfsProfile: state.profile.vwfsData,
    }
}

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeModal: null,
            verifyModal: null,
            profileActiveTab: 0,
        }
        this.isAccountSectionFirst = !props.profile.hasMFAEnabled;
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    
    scrollToMobile = () => {
      let el = document.querySelector(".profile-mobile-field");
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center"});
      }
    }

    render() {
        const { profile, vwfsProfile } = this.props;
        const { profileActiveTab } = this.state;
        const hasVWFSProfile = Boolean(profile.vwfsId && vwfsProfile !== null);
        var loyaltyEndDate = moment(profile.loyaltyEligibiltyExpiryDate);
        if (!loyaltyEndDate.isValid()) {
            loyaltyEndDate = false;
        }
        return (
            <div className="page-wrapper profile">
                <main className="content-wrapper">

                    <h1 className="aui-headline-3 mt-5">Profile</h1>
                    <Crumb className="mb-6" links={[{label: "Profile"}]} />

                    <div className="row">
                        <div className="col-medium-6 d-flex flex-column">
                        
                            {hasVWFSProfile &&
                                <NavBar
                                    actions={[{ label: "myAudi" }, { label: "Audi Finance" }]}
                                    activeIndex={profileActiveTab}
                                    onActionClick={(idx) => { this.setState({ profileActiveTab: idx }); }}
                                    navType="tab"
                                    className="profile-nav"
                                />
                            }
                            <section className="mb-3 aui-color-gray20 aui-color-text-light">

                                {profileActiveTab === 0 && <div className="profile-tab">
                                    <div className="py-7">
                                        <div className="row">
                                            <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                                <IconUser large />
                                            </div>
                                            <div className="col-12 col-medium-8 px-6 px-medium-0">
                                                <h2 className="aui-headline-4 mb-3 headline-icon">
                                                    Profile
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                            <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                                <ProfileForm theme="light" loadingBgColour="gray20" />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="py-7">
                                        <div className="row">
                                            <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                                <IconPoiHome large />
                                            </div>
                                            <div className="col-12 col-medium-8 px-6 px-medium-0">
                                                <h2 className="aui-headline-4 mb-3 headline-icon">
                                                    Address
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                            <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                                <HomeAddressForm theme="light" loadingBgColour="gray20" />
                                            </div>
                                        </div>
                                    </div>
                                </div>}

                                {profileActiveTab === 1 && <div className="profile-tab">
                                    <div className="py-7">
                                        <div className="row">
                                            <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                                <IconUser large />
                                            </div>
                                            <div className="col-12 col-medium-8 px-6 px-medium-0">
                                                <h2 className="aui-headline-4 mb-3 headline-icon">
                                                    Finance Profile
                                                </h2>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                            <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                                <FinanceProfileForm theme="light" loadingBgColour="gray20" />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </section>
                            
                            <section className={classnames("py-7 mb-3 aui-color-gray40 aui-color-text-light", {"order-first": this.isAccountSectionFirst})}>
                                <div className="row">
                                    <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                        <IconLogin large />
                                    </div>
                                    <div className="col-12 col-medium-8 px-6 px-medium-0">
                                        <h2 className="aui-headline-4 mb-5 headline-icon">
                                            Account
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                    <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                        <p className="aui-headline-5 mb-3">Two-Factor Authentication (2FA)</p>
                                        <p className="mb-5">2FA adds an extra layer of protection to your account. You need two forms of ID (a password and a verification code) to access accounts.</p>
                                        {profile.hasMFAEnabled && <p className="mb-3"><IconSelect small /> <b>Your account is secured with 2FA.</b></p>}
                                        {profile.hasMFAEnabled && <p className="mb-5"><Button variant="text" theme="light" onClick={() => { this.setState({ activeModal: 'disableMFA' }); }}>Disable 2FA</Button></p>}
                                        {!profile.hasMFAEnabled && profile.mobile && <p className="mb-5"><Button variant="primary" theme="light" onClick={() => { this.setState({ activeModal: 'enableMFA' }); }}>Enable 2FA</Button></p>}
                                        {!profile.hasMFAEnabled && !profile.mobile && 
                                          <p className="mb-5">You must <button type="button" className="aui-textlink" onClick={this.scrollToMobile}>add a mobile phone number</button> to your profile before you can enable 2FA.</p>
                                        }
                                        <hr className="my-5" />
                                        <p className="aui-headline-5 mb-5">Password</p>
                                        <p className="mb-5">
                                            <Button variant="secondary" theme="light" onClick={() => { this.setState({ activeModal: 'pword' }); }}>Change Password</Button>
                                        </p>
                                        <hr className="my-5" />
                                        <p className="mb-5"><Button variant="text" theme="light" onClick={() => { this.setState({ activeModal: 'deactivate' }); }}>Deactivate Account</Button></p>
                                    </div>
                                </div>
                            </section>

                            <section className="py-7 mb-3 aui-color-gray95">
                                <div className="row">
                                    <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                        <IconCalendar large />
                                    </div>
                                    <div className="col-12 col-medium-8 px-6 px-medium-0">
                                        <h2 className="aui-headline-4 mb-5 headline-icon">
                                            Audi Experience eligibility
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                    <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                        <p className="mb-5">
                                            If you have a vehicle in its first 3 years of warranty or have performed scheduled servicing at an Audi Service Centre on or after 29 January 2021, you are eligible to join the Audi Experience program.
                                            For full details, please see the <a href="https://www.audi.com.au/au/web/en.html?#layer=/au/web/en/terms-and-conditions/audi-experience.html&_ga=2.68218833.1903272869.1607637079-1209861230.1607637079&data-innerlayer=true" target="_blank" rel="noopener noreferrer" className="aui-textlink">Terms and Conditions</a>.
                                        </p>
                                        {loyaltyEndDate && <p className={classnames("mb-5", {"aui-color-text-red": loyaltyEndDate.isBefore()})}><b>Eligibility end date:</b> {loyaltyEndDate.format('MMM Do, YYYY')}</p>}
                                    </div>
                                </div>
                            </section>

                        </div>
                        <div className="col-medium-6">
                            <section className="py-7 mb-3 aui-color-gray95">
                                <div className="row">
                                    <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                                        <IconDirectMail large />
                                    </div>
                                    <div className="col-12 col-medium-8 px-6 px-medium-0">
                                        <h2 className="aui-headline-4 mb-5 headline-icon">
                                            I am interested in a personalised ownership experience<sup>*</sup>:
                                        </h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                    <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                        <PreferenceForm loadingBgColour="gray95" />
                                        {hasVWFSProfile && <FinancePreferenceForm className="mb-7" loadingBgColour="gray95" />}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="disclaimer">
                                        <div className="col-12 col-medium-8 px-6 px-medium-0" />
                                        <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                                            *Audi Australia collects personal information to provide the best possible service and a personalised ownership experience. For more information, view the <a href="https://www.audi.com.au/au/web/en/privacy-policy.html" target="_blank">Audi Australia Data Collection Statement</a>.
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="mb-3 aui-color-gray95">
                                <PreferredDealerForm gtmEventLabel="profile" loadingBgColour="gray95" />
                                <PreferredServiceDealerForm gtmEventLabel="profile" loadingBgColour="gray95" />
                            </section>

                        </div>
                    </div>
                </main>

                <Modal isActive={Boolean(this.state.activeModal)} closeButton close={() => { this.setState({ activeModal: null }); }} modalStyle="layer">
                  <div className="px-7">
                    {this.state.activeModal === "pword" && <PasswordForm />}
                    {this.state.activeModal === "deactivate" && <DeactivateForm />}
                    {this.state.activeModal === "enableMFA" && <MFAEnableForm onSuccess={()=>{ this.setState({ activeModal: null }); }} />}
                    {this.state.activeModal === "disableMFA" && <MFADisableForm onSuccess={()=>{ this.setState({ activeModal: null }); }} />}
                  </div>
                </Modal>

            </div>
        );
    }
}

export default connect(mapStateToProps)(Profile);
