import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { withRouter } from "react-router";
import Button from '../../../audi-ui-components/Button';
import { getEventPeriodString, formatCurrency, formatDate } from '../helpers';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import { PATH_EVENT, PATH_OFFER } from '../../../constants';

class ListItem extends React.Component {

    isEventFull = () => {
        if (this.props.type.toLowerCase() === 'myaudi') {
            return this.props.sessions?.reduce((p, s) => p && s.spotsAvailable === 0, true);
        }
        return false;
    }

    isEventOpen = () => {
        if (this.props.type.toLowerCase() === 'myaudi') {
            return this.props.sessions?.reduce((p, s) =>
                Date.parse(s.eventStartDate) > Date.now() && s.openForRegistration || p, false
            );
        }
        return true;
    }

    canBookWaitlist = () => {
        if (!this.props.booking && this.props.waitlist && this.props.waitlist.session) {
            let sId = this.props.waitlist.session.id;
            let s = _find(this.props.sessions, {id: sId});
            if (s && s.canBook) {
                return true;
            }
            return false;
        }
        return false;
    }

    getButtonProps = () => {
        if (this.props.isPromo) {
            return {
                label: this.props.ctaText ? this.props.ctaText : 'Find out more',
                buttonType: "primary",
                href: this.props.externalUrl,
                target: "_blank",
                rel: "noopener noreferrer"
            };
        }

        const { id, type, canBook, canWaitlist } = this.props;
        let isFull = this.isEventFull();
        let isOpen = this.isEventOpen();
        let link = type.toLowerCase() === 'offer' ? `${PATH_OFFER}/${id}` : `${PATH_EVENT}/${id}`;

        if (this.props.booking) {
            return {
                label: 'View booking',
                buttonType: "secondary",
                onClick: () => { this.props.history.push(link); }
            };
        }

        if (this.props.waitlist) {
            if (this.canBookWaitlist()) {
                return {
                    label: 'Book now',
                    buttonType: "primary",
                    onClick: () => { this.props.history.push(link); }
                }
            } else {
                return {
                    label: 'View details',
                    buttonType: "secondary",
                    onClick: () => { this.props.history.push(link); }
                };
            }
        }
        
        if (!isOpen) {
            return {
                label: 'Event closed',
                buttonType: "primary",
                disabled: true
            };
        }
        
        if (!canBook && canWaitlist) {
            return {
                label: 'Join waitlist',
                buttonType: "primary",
                onClick: () => { this.props.history.push(link); }
            };
        }

        if (isFull) {
            return {
                label: 'View details',
                buttonType: "primary",
                onClick: () => { this.props.history.push(link); }
            };
        }

        return {
            label: (type.toLowerCase() === 'offer' || !canBook) ? 'View details' : 'Book now',
            buttonType: "primary",
            onClick: () => { this.props.history.push(link); }
        };
    }

    render() {
        const { name, thumbnail, venue, price, rebate, pricePrefix, sessions, booking, waitlist, isPromo, type, loyalty, isAuthed } = this.props;
        let isOpen = this.isEventOpen();
        let _style = {};
        if (thumbnail) {
            if (typeof thumbnail === 'string') {
                let img = decodeURIComponent(thumbnail);
                _style.backgroundImage = `url("${img}")`;
                _style.backgroundPosition = "50% 50%";
            }
            if (typeof thumbnail === 'object') {
                _style = {
                    backgroundImage: thumbnail.url,
                    backgroundPosition: `${thumbnail.x} ${thumbnail.y}`
                }
            }
        }
        let desc = this.props.shortDescription;
        if (!desc && venue) {
            desc = `${venue.suburb}${venue.state ? "," : ""} ${venue.state ? venue.state : ""}`;
            if (venue.isInternational) {
                if (venue.internationalAddress) {
                    desc = venue.internationalAddress;
                } else {
                    desc = `${venue.state}, ${venue.country}`;
                }
            }
        }
        let _rebate = loyalty ? rebate : 0;
        let buttonProps = this.getButtonProps();

        let title;
        if (type.toLowerCase() === 'virtual') {
            title = <>Virtual Event:<br />{name}</>;
        } else {
            title = <>{name}</>;
        }

        let usePulseClass = false;
        if (waitlist) {
            usePulseClass = this.canBookWaitlist();
        }

        let availability = false;
        if (isOpen && sessions?.length > 0 && !booking && !waitlist && !isPromo) {
          let isSoldout = _findIndex(sessions, function (o) { return o.spotsAvailable > 0; }) === -1;
          if (isSoldout) {
            let isWaitlistable = _findIndex(sessions, function(o) { return o.allowWaitlist && o.canWaitlist && o.waitlistSpotsAvailable > 0}) > -1;
            availability = isWaitlistable ? "Waitlist Available" : "Sold Out";
          } else {
            if (sessions.length === 1 && sessions[0].spotsAvailable <= 10) {
              availability = `${sessions[0].spotsAvailable} place${sessions[0].spotsAvailable === 1 ? '' : 's'} available`;
            }
          }
        }

        return (
            <div className={classnames("experience-item mb-7 aui-color-gray90", { "is-booked": booking, "is-waitlisted": waitlist && !booking, "pulse": usePulseClass })}>
                <div className="experience-item__img" style={_style} />
                <div className="experience-item__content">
                    <div className="experience-item__content-top p-5">
                        <div className="row no-gutters mb-3 experience-item__detail">
                            <div className="col">{type === "ryi" ? "Register your interest" : getEventPeriodString(this.props.eventStartDate, this.props.eventEndDate)}</div>
                            {isAuthed && type !== "ryi" &&
                                <div className="col-auto price">
                                    {pricePrefix && <span className="price-prefix">{pricePrefix}</span>}{formatCurrency(price - _rebate)}
                                </div>
                            }
                        </div>

                        <h2 className="aui-headline-5 mb-3 experience-item__name">
                            <b>{title}</b>
                        </h2>

                        {this.props.isPromo && this.props.tierDescription && (
                            <div className="tier-description mb-3">{this.props.tierDescription}</div>
                        )}

                        {booking && <div className="booking mb-3">
                            You are attending this event:<br />
                            {booking.attendees.length} ticket{booking.attendees.length === 1 ? '' : 's'}
                            {' '}- {formatDate(booking.session.eventStartDate)}
                        </div>}

                        {waitlist && !booking && <div className="booking mb-3">
                            You are on the waitlist for this event:<br />
                            {formatDate(waitlist.session.eventStartDate)}
                        </div>}

                        {availability && (
                            <div className="places places-low mb-3 aui-color-text-red">
                                <i>{availability}</i>
                            </div>
                        )}

                        {sessions.length > 1 && !booking && !waitlist && (
                            <div className="places mb-3"><i>{sessions.length} dates available</i></div>
                        )}

                    </div>

                    <div className="experience-item__content-bottom row no-gutters aui-color-gray85">
                        <div className="col px-5 experience-item__venue">{desc}</div>
                        <div className="col-auto"><Button {...buttonProps} /></div>
                    </div>

                </div>
            </div>
        );
    }
};

export default withRouter(ListItem);
