import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M11.5,15 L11.5,16 M9.9,7.5 C9.9,7.5 14,5.9 14.5,8.5 C14.9,10.4 11.5,12.7 11.5,12.7 L11.5,14"/>
          <circle cx="11.5" cy="11.5" r="11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M22.5,29 L22.5,31 M20,17.4 C20,17.4 25.9,15 26.7,18.9 C27.2,21.6 22.5,25 22.5,25 L22.5,27"/>
        <circle cx="23.5" cy="23.5" r="22"/>
      </g>
    </svg>
  );
};
