import React from 'react';
import classnames from 'classnames';
import Button from '../../../../audi-ui-components/Button';
import IconEdit from '../../../../audi-ui-components/icons/Edit';
import IconSelect from '../../../../audi-ui-components/icons/Select';
import { gtmPush } from '../../../../lib/gtm';

const FormSection = ({children, title, fields, values, errors, review, edit, toggleEdit}) => {
    let hasErrors = false;
    let len = fields.length;
    if (errors) {
        for (let i=0; i<len; i++) {
            let field = fields[i];
            if (field.name && errors[field.name]) {
                hasErrors = true;
                break;
            } else if (field.names) {
                for (let ii=0; ii<field.names.length; ii++) {
                    let n = field.names[ii];
                    if (errors[n]) {
                        hasErrors = true;
                        break;
                    }
                }
            }
        }
    }

    return (
        <section className={classnames("form-section mb-7", {"review": review, "editing": edit})}>
            <p className="mt-4 pt-4 aui-headline-5"><b>{title}:</b></p>

            {(!review || edit) && children}

            {(review && !edit) && <div className="mt-3">
                {fields.map((field, i) => <p key={i}>
                    {field.label}
                    {field.label ? `: ` : ""}
                    {field.name && field.name === "cardNumber" && values.cardNumber && values.cardNumber.replace(/^[\d-\s]+(?=\d{4})/, "************")}
                    {field.name && field.name !== "cardNumber" && values[field.name]}
                    {field.names && field.names.map(n => `${values[n] || ""} `)}
                    {field.suffix}
                </p>)}
            </div>}

            {review && typeof toggleEdit === "function" &&
                <Button
                    label={edit ? "Done" : "Edit"}
                    icon={edit ? <IconSelect small /> : <IconEdit small />}
                    onClick={() => {
                        if (!edit || !hasErrors) {
                            if (!edit) {
                                gtmPush("buyServicePlan", "BspEditDetails", "BspReview");
                            }
                            toggleEdit();
                        }
                    }}
                    className="review-edit-btn"
                />
            }
        </section>
    );
}

export default FormSection;
