import React from 'react';
import cn from 'classnames';
import { Route, Switch, Link } from 'react-router-dom';
import { loadPage } from '../../redux/Content';
import LoginModal from './LoginModal';
import HomeHeroSection from './HomeHeroSection';
import Button from '../../audi-ui-components/Button';
import CarIcon from '../../audi-ui-components/icons/Car';
import EventIcon from '../../audi-ui-components/icons/CalendarEvents';
import PoiIcon from '../../audi-ui-components/icons/Distance';
import { Helmet } from "react-helmet";
import {
  PATH_HOME,
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_FORGOT_PASS,
  PATH_REQUEST_CONFIRMAION,
  PATH_CONFIRM_EMAIL,
  PATH_RESET_PASS
} from '../../constants';

import { connect } from 'react-redux';
const mapStateToProps = state => {
  return {
    isAuthed: state.profile.isAuthed,
    homepage: state.content.homepage || {},
    isLoadingContent: state.content.isLoading
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadHome: () => {dispatch(loadPage('homepage'));},
  }
}

class Home extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.loadHome();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isAuthed !== this.props.isAuthed) {
          setTimeout(() => {
            this.props.loadHome();
          }, 1000);
        }
    }

  render() {
    const {heroMedia, title, subTitle, sections, seoMetaDescription} = this.props.homepage;
    return (
      <div className={cn("page-wrapper home", {"logged-in": this.props.isAuthed})}>
        
        <div className="content-wrapper pb-7">
          
          <Switch>
            <Route exact path={PATH_HOME} />
            <Route path={[PATH_LOGIN, PATH_REGISTER, PATH_FORGOT_PASS, PATH_RESET_PASS, PATH_REQUEST_CONFIRMAION, PATH_CONFIRM_EMAIL]}>
              <LoginModal />
            </Route>
            <Route path="*" render={({ staticContext }) => {
              if (staticContext) { staticContext.status = 404; }
              return <div>404 Not Found</div>
            }} />
          </Switch>
          
          {heroMedia && !this.props.isAuthed && <HomeHeroSection content={heroMedia} />}
          
          <div className="hp-sidebar">
            {sections?.map(section => {
              if (!section.content || section.content.length === 0 || section.content[0].group !== "Sidebar") { return null; }
              if (this.props.isAuthed && section.visibility === "loggedOut") { return null; }
              if (!this.props.isAuthed && section.visibility === "loggedIn") { return null; }
              let linkProp = {};
              let Tag = "a";
              if (section.content[0].link.indexOf("http") === 0) {
                linkProp.href = section.content[0].link;
                linkProp.target = "_blank";
              } else {
                linkProp.to = section.content[0].link;
                Tag = Link;
              }
              return (
                <Tag key={section.id} className="hp-tile aui-button aui-button--text aui-theme-light mb-5 mb-medium-2" {...linkProp}>
                  <div className="img">
                    <img src={section.content[0].backgroundImage?.url} alt="" style={{objectPosition: `${section.content[0].backgroundImage?.x} ${section.content[0].backgroundImage?.y}`}} />
                    <div className="hp-tile__overlay" />
                    <h4 className="aui-headline-4"><b>{section.content[0].title}</b></h4>
                  </div>
                  <div className={cn("hp-tile__text", {"has-icon": Boolean(section.content[0].icon)})}>
                    {section.content[0].icon === "car" && <CarIcon large />}
                    {section.content[0].icon === "calendar" && <EventIcon large />}
                    {section.content[0].icon === "poi" && <PoiIcon large />}
                    <p>{section.content[0].subTitle}</p>
                    <span className="aui-button__text">{section.content[0].ctaText}</span>
                  </div>
                </Tag>
              );
            })}
          </div>
          
          {heroMedia && this.props.isAuthed && <HomeHeroSection content={heroMedia} />}

          <div className="hp-main">
            {sections?.map(section => {
              if (!section.content || section.content.length === 0 || section.content[0].group !== "Main") { return null; }
              let linkProp = {};
              let Tag = "a";
              if (section.content[0].link.indexOf("http") === 0) {
                linkProp.href = section.content[0].link;
                linkProp.target = "_blank";
              } else {
                linkProp.to = section.content[0].link;
                Tag = Link;
              }
              return (
                <Tag key={section.id} className="hp-tile aui-button aui-button--text" {...linkProp}>
                  <div className="img mb-4">
                    <img src={section.content[0].backgroundImage?.url} alt="" style={{objectPosition: `${section.content[0].backgroundImage?.x} ${section.content[0].backgroundImage?.y}`}} />
                  </div>
                  <h4 className="aui-headline-4 mb-3"><b>{section.content[0].title}</b></h4>
                  <p className="mb-2">{section.content[0].subTitle}</p>
                  <span className="aui-button__text">{section.content[0].ctaText}</span>
                </Tag>
              );
            })}
          </div>
          
          <div className="clearfix d-none d-medium-block" />

        </div>

        <Helmet>
            {seoMetaDescription && <meta name="description" content={seoMetaDescription} />}
        </Helmet>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
