import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { validate } from '../../../../lib/validation';
import { getDaysToEvent } from '../../helpers';
import Button from '../../../../audi-ui-components/Button';
import IconEdit from '../../../../audi-ui-components/icons/Edit';
import IconForward from '../../../../audi-ui-components/icons/Forward';
import { gtmPush } from '../../../../lib/gtm';
import { PATH_PROFILE, FIELD_RADIOS, FIELD_TEXTAREA, FIELD_CHECKBOX, AE_TERMS_URL } from '../../../../constants';
import FormField from '../../../../components/FormField';
import HomeAddressForm from '../../../Profile/forms/HomeAddressForm';
import ProfileForm from '../../../Profile/forms/ProfileForm';
import AddressDistance from '../components/AddressDistance';

const allowWatchOnly = false;

const FormRegister = ({
    event,
    registerFormValues,
    profile,
    onSubmitHandler,
    selectedSession,
    isWaitlistBooking
}) => {

    const paidEvent = (event.price - event.rebate) > 0;

    const addressIsValid = Boolean(profile.data.homeAddress && profile.data.homeSuburb && profile.data.homeState && profile.data.homePostcode);
    const phoneIsValid = Boolean(profile.data.mobile);
    const isVirtualEvent = (event.type === 'Virtual');

    const [isEditingAddress, setIsEditingAddress] = useState(!addressIsValid && !isVirtualEvent);
    const [isEditingPhone, setIsEditingPhone] = useState(!phoneIsValid);

    let { spotsAvailable, canRefund } = selectedSession;
    let daysToEvent = getDaysToEvent(selectedSession.eventStartDate);

    const [isAddressValid, setIsAddressValid] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState("");
    const [watchOnly, setWatchOnly] = useState(false);

    const addressCallback = (isAddressValid_returned, address_returned, watchOnly_returned) => {
        setIsAddressValid(isAddressValid_returned);
        setDeliveryAddress(address_returned);
        setWatchOnly( watchOnly_returned );
    }

    const modifyBooking = (values, formikBag) => {
        if (isVirtualEvent && watchOnly && !allowWatchOnly) {
            formikBag.setSubmitting(false);
        } else if (
            (isAddressValid && isVirtualEvent)
            || !event.eventLocationCentre
            || !isVirtualEvent
        ) {
            values.deliveryAddress = deliveryAddress;
            values.watchOnly = watchOnly;
            onSubmitHandler(values);
        } else {
            formikBag.setSubmitting(false);
        }
    }

    var hasValidated = false;

    const validateForm = (values) => {
        hasValidated = true;

        let validation = validate(values, {
            guestFirstName: function (value, attributes, attributeName, options, constraints) {
                if (attributes.hasGuest === ("false" || false)) return null;
                return {
                    presence: {
                        message: "Please enter your guest's first name",
                        allowEmpty: false
                    },
                };
            },

            guestLastName: function (value, attributes, attributeName, options, constraints) {
                if (attributes.hasGuest === ("false" || false)) return null;
                return {
                    presence: {
                        message: "Please enter your guest's last name",
                        allowEmpty: false
                    }
                };
            },

            guestEmail: function (value, attributes, attributeName, options, constraints) {
                if (attributes.hasGuest === ("false" || false)) return null;
                return {
                    presence: {
                        message: "Please enter an email address for your guest",
                        allowEmpty: false
                    }
                };
            },

            understand: {
                presence: {
                    message: "This field is required",
                    allowEmpty: false
                },
                inclusion: {
                    within: [true],
                    message: "You need to check the checkbox"
                }
            },

            norefund: function (value, attributes, attributeName, options, constraints) {
                if (attributes.norefund === true || (canRefund && !event.nonRefundable) || !paidEvent) return null;
                return {
                    presence: {
                        message: "This field is required",
                        allowEmpty: false
                    },
                    inclusion: {
                        within: [true],
                        message: "You need to check the checkbox"
                    }
                };
            },

        }, { format: "firstError", fullMessages: true });

        return validation;
    }

    return (
        <>
            <h2 className="aui-headline-3 mt-4 mb-7"><b>Register</b></h2>

            {profile && !isEditingAddress && !isVirtualEvent && (
                <div className="your-details">
                    <div className="aui-textfield aui-textfield--multiline mb-6">
                        <div className="aui-textfield__field is-focused">
                            <div className="aui-textfield__label">Address</div>
                            <div className="your-details__name">
                                {profile.data.firstName} {profile.data.lastName}
                            </div>
                            <div className="your-details__address">
                                {profile.data.homeAddress}
                                <br />
                                {profile.data.homeSuburb}
                                <br />
                                {profile.data.homeState}
                                {', '}
                                {profile.data.homePostcode}
                            </div>
                        </div>
                    </div>
                    <button onClick={() => { setIsEditingAddress(true); }} className="aui-button your-details__edit">
                        <span className="aui-button__text-icon">
                            <span className="aui-button__text">Edit</span>
                            <IconEdit small />
                        </span>
                    </button>
                </div>
            )}

            {isEditingAddress && <div className="mb-5">
                {addressIsValid && <p>Edit your address</p>}
                {!addressIsValid && <p className="aui-color-text-red">Your address appears to be incomplete. Please update it below.</p>}
                <p>(This will update your profile.)</p>
                <HomeAddressForm cb={() => { setIsEditingAddress(false); }} onCancel={addressIsValid ? () => { setIsEditingAddress(false); } : false} loadingBgColour="gray90" />
            </div>}

            <Formik
                initialValues={registerFormValues}
                validate={validateForm}
                onSubmit={modifyBooking} >
                {formikBag => (
                    <Form>

                        {profile && isVirtualEvent && event.eventLocationCentre && event.eventLocationRadius && (
                            <div className="form-field mb-5">
                                <AddressDistance name="addressDistance"
                                    eventLocationRadius={event.eventLocationRadius}
                                    eventLocationLat={event.eventLocationCentre.lat}
                                    eventLocationLng={event.eventLocationCentre.lng}
                                    addressCallback={addressCallback}
                                    parentValidated={hasValidated}
                                    profileAddress={profile.data.homeAddress + ' ' + profile.data.homeSuburb + ' ' + profile.data.homeState}
                                    allowWatchOnly={allowWatchOnly}
                                />
                            </div>
                        )}

                        {event.allowsGuest && (spotsAvailable > 1 || event.isDoubleBooking) && (!isWaitlistBooking || event.waitlist.hasGuest || event.isDoubleBooking) &&
                            <FormField
                                name="hasGuest"
                                label={(event.isGuestFree || event.isDoubleBooking) ? "This ticket is for 2 people. Are you bringing a guest?" : "Would you like to bring a guest?"}
                                fieldType={FIELD_RADIOS}
                                options={[
                                    { label: 'Yes', value: 'true' },
                                    { label: 'No', value: 'false' },
                                ]}
                                formikBag={formikBag}
                            />
                        }
                        {formikBag.values && formikBag.values.hasGuest === 'true' && (
                            <>
                                <div className="row">
                                    <div className="col-small-6">
                                        <FormField name="guestFirstName" label="Guest first name" formikBag={formikBag} />
                                    </div>
                                    <div className="col-small-6">
                                        <FormField name="guestLastName" label="Guest last name" formikBag={formikBag} />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <FormField name="guestEmail" label="Guest email address" formikBag={formikBag} type="email" />
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="row">
                            <div className="col">
                                <FormField
                                    name='dietaryRequirements'
                                    fieldType={FIELD_TEXTAREA}
                                    formikBag={formikBag}
                                    label={
                                        `Do you ${formikBag.values.hasGuest === 'true' ? ' or your guest' : ''} have any special requirements (e.g. food allergies, accessibility)?`
                                    }
                                />
                            </div>
                        </div>
                        
                        <FormField
                            name="notifySMS"
                            label="Would you like to receive an SMS reminder?"
                            fieldType={FIELD_RADIOS}
                            options={[
                                { label: 'Yes', value: 'true' },
                                { label: 'No', value: 'false' },
                            ]}
                            formikBag={formikBag}
                        />
                        {formikBag.values && formikBag.values.notifySMS === 'true' && <>
                            {!isEditingPhone && <div className="your-details">
                                <div className="aui-textfield aui-textfield--multiline mb-6">
                                    <div className="aui-textfield__field is-focused">
                                        <div className="aui-textfield__label">Mobile phone</div>
                                        <div className="your-details__phone">{profile.data.mobile}</div>
                                    </div>
                                </div>
                                <button onClick={() => { setIsEditingPhone(true); }} className="aui-button your-details__edit">
                                    <span className="aui-button__text-icon">
                                        <span className="aui-button__text">Edit</span>
                                        <IconEdit small />
                                    </span>
                                </button>
                            </div>}
                            {isEditingPhone && <div className="mb-5">
                                {phoneIsValid && <p>Edit your mobile number</p>}
                                {!phoneIsValid && <p className="aui-color-text-red">Your mobile number appears to be incorrect. Please update it below.</p>}
                                <p>(This will update your profile.)</p>
                                <ProfileForm phoneOnly cb={() => { setIsEditingPhone(false); }} onCancel={phoneIsValid ? () => { setIsEditingPhone(false); } : false} loadingBgColour="gray90" />
                            </div>}
                        </>}
                        
                        <div className="pt-5" />
                        
                        <div className="row mt-4">
                            <div className="col">
                                <FormField name='understand'
                                    fieldType={FIELD_CHECKBOX}
                                    formikBag={formikBag}
                                    label={
                                        <span>
                                            I have read and accept the event <a href={AE_TERMS_URL} target="_blank" rel="noopener noreferrer" className="aui-textlink">terms and conditions</a>
                                        </span>
                                    }
                                />
                            </div>
                        </div>
                        {event && (!canRefund || event.nonRefundable) && paidEvent &&
                            <div className="row">
                                <div className="col">
                                    <FormField name='norefund'
                                        fieldType={FIELD_CHECKBOX}
                                        formikBag={formikBag}
                                        label={event.nonRefundable ? (
                                            <span>
                                                This is a non-refundable event.
                                                I understand that if I purchase a ticket and decide to cancel my booking I will not be entitled to a refund of the ticket price.
                                            </span>
                                        ) : (
                                            <span>
                                                This event starts in less than {daysToEvent > 1 ? daysToEvent + ' days' : '1 day'}.
                                                I understand that if I purchase a ticket and decide to cancel my booking I will not be entitled to a refund of the ticket price.
                                            </span>
                                        )}
                                    />
                                </div>
                            </div>
                        }
                        <Button
                            buttonType="primary"
                            label="Next"
                            icon={<IconForward small />}
                            iconPosition="right"
                            type="submit"
                            onClick={() => { gtmPush("AudiExperience", "eventRegisterNext", event.name); }}
                            disabled={formikBag.isSubmitting || (!addressIsValid && !isVirtualEvent) || (!phoneIsValid && formikBag.values.notifySMS === 'true')}
                        />

                        {!addressIsValid && !isVirtualEvent && <p className="my-4 aui-color-text-red">Please update your address before booking this event.</p>}
                        {!phoneIsValid && formikBag.values.notifySMS === 'true' && <p className="my-4 aui-color-text-red">Please update your mobile number to receive an SMS reminder.</p>}
                    </Form>
                )}
            </Formik>
        </>
    );
}

const mapStateToProps = state => {
    return {
        event: state.experience.currentEvent,
        isAuthed: state.profile.isAuthed,
        profile: state.profile,
    };
};
export default connect(mapStateToProps)(FormRegister);
