import React, { useEffect, useState } from 'react';
import HeroSection from '../../../components/HeroSection';
import Crumb from '../../../components/Crumb';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import IconArrowLeft from '../../../audi-ui-components/icons/ArrowLeftNormal';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { request } from '../../../lib/apiRequestWrapper';
import {
  PATH_EXPERIENCE,
  PATH_FAQ,
  PATH_LOGIN,
  BREAKPOINTS,
  FIELD_RADIOS,
  FIELD_CHECKBOXES,
  FIELD_TEXTAREA
} from '../../../constants';

import { connect } from 'react-redux';
const mapStateToProps = state => {
  return {
    profileData: state.profile.data,
    isAuthed: state.profile.isAuthed
  };
};

const ryiQuestions = {
  q1: "1. Would you like to learn more about your current vehicle or upcoming models?",
  q2: "2. Would you prefer in person or virtual events?",
  q3: "3. Do you prefer weekday or weekend events?",
  q4: "4. What are you interested in learning about? (Pick up to 3)"
};

const RYIEvent = ({ profileData, isAuthed, location }) => {
  
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [isLoading, setIsLoaading] = useState(true);
  const [hasPrevResponse, setHasPrevResponse] = useState(false);
  
  const handleSubmit = (values, formikProps) => {
    request(
        `${process.env.RAZZLE_API}/1/experience/ryiForm`,
        {
            method: "POST",
            body: JSON.stringify({
              question1: ryiQuestions.q1,
              answer1: values.q1,
              question2: ryiQuestions.q2,
              answer2: values.q2,
              question3: ryiQuestions.q3,
              answer3: values.q3,
              question4: ryiQuestions.q4,
              answer4: values.q4.join(","),
              answer4Other: values.q4other
            })
        }
    ).then((response) => {
        // console.log(response);
        setIsSubmitted(true);
        setSubmitSuccess(true);
        formikProps.setSubmitting(false);
    }).catch((error) => {
        console.error(error);
        setSubmitError(true);
        setIsSubmitted(true);
        formikProps.setSubmitting(false);
    });
  }
  
  const handleValidate = (values) => {
    let errs = {};
    if (!values.q1 || values.q1.length === 0) { errs.q1 = "Please select an option"; }
    if (!values.q2 || values.q2.length === 0) { errs.q2 = "Please select an option"; }
    if (!values.q3 || values.q3.length === 0) { errs.q3 = "Please select an option"; }
    if (!values.q4 || values.q4.length === 0) { errs.q4 = "Please select an option"; }
    if (values.q4 && values.q4.indexOf("Other") > -1 && !values.q4other) { errs.q4other = "Please provide details"; }
    return errs;
  }
  
  useEffect(()=>{
    window.scrollTo(0, 0);
    request(
        `${process.env.RAZZLE_API}/1/experience/getUserRyiResponse`,
        {
            method: "POST"
        }
    ).then((response) => {
        // console.log(response);
        if (response.answer1) {
          setHasPrevResponse(true);
          setIsSubmitted(true);
          setSubmitSuccess(true);
        }
        setIsLoaading(false);
    }).catch((error) => {
        console.error(error);
        setIsLoaading(false);
    });
  }, []);
  
  return (
    <div className="page-wrapper">
      <HeroSection
          className="mb-2"
          content={[{
              id: 'hero',
              url: `${process.env.PUBLIC_PATH}assets/ryi-hero.jpg`,
              type: 'image'
          }]}
          defaultType="image" gradient imgWide>
          <div className="pt-small-7 row">
              <div className="col-small-auto">
                  <Link to={PATH_EXPERIENCE}><IconArrowLeft large /></Link>
              </div>
              <div className="col">
                  <h2 className="aui-headline-6 mb-3">
                      <small>Register your interest</small>
                  </h2>
                  <h1 className="aui-headline-3 headline-margin mb-small-6 ">
                      <b>Model Education event with Audi Australia Product Experts</b>
                  </h1>
              </div>
          </div>
      </HeroSection>
      
      <div className="content-wrapper">
        <Crumb className="mb-4" />
        
        <section className="mb-7">
          <h4 className="aui-headline-4 mb-5"><b>Model Education event with Audi Australia Product Experts</b></h4>
          <p className="mb-3">Whether you have just bought a new Audi or simply want to delve deeper into the technology at your disposal, this event hosted by Audi product experts, is designed to provide you the chance to discover more about your Audi model.</p>
          <p>We invite you to register your interest for this Audi Model Education event with Audi Australia Product Experts. The experience will be interactive and provide the opportunity to ask questions.</p>
        </section>
      
        <section className="event-registration outdent aui-color-gray90">
          <div className="content-wrapper py-7">
            
            {!profileData?.isLoyaltyEligible && isAuthed && <Link to={PATH_FAQ + "/audi-experience"} className="aui-button aui-button--primary">
                <span className="aui-button__text">View Eligibility</span>
            </Link>}

            {!isAuthed && <Link to={{ pathname: PATH_LOGIN, state: { from: location } }} className="aui-button aui-button--primary">
                <span className="aui-button__text">Login to register</span>
            </Link>}
            
            {isAuthed && profileData?.isLoyaltyEligible && submitSuccess &&
              <div className="mb-7">
                <h4 className="aui-headline-4 mb-5">Register your interest</h4>
                {!hasPrevResponse && <p>Thank you! We will be in touch.</p>}
                {hasPrevResponse && <p>You’ve already registered your interest in this event. We’ll be in touch soon.</p>}
              </div>
            }
            
            {isAuthed && profileData?.isLoyaltyEligible && !submitSuccess && <Formik onSubmit={handleSubmit} initialValues={{}} validate={handleValidate}>
              {formikProps => (
                <Form className="position-relative">
                  {(formikProps.isSubmitting || isLoading) && <LoadingOverlay bgColour="gray95" />}
                  <h4 className="aui-headline-4 mb-5">Register your interest</h4>
                  <p className="mb-7">Tell us more about what you would like to experience from the event:</p>
                  <FormField fieldType={FIELD_RADIOS}
                    name="q1"
                    label={ryiQuestions.q1}
                    options={["My current vehicle", "Upcoming models"]}
                    formikBag={formikProps}
                    className="mb-7"
                  />
                  <FormField fieldType={FIELD_RADIOS}
                    name="q2"
                    label={ryiQuestions.q2}
                    options={["In person", "Virtual", "No preference"]}
                    formikBag={formikProps}
                    className="mb-7"
                  />
                  <FormField fieldType={FIELD_RADIOS}
                    name="q3"
                    label={ryiQuestions.q3}
                    options={["Weekday", "Weekend"]}
                    formikBag={formikProps}
                    className="mb-7"
                  />
                  <FormField fieldType={FIELD_CHECKBOXES}
                    name="q4"
                    label={ryiQuestions.q4}
                    maxLength={3}
                    options={["Upcoming model launches", "Technology in car", "Electric vehicles", "Drive range and driver assisance", "Other"]}
                    formikBag={formikProps}
                  />
                  {formikProps.values?.q4?.indexOf("Other") > -1 && <FormField fieldType={FIELD_TEXTAREA}
                    name="q4other"
                    label="Other (please specify)"
                    maxLength={2000}
                    showCount
                    formikBag={formikProps}
                  />}
                  {isSubmitted && submitError && <div className="mt-7">There was a problem submitting the form</div>}
                  <div className="mt-7">
                    <Button variant="primary" type="submit">Submit</Button>
                  </div>
                </Form>
              )}
            </Formik>}
          </div>
        </section>
      </div>
    </div>
  );
}

export default connect(mapStateToProps)(RYIEvent);
