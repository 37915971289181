import React from 'react';

import SystemYoutube from '../audi-ui-components/icons/SystemYoutube';
import SystemInstagram from '../audi-ui-components/icons/SystemInstagram';
import SystemFacebook from '../audi-ui-components/icons/SystemFacebook';

const SocialIcons = () => {
  return (
    <ul className="my-3 my-large-0 p-0 d-inline-flex">
      <li>
        <a
          className="pr-3 pr-medium-2 pr-large-3 d-block"
          href="https://www.youtube.com/user/AudiAustralia"
          target="_blank"
          rel="noopener noreferrer">
          <SystemYoutube small />
        </a>
      </li>
      <li>
        <a
          className="px-3 px-medium-2 px-large-3 d-block"
          href="https://www.instagram.com/audiaustralia/"
          target="_blank"
          rel="noopener noreferrer">
          <SystemInstagram small />
        </a>
      </li>
      <li>
        <a
          className="pl-3 pl-medium-2 pl-large-3 d-block"
          href="https://www.facebook.com/AudiAustralia"
          target="_blank"
          rel="noopener noreferrer">
          <SystemFacebook small />
        </a>
      </li>
    </ul>
  );
};

SocialIcons.propTypes = {};

export default SocialIcons;
