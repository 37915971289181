export const APPLICATION_CONTAINER_ID = 'AppContainer';

export const PATH_HOME = '/';
export const PATH_LOGIN = '/login';
export const PATH_REGISTER = '/signup';
export const PATH_FORGOT_PASS = '/forgot-password';
export const PATH_REQUEST_CONFIRMAION = '/request-email-confirmation';
export const PATH_CONFIRM_EMAIL = '/confirm';
export const PATH_RESET_PASS = '/reset';

export const PATH_PROFILE = '/profile';
export const PATH_VEHICLES = '/vehicles';
export const PATH_FINANCE = '/finance';

export const PATH_EXPERIENCE = '/experience';
export const PATH_EXPERIENCE_OVERVIEW = '/audi-experience';
export const PATH_EVENT = '/experience/event';
export const PATH_OFFER = '/experience/offer';
export const PATH_MY_BOOKINGS = '/experience/my-bookings';

export const PATH_FAQ = '/faq';
export const PATH_BENEFITS = '/ownership-benefits';

export const pathRequiresAuth = (path) => {
  let a = [PATH_PROFILE, PATH_VEHICLES, PATH_FINANCE, PATH_MY_BOOKINGS];
  let r = false;
  for (let i=0; i < a.length; i++) {
    if (path.indexOf(a[i]) === 0) {
      r = true;
      break;
    }
  }
  return r;
}

export const SF_DATE_FORMAT = 'DD/MM/YYYY';

// Form field constants
export const FIELD_CHECKBOX = 'FIELD_CHECKBOX';
export const FIELD_CHECKBOXES = 'FIELD_CHECKBOXES';
export const FIELD_RADIOS = 'FIELD_RADIO';
export const FIELD_SELECT = 'FIELD_SELECT';
export const FIELD_DEALER_SELECT = 'FIELD_DEALER_SELECT';
export const FIELD_DROPDOWN = 'FIELD_DROPDOWN';
export const FIELD_TEXT = 'FIELD_TEXT';
export const FIELD_TEXTAREA = 'FIELD_TEXTAREA';
export const FIELD_SWITCH = 'FIELD_SWITCH';
export const FIELD_DATEPICKER = 'FIELD_DATEPICKER';
export const LABEL_OPTIN = `Please keep me up to date with occasional communications on events, experiences, news, products and offers.`;

export const OPTS_STATES = [
  {value: 'ACT', label: 'ACT'},
  {value: 'NSW', label: 'New South Wales'},
  {value: 'NT', label: 'Northern Territory'},
  {value: 'QLD', label: 'Queensland'},
  {value: 'SA', label: 'South Australia'},
  {value: 'TAS', label: 'Tasmania'},
  {value: 'VIC', label: 'Victoria'},
  {value: 'WA', label: 'Western Australia'},
];

// Vehicle images -- add {vehcile.vin}.png to end
export const MEDIASERVICE = 'https://mediaservice.audi.com/media/live/';

export const VWFS_CUSTOMER_ROLES = {
  "P": "Primary Borrower",
  "C": "Co-Borrower",
  "G": "Guarantor"
};

export const AE_ITEMS_PER_PAGE = 20;

export const INTERESTS = [
  { label: 'Culture', slug: 'culture' },
  { label: 'Film and theatre', slug: 'film' },
  { label: 'Sport', slug: 'sport' },
  { label: 'Motorsport', slug: 'motorsport' },
  { label: 'Food and wine', slug: 'food' },
  { label: 'Fashion', slug: 'fashion' },
  { label: 'Lifestyle', slug: 'lifestyle'}
];

export const EXP_MONTHS = [
  { value: 1, label: '01' },
  { value: 2, label: '02' },
  { value: 3, label: '03' },
  { value: 4, label: '04' },
  { value: 5, label: '05' },
  { value: 6, label: '06' },
  { value: 7, label: '07' },
  { value: 8, label: '08' },
  { value: 9, label: '09' },
  { value: 10, label: '10' },
  { value: 11, label: '11' },
  { value: 12, label: '12' },
];
export const EXP_YEARS = [
  { value: 2020, label: '2020' },
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
  { value: 2024, label: '2024' },
  { value: 2025, label: '2025' },
  { value: 2026, label: '2026' },
  { value: 2027, label: '2027' },
  { value: 2028, label: '2028' },
];

export const BREAKPOINTS = {
  tiny: 480,
  xsmall: 600,
  small: 740,
  medium: 960,
  large: 1264,
  xlarge: 1424
};

export const AE_REFUND_POLICY_URL = 'https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms-and-conditions/audi-experience.html';
export const AE_TERMS_URL = 'https://www.audi.com.au/au/web/en.html#layer=/au/web/en/terms-and-conditions/audi-experience.html';
export const EMAIL_CUSTOMER_SERVICE = 'customerassistance@audi-info.com.au';
export const PHONE_CUSTOMER_SERVICE = '1800 502 834';
export const AUDI_SHOP_URL = 'https://audishop-australia.myshopify.com/';
export const AUDI_BOOK_SERVICE_URL = 'https://consumer.xtime.net.au/scheduling/?variant=AUDIAUSTRALIA&bx=437950&webKey=';
export const AUDI_SERVICE_PLAN_URL = 'https://www.audi.com.au/au/web/en/owners/service/audi-genuine-care-service-plans.html';
export const AUDI_SERVICE_PLAN_TERMS = 'https://www.audi.com.au/content/dam/nemo/australia/Owners/audi_service/genuine_care_services_plan/Audi_Genuine_Care_-_5_Year_Service_Plan_-_Terms_and_Conditions.pdf';

export const GOOGLE_API_KEY = 'AIzaSyAUWuCNXOLCJ71YctJmhS1JCcDBV08Umi8';

export const MAINTENANCE = false;

export const FEATURE = {
    buyServicePlan: true
};

export const JELLYBEAN_MAP = {
  "a1": ["a1"],
  "a3": ["a3", "s3", "rs 3"],
  "a4": ["a4", "s4", "rs 4"],
  "a5": ["a5", "s5", "rs 5"],
  "a6": ["a6", "s6", "rs 6"],
  "a7": ["a7", "s7", "rs 7"],
  "a8": ["a8", "s8"],
  "etrongt": ["e-tron gt"],
  "q2": ["q2"],
  "q3": ["q3"],
  "q4etron": ["q4"],
  "q5": ["q5"],
  "q7": ["q7"],
  "q8": ["q8"],
  "tt": ["tt"],
};
