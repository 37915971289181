import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LoadingOverlay from '../../../components/LoadingOverlay';
import IconEdit from '../../../audi-ui-components/icons/Edit';
import IconCancel from '../../../audi-ui-components/icons/Cancel';
import Button from '../../../audi-ui-components/Button';
import TextField from '../../../audi-ui-components/TextField';
import { request } from '../../../lib/apiRequestWrapper';

class LocationEditSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditing: false,
      isLoadingList: false,
      error: null,
      isSubmitting: false,
      addressText: '',
      selectedMoniker: null,
      list: [],
    };
    this.fetchTimeout = null;
  }

  handleToggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  handleSubmitError = error => {
    this.setState({ error, isSubmitting: false });
  };

  handleSubmitSuccess = response => {
    this.setState({
      error: null,
      isSubmitting: false,
      list: [],
      selectedMoniker: null,
      addressText: '',
    });
    if (this.props.closeOnSuccess) {
      this.handleToggleEdit();
    }
  };

  handleSubmit = () => {
    if (typeof this.props.onSubmit === 'function') {
      this.setState({ isSubmitting: true });
      this.props.onSubmit(
        {
          identifier: this.props.location.identifierId,
          monikerId: this.state.selectedMoniker,
          customerNumber: this.props.customerNumber,
        },
        this.handleSubmitSuccess,
        this.handleSubmitError
      );
    }
  };

  handleChange = val => {
    clearTimeout(this.fetchTimeout);
    this.setState({ addressText: val, selectedMoniker: null });
    if (val || val.length > 3) {
      this.fetchTimeout = setTimeout(this.getSuggestions, 500);
    } else {
      this.setState({ list: [] });
    }
  };

  getSuggestions = () => {
    this.setState({ isLoadingList: true });
    request(`${process.env.RAZZLE_API}/1/vwfs/address/search`, {
      params: { query: this.state.addressText },
    })
      .then(response => {
        this.setState({ isLoadingList: false, list: response });
      })
      .catch(error => {
        console.error(error);
        this.setState({ isLoadingList: false, error: error });
      });
  };

  handleSelect = item => {
    this.setState({ addressText: item.Text, selectedMoniker: item.Moniker });
  };

  render() {
    const { isEditable, className, style, location, theme } = this.props;
    const isEditing = this.state.isEditing;
    return (
      <div
        className={classnames('editable', className, {
          editing: isEditing,
          'not-editing': !isEditing,
        })}
        style={style}>
        <header className="d-flex justify-content-between">
          <h5 className="aui-headline-4 title">
            <b>{location.locationDesc}</b>
          </h5>
          {isEditable && !isEditing && (
            <button
              className={classnames("aui-button edit-btn edit", {[`aui-theme-${theme}`]: theme})}
              onClick={this.handleToggleEdit}
            >
              <IconEdit small /> <span className="edit-btn__text">Edit</span>
            </button>
          )}
          {isEditable && isEditing && (
            <button className={classnames("aui-button edit-btn cancel", {[`aui-theme-${theme}`]: theme})} onClick={this.handleToggleEdit}>
              <IconCancel small /> <span className="edit-btn__text">Cancel</span>
            </button>
          )}
        </header>

        {isEditing && (
          <div className="fields editing">
            {this.state.isSubmitting && <LoadingOverlay bgColour={this.props.loadingBgColour} />}
            <p>
              Start typing your address and select it from the list that appears
              below.
            </p>
            <div className="finance__suggestion-input-wrapper mb-3">
              <TextField
                name="address"
                value={this.state.addressText}
                type="text"
                labelText="Address"
                onChange={this.handleChange}
                autoComplete="off"
                theme={this.props.theme || null}
              />
              {this.state.addressText &&
                this.state.addressText.length > 3 &&
                !this.state.selectedMoniker && (
                  <div className="list">
                    {this.state.isLoadingList && <LoadingOverlay />}
                    {this.state.list.map((item, i) => (
                      <p
                        key={i}
                        className="item"
                        onClick={() => {
                          this.handleSelect(item);
                        }}>
                        {item.Text}
                      </p>
                    ))}
                  </div>
                )}
            </div>
            <p>
              <Button
                label="Save changes"
                buttonType="primary"
                disabled={this.state.selectedMoniker === null}
                onClick={this.handleSubmit}
                theme={this.props.theme || null}
              />
            </p>
            {this.state.error && this.state.error.message && (
              <p className="aui-color-text-red">{this.state.error.message}</p>
            )}
          </div>
        )}

        {!isEditing && (
          <div className="fields not-editing">
            <div>{location.addressLine1}</div>
            <div>{location.addressLine2}</div>
            <div>{location.addressLine3}</div>
            <div>{location.addressLine4}</div>
            <div>{location.postCode}</div>
          </div>
        )}
      </div>
    );
  }
}

LocationEditSection.propTypes = {
  isEditable: PropTypes.bool,
  location: PropTypes.object,
  customerNumber: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onSubmit: PropTypes.func, // should take form data, success callback, error callback
  closeOnSuccess: PropTypes.bool,
};

LocationEditSection.defaultProps = {
  isEditable: false,
  location: {},
  className: '',
  style: {},
};

export default LocationEditSection;
