import { request } from '../lib/apiRequestWrapper';
import cookie from '../lib/cookie';
import _merge from 'lodash/merge';
import moment from 'moment';
import { parsePhoneNumber } from 'libphonenumber-js';
import { SF_DATE_FORMAT } from '../constants';

import { loadVehicles } from './Vehicles';
// import { loadTickets } from './Experience';

import { SET_LIST as VEHICLES_SET_LIST } from './Vehicles';

const SET_PROFILE_DATA = "PROFILE/SET_PROFILE_DATA";
const UPDATE_PROFILE_DATA = "PROFILE/UPDATE_PROFILE_DATA";
const SET_MULTIPLE = "PROFILE/SET_MULTIPLE";
const SET_IS_AUTHED = "PROFILE/SET_IS_AUTHED";
const SET_ALERTS = "PROFILE/SET_ALERTS";
const SET_SERVICE_LOC = "PROFILE/SET_SERVICE_LOC";
const SET_LOADING = "PROFILE/SET_LOADING";
const SET_INIT_DONE = "PROFILE/SET_INIT_DONE";
const SET_UPDATING = "PROFILE/SET_UPDATING";
const SET_ERROR = "PROFILE/SET_ERROR";
const LOGOUT = "PROFILE/LOGOUT";
const DEACTIVATE_PROFILE = "PROFILE/DEACTIVATE";

const SET_VWFS_PROFILE = "PROFILE/SET_VWFS_PROFILE";
const SET_VWFS_ID = "PROFILE/SET_VWFS_ID";

const STATUS_MULTIPLE_PROFILES = 300;
const STATUS_USER_NOT_FOUND = 404;
const STATUS_UNAUTHORISED = 401;

export function loadProfile(cb, errCb, auth) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        dispatch({ type: SET_ERROR, payload: null });

        return request(
            `${process.env.RAZZLE_API}/1/account/profile`,
            false,
            auth
        ).then((data) => {
            // cookie.setItem('auth', JSON.stringify(auth), '', '/');
            if (data.settings) {
                data.settings = JSON.parse(data.settings);
            }
            if (!data.settings || data.settings === null) {
                data.settings = {};
            }
            if (data.loyaltyEligibiltyExpiryDate) {
                data.loyaltyEligibiltyExpiryDate = moment(data.loyaltyEligibiltyExpiryDate, SF_DATE_FORMAT).format("YYYY-MM-DD");
            }
            dispatch({ type: SET_PROFILE_DATA, payload: data });
            dispatch({ type: SET_INIT_DONE, payload: true });
            // dispatch({ type: SET_IS_AUTHED, payload: true });
            // dispatch({ type: SET_LOADING, payload: false });
            // dispatch({ type: SET_LOADED, payload: true });
            let newAuth = data.newAuth || false;
            dispatch(loadVehicles(newAuth));
            // dispatch(loadTickets(newAuth));
            if (data.preferredServiceLocation) {
                dispatch(getPreferredServiceLocation(data.preferredServiceLocation));
            }
            if (!cookie.getItem('alertsdone')) {
              dispatch(getAlerts(newAuth));
            }
            if (data.vwfsId) {
                dispatch(loadVWFSProfile(newAuth));
            }
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            switch (error.status) {
                case STATUS_MULTIPLE_PROFILES:
                    return dispatch(loadAllProfiles(auth));
                case STATUS_USER_NOT_FOUND:
                case STATUS_UNAUTHORISED:
                    dispatch(logout(error));
                    if (typeof errCb === 'function') { errCb(error); }
                    return;
                default:
                    console.error('loadProfile', error);
                    dispatch({ type: SET_LOADING, payload: false });
                    dispatch({ type: SET_INIT_DONE, payload: true });
                    if (typeof errCb === 'function') { errCb(error); }
            }
        });
    };
}

export function noProfile() {
    return (dispatch, getState) => {
        dispatch({ type: SET_INIT_DONE, payload: true });
    };
}

export function loadAllProfiles(_auth) {
    return (dispatch, getState) => {
        const auth = _auth || JSON.parse(cookie.getItem('auth'));

        return request(
            `${process.env.RAZZLE_API}/1/account/profiles`,
            null,
            auth
        ).then((data) => {
            dispatch({ type: SET_MULTIPLE, payload: data });
            dispatch({ type: SET_LOADING, payload: false });
            dispatch({ type: SET_INIT_DONE, payload: true });
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_LOADING, payload: false });
            dispatch({ type: SET_INIT_DONE, payload: true });
        });
    };
}

// in case multiple profiles are found - happens rarely
export function selectGoldenRecord(id, successCallback, errorCallback) {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        return request(
            `${process.env.RAZZLE_API}/1/account/profile/select`,
            {
                method: "PUT",
                body: JSON.stringify({ id }),
            }
        ).then(() => {
            dispatch(loadProfile());
            if (typeof successCallback === 'function') {
                successCallback();
            }
        }).catch((error) => {
            if (typeof errorCallback === 'function') {
                dispatch({ type: SET_LOADING, payload: false });
                errorCallback(error);
            }
        });
    };
}

export function logout(error) {
    return (dispatch, getState) => {
        // console.log("logout", error);
        cookie.removeItem('auth', '/');
        cookie.removeItem('alertsdone', '/');
        setTimeout(() => {
            dispatch({ type: LOGOUT });
            dispatch({ type: VEHICLES_SET_LIST, payload: null });
            if (error) {
                dispatch({ type: SET_ERROR, payload: error });
            }
        }, 300);
    }
}

export function updateProfile(formData, cb, errorCb) {
    return (dispatch, getState) => {
        // console.log("update profile", formData);
        dispatch({ type: SET_UPDATING, payload: true });
        let oldSettings = getState().profile.data.settings;
        let newSettings = oldSettings;
        if (formData.settings) {
            newSettings = Object.assign({}, oldSettings, formData.settings);
            formData.settings = JSON.stringify(newSettings);
        }
        return request(
            `${process.env.RAZZLE_API}/1/account/profile`,
            {
                method: "PATCH",
                body: JSON.stringify(formData),
            }
        ).then(() => {
            if (formData.settings) {
                formData.settings = newSettings;
            }
            dispatch({ type: UPDATE_PROFILE_DATA, payload: formData });
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_UPDATING, payload: false });
            if (typeof errorCb === 'function') { errorCb(error); }
        });
    };
}
export function updatePreferences(formData, cb, errorCb) {
    return (dispatch, getState) => {
        dispatch({ type: SET_UPDATING, payload: true });
        const profile = getState().profile.data;
        var data = {...formData, id: profile.id, firstName: profile.firstName, lastName: profile.lastName, email: profile.email, mobile: profile.mobile};
        return request(
            `${process.env.RAZZLE_API}/1/account/profile/preferenceCentre`,
            {
                method: "PATCH",
                body: JSON.stringify(data),
            }
        ).then(() => {
            dispatch({ type: UPDATE_PROFILE_DATA, payload: formData });
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_UPDATING, payload: false });
            if (typeof errorCb === 'function') { errorCb(error); }
        });
    };
}

export function deactivateProfile(formData, cb, errorCb) {
    return (dispatch, getState) => {
        dispatch({ type: SET_UPDATING, payload: true });
        return request(
            `${process.env.RAZZLE_API}/2/account/deleteUser`,
            {
                method: "POST",
                body: JSON.stringify(formData),
            }
        ).then(() => {
            dispatch({ type: SET_UPDATING, payload: false });
            if (typeof cb === 'function') { cb(); }
        }).catch((error) => {
            console.error(error);
            dispatch({ type: SET_UPDATING, payload: false });
            if (typeof errorCb === 'function') { errorCb(error); }
        });
    };
}

export function changePassword(formData, cb, errorCb) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/2/account/password/change`,
            {
                method: "PUT",
                body: JSON.stringify({previousPassword: formData.oldPassword, newPassword: formData.newPassword}),
            }

        ).then(() => {
            if (typeof cb === 'function') { cb(); }

        }).catch((error) => {
            console.error(error);
            if (typeof errorCb === 'function') { errorCb(error); }
        });
    };
}

export function getAlerts(auth) {
    return (dispatch, getState) => {
        // const auth = _auth || JSON.parse(cookie.getItem('auth'));

        return request(
            `${process.env.RAZZLE_API}/1/account/alerts`, {}, auth
        ).then((data) => {
            dispatch({ type: SET_ALERTS, payload: data });
            cookie.setItem('alertsdone', true, '', '/');
        }).catch((error) => {
            console.error(error);
        });

    }
}

export function getPreferredServiceLocation(id) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/1/services/locations?$filter=id eq '${id}'`
        ).then((data) => {
            dispatch({ type: SET_SERVICE_LOC, payload: data[0] });
        }).catch((error) => {
            console.error(error);
        });
    }
}

export function enableMFA(cb, errCb) {
    return (dispatch, getState) => {
        let mobile = getState().profile.data.mobile;
        let parsed = parsePhoneNumber(mobile, 'AU');
        if (!parsed.isPossible()) {
          if (typeof errCb === "function") { return errCb("Phone number is invalid"); }
        }
        // console.log(parsed);
        return request(
            `${process.env.RAZZLE_API}/2/account/enableMFA`,
            {
              method: "POST",
              body: JSON.stringify({enableMFA: true, userAttributes: [{name: "phone_number", value: parsed.number}]})
            }
        ).then(() => {
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            // console.error(error);
            if (typeof errCb === "function") { errCb(error); }
        });
    }
}

export function verifyMFA(code, cb, errCb) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/2/account/verifyphonenumber`,
            {
              method: "POST",
              body: JSON.stringify({code: code})
            }
        ).then(() => {
            dispatch({ type: UPDATE_PROFILE_DATA, payload: {hasMFAEnabled: true} });
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            // console.error(error);
            if (typeof errCb === "function") { errCb(error); }
        });
    }
}

export function disableMFA(cb, errCb) {
    return (dispatch, getState) => {
      console.log("test");
        return request(
            `${process.env.RAZZLE_API}/2/account/disableMFA`, {method: "POST"}
        ).then(() => {
            dispatch({ type: UPDATE_PROFILE_DATA, payload: {hasMFAEnabled: false} });
            if (typeof cb === "function") { cb(); }
        }).catch((error) => {
            // console.error(error);
            if (typeof errCb === "function") { errCb(error); }
        });
    }
}

/*
 * VWFS Profile functions
 */

export function setHasVWFS(vwfsId) {
    return (dispatch, getState) => {
        dispatch({ type: SET_VWFS_ID, payload: vwfsId }); // TODO add action
    }
}

export function loadVWFSProfile(auth) {
    return (dispatch, getState) => {
        request(
            `${process.env.RAZZLE_API}/1/vwfs/customer`, {}, auth
        ).then((response) => {
            dispatch({ type: SET_VWFS_PROFILE, payload: response });
        }).catch((error) => {
            console.error(error);
        });
    }
}

export function updateVWFSProfile(formData, successCallback, errorCallback) {
    return (dispatch, getState) => {
        let path = '';
        let method = 'PATCH';
        let _formData = formData;
        if (formData.hasOwnProperty('email')) { // email
            path = 'vwfs/customer/email';
        }
        if (formData.hasOwnProperty('monikerId')) { // location
            path = `vwfs/customer/location/${formData.identifier}`;
            _formData = { monikerId: formData.monikerId };
        }
        if (formData.hasOwnProperty('homePhone') || formData.hasOwnProperty('businessPhone') || formData.hasOwnProperty('mobilePhone')) {
            path = 'vwfs/customer/contacts';
        }

        return request(
            `${process.env.RAZZLE_API}/1/${path}`,
            {
                method,
                body: JSON.stringify(_formData)
            }
        ).then((response) => {
            // refetch profile
            return request(
                `${process.env.RAZZLE_API}/1/vwfs/customer`
            ).then((response) => {
                dispatch({ type: SET_VWFS_PROFILE, payload: response });
                if (successCallback) { successCallback(response); }
            }).catch((error) => {
                if (errorCallback) { errorCallback(error); }
            });
        }).catch((error) => {
            if (errorCallback) { errorCallback(error); }
        });
    }
}

export function updateVWFSPreferences(formData, cb, errorCb) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/1/vwfs/customer/preferences`,
            {
                method: "PATCH",
                body: JSON.stringify(formData)
            }
        ).then((response) => {
          if (cb) { cb(response); }
        }).catch((error) => {
          if (errorCb) { errorCb(error); }
        });
    };
}

export function submitVWFSMobileVerification(formData, successCallback, errorCallback) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/1/vwfs/customer/contacts`,
            {
                method: "PATCH",
                body: JSON.stringify(formData)
            }
        ).then((response) => {
            // refetch profile
            return request(
                `${process.env.RAZZLE_API}/1/vwfs/customer`
            ).then((response) => {
                dispatch({ type: SET_VWFS_PROFILE, payload: response });
                if (successCallback) { successCallback(response); }
            }).catch((error) => {
                if (errorCallback) { errorCallback(error); }
            });
        }).catch((error) => {
            if (errorCallback) { errorCallback(error); }
        });
    }
}

const defaultState = {
    data: null,
    vwfsData: null,
    alerts: null,
    preferredServiceLocation: null,
    isAuthed: false,
    isLoading: false,
    initLoadDone: false, // indicates if the app has attempted to load the profile from the session cookie on initial page load
    isUpdating: false,
    error: null,
    multiples: [],
}

export function profileReducer(state = defaultState, action) {
    switch (action.type) {

        case SET_LOADING:
            return Object.assign({}, state, {
                isLoading: action.payload
            });

        case SET_INIT_DONE:
            return Object.assign({}, state, {
                initLoadDone: action.payload
            });

        case SET_UPDATING:
            return Object.assign({}, state, {
                isUpdating: action.payload
            });

        case SET_IS_AUTHED:
            return Object.assign({}, state, {
                isAuthed: action.payload
            });

        case SET_PROFILE_DATA:
            return Object.assign({}, state, {
                data: action.payload,
                isAuthed: true,
                isLoading: false,
                multiples: []
            });

        case UPDATE_PROFILE_DATA:
            return Object.assign({}, state, {
                data: Object.assign({}, state.data, action.payload),
                isUpdating: false
            });

        case SET_MULTIPLE:
            return Object.assign({}, state, {
                multiples: action.payload
            });

        case SET_ERROR:
            return Object.assign({}, state, {
                error: action.payload
            });

        case SET_VWFS_PROFILE:
            return Object.assign({}, state, {
                vwfsData: action.payload
            });

        case SET_ALERTS:
            return Object.assign({}, state, {
                alerts: action.payload
            });

        case SET_SERVICE_LOC:
            return Object.assign({}, state, {
                preferredServiceLocation: action.payload
            });

        case LOGOUT:
            return Object.assign({}, defaultState, {
                initLoadDone: true
            });

        default:
            return state;
    }
}
