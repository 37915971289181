import React from 'react';
import classnames from 'classnames';
import IconSystemHelp from '../audi-ui-components/icons/SystemHelp';

class Tooltip extends React.Component {

  static defaultProps = {
    trigger: <IconSystemHelp small />,
    style: {}
  }

  constructor(props) {
    super(props);
    this.state = {
      showTip: false
    };
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleOutsideClick, false);
  }

  handleTriggerClick = () => {
    if (!this.state.showTip) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }
    this.setState({showTip: !this.state.showTip});
  }

  handleOutsideClick = (e) => {
    if (this.node.contains(e.target)) {
      return;
    }
    this.handleTriggerClick();
  }

  render() {
    return (
      <span ref={node => { this.node = node; }} className={classnames("tooltip", this.props.className)} style={this.props.style}>
        <span className="trigger" onClick={this.handleTriggerClick}>{this.props.trigger}</span>
        <span className={classnames('tip', {'show': this.state.showTip})}>
          <span className="tip-inner">
            {this.props.tip}
          </span>
        </span>
      </span>
    );
  }
}

export default Tooltip;
