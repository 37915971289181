// Store
import { thunk } from './middleware';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

// Reducers
import {profileReducer} from './Profile';
import {vehiclesReducer} from './Vehicles';
import {financeReducer} from './Finance';
import {expReducer} from './Experience';
import {contentReducer} from './Content';
import {dealersReducer} from './Dealers';

const combinedReducers = combineReducers({
  profile: profileReducer,
  vehicles: vehiclesReducer,
  finance: financeReducer,
  experience: expReducer,
  content: contentReducer,
  dealers: dealersReducer
});
let devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const createAppStore = (initialState) => createStore(combinedReducers, initialState, composeEnhancers(applyMiddleware(thunk)));

// https://github.com/zalmoxisus/redux-devtools-extension
