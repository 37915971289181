import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M22.5,12.5 C22.5,18.6 17.6,23.5 11.5,23.5 C5.4,23.5 0.5,18.6 0.5,12.5 C0.5,6.4 5.4,1.5 11.5,1.5 C17.6,1.5 22.5,6.4 22.5,12.5 Z M11.5,13 L11.5,5 L11.5,13 Z M16,12.5 L11.5,12.5 L16,12.5 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <circle cx="23.5" cy="23.5" r="3"/>
        <path d="M41.5,23.5 C41.5,33.4 33.4,41.5 23.5,41.5 C13.6,41.5 5.5,33.4 5.5,23.5 C5.5,13.6 13.6,5.5 23.5,5.5 C33.4,5.5 41.5,13.6 41.5,23.5 Z" strokeLinejoin="round"/>
        <path d="M23.5,20.5 L23.5,9" strokeLinejoin="round"/>
        <path d="M26.5,23.5 L34,23.5"/>
      </g>
    </svg>
  );
};
