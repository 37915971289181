import React from 'react';
import classnames from 'classnames';
import LoadingOverlay from '../../../components/LoadingOverlay';
import DownloadPDFLink from './DownloadPDFLink';
import moment from 'moment';

class TabStatements extends React.Component {
  render() {
    const { statements, isLoadingStatements, contractId } = this.props;
    return (
      <div className="finance__tab-statements aui-color-gray90 p-3 p-small-6">
        {isLoadingStatements && <LoadingOverlay type="placeholder" />}

        <div className="container-fluid px-small-0">
          <div className="py-6">
            {statements.length > 0 && statements.map((s, i) => (
                <div className="row finance__statement" key={i}>
                  <div className="col">
                    <p className="aui-headline-4 mb-3">{s.description}</p>
                    <p className="">
                      {moment(s.startdate, 'YYYYMMDD').format('DD.MMM YYYY')} -{' '}
                      {moment(s.endDate, 'YYYYMMDD').format('DD.MMM YYYY')}
                    </p>
                  </div>
                  <div className="col-auto">
                    <p style={{ textAlign: 'right' }}>
                      <DownloadPDFLink
                        filename={`${contractId}_Statement_${s.startdate}.pdf`}
                        fetchUrl={`${process.env.RAZZLE_API}/1/vwfs/contract/${contractId}/statementpdf?start=${s.startdate}&end=${s.endDate}`}
                      />
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TabStatements;
