import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { request } from '../../../../lib/apiRequestWrapper';
import { updateDietaryRequirements } from '../../../../redux/Experience';
import IconForward from '../../../../audi-ui-components/icons/Forward';
import Button from '../../../../audi-ui-components/Button';
import { FIELD_TEXTAREA } from '../../../../constants';
import FormField from '../../../../components/FormField';
import { gtmPush } from '../../../../lib/gtm';

const EventModifyRequirementsForm = ({ currentEvent, updateDietaryRequirements, goToSummary }) => {

  let booking = currentEvent && currentEvent.booking;
  let dietaryRequirements = booking && booking.dietaryRequirements;

  const onSubmitHandler = async (values, formikBag) => {

    try {
      let response = await request(
        `${process.env.RAZZLE_API}/1/experience/bookings/${booking.id}/update`,
        {
          method: "PATCH",
          body: JSON.stringify(values),
        }
      );
    } catch(error) {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setErrors(errors);
      }
      return;
    }

    // Slot the new value into state, to avoid refetching the whole event/booking.
    updateDietaryRequirements(values.dietaryRequirements);
    gtmPush("AudiExperience", "eventModifyAttendanceUpdated", currentEvent.name);
    formikBag.setSubmitting(false);

    // return View to Summary
    goToSummary();
    return;
  }

  return (
    <>
      <h2 className="aui-headline-3 mt-4 mb-7"><b>Update Special Requirements</b></h2>
      <Formik
        onSubmit={onSubmitHandler}
        initialValues={{dietaryRequirements: dietaryRequirements}} >
        {formikBag => (
          <Form>
            <div className="row">
              <div className="col">
                <FormField fieldType={FIELD_TEXTAREA} formikBag={formikBag} name='dietaryRequirements'
                  label={`Do you ${formikBag.values.guest ? ' or your guest' : ''}
                          have any special requirements (e.g. food allergies, accessibility)?`} />
              </div>
            </div>
            <Button
              buttonType="primary"
              label="Update"
              icon={<IconForward small />}
              iconPosition="right"
              type="submit"
              disabled={formikBag.isSubmitting} />
            {` `}
            <Button label="Cancel"
              buttonType="secondary"
              onClick={goToSummary}
            />
          </Form>
        )}
      </Formik>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    updateDietaryRequirements: (value) => {
      dispatch(updateDietaryRequirements(value));
    },
  };
};

const mapStateToProps = state => {
  return {
    currentEvent: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    profile: state.profile,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(EventModifyRequirementsForm);
