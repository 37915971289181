import React from 'react';
import PropTypes from 'prop-types';
import Radio from './Radio';
import Fieldset from './Fieldset';

const RadioFieldset = ({ name, label, value, onChange, options, ...rest }) => {
  return (
    <Fieldset title={label} {...rest}
      fields={options.map(opt => <Radio name={name} label={opt.label ? opt.label : opt} value={opt.value ? opt.value : opt} checked={opt.value ? opt.value === value : opt === value} disabled={opt.disabled} onChange={onChange} />)}
      fieldType="radios" />
  );
}

RadioFieldset.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_fieldset-themes.scss
};

export default RadioFieldset;
