import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Spinner from '../audi-ui-components/Spinner';

const LoadingOverlay = (props) => {
  return (
    <div className={classnames("loader", props.type, props.bgColour)}>
      <div className="loader__inner">
        <Spinner isContinuous />
        {props.children && <div>{props.children}</div>}
      </div>
    </div>
  );
}

LoadingOverlay.propTypes = {
  type: PropTypes.oneOf(['overlay', 'placeholder'])
};

LoadingOverlay.defaultProps = {
  type: 'overlay'
};

export default LoadingOverlay;
