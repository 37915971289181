import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M5.5,19.5 L5.5,22.5 C5.5,23.1 5.9,23.5 6.5,23.5 L21.5,23.5 C22.1,23.5 22.5,23.1 22.5,22.5 L22.5,6" stroke="currentColor"/>
          <path d="M0.5,3 L0.5,18.5 C0.5,19 0.9,19.5 1.5,19.5 L13.5,19.5 L18.5,14.5 L18.5,3" stroke="currentColor" strokeLinejoin="round"/>
          <path d="M18.5,14.5 L13.5,14.5 L13.5,19.5 M0,0.5 L19,0.5" stroke="currentColor"/>
          <path d="M4,5.9 L6.1,5.4 L6.7,5.4 L6.7,11.2 L8.5,11.2 L8.5,12 L4,12 L4,11.2 L5.8,11.2 L5.8,6.3 L4,6.7 L4,5.9 Z M9.7,11.7 L9.7,10.9 C10.4,11.1 11.2,11.2 12,11.3 C13.4,11.3 14,10.8 14,10.1 C14,8.9 11.3,8.7 9.9,8.7 L10.1,5.4 L14.6,5.4 L14.6,6.2 L10.9,6.2 L10.8,8 C12.7,8.1 14.9,8.5 14.9,10.1 C14.9,11.2 14,12.1 11.9,12.1 C11.1,12.1 10.4,11.9 9.7,11.7 Z" fill="currentColor" fillRule="nonzero"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M12.5,38.5 L12.5,43.6 C12.5,44.6 13.3,45.5 14.4,45.5 L42.6,45.5 C43.6,45.5 44.5,44.7 44.5,43.6 L44.5,13" stroke="currentColor"/>
        <path d="M3.5,7 L3.5,36.6 C3.5,37.6 4.3,38.5 5.4,38.5 L27.6,38.5 L37.5,28.6 L37.5,7" stroke="currentColor" strokeLinejoin="round"/>
        <path d="M37.5,28.5 L27.5,28.5 L27.5,38.5 M3,2.5 L38,2.5" stroke="currentColor"/>
        <path d="M13,11.9 L9.1,12.8 L9.1,13.6 L12.7,12.8 L12.7,23.8 L9.1,23.8 L9.1,24.6 L17.6,24.6 L17.6,23.8 L14,23.8 L14,11.8 L13,11.8 L13,11.9 Z M22.3,17.2 L21.9,17.2 L22.1,12.8 L29.8,12.8 L29.8,12 L21.1,12 L20.9,18 C26.5,18.1 29.3,19.2 29.3,21.2 C29.3,22.5 28.5,24 24.7,24 C23.2,24 21.8,23.8 20.4,23.4 L20.4,24.3 C21.8,24.7 23.2,24.9 24.7,24.9 C28.5,24.9 30.6,23.6 30.6,21.2 C30.6,18.8 27.8,17.5 22.3,17.2 Z" fill="currentColor" fillRule="nonzero"/>
      </g>
    </svg>
  );
};
