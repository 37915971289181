import React from 'react';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        isLoadingProfile: state.profile.isLoading,
        profile: state.profile.data,
    }
}

class ContactUs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            frameHeight: 0
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('message', this.handleFrameHeight, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleFrameHeight, false);
    }

    handleFrameHeight = (e) => {
        if (e.origin === process.env.RAZZLE_FORMS_URL) {
            var data = JSON.parse( e.data );
            this.setState({frameHeight: data.height});
        }
    }

    render() {
        const {profile} = this.props;
        const {frameHeight} = this.state;
        var q = "?embedded=true&myaudi=true&ui=none";
        if (profile && profile.id) {
            q += `&sfid=${profile.id}`;
        }
        return (
            <div className="page-wrapper">
                <div className="content-wrapper pt-3">
                    <iframe style={{height: frameHeight > 0 ? `${frameHeight}px` : 'auto'}} src={`${process.env.RAZZLE_FORMS_URL}/contact-us${q}`} />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(ContactUs);
