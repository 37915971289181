import React from 'react';
import FormField from '../../../../components/FormField';
import { FIELD_SELECT, EXP_MONTHS, EXP_YEARS } from '../../../../constants';
import { formatCreditCard } from '../../helpers';

const CreditCardInput = ({ formikBag, className }) => {

  return (
    <div className={className}>
      <div className="row">
        <div className="col">
          <FormField
            name="cardNumber"
            formikBag={formikBag}
            label="Card Number"
            formatValue={formatCreditCard}
          />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <FormField
            name="cardMonth"
            fieldType={FIELD_SELECT}
            formikBag={formikBag}
            label="Expiry Month"
            options={EXP_MONTHS}
          />
        </div>
        <div className="col">
          <FormField
            name="cardYear"
            fieldType={FIELD_SELECT}
            formikBag={formikBag}
            label="Expiry Year"
            options={EXP_YEARS}
          />
        </div>
        <div className="col">
          <FormField
            name="cardCVC"
            formikBag={formikBag}
            label="CVC"
          />
        </div>
      </div>
    </div>
  );
}

export default CreditCardInput;
