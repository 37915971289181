import React from 'react';

export default (_props) => {
    const { className, size, large, small, ...props } = _props;

  // small icon
    if ((size && size.toLowerCase() === 'small') || small) {
        return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M17.2549211,12.7792346 L19.6534182,21.1437882 L19.6534182,21.1437882 L17.0060905,20.0823732 L15.3277406,22.3841563 L13.1916827,14.934837 M11.3122109,14.9353793 L9.17630848,22.3841563 L9.17630848,22.3841563 L7.49371152,20.0811553 L4.85063085,21.1437882 L7.24815467,12.782629 M12.25,15 C15.9779221,15 19,11.9779221 19,8.25 C19,4.52207794 15.9779221,1.5 12.25,1.5 C8.52207794,1.5 5.5,4.52207794 5.5,8.25 C5.5,11.9779221 8.52207794,15 12.25,15 Z M9.25,8.29350817 L11.4,10.3935082 L15.2482928,5.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" />
      </svg>
    );
    }

  // large icon
    return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M37.2998972,41.2875763 L32.0052417,39.1647463 L28.6485419,43.7683125 L25.1170895,31.4526746 C28.3426584,31.1781583 31.2323345,29.7258581 33.3542884,27.5276033 L37.2998972,41.2875763 Z M22.8829105,31.4526746 L19.3514581,43.7683125 L15.9862642,39.1623107 L10.7001028,41.2875763 L14.6457116,27.5276033 M24,31.5 C31.1797017,31.5 37,25.6797017 37,18.5 C37,11.3202983 31.1797017,5.5 24,5.5 C16.8202983,5.5 11,11.3202983 11,18.5 C11,25.6797017 16.8202983,31.5 24,31.5 Z M18,19.6 L22.3,23.8 L30.4,13.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd" />
    </svg>
  );
};
