import React from 'react';
import VINHelp from './components/VINHelp';
import CollapsibleSection from '../../components/CollapsibleSection';
import RegisterVehicleForm from './forms/RegisterVehicleForm';
import Crumb from '../../components/Crumb';
import { Helmet } from "react-helmet";

class VehicleRegister extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className="page-wrapper">
                <div className="content-wrapper pb-7">

                    <h2 className="aui-headline-3 mt-5">Add a vehicle</h2>
                    <Crumb className="mb-6" />

                    <div className="row">
                        <div className="col-medium-6 mb-7">
                            <p className="mb-3">
                                If you have just purchased a new or demonstrator vehicle from an Audi dealer, it will appear on myAudi Australia once your dealer processes the sale. This can take up to 72 hours.
                            </p>
                            <p className="mb-3">
                                If you have purchased a pre-owned or privately listed Audi, you can add your vehicle by completing the <span className="d-none d-medium-inline">adjacent</span> <span className="d-medium-none">below</span> form.
                            </p>
                            <p className="mb-7">
                                For assistance, please call Audi Customer Care on <a className="aui-textlink" href="tel:1800502834">1800 50 AUDI (2834)</a> between 8am and 6pm AEST, Monday to Friday. Please have your personal details (including your vehicle proof of purchase, vehicle model, VIN and registration) ready when you call.
                            </p>

                            <div className="aui-color-gray95 p-5">
                                <CollapsibleSection visibleContent={(<p className="aui-headline-5">Where to find your Vehicle Identification Number (VIN)</p>)} toggleOnClickVisible>
                                    <VINHelp />
                                </CollapsibleSection>
                            </div>

                        </div>
                        <div className="col-medium-1"></div>
                        <div className="col-medium-5">
                            <RegisterVehicleForm />
                        </div>
                    </div>
                </div>

                <Helmet>
                    <title>Register your Audi | myAudi Australia</title>
                </Helmet>
            </div>
        );
    }
}

export default VehicleRegister;
