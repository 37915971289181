import React from 'react';
import { connect } from 'react-redux';
import _filter from 'lodash/filter';
import { loadBookedEvents } from '../../redux/Experience';
import HeroSection from '../../components/HeroSection';
import ListItem from './components/ListItem';
import LoadingOverlay from '../../components/LoadingOverlay';
import { Link } from 'react-router-dom';
import IconArrowLeft from '../../audi-ui-components/icons/ArrowLeftNormal';
import { PATH_EXPERIENCE } from '../../constants';

const mapStateToProps = state => {
    return {
        list: state.experience.listBooked,
        isLoading: state.experience.isLoading
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadBookedEvents: () => {
            dispatch(loadBookedEvents());
        }
    };
};

class MyBookings extends React.Component {

    componentDidMount() {
        this.props.loadBookedEvents();
    }

    render() {
        const { list } = this.props;
        const bookings = _filter(list, {hasBooked: true});
        const waitlists = _filter(list, {isOnWaitlist: true});
        return (
            <div className="experience page-wrapper">

                <HeroSection
                    className="mb-2 gradient-overlay"
                    content={[{
                        id: 'bookings-hero',
                        type: 'image',
                        url: `${process.env.PUBLIC_PATH}assets/ae-generic-hero-01.jpg`,
                    }]}
                    defaultType="image"
                    imgWide
                >
                    <div className="pt-small-7 row">
                        <div className="col-small-auto d-none d-small-block">
                            <Link to={PATH_EXPERIENCE}><IconArrowLeft large /></Link>
                        </div>
                        <div className="col">
                            <h1 className="aui-headline-3 pt-4"><b>My Bookings</b></h1>
                        </div>
                    </div>
                </HeroSection>

                <div className="content-wrapper">

                    {this.props.isLoading &&
                        <LoadingOverlay type="placeholder">Loading Experiences</LoadingOverlay>
                    }

                    {!this.props.isLoading && <div className="row">
                        <div className="col-12 col-xlarge">
                            {bookings && bookings.length > 0 && <div className="experience-list pt-5">
                                {waitlists && waitlists.length > 0 && <p className="aui-headline-4 mb-5">Bookings</p>}
                                {bookings.map(event => {
                                    return (<ListItem key={event.id} {...event} />);
                                })}
                            </div>}
                            {(!bookings || bookings.length === 0) && (!waitlists || waitlists.length === 0) && <p className="mb-7 py-7 text-center aui-headline-4 aui-color-text-red">No bookings found.</p>}
                        </div>

                        {waitlists && waitlists.length > 0 && <>
                            <div className="col-xlarge-1"></div>
                            <div className="col-12 col-xlarge">
                                <div className="experience-list pt-5">
                                    <p className="aui-headline-4 mb-5">Waitlist</p>
                                    {waitlists.map(event => {
                                        return (<ListItem key={event.id} {...event} />);
                                    })}
                                </div>
                            </div>
                        </>}
                    </div>}

                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBookings);
