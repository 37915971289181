import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';


const ProgressIndicator = ({ className, currentStep, onStepClick, totalSteps }) => {

  const handleClick = (e) => {
    onStepClick(e.currentTarget.dataset.step);
  }

  const steps = [];
  for (let i = 1; i <= totalSteps; i++) {
    steps.push(i);
  }

  return (
    <div className={classnames(`progress-indicator`, className)}>
      {steps.map((step) => {
        const clickable = step < currentStep && onStepClick;
        const classes = classnames('progress-indicator__item', {
          'progress-indicator__item--clickable': clickable,
          'progress-indicator__item--complete': step < currentStep,
          'progress-indicator__item--current': step === currentStep,
        });
        return (
          <div
            key={step}
            className={classes}
            onClick={clickable ? handleClick : undefined}
            data-step={step}
          >
            {step}
          </div>
        );
      })}
    </div>
  );
}

ProgressIndicator.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  onStepClick: PropTypes.func,
  totalSteps: PropTypes.number.isRequired,
};

export default ProgressIndicator;
