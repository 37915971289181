import React from 'react';
import { Route, Redirect, Switch, Link } from 'react-router-dom';
import { withRouter } from "react-router";
import { connect } from 'react-redux';
import queryString from 'query-string';
import Modal from '../../audi-ui-components/Modal';
import Spinner from '../../audi-ui-components/Spinner';
import { loadProfile, updateProfile, selectGoldenRecord, logout } from '../../redux/Profile';

import LoginForm from './components/LoginForm';
import RegisterForm from './components/RegisterForm';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import ResetPasswordForm from './components/ResetPasswordForm';
import RequestConfirmationEmailForm from './components/RequestConfirmationEmailForm';
import EmailConfirmation from './components/EmailConfirmation';
import MultipleProfilesForm from './components/MultipleProfilesForm';
import LoadingOverlay from '../../components/LoadingOverlay';
import Tooltip from '../../components/Tooltip';

import {
    PATH_HOME,
    PATH_LOGIN,
    PATH_REGISTER,
    PATH_FORGOT_PASS,
    PATH_RESET_PASS,
    PATH_REQUEST_CONFIRMAION,
    PATH_CONFIRM_EMAIL,
    pathRequiresAuth
} from '../../constants';

const mapStateToProps = state => {
    return {
        isLoadingProfile: state.profile.isLoading,
        data: state.profile.data,
        multiples: state.profile.multiples,
        multiplesLength: state.profile.multiples.length,
        initLoadDone: state.profile.initLoadDone,
        isAuthed: state.profile.isAuthed
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadProfile: (cb, errCb, auth) => { dispatch(loadProfile(cb, errCb, auth)); },
        updateProfile: (formData, cb, errorCb) => { dispatch(updateProfile(formData, cb, errorCb)); },
        selectGoldenRecord: (id, sCb, eCb) => { dispatch(selectGoldenRecord(id, sCb, eCb)); },
        logout: () => { dispatch(logout()); }
    }
}

class LoginModal extends React.Component {

    constructor(props) {
        super(props);
        let queryParams = queryString.parse(props.location.search);
        this.state = {
            email: queryParams?.email ? queryParams.email : "",
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data === null && this.props.data && this.props.data.firstName) {
          // console.log("data received", this.props.data.settings);
          if (!this.props.data.hasMFAEnabled && !(this.props.data.settings?.hasSeenMFANotice)) {
            // console.log("showing MFA notice");
          } else {
            // console.log("this modal will self-destruct in 3 seconds");
            setTimeout(() => { this.handleCloseModal(); }, 3000);
          }
        }
    }

    handleCloseModal = () => {
        // console.log("closing modal");
        if (this.props.data && !this.props.data.hasMFAEnabled && !this.props.data.settings?.hasSeenMFANotice) {
          this.props.updateProfile({settings: {hasSeenMFANotice: true}});
        }
        let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };
        if (this.props.location?.state?.selectedSessionIdx) {
          from.state = {selectedSessionIdx: this.props.location.state.selectedSessionIdx};
        }
        if (pathRequiresAuth(from.pathname)) {
            from = { pathname: PATH_HOME };
        }
        this.props.history.push(from);
    }

    goTo = (path, step) => {
        let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };
        let newState = { from: from, step: step };
        if (this.props.location?.state?.selectedSessionIdx) {
          newState.selectedSessionIdx = this.props.location.state.selectedSessionIdx;
        }
        this.props.history.push({ pathname: path, state: newState });
    }

    render() {
        const { data, multiples, initLoadDone, isLoadingProfile } = this.props;

        if (!initLoadDone) {
            // initial page load, the app is checking for a cookie and attempting to load the profile
            return (
                <Modal isActive={true} modalStyle="layer" closeButton close={this.handleCloseModal}>
                    <div style={{ textAlign: 'center' }} className="py-7">
                        <LoadingOverlay type="placeholder" />
                    </div>
                </Modal>
            );
        }

        if (data && data.firstName) {
            // profile data received
            if (!data.hasMFAEnabled && !data.settings?.hasSeenMFANotice) {
                return (
                    <Modal isActive={true} modalStyle="layer" closeButton close={this.handleCloseModal}>
                        <div className="py-7">
                            <p className="aui-headline-4 mb-7"><b>Welcome, {data.firstName} {data.lastName}</b></p>
                            <p className="mb-3">We have recently enhanced our account security to make your experience with us as safe as possible. That's why we strongly recommend enabling <b>Two-Factor Authentication (2FA)</b> <Tooltip trigger={<span style={{background:"#000", color:"#fff", borderRadius:"50%", display:"flex", justifyContent:"center", alignItems:"center", width:"1.3em", height:"1.3em"}}><b style={{paddingTop:"2px"}}>?</b></span>} tip="2FA adds an extra layer of protection to your account. You need two forms of ID (like a password and a code) to access accounts." /> for your account.</p>
                            <p>How to enable 2FA:</p>
                            <ol className="mb-3">
                              <li>Go to your <Link to="/profile" className="aui-textlink">Profile settings</Link>.</li>
                              <li>In your Account section, look for the "Enable 2FA" button.</li>
                              <li>Click the button to set up 2FA. You will need to verify your phone number.</li>
                            </ol>
                            <p className="mb-3">If you have any questions or need assistance, please <Link to="/contact-us" className="aui-textlink">reach out to our support team</Link>.</p>
                            <p>Your Audi Australia team</p>
                            <p className="mt-5"><button type="button" className="aui-textlink" onClick={this.handleCloseModal}><b>Skip for now</b></button></p>
                        </div>
                    </Modal>
                )
            }
            return (
                <Modal isActive={true} modalStyle="layer" className="welcome-modal">
                    <div style={{ textAlign: 'center' }} className="py-7">
                        <p className="aui-headline-4 mb-7"><b>Welcome, {data.firstName} {data.lastName}</b></p>
                        <Spinner isContinuous theme="light" />
                    </div>
                </Modal>
            );
        }

        if (multiples && multiples.length > 1) {
            // multiple profiles received
            return (
                <Modal isActive={true} modalStyle="layer" closeButton close={this.handleCloseModal}>
                    <div className="py-7">
                        <MultipleProfilesForm
                            multiples={multiples}
                            selectGoldenRecord={this.props.selectGoldenRecord}
                            logout={this.props.logout}
                            loading={isLoadingProfile}
                        />
                    </div>
                </Modal>
            );
        }

        // no profile data received, continue with rendering forms

        let { from } = this.props.location.state || { from: { pathname: PATH_HOME } };
        let { step } = this.props.location.state || 1;

        let queryParams = queryString.parse(this.props.location.search);

        return (
            <Modal isActive={true} closeButton close={this.handleCloseModal} modalStyle="layer">
                <div className="modal-form py-7">

                    <Switch>
                        <Route path={PATH_LOGIN}>
                            <LoginForm
                                initialValues={{ email: this.state.email }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                loadProfile={this.props.loadProfile}
                                onLoadProfile={() => { /*setTimeout(() => { this.props.history.replace(from); }, 3000);*/ }}
                                goTo={this.goTo}
                                initialStep={step}
                                from={from}
                            />
                        </Route>

                        <Route path={PATH_REGISTER}>
                            <RegisterForm
                                initialValues={{ email: this.state.email, firstName: "", lastName: "", mobile: "", postcode: "", password: "", confirmPassword: "" }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                goTo={this.goTo}
                                initialStep={step}
                                from={from}
                                query={queryParams}
                            />
                        </Route>

                        <Route path={PATH_REQUEST_CONFIRMAION}>
                            <RequestConfirmationEmailForm
                                initialValues={{ email: this.state.email }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                goTo={this.goTo}
                            />
                        </Route>

                        <Route path={PATH_FORGOT_PASS}>
                            <ForgotPasswordForm
                                initialValues={{ email: this.state.email }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                goTo={this.goTo}
                                initialStep={step}
                            />
                        </Route>

                        <Route path={PATH_RESET_PASS}>
                            <ResetPasswordForm
                                initialValues={{ email: this.state.email }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                goTo={this.goTo}
                                initialStep={step}
                            />
                        </Route>

                        <Route path={PATH_CONFIRM_EMAIL}>
                            <EmailConfirmation
                                initialValues={{ email: this.state.email }}
                                onChangeEmail={(v) => { this.setState({ email: v }); }}
                                goTo={this.goTo}
                            />
                        </Route>
                    </Switch>

                </div>
            </Modal>
        );
    }
}

const RouterLoginModal = withRouter(LoginModal);
export default connect(mapStateToProps, mapDispatchToProps)(RouterLoginModal);
