import React from 'react';
import classnames from 'classnames';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import IconSystemSelect from '../../../audi-ui-components/icons/SystemSelect';
import { request } from '../../../lib/apiRequestWrapper';
import { OPTS_STATES, FIELD_SELECT } from '../../../constants';
import _find from 'lodash/find';

import { connect } from 'react-redux';
import { rbGetMakes, rbGetFamilies } from '../../../redux/Vehicles';
const mapStateToProps = state => {
    return {
        rbMakes: state.vehicles.rbMakes,
        rbFamilies: state.vehicles.rbFamilies
    }
}
const mapDispatchToProps = dispatch => {
    return {
        rbGetMakes: () => { dispatch(rbGetMakes()); },
        rbGetFamilies: (m) => { dispatch(rbGetFamilies(m)); },
    };
};

class TradeInEstimateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            rbData: null,
            rbList: null,
            regoError: false,
            carNotFound: false,
            showEstimate: false,
            showModelFields: false,
            showExtraFields: false,
            showRegoErrors: false,
            showModelErros: false
        }
    }

    componentDidMount() {
        const {rbMakes, rbGetMakes, formikBag} = this.props;
        if (!rbMakes || rbMakes === null || rbMakes.length === 0) {
            rbGetMakes();
        }
        // check if we need to reset, TODO more checks
        if (this.state.showModelFields && !formikBag.values.tradeMakeCode) {
            this.reset();
        }
        // get lowest allowed year
        var d = new Date();
        this.currentYear = d.getFullYear();
        this.lowestYear = d.getFullYear() - 10;
    }

    handleNextRego = () => {
        const {values} = this.props.formikBag;
        // validate
        if (!values.tradeRego || !values.tradeState) {
            let errors = {};
            if (!values.tradeRego) { errors.tradeRego = "Please enter your vehicle registration number"; }
            if (!values.tradeState) { errors.tradeState = "Please select your registration state"; }
            this.props.formikBag.setErrors(errors);
            this.setState({showRegoErrors: true});
            return false;
        }
        // send request
        this.setState({loading: true});
        request(
            `${process.env.RAZZLE_API}/1/services/vehicle/${values.tradeRego}/${values.tradeState}`,
            {method: 'POST'}
        ).then((response) => {
            // console.log(response);
            if (Array.isArray(response)) {
                if (response.length) {
                    this.setState({rbList: response, loading: false});
                } else {
                    // empty list returned
                    this.setState({loading: false, regoError: true, showModelFields: true});
                }
            } else {
                this.setState({rbData: response, loading: false});
            }
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false, regoError: true, showModelFields: true});
        });
    }

    handleNextModel = () => {
        const {values} = this.props.formikBag;
        // validate
        if (!values.tradeMakeCode || !values.tradeYear) {
            let errors = {};
            if (!values.tradeMakeCode) { errors.tradeMakeCode = "Please select a make"; }
            if (!values.tradeYear) { errors.tradeYear = "Please select a year"; }
            this.props.formikBag.setErrors(errors);
            this.setState({showModelErros: true});
            return false;
        }
        // send request
        this.setState({loading: true, regoError: false});
        request(
            `${process.env.RAZZLE_API}/1/services/vehicle/pricing`,
            {
                method: 'POST',
                body: JSON.stringify({"makecode": values.tradeMakeCode, "familycode": values.tradeFamilyCode, "year": values.tradeYear})
            }
        ).then((response) => {
            // console.log(response);
            if (Array.isArray(response)) {
                if (response.length) {
                    this.setState({rbList: response, loading: false});
                } else {
                    // empty list returned
                    this.setState({loading: false, carNotFound: true, showExtraFields: true});
                }
            } else {
                this.setState({rbData: response, loading: false});
            }
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false, carNotFound: true, showExtraFields: true});
        });
    }

    selectCar = (rbc) => {
        const {formikBag} = this.props;
        const {rbData, rbList} = this.state;
        if (rbc && typeof rbc === "string") {
            // selected from list
            formikBag.setFieldValue("redbookcode", rbc);
            this.setState({loading: true});
            request(
                `${process.env.RAZZLE_API}/1/services/vehicle/code/${rbc}`,
                {method: 'POST'}
            ).then((car) => {
                // console.log(car);
                this.setState({loading: false, rbList: null, rbData: car, showEstimate: true, showExtraFields: true});
                if (car.make) { formikBag.setFieldValue("tradeMake", car.make); }
                if (car.family) { formikBag.setFieldValue("tradeModel", car.family); }
                if (car.year) { formikBag.setFieldValue("tradeYear", car.year); }
            }).catch((error) => {
                console.error(error);
                let car = _find(rbList, {rbc: rbc}); // use what we've got
                this.setState({loading: false, rbList: null, rbData: car, showEstimate: true, showExtraFields: true});
                if (car.make) { formikBag.setFieldValue("tradeMake", car.make); }
                if (car.family) { formikBag.setFieldValue("tradeModel", car.family); }
                if (car.year) { formikBag.setFieldValue("tradeYear", car.year); }
            });
        } else {
            // confirm single
            this.setState({showEstimate: true, showExtraFields: true});
            if (rbData.rbc) { formikBag.setFieldValue("redbookcode", rbData.rbc); }
            if (rbData.make) { formikBag.setFieldValue("tradeMake", rbData.make); }
            if (rbData.family) { formikBag.setFieldValue("tradeModel", rbData.family); }
            if (rbData.year) { formikBag.setFieldValue("tradeYear", rbData.year); }
        }
    }

    notMyCar = () => {
        const {showModelFields} = this.state;
        if (!showModelFields) {
            this.setState({rbData: null, showModelFields: true});
        } else {
            this.setState({carNotFound: true, rbData: null, showExtraFields: true});
        }
    }

    clearRbData = () => {
        this.setState({rbList: null, rbData: null, carNotFound: false, showExtraFields: false});
    }

    reset = () => {
        this.setState({rbData: null, rbList: null, regoError: false, carNotFound: false, showEstimate: false, showModelFields: false, showExtraFields: false});
        this.props.formikBag.setValues(Object.assign({}, this.props.formikBag.values, {
            tradeState: "", tradeRego: "", tradeMakeCode: "", tradeMake: "", tradeFamilyCode: "", tradeModel: "", tradeYear: "", redbookcode: ""
        }));
    }

    render() {
        const { formikBag, rbMakes, rbFamilies } = this.props;
        const { rbData, rbList, showModelFields, showExtraFields, showEstimate } = this.state;
        var famOpts = (formikBag.values.tradeMakeCode && rbFamilies[formikBag.values.tradeMakeCode]) ? rbFamilies[formikBag.values.tradeMakeCode] : [];
        var yearOpts = [];
        var selectedMake = null;
        var selectedFam = null;
        if (formikBag.values.tradeMakeCode) {
            selectedMake = _find(rbMakes, {value: formikBag.values.tradeMakeCode});
        }
        if (formikBag.values.tradeFamilyCode) {
            selectedFam = _find(famOpts, {value: formikBag.values.tradeFamilyCode});
            let latestyear = selectedFam.latestyear;
            for (let i=0; i<10; i++) {
                let yr = latestyear - i;
                if (yr >= this.lowestYear || i === 0) {
                    yearOpts.push({label: `${yr}`, value: `${yr}`});
                }
            }
        }
        let latestyear = this.currentYear;
        if (selectedFam) { latestyear = selectedFam.latestyear; }
        else if (selectedMake) { latestyear = selectedMake.latestyear; }
        for (let i=0; i<10; i++) {
            let yr = latestyear - i;
            if (yr >= this.lowestYear || i === 0) {
                yearOpts.push({label: `${yr}`, value: `${yr}`});
            }
        }
        return (
            <div className={classnames("trade-in-form position-relative", this.props.className)}>

                {this.state.loading && <LoadingOverlay />}

                <p className="mb-2">Enter vehicle details to start your trade-in estimate.</p>

                <div className="row align-items-end mb-4">
                    <div className="col">
                        <div className="row">
                            <div className="col-xsmall">
                                <FormField name="tradeState"
                                    label="Registration state"
                                    options={OPTS_STATES}
                                    fieldType={FIELD_SELECT}
                                    formikBag={formikBag}
                                    showErrors={this.state.showRegoErrors}
                                />
                            </div>
                            <div className="col-xsmall">
                                <FormField name="tradeRego"
                                    label="Registration number"
                                    formikBag={formikBag}
                                    formatValue={(v) => { return v.toUpperCase().replace(" ", ""); }}
                                    showErrors={this.state.showRegoErrors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-tiny-auto mt-4 mt-tiny-0">
                        {(!showModelFields && !rbData) && <Button label="Next" buttonType="secondary" onClick={this.handleNextRego} className="mb-5" />}
                    </div>
                </div>

                {showModelFields && rbMakes && <div className="row align-items-end mb-4">
                    {this.state.regoError && <div className="col-12">
                        <p className="aui-color-text-red mb-3">We could not find your vehicle. Please complete the following fields and try again.</p>
                    </div>}
                    <div className="col">
                        <div className="row">
                            <div className="col-xsmall">
                                <FormField
                                    name="tradeMakeCode"
                                    fieldType={FIELD_SELECT}
                                    label="Make"
                                    options={rbMakes}
                                    formikBag={formikBag}
                                    onChange={(v) => {
                                        this.props.rbGetFamilies(v);
                                        formikBag.setFieldValue("tradeFamilyCode", "");
                                        formikBag.setFieldValue("tradeModel", "");
                                        formikBag.setFieldValue("tradeYear", "");
                                        let opt = _find(rbMakes, {value: v});
                                        formikBag.setFieldValue("tradeMake", opt.label);
                                        this.clearRbData();
                                    }}
                                    showErrors={this.state.showModelErros}
                                />
                            </div>
                            <div className="col-xsmall">
                                <FormField
                                    name="tradeFamilyCode"
                                    label="Model"
                                    fieldType={FIELD_SELECT}
                                    options={famOpts}
                                    formikBag={formikBag}
                                    onChange={(v) => {
                                        formikBag.setFieldValue("tradeYear", "");
                                        let opt = _find(famOpts, {value: v});
                                        formikBag.setFieldValue("tradeModel", opt.label);
                                        this.clearRbData();
                                    }}
                                    showErrors={this.state.showModelErros}
                                />
                            </div>
                            <div className="col-xsmall">
                                <FormField
                                    name="tradeYear"
                                    label="Year"
                                    fieldType={FIELD_SELECT}
                                    options={yearOpts}
                                    formikBag={formikBag}
                                    onChange={() => {
                                        this.clearRbData();
                                    }}
                                    showErrors={this.state.showModelErros}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-tiny-auto mt-4 mt-tiny-0">
                        {(!rbData && !showExtraFields) &&
                            <Button label="Next" buttonType="secondary" onClick={this.handleNextModel} disabled={!formikBag.values.tradeYear} className="mb-5" />
                        }
                    </div>
                </div>}

                {rbData && <div className="mb-4">
                    {!showEstimate && <p className="mb-2">Is this your vehicle?</p>}
                    <div className="row">
                        <div className="col">
                            <div className="row">
                                <div className="col-12 col-small-6">
                                    <p><b>{rbData.make} {rbData.family} {rbData.year}</b></p>
                                    <p>{rbData.description}</p>
                                </div>
                                <div className="col-12 col-small-6">
                                    <p>Engine: {rbData.fueltype}</p>
                                    <p>Transmission: {rbData.geartype}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xsmall-auto">
                            {!showEstimate && <div className="row flex-nowrap">
                                <div className="col-auto">
                                    <Button label="No" buttonType="secondary" onClick={this.notMyCar} />
                                </div>
                                <div className="col-auto">
                                    <Button label="Yes" buttonType="secondary" onClick={this.selectCar} />
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>}

                {rbList && <div className="mb-4 rb-list">
                    <p className="mb-2">We found several possible matches. Please select the correct one.</p>
                    {rbList.map((car) => {
                        return (
                            <div className="row rb-list__item" onClick={() => { this.selectCar(car.rbc); }} key={car.rbc}>
                              <div className="col">
                                <p><b>{car.make || selectedMake.label} {car.family || selectedFam.label} {car.year}</b></p>
                                <p>{car.description}</p>
                              </div>
                              <div className="col-auto">
                                <IconSystemSelect large />
                              </div>
                            </div>
                        );
                    })}
                </div>}

                {showEstimate && rbData && rbData.tradeinmin && <div className="mb-4">
                    <p>The trade-in estimate for your {rbData.make} {rbData.family} {rbData.year} could fall somewhere between<sup>*</sup></p>
                    <div className="rb-estimate">
                        ${rbData.tradeinmin}
                        <span className="rb-estimate-arrow" />
                        ${rbData.tradeinmax}
                    </div>
                </div>}

                {showEstimate && rbData && !rbData.tradeinmin && <div className="mb-4 aui-color-text-red">
                    Sorry, we don't have a trade-in value for that vehicle. Your dealer will evaluate your vehicle and supply you with a trade-in value.
                </div>}

                {this.state.carNotFound && <div className="mb-4 aui-color-text-red">
                    Sorry, we don't have a trade-in value for that vehicle. Your dealer will evaluate your vehicle and supply you with a trade-in value.
                </div>}

                {showExtraFields && <div className="mb-4">
                    <p>Please complete these fields</p>
                    <div className="row">
                        <div className="col-12 col-tiny-6">
                            <FormField
                                name="tradeKms"
                                label="KMs"
                                formikBag={formikBag}
                                formatValue={(v) => { return v.replace(/\D/g,''); }}
                            />
                        </div>
                        <div className="col-12 col-tiny-6">
                            <FormField
                                name="tradeHasFinance"
                                fieldType={FIELD_SELECT}
                                label="Currently financed*"
                                formikBag={formikBag}
                                options={[{ label: "No", value: "no" }, { label: "Yes", value: "yes" }]}
                            />
                        </div>
                    </div>
                </div>}

                {(formikBag.errors.tradeMake || formikBag.errors.tradeModel || formikBag.errors.tradeYear) && formikBag.submitCount > 0 &&
                    <p className="aui-color-text-red my-2">Please complete the trade-in estimate</p>
                }

                {(rbData || rbList || showModelFields) && <p className="mt-4">
                    <Button label="Reset trade-in" buttonType="secondary" onClick={this.reset} />
                </p>}

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TradeInEstimateForm);
