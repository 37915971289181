import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../audi-ui-components/Button';
import IconInspection from '../../../audi-ui-components/icons/Inspection';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import { gtmPush } from '../../../lib/gtm';
import { PATH_PROFILE, AUDI_BOOK_SERVICE_URL } from '../../../constants';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Modal from '../../../audi-ui-components/Modal';

const ServiceHistory = ({ serviceHistory, preferredServiceLocation, dueForService, setActiveModal, activeModal, rego }) => {
    return (
        <div className="service-history aui-color-gray85 pr-medium-3 d-flex flex-column" style={{ height: '100%' }}>
            <div className="row align-items-center pt-7">

                <div className="col-12 col-small-2 d-flex justify-content-center justify-content-small-end px-2 py-4">
                    <IconInspection />
                </div>
                <div className="col-10 offset-1 col-small-8 offset-small-0">
                    <h2 className="aui-headline-3 d-flex justify-content-start justify-content-small-start">
                        <b>Service History</b>
                    </h2>
                </div>

            </div>
            <div className="row pb-7 flex-grow-1">

                <div className="col-1 col-small-2" />
                <div className="col-10 col-small-8">

                    {!serviceHistory &&
                        <LoadingOverlay type="placeholder" />
                    }

                    {serviceHistory && serviceHistory.length <= 0 &&
                        <div className="pt-3">No service history found for this vehicle.</div>
                    }

                    {serviceHistory &&
                        <ul>

                            {serviceHistory.map(({ serviceDetails }) => {
                                return (
                                    <li className="py-3" key={'service-link-' + serviceDetails.serviceNumber} onClick={() => { setActiveModal('service-modal-' + serviceDetails.serviceNumber) }}>
                                        <div className="row">
                                            <div className="col-6">
                                                <span>
                                                    <b>{serviceDetails.serviceDate}</b>
                                                </span>
                                            </div>
                                            <div className="col-6">
                                                <span>{serviceDetails.scheduledServiceDescription}</span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}

                        </ul>
                    }

                    {serviceHistory &&
                        <div>
                            {serviceHistory.map(({ serviceDetails }) => {
                                return (
                                    <div key={'service-modal-' + serviceDetails.serviceNumber}>
                                        <Modal isActive={activeModal === ('service-modal-' + serviceDetails.serviceNumber)}
                                            closeButton
                                            close={() => { setActiveModal(null); }}
                                            modalStyle="layer">

                                            <div className="modal-form py-7">
                                                <div className="py-4">
                                                <h1 className="aui-headline-3">
                                                    {serviceDetails.serviceDate}
                                                </h1>
                                                <h1 className="aui-headline-3">
                                                    {serviceDetails.scheduledServiceDescription}
                                                    </h1>
                                                    </div>

                                                <div>
                                                    Dealer Name: {serviceDetails.dealerName}
                                                </div>
                                                <div>
                                                    Dealer Code: {serviceDetails.dealerCode}
                                                </div>
                                                <div>
                                                    Rego: {rego}
                                                </div>
                                                <div>
                                                    VIN: {serviceDetails.vin}
                                                </div>
                                                <div>
                                                    Service Invoice: {serviceDetails.serviceNumber}
                                                </div>
                                            </div>

                                        </Modal>
                                    </div>

                                );
                            })}
                        </div>
                    }




                    <div className="mt-6">
                        {/* {TODO:: logic for determining Due for Service */
                            dueForService && <div className="warning mt-7 aui-color-text-red d-flex align-item-center">
                                <IconCaution small /> <b> This vehicle is due for a service</b>
                            </div>
                        }

                        {preferredServiceLocation && <p className="my-5">
                            {preferredServiceLocation.webkey &&
                                <Button
                                    href={`${AUDI_BOOK_SERVICE_URL}${preferredServiceLocation.webkey}`}
                                    rel="noopener noreferrer" target="_blank"
                                    onClick={() => { gtmPush("myAudi", "bookMyService", "vehicle"); }}
                                    buttonType="primary"
                                    label={`Book my service at ${preferredServiceLocation.dealerName}`}
                                />
                            }
                            {!preferredServiceLocation.webkey && <>Call {preferredServiceLocation.dealerName} on <b>{preferredServiceLocation.phone}</b> to book a service</>}
                        </p>}
                        <p>
                            <Button variant="text" onClick={() => { setActiveModal('change-dealer'); gtmPush("myAudi", "notMyDealer", "vehicle"); }}>
                              {preferredServiceLocation ? "Not your preferred service centre?" : (<>Select your <span className="d-medium-none">preferred</span> Audi Service Centre</>)}
                            </Button>
                        </p>
                    </div>

                </div>
                <div className="col-auto col-small-2"></div>

            </div>
        </div>
    );
};

export default ServiceHistory;
