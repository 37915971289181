import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../../../audi-ui-components/Button';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCar from '../../../audi-ui-components/icons/Car';
import LoadingOverlay from '../../../components/LoadingOverlay';

class MultipleProfilesForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedId: null,
            error: false
        }
    }

    selectItem = (id) => {
        this.setState({selectedId: id});
    }

    handleSubmit = () => {
        if (!this.state.selectedId) return;
        this.props.selectGoldenRecord(
            this.state.selectedId,
            null,
            (error) => {
                this.setState({error: true});
            }
        );
    }

    render() {
        const {selectedId} = this.state;
        return (
            <div>

                {this.props.loading && <LoadingOverlay />}

                <p className="aui-headline-4 mb-5">We have found multiple records matching your email address.</p>
                <p className="mb-3">
                  Please select the most correct details from the options shown below.
                  Your selection will be associated with your myAudi Australia account.
                  Please note that once you have made your selection you will not be able to change it.
                </p>
                <p className="mb-7">
                  If you need assistance with this process, or are having difficulty,
                  please contact Audi Customer Care on <a className="aui-textlink" href="tel:1800502834">1800 50 AUDI (2834)</a> between 8am and 6pm AEST, Monday to Friday.
                </p>

                <div className="row multiples-grid mb-5">
                    {this.props.multiples.map(item => {
                        let addressLine1 = item.homeAddress ? `${item.homeAddress},` : ' ';
                        let addressLine2 = [item.homeSuburb, item.homeState, item.homePostcode].filter(s => !!s).join(', ');
                        return (
                            <div key={item.id}
                              className="col-12 col-small-6 col-medium-4"
                              onClick={() => this.selectItem(item.id)}
                            >
                                <div className={classnames("multiples-item", {"selected": item.id === selectedId})}>
                                    <span className="multiples-item__checkbox">
                                        <IconSelect small className={classnames({"d-none": item.id !== selectedId})} />
                                    </span>
                                    <div className="multiples-item__coords">
                                        <p className="aui-headline-5 mb-2">{item.firstName} {item.lastName}</p>
                                        <p className="mb-4">{item.mobile}</p>
                                        <p>{addressLine1}<br />{addressLine2}</p>
                                    </div>
                                    <div className="multiples-item__details">
                                        <p><IconCar small /> <b>{item.ownedVehicles}</b></p>
                                    </div>
                               </div>
                            </div>
                        );
                    })}
                </div>

                {this.state.error && <p className="py-3 aui-color-text-red">There was a problem updating the record.</p>}

                <Button label="Update records"
                    buttonType="primary"
                    disabled={!selectedId || selectedId === null}
                    onClick={this.handleSubmit}
                />
                {` `}
                <Button label="Cancel"
                    buttonType="secondary"
                    onClick={this.props.logout}
                />
            </div>
        );
    }
}

export default MultipleProfilesForm;
