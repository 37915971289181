import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

class NavDropdown extends React.Component {

  constructor(props) {
    super(props);
    this.elementRef = React.createRef();
    this.state = {
      isActive: false
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }

  handleClickOutside = (e) => {
    if (this.elementRef && !this.elementRef.current.contains(e.target)) {
      this.setState({isActive: false});
    }
  }

  render() {
    const {actions, activeIndex, onActionClick, sticky, theme} = this.props;
    const {isActive} = this.state;
    return(
      <nav ref={this.elementRef} style={this.props.style} className={classnames("aui-nav", "aui-nav--dropdown", `${this.props.className}`, {[`aui-theme-${theme}`]: theme, 'is-active': isActive})}>
        <div className="aui-nav__panel">
          <ul className="aui-nav__items">
            {actions.map((action, index) => {
              if (typeof action.enabled !== "undefined" && action.enabled === false) { return null; }
              let _active = index === activeIndex;
              let Component = action.to ? Link : action.href ? "a" : "button";
              let link = {};
              if (action.to) { link.to = action.to; }
              if (action.href) { link.href = action.href; }
              if (!action.to && !action.href) { link.type = "button"; }
              let _style = {transitionDelay: `${.05 * index}s`, WebkitTransitionDelay: `${.05 * index}s`};
              return (
                <li key={`ni_${index}`} className="aui-nav__item" style={_style}>
                  <Component
                    {...link}
                    className={classnames("aui-nav__action", action.className, {"is-active": _active, "is-disabled": action.disabled})}
                    onClick={(e) => {
                      if (!action.disabled) {
                        this.setState({isActive: false}, () => { onActionClick(index, e); });
                      }
                    }}
                    disabled={action.disabled}
                  >
                    {action.text}
                  </Component>
                </li>
              );
            })}
          </ul>
        </div>
        <button className="aui-nav__toggle" type="button" onClick={(e) => { this.setState({isActive: !isActive}); e.preventDefault(); }}>
          {(activeIndex || activeIndex === 0) && <span className="aui-nav__toggle-label">{actions[activeIndex].text}</span>}
          {(!activeIndex && activeIndex !== 0) && this.props.label && <span className="aui-nav__toggle-label">{this.props.label}</span>}
        </button>
        <div className="aui-nav__underline"></div>
      </nav>
    );
  }

}

NavDropdown.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    className: PropTypes.string
  })),
  activeIndex: PropTypes.number,
  onActionClick: PropTypes.func,
  label: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'black', 'warmsilver', 'silver', 'red']) // see styles/components/_nav-themes.scss
};

NavDropdown.defaultProps = {
  style: {},
  className: '',
  onActionClick: () => {}
}

export default NavDropdown;
