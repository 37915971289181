import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';

import { connect } from 'react-redux';
import { enableMFA, verifyMFA } from '../../../redux/Profile';
const mapStateToProps = state => {
    return {
        isAuthed: state.profile.isAuthed,
        profile: state.profile.data,
        isLoadingProfile: state.profile.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        enableMFA: (cb, errCb) => {dispatch(enableMFA(cb, errCb));},
        verifyMFA: (code, cb, errCb) => {dispatch(verifyMFA(code, cb, errCb));}
    }
}

const MFAEnableForm = ({ enableMFA, verifyMFA, profile, onSuccess = ()=>{} }) => {
  
  const [error, setError] = useState(null);
  const [step, setStep] = useState(1);
  
  const handleValidate = (values) => {
    if (!values.code) {
      return {code: "Please enter your verification code"};
    }
    return {};
  }
  
  const handleSubmit = (values, formikBag) => {
    setError(null);
    verifyMFA(
      values.code,
      onSuccess,
      (err)=>{
        let msg = "An error has occured";
        if (err.body?.message) { msg = err.body.message; }
        setError(msg);
        formikBag.setSubmitting(false);
      }
    )
  }
  
  useEffect(() => {
    enableMFA(
      ()=>{ setStep(2); },
      (err)=>{ setError(err); }
    );
  }, []);
  
  return (
    <div className="loading-wrapper">
      <p className="aui-headline-4 mb-5">Enable Two-Factor Authentication (2FA)</p>
      {error && <p className="mb-5 aui-color-text-red">{error}</p>}
      
      {step === 1 && !error && <LoadingOverlay type="placeholder">A verification code is being sent</LoadingOverlay>}
      
      {step === 2 && <>
        <p className="mb-5">A verification code has been sent to <b>{profile.mobile}</b>. Please enter it below.</p>
        <Formik validate={handleValidate} onSubmit={handleSubmit} initialValues={{code: ""}}>
            {(formikBag) => (
                <div>
                  {formikBag.isSubmitting && <LoadingOverlay type="overlay" />}
                  <FormField name="code" label="Verification code" formikBag={formikBag} />
                  <p><Button type="button" variant="primary" onClick={formikBag.handleSubmit}>Submit</Button></p>
                </div>
            )}
        </Formik>
      </>}
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MFAEnableForm);
