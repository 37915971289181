import React from 'react';

import Button from '../../../../audi-ui-components/Button';

import { PHONE_CUSTOMER_SERVICE, EMAIL_CUSTOMER_SERVICE } from '../../../../constants';

const FormConfirmation = ({
  allowsGuest,
  booking,
  editBookingSteps,
  setEditBookingStep,
  // toggleRegistering,
  // toggleAddingGuest,
  // toggleModifyingGuest,
  selectedSession
}) => {

  const toAddGuest = () => {
    setEditBookingStep(editBookingSteps.addGuest);
    // toggleRegistering();
    // toggleAddingGuest();
  }

  const toModifyGuest = () => {
    setEditBookingStep(editBookingSteps.modGuest);
    // toggleRegistering();
    // toggleModifyingGuest()
  }

  return (
    <>
      <h2 className="aui-headline-3 mt-4 mb-7"><b>Confirmation</b></h2>
      <p>Thank you for choosing to attend an Audi Experience event! We look forward to welcoming you.
      We have sent your confirmation to your email, and will also send you a reminder email prior to
            the event. </p>
      <div className="my-7">
        {allowsGuest &&
          booking &&
          booking.canModify &&
          booking.attendees.length === 2 &&
          <Button
            buttonType="secondary"
            label="Modify Attendance"
            onClick={toModifyGuest} />
        }
        {allowsGuest &&
          booking &&
          booking.canModify &&
          booking.attendees.length === 1 &&
          selectedSession.spotsAvailable > 0 &&
          <Button
            buttonType="secondary"
            label="Add a Guest"
            onClick={toAddGuest} />
        }
      </div>
      <p className="disclaimer">
        If you have any questions, please call us on
      <a href={`tel:${PHONE_CUSTOMER_SERVICE}`}> {PHONE_CUSTOMER_SERVICE} </a>
        between 8am and 6pm AEST, Monday to Friday. Alternatively, you can email us on
      <a href={`mailto:${EMAIL_CUSTOMER_SERVICE}`}> {EMAIL_CUSTOMER_SERVICE}</a>.
    </p>
  </>);
}

export default FormConfirmation;
