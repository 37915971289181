import React from 'react';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { request } from '../../../lib/apiRequestWrapper';
import CollapsibleSection from '../../../components/CollapsibleSection';
import Button from '../../../audi-ui-components/Button';
import IconDownload from '../../../audi-ui-components/icons/Download';
import { gtmPush } from '../../../lib/gtm';

class TabEnquiries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestLoading: false,
      requestResponse: null,
      consentLoading: false,
      consentResponse: null,
    };
  }

  handleRequestContract = () => {
    // enquiryType="COPYCONTRACT"
    this.setState({ requestLoading: true, requestResponse: null });
    request(
      `${process.env.RAZZLE_API}/1/vwfs/contract/${this.props.contractId}/enquiry`,
      {
        method: 'POST',
        body: JSON.stringify({
          enquiryType: 'COPYCONTRACT',
          customerId: this.props.vwfsId,
        }),
      }
    )
      .then(response => {
        this.setState({
          requestLoading: false,
          requestResponse: response,
        });
        gtmPush("myAudiFinance", "requestMyContract", "finance");
      })
      .catch(error => {
        this.setState({
          requestLoading: false,
          requestResponse: { errorMessage: error.message },
        });
      });
  };

  render() {
    const { enquiryLinks } = this.props;
    const { requestLoading, requestResponse } = this.state;
    return (
      <article className="finance__tab-enquiries aui-color-gray90 py-6">
        <div className="container-fluid px-small-0">
          <div className="row py-6">
            <div className="col-medium-1"></div>

            <div className="col">
              <div className="finance__enquiry-section p-7 mb-3 aui-color-white">
                {requestLoading && <LoadingOverlay />}
                <h5 className="aui-headline-4 mb-5">
                  Request a copy of your contract
                </h5>
                <p className="mb-3">
                  Request a copy of your contract by copmpleting this form. Once
                  submitted, a member of our customer service team will email
                  you the document.
                </p>
                <p className="mb-3">
                  <Button
                    buttonType="primary"
                    label="Request my contract"
                    onClick={this.handleRequestContract}
                  />
                </p>
                {requestResponse && requestResponse.enquiryMessage && (
                  <p className="finance__success">
                    {requestResponse.enquiryMessage}
                  </p>
                )}
                {requestResponse && requestResponse.errorMessage && (
                  <p className="finance__error">
                    {requestResponse.errorMessage}
                  </p>
                )}
              </div>

              <div className="finance__enquiry-section p-7 mb-3 aui-color-white">
                {this.props.isLoadingEnquiries && <LoadingOverlay />}
                <h5 className="aui-headline-4 mb-5">
                  Give someone else consent over your account
                </h5>
                <p className="mb-3">
                  If you would like to give a trusted contact or relative
                  control over your account, you can download a Third Party
                  Consent PDF form here.
                </p>
                {enquiryLinks.tpauth && enquiryLinks.tpauth.enquiryUrl && (
                  <p>
                    <Button
                      buttonType="primary"
                      label="Download third party authorisation form"
                      onClick={() => { gtmPush("myAudiFinance", "download3PAF", "finance"); }}
                      href={enquiryLinks.tpauth.enquiryUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      icon={<IconDownload small />}
                    />
                  </p>
                )}
                {enquiryLinks.tpauth && enquiryLinks.tpauth.error && (
                  <p className="finance__error">
                    There was a problem retrieving the form. Please try
                    refreshing the page, or contact Customer Service.
                  </p>
                )}
              </div>

              <div className="finance__enquiry-section p-7 aui-color-white">
                <CollapsibleSection
                  toggleOnClickVisible
                  visibleContent={
                    <div>
                      <h5 className="aui-headline-4 mb-5">
                        Apply for hardship
                      </h5>
                      <p className="mb-3">
                        Request consideration for financial hardship by
                        completing this form. Once submitted, a representative
                        from our Hardship Team will assess your application to
                        see what arrangements may be suitable for your
                        circumstances. We will notify you of the outcome within
                        21 days.
                      </p>
                      <p>
                        <Button buttonType="text" label="Apply for hardship" />
                      </p>
                    </div>
                  }>
                  <hr />
                  <p className="mb-3">
                    We understand that life can take unexpected turns and anyone
                    can experience financial hardship, due to unforseen
                    circumstances. We are commited to assisting you with finding
                    a suitable solution to manage your repayments, whilst you
                    get back on your feet.
                  </p>
                  <p className="mb-3">
                    <b>What is Financial Hardship?</b>
                    <br />
                    Financial hardship is when a customer is willing and has the
                    intention to pay, but due to a change in their
                    circumstances, is now unable to meet their repayments or
                    existing financial obligations. With formal hardship
                    assistance, their financial situation will be restored.
                  </p>
                  <p className="mb-3">
                    <b>Reasons which may qualify you for Financial Hardship</b>
                    <br />
                    Common events contributing to financial difficulty may
                    include:
                  </p>
                  <ul className="mb-3">
                    <li>Changes in income and/or expenses</li>
                    <li>
                      Changes in employment (such as reduced hours, loss of job,
                      reduced pay)
                    </li>
                    <li>
                      Significant life events such as (illness, injury,
                      relationship break down, death or disablity)
                    </li>
                    <li>Natural disaster</li>
                    <li>
                      Legal matters such as court actions, judgement or court
                      orders
                    </li>
                    <li>
                      Company failure or some other event such as insolvency or
                      administration
                    </li>
                  </ul>
                  <p className="mb-3">
                    <Button
                      buttonType="primary"
                      onClick={() => { gtmPush("myAudiFinance", "downloadHardshipForm", "finance"); }}
                      href={`/pdf/013_20181026.03.02.Hardship_Application.pdf`}
                      target="_blank"
                      rel="noopener noreferrer"
                      label="Download Hardship form"
                      icon={<IconDownload small />}
                    />
                  </p>
                </CollapsibleSection>
              </div>
            </div>

            <div className="col-medium-1"></div>
          </div>
        </div>
      </article>
    );
  }
}

export default TabEnquiries;
