import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import { validate } from '../../../lib/validation';
import { loginSchema } from '../schema';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';

import {
  PATH_LOGIN,
  PATH_RESET_PASS
} from '../../../constants';

class ForgotPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: props.initialStep || 1,
      isSubmitting: false,
      serverError: null
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true, serverError: null});
    request(
      `${process.env.RAZZLE_API}/2/account/password/request`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then((response) => {
      this.props.goTo(PATH_RESET_PASS);
      gtmPush("myAudi", "passwordResetSubmit", values.email);
    }).catch((error) => {
      this.props.goTo(PATH_RESET_PASS);
    });
  }

  validateForm = (values) => {
    var schema = {
      email: loginSchema['email']
    };
    return validate(values, schema, {format: "firstError", fullMessages: false})
  }

  render() {
    const { step } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form>

            {this.state.isSubmitting && <LoadingOverlay />}

            {step === 1 && <>
              <p className="aui-headline-4 mb-3"><b>Forgotten your password?</b></p>
              <p className="mb-3">Enter your registered email to reset your password.</p>
              <FormField name="email" label="Email" formikBag={formikBag} onChange={this.props.onChangeEmail} />
              <Button label="Reset Password" type="submit" buttonType="primary" />
              {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
            </>}

            {step === 2 && <>
              <p className="mb-3">If this user account exists, an email will be in your inbox shortly.</p>
              <Button label="Login" buttonType="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }} />
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

ForgotPasswordForm.propTypes = {
  goTo: PropTypes.func,
  onChangeEmail: PropTypes.func,
  initialValues: PropTypes.object,
}

export default ForgotPasswordForm;
