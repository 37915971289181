import { request } from '../lib/apiRequestWrapper';
import { contentData } from './tempData';

const SET_PAGE = 'CONTENT/SET_PAGE';
const SET_LOADING = 'CONTENT/SET_LOADING';

export function loadPage(slug) {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING, payload: true });

    return new Promise((resolve, reject) => {
      return request(`${process.env.RAZZLE_CMS_API}/1/cms/contentPage/${slug}`)
        .then(response => {
          dispatch({ type: SET_PAGE, payload: response, slug: slug });
          // dispatch({ type: SET_LOADING, payload: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: SET_PAGE, payload: {error: error.status}, slug: slug });
          resolve();
        });
    });
  };
}

export function loadFaq() {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING, payload: true });

    return new Promise((resolve, reject) => {
      return request(`${process.env.RAZZLE_CMS_API}/1/cms/FAQs`)
        .then(response => {
          dispatch({ type: SET_PAGE, payload: response, slug: 'faq' });
          // dispatch({ type: SET_LOADING, payload: false });
          resolve();
        })
        .catch(error => {
          console.error(error);
          dispatch({ type: SET_LOADING, payload: false });
          resolve();
        });
    });
  };
}

const defaultState = {
  isLoading: false,
};

export function contentReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload,
      });

    case SET_PAGE:
      return Object.assign({}, state, {
        [action.slug]: action.payload,
        isLoading: false,
      });

    default:
      return state;
  }
}
