import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { PATH_EXPERIENCE, PATH_EVENT, PATH_OFFER } from '../../constants';
import { Helmet } from "react-helmet";
import Modal from '../../audi-ui-components/Modal';
import Button from '../../audi-ui-components/Button';
import IconWarranty from '../../audi-ui-components/icons/Warranty';
import ExperienceHome from './ExperienceHome';
import EventDetail from './EventDetail';
import OfferDetail from './OfferDetail';
import RYIEvent from './EventDetail/RYIEvent';
import LoadingOverlay from '../../components/LoadingOverlay';
import cookie from '../../lib/cookie';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        initLoadDone: state.profile.initLoadDone,
        loyalty: state.profile.data ? (state.profile.data.isLoyaltyMember && state.profile.data.isLoyaltyEligible) : false,
    }
}

class Experience extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLoyaltyModal: false
        }
    }

    componentDidMount() {
        if (!cookie.getItem("loyaltyMsgDone")) {
            this.setState({showLoyaltyModal: true});
        }
    }

    closeLoyaltyModal = () => {
        this.setState({showLoyaltyModal: false});
        cookie.setItem('loyaltyMsgDone', true, '', '/');
    }

    render() {
        const {initLoadDone, loyalty} = this.props;
        const {showLoyaltyModal} = this.state;
        var isSsr = typeof window === "undefined";
        return (<>
            <Switch>
                <Route exact path={PATH_EXPERIENCE}><ExperienceHome /></Route>
                <Route path={`${PATH_EVENT}/ryi`} render={routeProps => <RYIEvent key="ryievent" {...routeProps} />} />
                <Route path={`${PATH_EVENT}/:id`} render={routeProps => <EventDetail key={routeProps.match.params.id} {...routeProps} />} />
                <Route path={`${PATH_OFFER}/:id`} render={routeProps => <OfferDetail key={routeProps.match.params.id} {...routeProps} />} />
            </Switch>

            {(!initLoadDone && !isSsr) && <div className="position-relative"><LoadingOverlay type="placeholder" /></div>}

            <Modal isActive={initLoadDone && !loyalty && showLoyaltyModal} modalStyle="layer" closeButton close={this.closeLoyaltyModal}>
                <div className="modal-form py-7">
                    <div className="row align-items-center">
                        <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                            <IconWarranty large />
                        </div>
                        <div className="col-12 col-medium-8 px-6 px-medium-0 text-center text-medium-left">
                            <h2 className="aui-headline-4 mb-3 headline-icon">
                                Audi Experience eligibility
                            </h2>
                        </div>
                    </div>
                    <p className="my-5">If you have a vehicle that is in its manufacturer’s warranty period (i.e. 3 years for vehicles with an original new car warranty start date that occurred prior to 1 January 2022 or 5 years for vehicles with an original new car warranty start date that occurred on or after 1 January 2022) or have performed scheduled servicing at an Audi Service Centre, you are eligible to join the Audi Experience program.</p>
                    <p className="d-xsmall-none text-right"><Button label="OK" buttonType="primary" onClick={this.closeLoyaltyModal} /></p>
                </div>
            </Modal>

            <Helmet>
                <title>Audi Experience | Exclusive Offers & Events | myAudi Australia</title>
                <meta name="description" content="Discover Audi Experience and have access to exclusive offers and events that will enhance your Audi Experience. View the event calendar today!" />
            </Helmet>
        </>);
    }
}

export default connect(mapStateToProps)(Experience);
