import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class TextArea extends React.Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isFocused: false,
      remainingChars: (props.showCount && props.maxLength) ? props.maxLength - props.value.length : null
    };
  }

  componentDidMount() {
    if (this.props.autosize) {
      let style = window.getComputedStyle(this.inputRef.current);
      this._inputBorder = parseInt(style.borderTop) + parseInt(style.borderBottom) || 0;
    }
  }

  handleChange = (e) => {
    let value = e.target.value;
    if (this.props.showCount && this.props.maxLength) {
      let c = this.props.maxLength - value.length;
      this.setState({remainingChars: c});
    }
    if (this.props.autosize) {
      this.adjustHeightToContent();
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, e);
    }
  }

  handleFocus = (e) => {
    this.setState({isFocused: true});
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  handleBlur = (e) => {
    this.setState({isFocused: false});
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  handleKeyDown = (e) => {
    if (e.keyCode === 13 && typeof this.props.onEnter === 'function') {
      this.props.onEnter();
    }
    if (typeof this.props.onKeyDown === 'function') {
      this.props.onKeyDown(e);
    }
  }

  adjustHeightToContent() {
    let htmlTop = window.pageYOffset;
    let bodyTop = document.body.scrollTop;
    let originalHeight = this.inputRef.current.style.height;

    this.inputRef.current.style.height = 'auto';
    if (this.inputRef.current.scrollHeight === 0) {
      // If the scrollHeight is 0, then the element probably has display:none or is detached from the DOM.
      this.inputRef.current.style.height = originalHeight;
      return;
    }
    this.inputRef.current.style.height = `${this.inputRef.current.scrollHeight + this._inputBorder}px`;

    // prevents scroll position jump
    document.documentElement.scrollTop = htmlTop;
    document.body.scrollTop = bodyTop;
  }

  renderMessage = () => {
    const {isValid, isInvalid, validMessage, invalidMessage, description} = this.props;
    if (isValid && validMessage) {
      return (<span className="aui-textfield__valid">{validMessage}</span>);
    }
    if (isInvalid && invalidMessage) {
      return (<span className="aui-textfield__error">{invalidMessage}</span>);
    }
    if (description) {
      return (<span className="aui-textfield__description">{description}</span>);
    }
    return false;
  }

  render() {
    const {isValid, isInvalid, showCount, theme} = this.props;
    const {isFocused, remainingChars} = this.state;
    return(
      <div style={this.props.style} className={classnames(
        "aui-textfield",
        "aui-textfield--multiline",
        `${this.props.className}`,
        {
          "is-focused": isFocused,
          "is-dirty": this.props.value || this.props.value === 0,
          "is-valid": isValid,
          "is-invalid": isInvalid,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        <div className="aui-textfield__field">
          <textarea name={this.props.name}
            ref={this.inputRef}
            id={this.props.name}
            disabled={this.props.diabled}
            className="aui-textfield__input"
            rows={this.props.rows}
            onChange={this.handleChange}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onKeyDown={this.handleKeyDown}
            value={this.props.value}
            maxLength={this.props.maxLength}
          ></textarea>
          <label className="aui-textfield__label" htmlFor={this.props.name}>{this.props.label}</label>
          <span className="aui-textfield__focus-line"></span>
        </div>
        {showCount && <span className="aui-textfield__counter"><span className="aui-textfield__counter-value">{remainingChars}</span></span>}
        {this.renderMessage()}
      </div>
    );
  }

}

TextArea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  maxLength: PropTypes.number,
  showCount: PropTypes.bool,
  rows: PropTypes.number,
  autosize: PropTypes.bool,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_textfield-themes.scss
};

TextArea.defaultProps = {
  rows: 3,
  autosize: true,
  onChange: () => {},
  style: {},
  className: ''
}

export default TextArea;
