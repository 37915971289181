import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M17.5,23.3 L21.5,19.5 C21.7,19.3 21.7,18.7 21.5,18.5 L18.5,15.5 C18.3,15.3 17.7,15.3 17.5,15.5 L16,16.8 C16,16.8 15.3,17.9 11.1,13.7 L10.6,13.2 C6.3,8.9 7.5,8.5 7.5,8.5 L8.5,7.5 C8.7,7.3 8.7,6.7 8.5,6.5 L5.5,3.5 C5.3,3.3 4.7,3.3 4.5,3.5 L0.7,7.5 C0.7,7.5 6.5,17.1 17.5,23.3 Z"/>
          <path d="M13,0.5 C18.7,0.5 23.5,5.4 23.5,11 M13,5.5 C15.9,5.5 18.5,8.2 18.5,11"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M34.5,46 L41.5,38.5 C42,38 42,37 41.5,36.5 L36.5,31.5 C36,31 35,31 34.5,31.5 L32,33.5 C32,33.5 30.7,35.7 22.2,27.2 L21.2,26.2 C12.7,17.7 14.5,17 14.5,17 L16.5,13.5 C17,13 17,12 16.5,11.5 L12.5,7.5 C12,7 11,7 10.5,7.5 L2.5,14.5 C2.5,14.5 12.5,33.6 34.5,46 Z"/>
        <path d="M26,1.5 C37.3,1.5 46.5,10.7 46.5,22 M26,11.5 C31.8,11.5 36.5,16.2 36.5,22"/>
      </g>
    </svg>
  );
};
