import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';

import { request } from '../../../../lib/apiRequestWrapper';
import { validate } from '../../../../lib/validation';
import { addGuest } from '../../../../redux/Experience';
import CreditCardInput from '../components/CreditCardInput';
import Button from '../../../../audi-ui-components/Button';
import IconArrowLeftNormal from '../../../../audi-ui-components/icons/ArrowLeftNormal';
import IconForward from '../../../../audi-ui-components/icons/Forward';

const FormGuestPayment = ({
  event,
  setStep,
  onConfirmClick,
  onBackToSummaryClick,
  addGuestValues,
  addGuest
}) => {

  let Stripe = null;
  if (typeof window !== 'undefined' && window.Stripe) {
    Stripe = window.Stripe;
  }

  const { booking } = event;
  const totalCost = (event.isGuestFree || event.isDoubleBooking) ? 0 : event.guestPrice;

  const onSubmitHandler = (values, formikBag) => {

    const guestValues = {
      firstName: addGuestValues.firstName,
      lastName: addGuestValues.lastName,
      email: addGuestValues.email,
      chargeToken: null,
      isGuest: true
    }

    // Take payment if required
    if ( totalCost > 0 ) {

      const cardDetails = {
        number: values.cardNumber.replace(/ /g, ''),
        cvc: values.cardCVC,
        exp_month: values.cardMonth,
        exp_year: values.cardYear,
      }

      // Stripe token & API submission
      Stripe.card.createToken(cardDetails, (status, response) => {

        if (response.error) {
          formikBag.setErrors({ submit: response.error.message });
          formikBag.setSubmitting(false);
          return;
        }

        guestValues.chargeToken = response.id;

        submitToApi(formikBag, guestValues);
      });
    } else {
      submitToApi(formikBag, guestValues);
    }

    return;
  }

  const submitToApi = async (formikBag, guestValues) => {
    try {
      let response = await request(
        `${process.env.RAZZLE_API}/3/experience/bookings/${booking.id}/attendee`,
        {
          method: "POST",
          body: JSON.stringify(guestValues),
        }
      );

      // Slot the new values into state, to avoid refetching the whole event/booking.
      guestValues.id = response.id;
      addGuest(guestValues);

      formikBag.setSubmitting(false);
      onConfirmClick();

    } catch (error) {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setErrors(errors);
      }
      formikBag.setSubmitting(false);
    }
  }

  const onBackClick = () => {
    setStep(1);
  }

  const initialValues = {
    cardNumber: '',
    cardCVC: '',
    cardMonth: '',
    cardYear: '',
  }

  const validationFields = {
    cardNumber: {
      presence: {
        message: "This field is required",
        allowEmpty: false
      },
      stripeCardNumber: {
        message: "Please enter a valid card number"
      }
    },
    cardCVC: {
      presence: {
        message: "This field is required",
        allowEmpty: false
      },
      stripeCVC: {
        message: "Please enter a valid CVC"
      }
    },
    cardMonth: {
      presence: {
        message: "This field is required",
        allowEmpty: false
      }
    },
    cardYear: {
      presence: {
        message: "This field is required",
        allowEmpty: false
      }
    }
  }

  const validateForm = (values) => {
    let validation = validate(values, validationFields, { format: "firstError", fullMessages: true });
    return validation;
  }

  return (
    <>
      <h2 className="aui-headline-3 mt-4 mb-4"><b>Payment</b></h2>
      <Button
        className="btn btn--incognito btn--back mb-7"
        onClick={onBackClick}
        label="Back"
        icon={<IconArrowLeftNormal small />} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-8 aui-headline-5"><b>Summary</b></div>
          <div className="col"></div>
        </div>
        <div className="row">
          <div className="col-8">{event.name} (guest ticket)</div>
          <div className="col d-flex justify-content-end aui-headline-5">
            {!(event.isGuestFree || event.isDoubleBooking) && <>${event.guestPrice.toFixed(2)} </>}
          </div>
        </div>
        <hr className="my-4" />
        <div className="row">
          <div className="col-8"></div>
          <div className="col d-flex justify-content-end aui-headline-5">
            {totalCost > 0 && <b>${event.guestPrice.toFixed(2)}</b>}
            {!totalCost || totalCost === 0 && <b>no payment required</b>}
          </div>
        </div>
        {totalCost > 0 && <div className="row justify-content-end mt-3">
          <small>Price includes GST</small>
        </div>}
      </div>
      <Formik
        initialValues={initialValues}
        validate={totalCost > 0 && validateForm}
        onSubmit={onSubmitHandler} >
        {formikBag => <Form>
          {totalCost > 0 && <CreditCardInput formikBag={formikBag} />}
          {formikBag.errors && formikBag.errors.submit && <p className="aui-color-text-red py-3">{formikBag.errors.submit}</p>}
          <div className="row mt-7 mb-3">
            <div className="col">
              <Button
                buttonType="primary"
                label={totalCost > 0 ? 'Submit' : 'Confirm'}
                icon={<IconForward small />}
                iconPosition="right"
                type="submit"
                disabled={formikBag.isSubmitting} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Button
                buttonType="secondary"
                label="Cancel"
                onClick={onBackToSummaryClick}
                disabled={formikBag.isSubmitting} />
            </div>
          </div>
        </Form>}
      </Formik>
    </>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addGuest: (values) => {
      dispatch(addGuest(values));
    },
  };
};

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormGuestPayment);
