import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import Media from '../../components/Media';
import Button from '../../audi-ui-components/Button';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';

const HomeHeroSection = (props) => {
  let textColour = "light";
  return (
    <div className="home-hero outdent-below-medium mb-5 mb-medium-2">
      <Carousel interval={process.env.RAZZLE_APP_ENV === "local" ? null : 8000} controls={false}>
        {props.content.map((item, i) => {
          return (
            <Carousel.Item key={item.id}>
              <div key={item.id} id={`hero-home-${i}`} className={cn('hero home gradient-overlay')}>
                <div className={`overlay aui-color-text-${textColour}`}>
                  <div className="content-wrapper">
                    
                    <div className="row">
                      <div className={cn("col-10 col-medium-7")}>
                        <h3 className="aui-headline-3 mb-3 pt-5"><b>{item.heading}</b></h3>
                        <p className="mb-5" style={{fontWeight: "400"}}>{item.subHeading}</p>
                        <div className={cn("pb-5", {"multi": item.ctaCollection && item.ctaCollection.length === 2})}>
                          {item.ctaCollection && item.ctaCollection.map((cta,ii) => {
                              if (cta.link?.indexOf("http") === 0) {
                                  return (<Button key={`hero-cta-${ii}`} label={cta.label} href={cta.link} target="_blank" rel="noreferrer" buttonType={cta.type?.toLowerCase()} theme="light" className="mr-3 mb-3" />);
                              }
                              return (
                                  <Link key={`hero-cta-${ii}`} to={cta.link} className={`aui-button aui-button--${cta.type?.toLowerCase()} aui-theme-light mr-3 mb-3`}><span className="aui-button__text">{cta.label}</span></Link>
                              );
                          })}
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
                
                {item.mobileUrl && <>
                  <div className="hero-media d-none d-small-block"><Media type={item.type} url={item.url} noControls useEmbedWrapper /></div>
                  <div className="hero-media d-small-none"><Media type={item.type} url={item.mobileUrl} noControls useEmbedWrapper /></div>
                </>}
                {!item.mobileUrl && <div className="hero-media"><Media type={item.type} url={item.url} noControls useEmbedWrapper /></div>}
              
              </div>
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default HomeHeroSection;
