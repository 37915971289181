export function getVehicleFallbackThumb(modelNameSD, modelYear) {
    let year = '';
    if (modelYear) {
        year = `${modelYear}-`;
    }
    let slug = modelNameSD.toLowerCase().replace('é','e').replace(/ /gi, '-');
    return `${process.env.PUBLIC_PATH}assets/models/thumb/${year}${slug}.jpg`;
}

export function getVehicleFallbackLarge(modelNameSD, modelYear) {
    let year = '';
    if (modelYear) {
        year = `${modelYear}-`;
    }
    let slug = modelNameSD.toLowerCase().replace('é','e').replace(/ /gi, '-');
    return `${process.env.PUBLIC_PATH}assets/models/${year}${slug}.jpg`;
}
