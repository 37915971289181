import React from 'react';

class LocateADealer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            frameHeight: 0
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('message', this.handleFrameHeight, false);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleFrameHeight, false);
    }

    handleFrameHeight = (e) => {
        if (e.origin === process.env.RAZZLE_FORMS_URL) {
            var data = JSON.parse( e.data );
            this.setState({frameHeight: data.height});
        }
    }

    render() {
        const {frameHeight} = this.state;
        return (
            <div className="page-wrapper">
              <div className="content-wrapper">
                <iframe style={{height: frameHeight > 0 ? `${frameHeight}px` : 'auto', minHeight: "800px"}} src={`${process.env.RAZZLE_FORMS_URL}/locate-a-dealer?embedded=true&myaudi=true`} />
              </div>
            </div>
        );
    }
}

export default LocateADealer;
