import React, { useState } from 'react';
import Media from './../../../components/Media';
import { gtmPush } from '../../../lib/gtm';
import Button from '../../../audi-ui-components/Button';

const TradeVehicle = ({ onClick, onHide }) => {

  return (
    <div className="trade-vehicle" style={{height:'100%'}}>
      <Media
        type="image"
        url={`${process.env.PUBLIC_PATH}assets/1920x1080_AA6_L_181005.jpg`}
        altText="Trade Vehicle Tile Background Image"
        useFullImg={false}
        className="position-relative">
        <div className="overlay d-flex flex-column justify-content-end px-3 px-small-6 py-6 aui-color-text-light">
          <div className="aui-headline-5 mb-3">
            <b>Thinking of trading in your vehicle?</b>
          </div>
          <p className="disclaimer">
            <b>
              Enquire now for an obligation-free valuation of your Audi.
            </b>
          </p>
          <div className="d-flex">
            <Button
              buttonType="primary"
              theme="light"
              label="Enquire about a trade-in"
              className="mt-3 mr-4"
              onClick={() => { onClick(); gtmPush("myAudi", "tradeInEnquiry", "vehicle"); }}
            />
          </div>
        </div>
      </Media>
    </div>
  );
};

export default TradeVehicle;
