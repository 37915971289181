import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M22.5,19.5 L3.5,19.5 C2.4,19.5 1.5,18.6 1.5,17.5 L1.5,4.5 L20.5,4.5 C21.6,4.5 22.5,5.4 22.5,6.5 L22.5,19.5 Z"/>
          <path d="M5 9.5L12 15 19 9.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M45.5,39.5 L4.5,39.5 C3.4,39.5 2.5,38.6 2.5,37.5 L2.5,8.5 L43.5,8.5 C44.6,8.5 45.5,9.4 45.5,10.5 L45.5,39.5 Z"/>
        <path d="M7 17L24 30 41 17"/>
      </g>
    </svg>
  );
};
