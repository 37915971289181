import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Button from '../../../audi-ui-components/Button';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import { passwordSchema } from '../schema';
import { validate } from '../../../lib/validation';
import { gtmPush } from '../../../lib/gtm';
import { changePassword } from '../../../redux/Profile';
import LoadingOverlay from '../../../components/LoadingOverlay';

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changePassword: (formData, cb, errCb) => { dispatch(changePassword(formData, cb, errCb)); }
    }
}

class PasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            errorMessage: false
        }
    }

    validateForm = (values) => {
        return validate(values, passwordSchema, { format: "firstError", fullMessages: false });
    }

    handleSubmit = (values, formikBag) => {
        this.setState({ isUpdating: true, isSaved: false, errorMessage: false });
        this.props.changePassword(
            values,
            () => {
                formikBag.resetForm({});
                this.setState({ isUpdating: false, isSaved: true });
                gtmPush("myAudi", "passwordUpdateSuccess", "profile");
            },

            (error) => {
                if (error.status == 400 && error.body.message) {
                    this.setState({ isUpdating: false, errorMessage: error.body.message });
                } else {
                    this.setState({ isUpdating: false, errorMessage: "Not saved" });
                }
            }
        );
    }

    render() {
        return (
            <Formik validate={this.validateForm}
                onSubmit={this.handleSubmit}
                initialValues={{ oldPassword: '', newPassword: '', confirmPassword: '' }}>

                {(formikBag) => (
                    <Form>

                        {this.state.isUpdating && <LoadingOverlay />}
                        <p className="aui-headline-4 mb-3"><b>Update your password.</b></p>

                        <FormField name="oldPassword" label="Old Password" type="password" autoComplete="new-password" formikBag={formikBag} />
                        <FormField name="newPassword" label="New Password" type="password" autoComplete="new-password" formikBag={formikBag} />
                        <FormField name="confirmPassword" label="Repeat New Password" type="password" autoComplete="new-password" formikBag={formikBag} />

                        <Button label="Update Password" type="submit" buttonType="primary" />

                        {(this.state.isSaved && !formikBag.dirty) &&
                            <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                        }
                        {this.state.errorMessage &&
                            <span className="aui-color-text-red px-3"><IconCaution small /> {this.state.errorMessage}</span>
                        }
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);
