import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { gtmPush } from '../../../../lib/gtm';
import { request } from '../../../../lib/apiRequestWrapper';
import { validate } from '../../../../lib/validation';
import { addBooking } from '../../../../redux/Experience';
import CreditCardInput from '../components/CreditCardInput';
import Button from '../../../../audi-ui-components/Button';
import IconArrowLeftNormal from '../../../../audi-ui-components/icons/ArrowLeftNormal';
import IconForward from '../../../../audi-ui-components/icons/Forward';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import Modal from '../../../../audi-ui-components/Modal';
import { PATH_MY_BOOKINGS } from '../../../../constants';

const FormPayment = ({
    selectedSession,
    onConfirmClick,
    onBackToRegistrationClick,
    event,
    registerFormValues,
    addBooking,
    isWaitlistBooking,
    profileData,
    setDonation,
    setDonateError
}) => {

    let Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
        Stripe = window.Stripe;
    }

    const ticketPriceTotal = registerFormValues.watchOnly === true ? 0 : event.price;
    const rebatePriceTotal = registerFormValues.watchOnly === true ? 0 : event.rebate;
    let totalCost = ticketPriceTotal - rebatePriceTotal;
    if (registerFormValues.hasGuest === 'true' && !(event.isGuestFree || event.isDoubleBooking)) {
        totalCost += event.guestPrice;
    }
    const hasDonation = registerFormValues.donation && registerFormValues.donation > 0;

    const controller = new AbortController();
    let requestTimeout = null;
    const [reqHasTimedOut, setReqHasTimedOut] = useState(false);

    const onSubmitHandler = (values, formikBag) => {

        const bookingValues = {
            eventId: registerFormValues.eventId,
            sessionId: selectedSession.id,
            dietaryRequirements: registerFormValues.dietaryRequirements,
            hasGuest: registerFormValues.hasGuest,
            guestFirstName: registerFormValues.guestFirstName,
            guestLastName: registerFormValues.guestLastName,
            guestEmail: registerFormValues.guestEmail,
            chargeToken: null,
            agreedToTerms: registerFormValues.understand,
            deliveryAddress: registerFormValues.deliveryAddress,
            watchOnly: registerFormValues.watchOnly,
            notifySMS: registerFormValues.notifySMS
        }
        if (isWaitlistBooking) {
            bookingValues.waitlistId = event.waitlist.id;
        }

        const cardDetails = {
            number: values.cardNumber.replace(/ /g, ''),
            cvc: values.cardCVC,
            exp_month: values.cardMonth,
            exp_year: values.cardYear,
        }

        // Take payment if required
        if ( totalCost > 0 ) {
            // Stripe token & API submission
            Stripe.card.createToken(cardDetails, (status, response) => {

                if (response.error) {
                    formikBag.setErrors({submit: response.error.message});
                    formikBag.setSubmitting(false);
                    return;
                }

                bookingValues.chargeToken = response.id;

                submitToApi(formikBag, bookingValues, cardDetails);
            });
        } else {
            submitToApi(formikBag, bookingValues, cardDetails);
        }
        return;
    }

    const submitToApi = async (formikBag, bookingValues, cardDetails) => {
        requestTimeout = setTimeout(() => {
            controller.abort();
            setReqHasTimedOut(true);
            requestTimeout = null;
            formikBag.setSubmitting(false);
        }, 120000);
        try {
            let response = await request(
                `${process.env.RAZZLE_API}/3/experience/bookings`,
                {
                    method: "POST",
                    body: JSON.stringify(bookingValues),
                    signal: controller.signal
                }
            );

            // Update booking on the event in redux store.

            gtmPush("AudiExperience", "eventPaymentSuccess", event.name);
            if (hasDonation) {
                clearTimeout(requestTimeout);
                submitDonation(formikBag, cardDetails, response);
            } else {
                clearTimeout(requestTimeout);
                addBooking(response);
                onConfirmClick();
            }

        } catch (error) {
            console.error(error);
            let msg = "We are unable to process your order at the time. Please try again in a few minutes or call customer service on 1300 557 405";
            if (error.status === 409) {
                msg = <>Our booking system is telling us that you have already booked this event. Please check <Link to={PATH_MY_BOOKINGS} className="aui-textlink">your Bookings</Link> to confirm.</>;
            } else if (error.body && error.body.message) {
                msg = error.body.message;
            }
            let errors = {submit: msg};
            if (error.body && error.body.modelState) {
                for (let key in error.body.modelState) {
                    if (!Object.hasOwnProperty(key)) {
                        errors[key] = error.body.modelState[key][0];
                    }
                }
            }
            formikBag.setErrors(errors);
            formikBag.setSubmitting(false);
            clearTimeout(requestTimeout);
        }
    }

    const submitDonation = (formikBag, cardDetails, bookingResponse) => {
        Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_KEY_DONATE);
        setTimeout(() => {
            Stripe.card.createToken(cardDetails, (status, response) => {
                if (response.error) {
                    setDonateError(true);
                    Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
                    addBooking(bookingResponse);
                    onConfirmClick();
                } else {
                    let donationObj = {
                        chargeToken: response.id,
                        totalAmount: registerFormValues.donation,
                        firstName: profileData.firstName,
                        lastName: profileData.lastName,
                        email: profileData.email,
                        phoneNumber: profileData.mobile,
                        source: "myAudi",
                        optout: true,
                        optin: false
                    };
                    request(
                        `${process.env.RAZZLE_FOUNDATION_API}/1/cms/donate`,
                        {
                            method: "POST",
                            body: JSON.stringify(donationObj)
                        }
                    ).then((response) => {
                        console.log(response);
                        setDonation(registerFormValues.donation);
                        Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
                        addBooking(bookingResponse);
                        onConfirmClick();
                    }).catch((error) => {
                        console.error(error);
                        setDonateError(true);
                        Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
                        addBooking(bookingResponse);
                        onConfirmClick();
                    });
                }
            });
        }, 500);
    }

    const initialValues = {
        cardNumber: '',
        cardCVC: '',
        cardMonth: '',
        cardYear: '',
    }

    const validationFields = {
        cardNumber: {
            presence: {
                message: "This field is required",
                allowEmpty: false
            },
            stripeCardNumber: {
                message: "Please enter a valid card number"
            }
        },
        cardCVC: {
            presence: {
                message: "This field is required",
                allowEmpty: false
            },
            stripeCVC: {
                message: "Please enter a valid CVC"
            }
        },
        cardMonth: {
            presence: {
                message: "This field is required",
                allowEmpty: false
            }
        },
        cardYear: {
            presence: {
                message: "This field is required",
                allowEmpty: false
            }
        }
    }

    const validateForm = (values) => {
        let validation = validate(values, validationFields, { format: "firstError", fullMessages: true });
        return validation;
    }

    return (
        <Formik initialValues={initialValues} validate={totalCost > 0 && validateForm} onSubmit={onSubmitHandler}>
            {formikBag => <Form>

                <Modal isActive={formikBag.isSubmitting || reqHasTimedOut} modalStyle="layer">
                    <LoadingOverlay type="placeholder">
                        {!reqHasTimedOut && <p>We are currently processing your order.<br /> Please don’t refresh your browser.</p>}
                        {reqHasTimedOut && <>
                            <p className="mb-3">Our servers are busy processing orders at this time. Please try again in a few minutes.</p>
                            <Button label="OK"
                                buttonType="primary"
                                onClick={() => { setReqHasTimedOut(false); }}
                            />
                        </>}
                    </LoadingOverlay>
                </Modal>

                <h2 className="aui-headline-3 mt-4 mb-4"><b>Payment</b></h2>
                <Button label="Back to registration"
                    className="btn btn--incognito btn--back mb-7"
                    onClick={onBackToRegistrationClick}
                    icon={<IconArrowLeftNormal small />}
                />
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-8 aui-headline-5"><b>Summary</b></div>
                        <div className="col"></div>
                    </div>
                    <div className="row">
                        <div className="col-8">{event.name}</div>
                        <div className="col d-flex justify-content-end aui-headline-5">${ticketPriceTotal.toFixed(2)}</div>
                    </div>
                    {rebatePriceTotal!=0 && <div className="row mt-3">
                        <div className="col-8">Less loyalty rebate</div>
                        <div className="col d-flex justify-content-end aui-headline-5">-${rebatePriceTotal.toFixed(2)}</div>
                    </div>}
                    {registerFormValues.hasGuest === 'true' && !(event.isGuestFree || event.isDoubleBooking) && <div className="row mt-3">
                        <div className="col-8">Guest ticket</div>
                        <div className="col d-flex justify-content-end aui-headline-5">${event.guestPrice.toFixed(2)}</div>
                    </div>}
                    <hr className="my-4" />
                    <div className="row">
                        <div className="col-8"></div>
                        <div className="col d-flex justify-content-end aui-headline-5"><b>${totalCost.toFixed(2)}</b></div>
                    </div>
                    <div className="row justify-content-end mt-3">
                        <small>Price includes GST</small>
                    </div>
                    {hasDonation && <>
                        <hr className="my-4" />
                        <div className="">
                            <div className="row">
                                <div className="col-8"><b className="aui-font-extended"><span className="aui-color-text-red">Audi</span> Foundation</b> donation</div>
                                <div className="col"></div>
                                <div className="col text-right aui-font-extended"><b>${registerFormValues.donation}</b></div>
                            </div>
                        </div>
                        <hr className="my-4" />
                    </>}
                </div>
                {(totalCost > 0 || hasDonation) && <CreditCardInput formikBag={formikBag} className="my-5 aui-color-gray90 py-2 px-5" />}
                {formikBag.errors && formikBag.errors.submit && <p className="aui-color-text-red py-3">{formikBag.errors.submit}</p>}
                <div className="mt-5"><Button label={totalCost > 0 ? 'Submit' : 'Confirm'}
                    buttonType="primary"
                    type="submit"
                    icon={<IconForward small />}
                    iconPosition="right"
                    disabled={formikBag.isSubmitting}
                /></div>
            </Form>}
        </Formik>
    );
}

const mapDispatchToProps = dispatch => {
  return {
    addBooking: booking => dispatch(addBooking(booking)),
  };
};

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    profile: state.profile,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormPayment);
