// import { useState } from "react";
import { Link } from 'react-router-dom';
import { PATH_VEHICLES } from '../../constants';

const JellybeanLink = ({ vin, imgUrl, modelName }) => {
  // const [imgLoaded, setImgLoaded] = useState(false);
  return (
    <Link className="nav-vehicle d-none d-medium-flex me-2" to={`${PATH_VEHICLES}/${vin}`}>
      {imgUrl && <span className="nav-vehicle__jellybean"><img src={imgUrl} alt="" /></span>}
      {!imgUrl && <span className="nav-vehicle__text">Audi {modelName}</span>}
    </Link>
  );
};

export default JellybeanLink;
