import React from 'react';
import classnames from 'classnames';
import moment from 'moment';
import Button from '../../../audi-ui-components/Button';
import IconWarranty from '../../../audi-ui-components/icons/Warranty';
import IconDocuments from '../../../audi-ui-components/icons/Documents';
import { gtmPush } from '../../../lib/gtm';

const Warranty = ({
    warrantyStartDate,
    warrantyEndDate,
    warrantyType,
    extendedWarrantyFlag,
    extWarrantyCoverStartDate,
    extWarrantyCoverEndDate,
    aapWarrantyFlag,
    aapWarrantyStartDate,
    aapWarrantyEndDate,
    aaExtendedWarrantyFlag,
    aaExtendedWarrantyStartDate,
    aaExtendedWarrantyEndDate,
    showActiveModal
}) => {

    var today = moment();
    var stdWarrantyYrs = warrantyType === "three" ? 3 : 5;
    var isStdWarrantyEnded = today.isAfter(warrantyEndDate);
    var isExtWarrantyEligible = stdWarrantyYrs === 3 ? moment(warrantyStartDate).add(2, 'years').isAfter(today) : false;
    var isExtWarrantyPurchased = false;
    
    var pastExtWarranties = [];
    var currentExtWarranties = [];
    var futureExtWarranties = [];

    if (aaExtendedWarrantyFlag && aaExtendedWarrantyStartDate && aaExtendedWarrantyEndDate) {
        isExtWarrantyPurchased = true;
        let obj = {name: "aaExtendedWarranty", start: aaExtendedWarrantyStartDate, end: aaExtendedWarrantyEndDate};
        if (today.isBefore(aaExtendedWarrantyStartDate)) {
          futureExtWarranties.push(obj);
        } else if (today.isAfter(aaExtendedWarrantyStartDate) && today.isBefore(aaExtendedWarrantyEndDate)) {
          currentExtWarranties.push(obj);
        } else if (today.isAfter(aaExtendedWarrantyEndDate)) {
          pastExtWarranties.push(obj);
        }
    }
    if (extendedWarrantyFlag && extWarrantyCoverStartDate && extWarrantyCoverEndDate) {
        isExtWarrantyPurchased = true;
        let obj = {name: "extendedWarranty", start: extWarrantyCoverStartDate, end: extWarrantyCoverEndDate};
        if (today.isBefore(extWarrantyCoverStartDate)) {
          futureExtWarranties.push(obj);
        } else if (today.isAfter(extWarrantyCoverStartDate) && today.isBefore(extWarrantyCoverEndDate)) {
          currentExtWarranties.push(obj);
        } else if (today.isAfter(extWarrantyCoverEndDate)) {
          pastExtWarranties.push(obj);
        }
    }
    if (aapWarrantyFlag && aapWarrantyStartDate && aapWarrantyEndDate) {
        isExtWarrantyPurchased = true;
        let obj = {name: "aapWarranty", start: aapWarrantyStartDate, end: aapWarrantyEndDate};
        if (today.isBefore(aapWarrantyStartDate)) {
          futureExtWarranties.push(obj);
        } else if (today.isAfter(aapWarrantyStartDate) && today.isBefore(aapWarrantyEndDate)) {
          currentExtWarranties.push(obj);
        } else if (today.isAfter(aapWarrantyEndDate)) {
          pastExtWarranties.push(obj);
        }
    }

    if (pastExtWarranties.length > 1) {
      pastExtWarranties.sort((a,b) => {
        if (moment(a.end).isBefore(b.end)) {
          return 1;
        }
        return -1;
      });
    }
    if (currentExtWarranties.length > 1) {
      // this really shouldn't happen
      currentExtWarranties.sort((a,b) => {
        if (moment(a.end).isBefore(b.end)) {
          return -1;
        }
        return 1;
      });
    }
    if (futureExtWarranties.length > 1) {
      futureExtWarranties.sort((a,b) => {
        if (moment(a.start).isBefore(b.start)) {
          return -1;
        }
        return 1;
      });
    }

    return (
        <div className="aui-color-gray85 mt-3 mb-3 pb-5">
            <div className="row">

                <div className={classnames("col-12", {"col-medium-6": stdWarrantyYrs === 3})}>
                    <div className="py-5 py-medium-7">
                        <div className="row align-items-center">
                            <div className="col-12 col-small-2 d-flex justify-content-center justify-content-small-end px-2 py-4">
                                <IconWarranty />
                            </div>
                            <div className="col-10 offset-1 col-small-8 offset-small-0">
                                <h2 className="aui-headline-3"><b>New Car Warranty</b></h2>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-1 col-small-2" />
                            <div className="col-10 col-small-8">
                                <p>
                                    Your Audi comes with a {stdWarrantyYrs} year, unlimited kilometre factory warranty as standard.
                                </p>
                                {isStdWarrantyEnded && <p>Your factory warranty has now ended.</p>}
                                {!isStdWarrantyEnded && <>
                                    <p>Your new car factory warranty will end on:</p>
                                    <p className="aui-headline-3 my-5">
                                        {moment(warrantyEndDate).format('MMM Do, YYYY')}
                                    </p>
                                    <p className="disclaimer">*Warranty eligibility is subject to adhering to the terms and conditions of your warranty.</p>
                                </>}
                            </div>
                            <div className="col-auto col-small-2" />
                        </div>
                    </div>
                </div>

                {stdWarrantyYrs === 3 && <div className="col-12 col-medium-6">
                    <div className="py-5 py-medium-7">
                        <div className="row align-items-center">
                            <div className="col-12 col-small-2 d-flex justify-content-center justify-content-small-end px-2 py-4">
                                <IconDocuments />
                            </div>
                            <div className="col-10 offset-1 col-small-8 offset-small-0">
                                <h2 className="aui-headline-3"><b>Extended Warranty</b></h2>
                            </div>
                        </div>
                        <div className="row pt-3">
                            <div className="col-1 col-small-2" />

                            {!isExtWarrantyPurchased && isExtWarrantyEligible && <div className="col-10 col-small-8">
                                <p>
                                    You may be eligible to purchase the Genuine Audi Extended Warranty.
                                    <br />There are two Audi Extended Warranty options available:
                                    <br />&bull; Two years/100,000kms or
                                    <br />&bull; Two years/150,000kms.
                                    <br />The Audi Extended Warranty is available for purchase up to two years from the new vehicle warranty start date.
                                    This offer is subject to <a className="aui-textlink" href="http://image.email.audiaustralia.com.au/lib/fe3715707564007d7c1373/m/2/7c3d34bc-d75d-4e87-b1a7-31711f7ce793.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a>.
                                </p>
                                <Button
                                    buttonType="secondary"
                                    label="Enquire about an Extended Warranty"
                                    className="mt-6"
                                    onClick={() => { showActiveModal('enquire-warranty'); gtmPush("myAudi", "extWarrantyEnquiry", "vehicle"); }}
                                />
                            </div>}

                            {!isExtWarrantyPurchased && !isExtWarrantyEligible && <div className="col-10 col-small-8">
                                <p className="mb-3">The Audi Extended Warranty must be purchased within two years from the start of the new vehicle warranty.</p>
                                <p>As your vehicle is now past the two year period you are no longer eligible.</p>
                            </div>}

                            {currentExtWarranties.length > 0 && <div className="col-10 col-small-8">
                                <p>Your new car extended warranty is now active.</p>
                                <p>Your new car extended warranty will end on:</p>
                                <p className="aui-headline-3 my-5">
                                    {moment(currentExtWarranties[0].end).format('MMM Do, YYYY')}
                                </p>
                            </div>}
                            
                            {currentExtWarranties.length === 0 && futureExtWarranties.length > 0 && <div className="col-10 col-small-8">
                                <p>Your new car extended warranty will begin on:</p>
                                <p className="aui-headline-3 my-5">
                                    {moment(futureExtWarranties[0].start).format('MMM Do, YYYY')}
                                </p>
                                <p>Your new car extended warranty will end on:</p>
                                <p className="aui-headline-3 my-5">
                                    {moment(futureExtWarranties[0].end).format('MMM Do, YYYY')}
                                </p>
                            </div>}

                            {currentExtWarranties.length === 0 && futureExtWarranties.length === 0 && pastExtWarranties.length > 0 && <div className="col-10 col-small-8">
                                <p>Your new car extended warranty has now ended.</p>
                            </div>}

                            <div className="col-auto col-small-2"></div>
                        </div>
                    </div>
                </div>}

            </div>
        </div>
    );
};

export default Warranty;
