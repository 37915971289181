import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { validate, defaultSchema, formatMobile } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import UploadField from '../../../components/UploadField';
import Tooltip from '../../../components/Tooltip';
import LoadingOverlay from '../../../components/LoadingOverlay';
import IconForward from '../../../audi-ui-components/icons/Forward';
import { fetchDealers } from '../../../redux/Dealers';
import { gtmPush } from '../../../lib/gtm';
import { FIELD_DEALER_SELECT, FIELD_DATEPICKER, FIELD_RADIOS } from '../../../constants';
import _find from 'lodash/find';
import moment from 'moment';

const mapStateToProps = state => {
  return {
    isLoading: state.profile.isLoading || state.dealers.isLoading,
    profile: state.profile.data ? state.profile.data : {},
    dealers: state.dealers.list
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchDealers: () => {dispatch(fetchDealers());}
  }
}

const RegisterVehicleForm = ({ profile, dealers, isLoading, fetchDealers }) => {

  useEffect(() => {
    if (!dealers || dealers.length === 0) {
      fetchDealers();
    }
  });

  const [files, setFiles] = useState(null);
  const [serverError, setServerError] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (values, formikBag) => {
    setServerError(null);
    setFileError(null);
    let fd = new FormData();
    fd.append('file', files[0]);
    request(
      `${process.env.RAZZLE_API}/1/request/uploadImage`,
      {
        method: 'POST',
        body: fd,
        headers: {
          'Cache-Control': 'no-cache'
        },
      }
    ).then(response => {
      // console.log(response);

      // submit form data
      let dos = moment(values.saleDate).format("YYYY/MM/DD");
      let _data = Object.assign({}, values, {saleDate: dos, RegistrationImageUrl: response});
      request(
        `${process.env.RAZZLE_API}/1/request/registerVehicle`,
        {
          method: 'POST',
          body: JSON.stringify(_data),
        }
      ).then(response => {
        // success
        formikBag.setSubmitting(false);
        setSubmitted(true);
        gtmPush("form", "submitSuccess", "addVehicleMyAudi", values);
      }).catch(error => {
        // error
        formikBag.setSubmitting(false);
        if (error.body && error.body.modelState) {
          let errors = {};
          for (let key in error.body.modelState) {
            if (!Object.hasOwnProperty(key)) {
              errors[key] = error.body.modelState[key][0];
            }
          }
          formikBag.setStatus(errors);
        } else if (error.message) {
          setServerError(error.message);
        } else if (error.body && error.body.message) {
          setServerError(error.body.message);
        }
      });
      // end submit form data

    }).catch(error => {
      formikBag.setSubmitting(false);
      if (error.body && error.body.message) {
        setFileError(error.body.message);
      }
    });

  }

  const validateForm = (values) => {
    let validation = validate(values, {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      emailAddress: defaultSchema.email,
      postcode: defaultSchema.postcode,
      mobilePhone: defaultSchema.mobile,
      registrationNumber: {
        presence: { message: "Please enter the vehicle registration number", allowEmpty: false }
      },
      vin: defaultSchema.vin,
      modelName: {presence: { message: "Please enter the model", allowEmpty: false }},
      saleDate: {
        presence: { message: "Please select the date", allowEmpty: false },
        isPastDate: true
      },
    }, { format: "firstError", fullMessages: true }) || {};
    if (values.saleType === 'audi' && !values.dealerId) {
      validation.dealerId = "Please select a dealer";
    }
    if (values.saleType === 'other' && !values.otherText) {
      validation.otherText = "Please provide details";
    }
    if (!files || files.length === 0) {
      validation.file = "This field is required";
    }
    return validation;
  }

  const dealer = _find(dealers, {name: profile.preferredDealer});

  return (
    <div className="">

      {submitted &&
        <div>
          <h2 className="aui-headline-4 mb-7">Thank you</h2>
          <p>Thank you for submitting your registration request. Audi Customer Care will contact you and confirm when we have processed your registration change.</p>
        </div>
      }

      {!isLoading && !submitted && <Formik
        initialValues={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          emailAddress: profile.email,
          mobilePhone: profile.mobile,
          postcode: profile.homePostcode,
          vin: "",
          modelName: "",
          saleType: "audi",
          dealerId: dealer ? dealer.dealerId : ""
        }}
        validate={validateForm}
        onSubmit={handleSubmit}>
        {formikBag => (
          <Form>
            {formikBag.isSubmitting && <LoadingOverlay />}
            {(!profile.firstName || !profile.lastName) && <>
              <FormField name="firstName" label="First name" formikBag={formikBag} />
              <FormField name="lastName" label="Last name" formikBag={formikBag} />
            </>}
            {(!profile.mobile || formikBag.errors.mobilePhone) && <FormField name="mobilePhone" label="Mobile phone" formikBag={formikBag} formatValue={formatMobile} />}
            {(!profile.homePostcode || formikBag.errors.postcode) && <FormField name="postcode" label="Postcode" formikBag={formikBag} />}

            <p className="mb-3">
                Please upload your vehicle proof of purchase or vehicle registration papers.
                <br />
                <Tooltip
                    trigger={<span className="aui-textlink">Why we need your registration papers?</span>}
                    tip="We are unable to legally record you as the owner of this vehicle without proof of ownership."
                />
            </p>

            <UploadField
              accept=".jpg,.jpeg,.png,.gif,.pdf"
              onChange={f => { setFiles(f); setFileError(null); formikBag.setFieldTouched("file"); }}
              isInvalid={Boolean(fileError || (formikBag.errors.file && (formikBag.touched.file || formikBag.submitCount > 0)))}
              invalidMessage={fileError || formikBag.errors.file}
            />

            <FormField name="vin" label="VIN" formikBag={formikBag} />
            <FormField name="registrationNumber" label="Registration" formikBag={formikBag} />
            <FormField name="modelName" label="Model" description="eg. 'A1 Sportback'" formikBag={formikBag} />

            <FormField name="saleType" label="How did you purchase this vehicle?" fieldType={FIELD_RADIOS} formikBag={formikBag} wrapperClassName="mt-6 mb-2" options={[
              {name: 'saleType', label: 'Audi Dealer', value: 'audi'},
              {name: 'saleType', label: 'Other', value: 'other'}
            ]} />
            {formikBag.values.saleType === 'audi' &&
              <FormField name="dealerId" label="Dealer" fieldType={FIELD_DEALER_SELECT} dealers={dealers || []} valueProp="dealerId" formikBag={formikBag} />
            }
            {formikBag.values.saleType === 'other' &&
              <FormField name="otherText" label="Please specify" description="eg. 'private purchase' or name of dealership" formikBag={formikBag} />
            }

            <FormField name="saleDate" label="Date of purchase" fieldType={FIELD_DATEPICKER} formikBag={formikBag} />

            <br />
            {serverError && <p className="mb-5 aui-color-text-red">{serverError}</p>}
            <p className="mt-4">
              <Button
                buttonType="primary"
                label="Add vehicle"
                icon={<IconForward small />}
                iconPosition="right"
                type="submit"
                disabled={formikBag.isSubmitting} />
            </p>
          </Form>
        )}
      </Formik>}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterVehicleForm);
