import React from 'react';

export default ({className, style, symbol}) => {
  return (
    <div className="disclaimer">
      <p className="aui-headline-5 mb-2">Data and valuations Disclaimer</p>
      <p className="mb-4">Automotive Data Services Pty Ltd (RedBook) has prepared this data and valuations from information gathered from a variety of sources. Whilst all care is taken in producing the data and valuations, RedBook cannot guarantee or make any representations regarding the use of, or reliance, on it. RedBook is not responsible for all the information provided to it and you should not rely on the data or valuations without making your own independent assessment of the vehicle and other sources of information. RedBook is not liable for any loss or damages (other than in respect of any liability which may not lawfully be excluded) relating to your use of, or reliance on, this valuation and data.</p>
      <p className="aui-headline-5 mb-2">Personal and Non Commercial Use Only</p>
      <p className="">Data and valuations on this website are provided for your personal and non-commercial use only. You must not, without the written approval of the Website owner:</p>
      <ul>
        <li>Modify, copy, distribute, transmit, display, perform, reproduce, publish or licence any data and valuations from this website;</li>
        <li>Use or attempt to use any data and valuations published on this website to create any web site or publication;</li>
        <li>Mirror or frame any data and valuations published within this website;</li>
        <li>Use any automated process of any sort to query, access or copy any data and valuations on this website or generate or compile any document or database based on the data and valuations published on this website; or</li>
        <li>Transfer or sell any data and valuations offered on this website;</li>
        <li>The website owner may terminate your right to access and use this website at any time.</li>
      </ul>
    </div>
  )
};
