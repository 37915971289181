import React from 'react';
import { connect } from 'react-redux';
import {
  setFilter,
  setTypeFilter,
  setInterestFilter,
  setShowPast
} from '../../../redux/Experience';
import classnames from 'classnames';
import Select from '../../../audi-ui-components/Select';
import Checkbox from '../../../audi-ui-components/Checkbox';
import Button from '../../../audi-ui-components/Button';
import DashboardIcon from '../../../audi-ui-components/icons/Dashboard';
import ListIcon from '../../../audi-ui-components/icons/List';
import {
  INTERESTS,
  OPTS_STATES
} from '../../../constants';

const mapStateToProps = state => {
  return {
    isGridLayout: state.experience.isGridLayout,
    showPast: state.experience.showPast,
    filterType: state.experience.filterType,
    filterTypeLength: state.experience.filterType.length, // triggers rerender
    filterLocation: state.experience.filterLocation,
    filterInterest: state.experience.filterInterest,
    filterInterestLength: state.experience.filterInterest.length, // triggers rerender
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setFilter: (name, value) => {
      dispatch(setFilter(name, value));
    },
    setInterestFilter: (slug, value) => {
      dispatch(setInterestFilter(slug, value));
    },
      setTypeFilter: (slug, value) => {
      dispatch(setTypeFilter(slug, value));
    },
    setShowPast: (value) => {
      dispatch(setShowPast(value));
    }
  };
};
class ExperienceFilter extends React.Component {
  render() {
    const {
      isGridLayout,
      showPast,
      filterType,
      filterLocation,
      filterInterest
    } = this.props;
    return (
      <div className="experience-filters indent">
        <h2 className="aui-headline-4 mb-5 text-xsmall-center text-medium-left">
          Filters
        </h2>

        <div className="px-2 px-small-0">
          <form className="experience-filters__inner row align-items-medium-start">
            <div className="col-6 col-tiny-4 col-medium-auto mb-6">
              <h3 className="aui-headline-6 mb-5">Type</h3>
              <div className="filter-row justify-content-around">
                <p className="mb-3">
                  <Checkbox
                    label="Events"
                    value=""
                    checked={filterType.indexOf("event") > -1}
                    onChange={(v, e) => {
                      this.props.setTypeFilter('event', e.target.checked);
                    }}
                  />
                </p>
                <p className="mb-3">
                  <Checkbox
                    label="Offers"
                    value=""
                    checked={filterType.indexOf("offer") > -1}
                    onChange={(v, e) => {
                      this.props.setTypeFilter('offer', e.target.checked);
                    }}
                  />
                 </p>
                 <p className="mb-3">
                     <Checkbox
                         label="Virtual"
                         value=""
                         checked={filterType.indexOf("virtual") > -1}
                         onChange={(v, e) => {
                             this.props.setTypeFilter('virtual', e.target.checked);
                         }}
                     />
                 </p>
              </div>
            </div>

            <div className="col-6 col-tiny-4 col-medium-auto mb-6">
              <h3 className="aui-headline-6 mb-5">Date</h3>
              <div className="filter-row justify-content-around">
                <p className="mb-3">
                  <Checkbox
                    label="Current"
                    value=""
                    checked={!showPast}
                    onChange={(v, e) => {
                      this.props.setShowPast(!e.target.checked);
                    }}
                  />
                </p>
                <p className="mb-3">
                  <Checkbox
                    label="Past"
                    value=""
                    checked={showPast}
                    onChange={(v, e) => {
                      this.props.setShowPast(e.target.checked);
                    }}
                  />
                </p>
              </div>
            </div>

            <div className="col-12 col-tiny-4 col-medium-3 mb-6">
              <h3 className="aui-headline-6 mb-5">Location</h3>
              <div className="mb-3">
                <Select
                  label="Location"
                  name="Location"
                  value={filterLocation}
                  options={[{label: "All locations", value: "all"}, ...OPTS_STATES, {label: "International", value: "international"}]}
                  floatingLabel={false}
                  onChange={(v, e) => {
                    this.props.setFilter('filterLocation', e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="col-12 col-medium-4 mb-6">
              <h3 className="aui-headline-6 mb-5">Interest</h3>
              <div className="row mb-3">
                {INTERESTS.map(interest => (
                  <div key={interest.slug} className="col-12 col-tiny-6 col-xsmall-4 col-medium-6 mb-3">
                    <Checkbox
                      label={interest.label}
                      value=""
                      checked={filterInterest.indexOf(interest.slug) > -1}
                      onChange={(v, e) => {
                        this.props.setInterestFilter(interest.slug, e.target.checked);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>

            {this.props.showViewOptions && <div className="col-12 col-medium-auto">
              <h3 className="aui-headline-6 d-none d-medium-block mb-5">
                View
              </h3>
              <div className="d-flex align-items-center d-flex justify-content-end">
                <Button
                  icon={<DashboardIcon large />}
                  className={classnames('grid-toggle dashboard', {
                    selected: isGridLayout,
                  })}
                  onClick={(v, e) => {
                    this.props.setFilter('isGridLayout', true);
                  }}
                />
                <Button
                  icon={<ListIcon large />}
                  className={classnames('grid-toggle list', {
                    selected: !isGridLayout,
                  })}
                  onClick={(v, e) => {
                    this.props.setFilter('isGridLayout', false);
                  }}
                />
              </div>
            </div>}

          </form>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceFilter);
