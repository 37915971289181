import React from 'react';
import { connect } from 'react-redux';
import { loadPage } from '../redux/Content';
import Section from '../components/Section';
import LoadingOverlay from '../components/LoadingOverlay';
import HeroSection from '../components/HeroSection';
import Crumb from '../components/Crumb';
import { Helmet } from "react-helmet";

const mapStateToProps = state => {
  return {
    content: state.content || {},
    isLoadingContent: state.content.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadContent: slug => {
      dispatch(loadPage(slug));
    },
  };
};

class ContentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      content: null,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { slug } = this.props;
    this.props.loadContent(slug);
  }

  render() {
    const pageContent = this.props.content[this.props.slug] || {};
    // console.log(pageContent);
    var sections = pageContent.sections;
    var rows = [];
    if (sections && sections.length) {
      var colCounter = 0;
      var rowCounter = 0;
      // loop through sections
      for (let i = 0; i < sections.length; i++) {
        // we need width to be a number
        sections[i].width = parseInt(sections[i].width) || 12;
        // set left and right
        if (colCounter === 0) {
          sections[i].left = true;
        }
        colCounter += sections[i].width;
        if (colCounter > 12) {
          sections[i].left = true;
          colCounter = 0;
        }
        let next = 0;
        if (i < sections.length - 1) {
          next = colCounter + parseInt(sections[i + 1].width);
        }
        if (sections[i].width === 12 || colCounter === 12 || next > 12) {
          sections[i].right = true;
          colCounter = 0;
        }
        // create a row if we don't have one
        if (!rows[rowCounter]) {
          rows[rowCounter] = [];
        }
        // push the section to the row
        rows[rowCounter].push(sections[i]);
        // increment the row counter
        if (sections[i].width === 12 || colCounter === 12 || next > 12) {
          rowCounter += 1;
        }
      }
    }
    return (
      <div className="page-wrapper">
        <article className="content-wrapper">
          
          {pageContent.heroMedia && (
            <HeroSection content={pageContent.heroMedia} imgWide outdent>
              <h1 className="aui-headline-3 headline-margin mb-small-6 pt-small-7 ">
                {pageContent.title}
              </h1>
            </HeroSection>
          )}

          {this.props.isLoadingContent && (
            <LoadingOverlay type="placeholder" />
          )}

          {pageContent.error && <p className="text-center py-7">Sorry, we couldn't load that page.</p>}

          <Crumb links={[{label: pageContent.title}]} />

          {pageContent.subTitle && <>
            <header className="row">
              <div className="col-small-1"></div>
              <div className="col-12 col-small-6">
                <h2
                  className="h2 aui-headline-4 subtitle py-7"
                  dangerouslySetInnerHTML={{
                    __html: pageContent.subTitle,
                  }}></h2>
                <div className="date"></div>
              </div>
            </header>
            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-12 col-small-10">
                <hr />
              </div>
            </div>
          </>}

          <div className="content">
            {rows &&
              rows.map((row, idx) => (
                <div className="row" key={idx}>
                  {row.map(section => (
                    <Section
                      key={section.id}
                      className="py-6"
                      {...section}
                    />
                  ))}
                </div>
              ))}
          </div>

        </article>

        <Helmet>
          <title>{(pageContent && pageContent.title) ? `${pageContent.title} | ` : ''}myAudi Australia</title>
          {(pageContent && pageContent.seoMetaDescription) && <meta name="description" content={pageContent.seoMetaDescription} />}
        </Helmet>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage);
