import React, { useState } from 'react';
import Button from '../../../audi-ui-components/Button';
import IconCancel from '../../../audi-ui-components/icons/Cancel';
import { request } from '../../../lib/apiRequestWrapper';
import LoadingOverlay from '../../../components/LoadingOverlay';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const UploadImageForm = ({ vin, modelNameSD, updateVehicleImage, setEditPreviewUrl, onClose, hasUserImage }) => {

    const [src, setSrc] = useState(null);
    const [blob, setBlob] = useState(null);
    const defaultCrop = {
        unit: 'px',
        width: 1,
        height: 1,
        aspect: 2.6,
        x: 0,
        y: 0
    };
    const [crop, setCrop] = useState(defaultCrop);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const [imageRef, setImageRef] = useState(null);
    const [isSaved, setIsSaved] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [isSizeError, setIsSizeError] = useState(false);

    const minWidth = 1000;
    const minHeight = 400; //2.6 aspect ratio, rounding up the height to nearest 100
    const [cropMinWidth, setCropMinWidth] = useState(0);
    const [cropMinHeight, setCropMinHeight] = useState(0);

    const onChangeImage = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                setSrc(reader.result)
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    }

    const onImageLoaded = (image) => {
        if (checkImageSize(image)) {
            setImageRef(image);
            setIsSaved(false);
            setIsError(false);

            updateMinDimensions(image);

            return false;
        }
    }

    const updateMinDimensions = (image) => {
        const scaleX = image.naturalWidth / image.width;
        var minW = minWidth / scaleX;
        setCropMinWidth(minW);

        const scaleY = image.naturalHeight / image.height;
        var minH = minHeight / scaleY;
        setCropMinHeight(minH);

        var newCrop = defaultCrop;
        newCrop.width = minW;
        newCrop.height = minH;
        setCrop(newCrop);

        makeClientCrop(image, newCrop);
    }

    const checkImageSize = (image) => {
        if (image.naturalWidth < minWidth || image.naturalHeight < minHeight) {
            setIsSizeError(true);
            handleCancel();
            return false;
        }
        else {
            setIsSizeError(false);
            return true;
        }
    }

    const onCropComplete = () => {
        makeClientCrop();
    }

    const onCropChange = (newCrop, percentCrop) => {
        setCrop(newCrop);
        setIsSaved(false);
        setIsError(false);
    }

    async function makeClientCrop(image = imageRef, newCrop = crop) {
        if (image) {
            const url = await getCroppedImg(
                image,
                'newFile.jpg',
                newCrop
            );
            setCroppedImageUrl(url);
            setEditPreviewUrl(url);
        }
    }


    const getCroppedImg = (image, fileName, newCrop = crop) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = newCrop.width * scaleX;
        canvas.height = newCrop.height * scaleY;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            newCrop.x * scaleX,
            newCrop.y * scaleY,
            newCrop.width * scaleX,
            newCrop.height * scaleY,
            0,
            0,
            newCrop.width * scaleX,
            newCrop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(b => {
                if (!b) {
                    console.error('Canvas is empty');
                    return;
                }
                b.name = fileName;
                setBlob(b);
                var fileUrl;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(b);
                resolve(fileUrl);
            }, 'image/jpeg');
        });
    }

    const handleUploadImage = () => {
        setIsSaved(false);
        setIsError(false);
        setIsBusy(true);

        let fd = new FormData();
        fd.append('file', blob, 'newFile.jpg');

        request(
            `${process.env.RAZZLE_API}/1/vehicles/${vin}/uploadImage`,
            {
                method: 'POST',
                body: fd,
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
        ).then(response => {
            // success
            setIsSaved(true);
            setIsError(false);
            setIsBusy(false);
            updateVehicleImage(response);
            handleCancel();
            onClose();
        }).catch(error => {
            // error
            setIsSaved(false);
            setIsError(true);
            setIsBusy(false);
        });
    }

    const handleCancel = () => {
        setSrc(null);
        setCroppedImageUrl(null);
        setEditPreviewUrl(null);
        setCrop(defaultCrop);
        setBlob(null);
        setImageRef(null);
        setIsSaved(false);
        setIsError(false);
    }

    const handleRemove = () => {

        request(
            `${process.env.RAZZLE_API}/1/vehicles/${vin}/removeImage`,
            {
                method: 'DELETE',
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }
        ).then(response => {
            // success
            setIsSaved(false);
            setIsError(false);
            setIsBusy(false);
            updateVehicleImage(null);
            onClose();
        }).catch(error => {
            // error
            setIsSaved(false);
            setIsError(true);
            setIsBusy(false);
        });

    }

    return (
        <div className="upload-image-form py-7 px-3 px-small-0">

            <Button buttonType="icon" icon={<IconCancel large />} onClick={onClose} className="close" />

            {(isBusy) && <LoadingOverlay type="overlay" />}

            <div className="row">
                <div className="col">
                    <h2 className="aui-headline-4 mb-3">Add my own photo</h2>
                    {!src && (
                        <p className="">Select a photo of your {modelNameSD}.</p>
                    )}
                    {!src && isSizeError &&
                        (<p className="size-error">Please select an image at least {minWidth}x{minHeight} pixels.</p>)
                    }
                    {src && !croppedImageUrl && (
                        <p className="">Crop your photo.</p>
                    )}
                    {croppedImageUrl && (
                        <p className="">Hit Save when you are happy with your crop.</p>
                    )}
                </div>
            </div>

            {!src && (
                <input type="file" accept="image/*" onChange={onChangeImage} className="aui-button" />
            )}

            {src && (
                <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={onImageLoaded}
                    onComplete={onCropComplete}
                    onChange={onCropChange}
                    minWidth={cropMinWidth}
                    minHeight={cropMinHeight}
                    keepSelection="true"
                />
            )}

            <div className="row">
                <div className="col-12 col-medium-6 p-1">
                    {src && (
                        <Button
                            buttonType="secondary"
                            label="Change photo"
                            onClick={handleCancel}
                            disabled={isBusy}
                        />
                    )}
                </div>
                <div className="col-12 col-medium-6 p-1">
                    {croppedImageUrl && (
                        <div>
                            <Button
                                buttonType="primary"
                                label="Save"
                                onClick={handleUploadImage}
                                disabled={isBusy}
                            />
                            {isSaved &&
                                <span className="aui-color-text-green px-3">Saved</span>
                            }
                            {isError &&
                                <span className="aui-color-text-red px-3">Not saved</span>
                            }
                        </div>
                    )}
                </div>
            </div>

            {!src && hasUserImage && (
                <div className="mt-5">
                    <h2 className="aui-headline-4 mb-3">Remove photo</h2>
                    <p className="">Delete the current photo of your {modelNameSD}.</p>
                    <Button
                        buttonType="secondary"
                        label="Remove photo"
                        onClick={handleRemove}
                        disabled={isBusy}
                    />
                </div>
            )}

        </div>
    );
}

export default UploadImageForm;
