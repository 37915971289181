import React from 'react';
import { connect } from 'react-redux';
import FinanceContractPreview from './components/FinanceContractPreview';
import ContactUsBox from './components/ContactUsBox';
import LoadingOverlay from '../../components/LoadingOverlay';
import { fetchContracts } from '../../redux/Finance';
import HeroSection from '../../components/HeroSection';

const mapStateToProps = state => {
  return {
    contractList: state.finance.contractList,
    isLoadingContractList: state.finance.isLoadingContractList,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchContracts: () => {
      dispatch(fetchContracts());
    },
  };
};

class ContractList extends React.Component {

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchContracts();
  }

  render() {
    const { contractList, isLoadingContractList } = this.props;
    return (
      <div className="page-wrapper">
        <HeroSection
            content={[{ type: "image", url: `${process.env.PUBLIC_PATH}assets/1920x1080_InlineMediaGallery_AQ8_D_181008.jpg`, x: "50%", y: "50%" }]}
            className="mb-2"
            imgWide
        >
            <h1 className="aui-headline-3 headline-margin mb-small-6 pt-small-7 aui-color-text-light">
                <b>All contracts</b>
            </h1>
        </HeroSection>
        <div className="content-wrapper pt-3 mb-7">
          {isLoadingContractList && (
            <LoadingOverlay type="placeholder">
              Loading your contracts
            </LoadingOverlay>
          )}
          {!isLoadingContractList && (
            <div className="row finance__contract-grid">
              {contractList.length > 0 &&
                contractList.map((contract, i) => {
                  return (
                    <div className="col-small-6 col-medium-4" key={i}>
                      <FinanceContractPreview contract={contract} />
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <ContactUsBox />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);
