import React from 'react';

class Maintenance extends React.Component {

    render() {
        return (
            <div className="page-wrapper">
                <div class="content-wrapper py-7">
                    <p className="aui-headline-3 mb-5">Maintenance</p>
                    <p className="mb-3">We are undergoing maintenance until week commencing 30 August. If you wish to get in touch in relation to your customer profile, contact preferences (including unsubscribes) or an Audi Experience booking please:</p>
                    <ul className="mb-5">
                        <li>Email us at <a className="aui-textlink" href="mailto:customerassistance@audi-info.com.au">customerassistance@audi-info.com.au</a></li>
                        <li>Call us on <a className="aui-textlink" href="tel:1300557405">1300 557 405</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Maintenance;
