import React, { useState } from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import { validate, defaultSchema } from '../../../../lib/validation';
import { formatMobile, formatCreditCard, formatCCExpire } from '../../../../lib/textFormatting';
import { request } from '../../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../../lib/gtm';
import Button from '../../../../audi-ui-components/Button';
import Radio from '../../../../audi-ui-components/Radio';
import FormField from '../../../../components/FormField';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import Tooltip from '../../../../components/Tooltip';
import FormSection from './FormSection';
import IconTime from '../../../../audi-ui-components/icons/Time';
import IconPower from '../../../../audi-ui-components/icons/Power';
import IconDollar from '../../../../audi-ui-components/icons/DocumentPricelistDollar';
import IconEdit from '../../../../audi-ui-components/icons/Edit';
import IconSelect from '../../../../audi-ui-components/icons/Select';
import IconCancel from '../../../../audi-ui-components/icons/Cancel';
import IconSystemHelp from '../../../../audi-ui-components/icons/SystemHelp';
import  { FIELD_SELECT, FIELD_RADIOS, OPTS_STATES } from '../../../../constants';

var today = new Date(),
    todayMonth = '' + (today.getMonth() + 1),
    todayDay = '' + today.getDate(),
    todayYear = today.getFullYear();
if (todayMonth.length < 2) { todayMonth = '0' + todayMonth; }
if (todayDay.length < 2) { todayDay = '0' + todayDay; }

const validationSchema = {
    plan: {
        presence: { message: "Please select a plan", allowEmpty: false }
    },
    firstName: defaultSchema.firstName,
    lastName: defaultSchema.lastName,
    addressLine1: {
        presence: { message: "Please enter your address", allowEmpty: false }
    },
    addressCity: {
        presence: { message: "Please enter your address", allowEmpty: false }
    },
    addressPostcode: defaultSchema.postcode,
    addressState: {
        presence: { message: "Please select your state", allowEmpty: false }
    },
    emailAddress: defaultSchema.email,
    mobileNumber: defaultSchema.mobile,
    cardName: defaultSchema.requiredField,
    cardNumber: defaultSchema.requiredField,
    cardExpire: defaultSchema.requiredField,
    cardCVC: defaultSchema.requiredField
};

class PurchaseForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            review: false,
            editPlan: false,
            editVehicle: false,
            editPersonal: false,
            editAddress: false,
            editContact: false
        }
    }

    validateForm = (values) => {
        return validate(values, validationSchema, {format: "firstError", fullMessages: false});
    }

    submitForm = (values, formikBag) => {
        if (this.state.review) {
            const {model} = this.props;
            this.setState({serverError: null, stripeError: null});

            // Stripe token & API submission
            let Stripe = null;
            if (typeof window !== 'undefined' && window.Stripe) {
                Stripe = window.Stripe;
            }
            let ccExp = values.cardExpire.split("/");
            const cardDetails = {
                number: values.cardNumber.replace(/ /g, ''),
                cvc: values.cardCVC,
                exp_month: ccExp[0],
                exp_year: `20${ccExp[1]}`,
            }
            // console.log("[card]", cardDetails);
            Stripe.card.createToken(cardDetails, (status, response) => {

                if (response.error) {
                    // Stripe returned an error
                    this.setState({stripeError: response.error.message, editPayment: true});
                    formikBag.setSubmitting(false);
                    return;
                }

                // no errors from Stripe, submit to backend

                let data = {
                    chargeToken: response.id,
                    planDetails: {
                        planType: this.props.availablePlans[values.plan].code,
                        purchaseDate: [todayYear, todayMonth, todayDay].join('-'),
                        purchasePrice: parseFloat(this.props.availablePlans[values.plan].price.replace(/[$,]+/g,"")),
                        dataSource: "myaudi.com.au"
                    },
                    vehicle: {
                        vin: values.vin,
                        mileage: values.mileage,
                        regoPlate: values.regoPlate
                    },
                    model: model,
                    customer: {
                        title: values.title,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        companyName: values.companyName,
                        companyABN: values.companyABN,
                        mobileNumber: values.mobileNumber,
                        emailAddress: values.emailAddress,
                        addressLine1: values.addressLine1,
                        addressLine2: values.addressLine2,
                        addressCity: values.addressCity,
                        addressPostcode: values.addressPostcode,
                        addressState: values.addressState
                    }
                };

                request(
                    `${process.env.RAZZLE_API}/1/serviceplan/purchase`,
                    {
                        method: 'POST',
                        body: JSON.stringify(data),
                    }
                ).then((res) => {
                    gtmPush("form", "submitSuccess", "buyServicePlan", {planDetails: data.planDetails, vehicle: data.vehicle, customer: data.customer});
                    this.props.onSubmitSuccess(res);
                }).catch((error) => {
                    console.error(error);
                    let errors = false;
                    if (error.body && error.body.modelState) {
                        // console.log("[modelState]", error.body.modelState);
                        errors = {};
                        for (let key in error.body.modelState) {
                            // console.log("[key]", key);
                            let dotIdx = key.lastIndexOf(".");
                            if (dotIdx === -1) {
                                errors[key] = error.body.modelState[key][0];
                            } else {
                                let newKey = key.substr(dotIdx + 1);
                                errors[newKey] = error.body.modelState[key][0];
                            }
                        }
                        // console.log("[errors]", errors);
                        formikBag.setErrors(errors);
                        this.scrollToFirstInvalid();
                    }
                    let msg = "An error has occured";
                    if (error.status === 409) {
                        msg = "Unable to process request, as this plan is already purchased for this vehicle. No payment has been taken."
                    } else if (error.message) {
                        msg = error.message;
                    } else if (error.body && error.body.message) {
                        msg = error.body.message;
                    }
                    this.setState({serverError: msg, review: !errors});
                    formikBag.setSubmitting(false);
                });
            });

        } else {
            this.setState({serverError: null, review: true});
            formikBag.setSubmitting(false);
            document.getElementsByClassName("aui-modal is-active")[0].scrollTop = 0;
            gtmPush("buyServicePlan", "BspReviewYourOrder", "BspChoose");
        }
    }

    scrollToFirstInvalid = () => {
        setTimeout(() => {
            let node = document.querySelector(".is-invalid");
            if (node && node !== null) {
                node.scrollIntoView();
            }
        }, 800);
    }

    render() {
        const {review, editPlan, activePlanModal, serverError} = this.state;
        const {profile, availablePlans} = this.props;
        const planKeys = Object.keys(availablePlans);
        return (
            <>
                {review && <p className="aui-headline-5 mb-7">Review your order</p>}
                <Formik
                    initialValues={{
                        plan: planKeys.length === 1 ? planKeys[0] : "",
                        mileage: this.props.mileage,
                        vin: this.props.vin,
                        regoPlate: this.props.rego,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                        addressLine1: profile.homeAddress,
                        addressCity: profile.homeSuburb,
                        addressPostcode: profile.homePostcode,
                        addressState: profile.homeState,
                        emailAddress: profile.email,
                        mobileNumber: profile.mobile,
                    }}
                    validate={this.validateForm}
                    onSubmit={this.submitForm}
                >
                    {formikBag => (
                        <Form>
                            {formikBag.isSubmitting && <LoadingOverlay />}

                            {(!review || editPlan || !formikBag.values.plan) && <section className={classnames("form-section mb-7 service-plan-radios", {"review": review, "editing": review && editPlan})}>
                                <p className="aui-headline-5 mb-3"><b>Choose an available plan:</b></p>
                                <div className="row mx-n4">
                                    {planKeys.map(p => {
                                        let plan = availablePlans[p];
                                        let t = null;
                                        if (plan.code === "FDSPTOPUP2")  {
                                            t = <Tooltip trigger={<sup><IconSystemHelp small /></sup>} tip="Top up your 3 Year Service Plan with the 4th and 5th services." />
                                        }
                                        return (<div className="col-12 col-small-6 px-4" key={plan.code}>
                                            <Radio name={plan.name}
                                                label={<>
                                                    <span className="aui-headline-4"><b>{plan.name}{t}</b><a className="aui-textlink" onClick={() => { this.setState({activePlanModal: `disc${p}`}); gtmPush("buyServicePlan", "BspPlanFootnote", "BspChoose"); }}>{plan.disclaimerSymbol}</a></span>
                                                    <br /><IconTime small /> Duration: {plan.duration}
                                                    <br /><IconPower small /> Maximum KM's: {plan.maxMileage}
                                                    <br /><IconDollar small /> Total cost: <b>{plan.price} AUD</b>
                                                    <br /><Button label="View all inclusions" buttonType="secondary" isStretched onClick={() => { this.setState({activePlanModal: `inc${p}`}); gtmPush("buyServicePlan", "BspFullPlanInclusions", "BspChoose"); }} />
                                                    {plan.termsLink && <><br /><a href={plan.termsLink} target="_blank" className="aui-textlink" onClick={() => { gtmPush("buyServicePlan", "BspPlanTnC", "BspChoose"); }}>Plan Terms &amp; Conditions</a></>}
                                                </>}
                                                value={p}
                                                checked={formikBag.values.plan === p}
                                                onChange={() => { formikBag.setFieldValue("plan", p); gtmPush("buyServicePlan", "BspPlanSelected", "BspChoose"); }}
                                            />
                                        </div>);
                                    })}
                                </div>
                                <div className={classnames("plan-info aui-color-gray95", {"px-5 pb-5 active": activePlanModal})}>
                                    <Button buttonType="icon" icon={<IconCancel large />} onClick={() => { this.setState({activePlanModal: null}); }} className="plan-info__close" />
                                    {planKeys.map(p => {
                                        let plan = availablePlans[p];
                                        return (<>
                                            <div className={activePlanModal === `inc${p}` ? "" : "d-none"} key={plan.code}>
                                                <p className="aui-headline-4 mb-3">{plan.name} Inclusions</p>
                                                <table className="plan-inclusions mb-5">
                                                    <thead>
                                                        <tr><th>Service Interval^</th><th>Service Plan Inclusions</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        {plan.serviceDetails.map((row, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>{row.interval}</td>
                                                                    <td dangerouslySetInnerHTML={{__html: row.inclusions}} />
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                                <p><small># The replacement of the listed inclusions is replaced on the specified time or km service</small></p>
                                                <p><small>^ Audi Service Interval is every 15,000km/12 Months, whichever occurs first</small></p>
                                            </div>
                                            <div className={activePlanModal === `disc${p}` ? "" : "d-none"} dangerouslySetInnerHTML={{__html: plan.disclaimer}} />
                                        </>);
                                    })}
                                </div>
                                {formikBag.errors.plan && <p className="aui-color-text-red my-2">{formikBag.errors.plan}</p>}
                                {review &&
                                    <Button
                                        label="Done"
                                        icon={<IconSelect small />}
                                        onClick={() => { this.setState({editPlan: false}); }}
                                        className="review-edit-btn"
                                    />
                                }
                            </section>}

                            {(review && !editPlan && formikBag.values.plan) && <section className="form-section mb-7 service-plan-radios review">
                                <p className="aui-headline-5">Service plan</p>
                                <div className="mt-3">
                                    <p>{availablePlans[formikBag.values.plan].name}</p>
                                    <p>Duration: {availablePlans[formikBag.values.plan].duration}</p>
                                </div>
                                <Button
                                    label="Edit"
                                    icon={<IconEdit small />}
                                    onClick={() => { this.setState({editPlan: true}); gtmPush("buyServicePlan", "BspEditDetails", "BspReview"); }}
                                    className="review-edit-btn"
                                />
                            </section>}

                            <FormSection title="Vehicle details"
                                review
                                fields={[{name: "mileage", suffix: "KMs"}, {name: "vin", label: "VIN"}, {name: "regoPlate", label: "Registration"}]}
                                values={formikBag.values}
                            >
                                <div className="row mx-n4">
                                    <FormField name="mileage" label="KM's" formikBag={formikBag} disabled wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="vin" label="VIN" formikBag={formikBag} disabled wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="regoPlate" label="Registration" formikBag={formikBag} disabled wrapperClassName="col-12 col-small-6 px-4" />
                                </div>
                            </FormSection>

                            <FormSection title="Personal details"
                                review={review}
                                edit={this.state.editPersonal}
                                toggleEdit={() => { this.setState({editPersonal: !this.state.editPersonal}); }}
                                fields={[{names: ["title", "firstName", "lastName"], label: "Name"}, {name: "companyName", label: "Company"}, {name: "companyABN", label: "Company ABN"}]}
                                values={formikBag.values}
                                errors={formikBag.errors}
                            >
                                <div className="row mx-n4">
                                    <FormField name="title" label="Title" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                </div>
                                <div className="row mx-n4">
                                    <FormField name="firstName" label="First name*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="lastName" label="Last name*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="companyName" label="Company" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="companyABN" label="Company ABN" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                </div>
                            </FormSection>

                            <FormSection title="Mailing address"
                                review={review}
                                edit={this.state.editAddress}
                                toggleEdit={() => { this.setState({editAddress: !this.state.editAddress}); }}
                                fields={[{name: "addressLine1"}, {name: "addressLine2"}, {names: ["addressCity", "addressState", "addressPostcode"]}]}
                                values={formikBag.values}
                                errors={formikBag.errors}
                            >
                                <div className="row mx-n4">
                                    <FormField name="addressLine1" label="Address line 1*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="addressLine2" label="Address line 2" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="addressCity" label="City/Suburb*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="addressPostcode" label="Postcode*" formikBag={formikBag} wrapperClassName="col-12 col-small-3 px-4" />
                                    <FormField name="addressState" label="State*" formikBag={formikBag} fieldType={FIELD_SELECT} options={OPTS_STATES} wrapperClassName="col-12 col-small-3 px-4" />
                                </div>
                            </FormSection>

                            <FormSection title="Contact details"
                                review={review}
                                edit={this.state.editContact}
                                toggleEdit={() => { this.setState({editContact: !this.state.editContact}); }}
                                fields={[{name: "emailAddress", label: "Email address"}, {name: "mobileNumber", label: "Mobile"}]}
                                values={formikBag.values}
                                errors={formikBag.errors}
                            >
                                <div className="row mx-n4">
                                    <FormField name="mobileNumber" label="Mobile*" formikBag={formikBag} formatValue={formatMobile} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="emailAddress" label="Email address*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                </div>
                            </FormSection>

                            <FormSection title="Payment details"
                                review={review}
                                edit={this.state.editPayment}
                                toggleEdit={() => { this.setState({editPayment: !this.state.editPayment}); }}
                                fields={[{name: "cardNumber"}, {name: "cardExpire", label: "Expiry"}, {name: "cardCVC", label: "CVC"}]}
                                values={formikBag.values}
                                errors={formikBag.errors}
                            >
                                <div className="row mx-n4">
                                    <FormField name="cardName" label="Full name*" formikBag={formikBag} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="cardNumber" label="Credit card number*" formikBag={formikBag} formatValue={formatCreditCard} wrapperClassName="col-12 col-small-6 px-4" />
                                    <FormField name="cardExpire" label="Expiry* (MM/YY)" formatValue={formatCCExpire} formikBag={formikBag} wrapperClassName="col-12 col-small-3 px-4" />
                                    <FormField name="cardCVC" label="CVC*" formikBag={formikBag} wrapperClassName="col-12 col-small-3 px-4" />
                                </div>
                                {this.state.stripeError && <p className="aui-color-text-red my-2">{this.state.stripeError}</p>}
                            </FormSection>

                            {review && <>
                                <hr className="my-7" />
                                <div className="row mb-7">
                                    <div className="col"><span className="aui-headline-5">Total cost:</span></div>
                                    <div className="col-auto"><span className="aui-headline-4">{availablePlans[formikBag.values.plan].price}</span></div>
                                </div>
                                <p>By clicking 'Place your order' you agree to the Terms &amp; Conditions</p>
                            </>}

                            <br />
                            {serverError && <p className="mb-5 aui-color-text-red">{serverError}</p>}
                            <p className="mt-4">
                                <Button
                                    buttonType="primary"
                                    label={review ? "Place your order" : "Review your order"}
                                    type="submit"
                                    onClick={this.scrollToFirstInvalid}
                                    disabled={formikBag.isSubmitting}
                                />
                                {` `}
                                {review && <Button label="Back" buttonType="secondary" onClick={() => { this.setState({review: false}); window.scroll(0,0); }} />}
                            </p>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

export default PurchaseForm;
