import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { loadEvent, unloadEvent, removeWaitlist } from '../../../redux/Experience';
import Modal from '../../../audi-ui-components/Modal';
import Button from '../../../audi-ui-components/Button';
import IconArrowLeft from '../../../audi-ui-components/icons/ArrowLeftNormal';
import { Link } from 'react-router-dom';
import LoadingOverlay from '../../../components/LoadingOverlay';
import HeroSection from '../../../components/HeroSection';
import Crumb from '../../../components/Crumb';
import EventDetailAside from './EventDetailAside';
import EventRegistration from '../EventRegistration';
import ResizeObserver from 'resize-observer-polyfill';
import { getEventPeriodString } from '../helpers';
import { gtmPush } from '../../../lib/gtm';
import { PATH_EXPERIENCE, PATH_LOGIN, BREAKPOINTS } from '../../../constants';

const mapStateToProps = state => {
    return {
        event: state.experience.currentEvent,
        isLoading: state.experience.isLoading,
        profile: state.profile,
        isAuthed: state.profile.isAuthed,
        type: state.profile.type
    };
};
const mapDispatchToProps = dispatch => {
    return {
        loadEvent: (id, cb) => dispatch(loadEvent(id, cb)),
        unloadEvent: () => dispatch(unloadEvent()),
        removeWaitlist: (id, successCb, errCb) => dispatch(removeWaitlist(id, successCb, errCb))
    };
};

class EventDetail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedSessionIdx: props.location?.state?.selectedSessionIdx || 0,
            waitlistSessionIdx: null,
            showWaitlistModal: false,
            isWaitlistBooking: false,
            isDecliningWaitlist: false,
            hideCancel: false,
            sidebarDown: false,
            scrollBottom: false,
            sidebarHeight: 0,
            regoHeightDiff: 0
        }
        this.refContent = React.createRef();
        this.refSidebar = React.createRef();
        this.refSidebarBottom = React.createRef();
        this.refForm = React.createRef();
        this.refPageBottom = React.createRef();
    }

    componentDidMount() {
        const {match, location, isAuthed, event} = this.props;
        if (!event || event.id !== match.params.id) {
            this.props.loadEvent(match.params.id, this.initEvent);
        } else {
            this.initEvent(event);
        }
        this.loadStripe();
        // check if the bottom of the page is visible
        this.bottomObserver = new IntersectionObserver((entries) => {
            this.setState({ scrollBottom: entries[0]['isIntersecting'] });
        }, { threshold: [0, 1] });
        this.bottomObserver.observe(this.refPageBottom.current);
        // check if the bottom of the sidebar has moved outside the content section
        this.sideObserver = new IntersectionObserver((entries) => {
            this.setState({ sidebarDown: !entries[0]['isIntersecting'] });
        }, { threshold: [0, 0.5, 1], root: this.refContent.current });
        this.sideObserver.observe(this.refSidebarBottom.current);
        // check for resize
        this.resizeObserver = new ResizeObserver(this.layoutUpdate);
        this.resizeObserver.observe(this.refSidebar.current);
        this.resizeObserver.observe(this.refForm.current);
    }

    componentWillUnmount() {
        this.props.unloadEvent();
        this.bottomObserver.unobserve(this.refPageBottom?.current);
        this.sideObserver.unobserve(this.refSidebarBottom?.current);
        this.resizeObserver.unobserve(this.refSidebar?.current);
        this.resizeObserver.unobserve(this.refForm?.current);
    }

    initEvent = (eventData) => {
        if (eventData.isOnWaitlist) {
            // validate waitlist
            let sIdx = 0;
            if (eventData.sessions && eventData.sessions.length > 1) {
                // find waitlisted session
                for (let i=1; i<eventData.sessions.length; i++) {
                    if (eventData.sessions[i].id === eventData.waitlist.session.id) {
                        sIdx = i;
                        break;
                    }
                }
            }
            this.setState({showWaitlistModal: eventData.sessions[sIdx].canBook, selectedSessionIdx: sIdx, waitlistSessionIdx: sIdx});
        } else {
            // if there is more than 1 session...
            if (eventData.sessions && eventData.sessions.length > 1 && eventData.sessions[0].spotsAvailable === 0) {
                // find first bookable session
                for (let i=1; i<eventData.sessions.length; i++) {
                    if (eventData.sessions[i].spotsAvailable > 0) {
                        this.setState({selectedSessionIdx: i});
                        break;
                    }
                }
            }
        }
    }

    layoutUpdate = () => {
        let ww = window.innerWidth;
        if (ww >= BREAKPOINTS.medium) {
            let wh = window.innerHeight;
            let sh = this.refSidebar && this.refSidebar.current ? this.refSidebar.current.offsetHeight : 1;
            let rh = this.refForm && this.refForm.current ? this.refForm.current.offsetHeight : 0;
            this.setState({ sidebarHeight: sh, regoHeightDiff: (rh < wh && rh > 0) ? wh - rh - 110 : 0 });
        } else {
            this.setState({ sidebarHeight: 0, regoHeightDiff: 0 });
        }
    }

    loadStripe = () => {
        if (typeof window !== 'undefined') {
            if (window.Stripe) {
                window.Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
            } else {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://js.stripe.com/v2/";
                script.onload = function () {
                    window.Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_PUBLIC_KEY);
                };
                document.getElementsByTagName("head")[0].appendChild(script);
            }
        }
    }

    handleButtonClick = () => {
        document.querySelector('.event-registration').scrollIntoView({
            behavior: 'smooth'
        });
        this.setState({ hideCancel: true });
    }

    acceptWaitlist = () => {
        this.setState({showWaitlistModal: false, isWaitlistBooking: true});
        gtmPush("AudiExperience", "eventWaitlistAccept", this.props.event.name);
    }

    declineWaitlist = () => {
        gtmPush("AudiExperience", "eventWaitlistDecline", this.props.event.name);
        this.setState({isDecliningWaitlist: true});
        this.props.removeWaitlist(this.props.event.waitlist.id, () => {
            this.props.history.push(PATH_EXPERIENCE);
        });
    }

    render() {
        const { event, profile, isLoading, isAuthed } = this.props;
        const { selectedSessionIdx, hideCancel } = this.state;
        const dateString = event && getEventPeriodString(event.eventStartDate, event.eventEndDate);
        const isLoyaltyEligible = isAuthed && profile && profile.data && profile.data.isLoyaltyEligible;
        var heroContent = [];
        if (event) {
            if (event.heroImages && event.heroImages.length) {
                heroContent = event.heroImages;
            } else if (event.heroImage) {
                heroContent = [{
                    id: 'hero',
                    url: event.heroImage.url,
                    type: 'image',
                    x: event.heroImage.x,
                    y: event.heroImage.y,
                    textColor: event.heroImage.textColor
                }];
            }
        }

        let name;
        let title;
        if (event) {
            if (event.type && event.type.toLowerCase() === 'virtual') {
                title = <>Virtual Event:<br />{event.name}</>;
            } else {
                title = <>{event.name}</>;
            }
        }
        
        if (!isLoading && event?.error) {
          let msg = "Sorry, there was an error fetching the event data.";
          if (event.error.status === 404) { msg = "Sorry, we couldn't find that event."; }
          return (
            <div className="page-wrapper">
              <div className="content-wrapper">
                <div className="event-content error">
                  <p className="text-center">{msg}
                  <br /><Link to={PATH_EXPERIENCE} className="aui-textlink">Return to events list</Link></p>
                </div>
                <div ref={this.refSidebar} />
                <div ref={this.refSidebarBottom} />
                <div ref={this.refForm} />
                <div ref={this.refPageBottom} />
              </div>
            </div>
          );
        }

        return (
            <div className="page-wrapper">

              {isLoading && <LoadingOverlay type="placeholder" />}

              {!isLoading && <HeroSection className="mb-2" content={heroContent} defaultType="image" gradient imgWide>
                  <div className="pt-small-7 row">
                      <div className="col-small-auto">
                          <Link to={PATH_EXPERIENCE}><IconArrowLeft large /></Link>
                      </div>
                      <div className="col">
                          <h2 className="aui-headline-6 mb-3">
                              <small>{dateString}</small>
                          </h2>
                          <h1 className="aui-headline-3 headline-margin mb-small-6 ">
                              <b>{title}</b>
                          </h1>
                      </div>
                  </div>
              </HeroSection>}
              
              <div className="content-wrapper">
                {!isLoading && event && <Crumb className="mb-4" />}

                <section ref={this.refContent} className="event-content">
                    <div className="row d-flex flex-column-reverse flex-medium-row">

                        <div className="col-12 col-medium-5" style={{ minHeight: `${this.state.sidebarHeight + 40}px` }}>
                            <div className={classnames("event-sidebar-wrapper outdent-below-medium", { "has-sticky": isLoyaltyEligible })}>
                                <div ref={this.refSidebar}
                                    className={classnames("event-sidebar pe-medium-7 pb-medium-7", { "down": this.state.sidebarDown })}
                                    style={{ marginTop: (this.state.scrollBottom && !isLoading) ? `${this.state.regoHeightDiff}px` : '0px' }}
                                >
                                    {event && event.name && <EventDetailAside
                                        event={event || {}}
                                        location={this.props.location}
                                        scrollTo={this.handleButtonClick}
                                        isLoyaltyEligible={isLoyaltyEligible}
                                        selectedSessionIdx={selectedSessionIdx}
                                        onSessionSelect={(s) => { this.setState({ selectedSessionIdx: s }) }} />}
                                    <div ref={this.refSidebarBottom} />
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-medium-7 col-large-6 pb-7">
                            {event && event.eventDetails && <div className="cms-content mb-7" dangerouslySetInnerHTML={{ __html: event.eventDetails }} />}
                            {event && event.termsAndConditionDetails && <div className="cms-content aui-small" dangerouslySetInnerHTML={{ __html: event.termsAndConditionDetails }} />}
                        </div>

                    </div>
                </section>

                <section ref={this.refForm} className="event-registration aui-color-gray90 outdent pb-7" style={{ minHeight: isLoyaltyEligible ? `${this.state.sidebarHeight + 120}px` : '0px' }}>
                  <div className="content-wrapper">
                    <div className="row">
                        <div className="col-medium-5"></div>
                        <div className="col-medium-7 col-large-6">
                            {isLoyaltyEligible && event && !event.error &&
                                <div className="pt-7">
                                    <EventRegistration
                                        selectedSessionIdx={selectedSessionIdx}
                                        waitlistSessionIdx={this.state.waitlistSessionIdx}
                                        isWaitlistBooking={this.state.isWaitlistBooking}
                                        hideCancel={hideCancel} />
                                </div>
                            }
                        </div>
                    </div>
                  </div>
                </section>

                <div ref={this.refPageBottom} />
              </div>

              <Modal isActive={Boolean(this.state.showWaitlistModal && this.props.event)} modalStyle="layer">
                  <div className="modal-form py-7">
                      {this.state.isDecliningWaitlist && <LoadingOverlay />}
                      <p className="mb-3">
                          <Button label="Accept" buttonType="primary" onClick={this.acceptWaitlist} />
                          {` `} Book your tickets
                      </p>
                      <p className="mb-3">
                          <Button label="Decline" buttonType="secondary" onClick={this.declineWaitlist} />
                          {` `} This will remove you from the waitlist
                      </p>
                  </div>
              </Modal>

            </div>
        );
    }
}

EventDetail.propTypes = {
    isLoading: PropTypes.bool,
    match: PropTypes.object,
    loadEvent: PropTypes.func,
    unloadEvent: PropTypes.func,
    event: PropTypes.object,
    profile: PropTypes.object,
    isAuthed: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetail);
