import React from 'react';

const ContactUsBox = () => {
  return (
    <div className="finance__contact-box aui-color-gray85 aui-color-text-black content-wrapper py-7">
      <p className="aui-headline-4 mb-3">Contact Us</p>
      {/*<p className="mb-5">
        <b>Require assistance?</b> If you require assistance with using the Finance tab within the portal please <a href="https://www.vwfs.com.au/Media/VWFSCorp/Media/myAudi-Online-Portal-User-Guide.pdf" target="_blank" rel="noopener noreferrer" className="aui-textlink">click here</a>.
      </p>*/}
      <p className="mb-5">
        If you need to get in contact with us, there are a number of ways we can help.
      </p>
      <p className="mb-5">
        <b>Monday - Friday, 9am - 5pm</b>
        <br />
        General Enquiries: <b>1300 734 567</b>
        <br />
        Portal Phone Support: <b>1300 017 163</b>
        <br />
        Calling from overseas <b>(+61 2) 9695 6311</b>
      </p>
      <p className="mb-5">
        <b>You can email us:</b>
        <br />
        If your enquiry is general in nature: <a href="mailto:customer_service@vwfs.com.au" className="aui-textlink">customer_service@vwfs.com.au</a>
        <br />
        If your enquiry is in relation to an overdue account: <a href="mailto:collections@vwfs.com.au" className="aui-textlink">collections@vwfs.com.au</a>
      </p>
      <p>
        <b>You can write to us:</b>
        <br />
        Audi Financial Services Australia
        <br />
        Locked Bag 4002
        <br />
        Chullora NSW 2190
      </p>
    </div>
  );
};

export default ContactUsBox;
