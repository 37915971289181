import validate from 'validate.js';
import { parsePhoneNumber } from 'libphonenumber-js';

var Stripe = null;
if (typeof window !== 'undefined' && window.Stripe) {
  Stripe = window.Stripe;
}

// return only the first error message
validate.formatters.firstError = function(errors) {
  var errObj = {};
  if (errors.length > 0) {
    for (let i=0; i<errors.length; i++) {
      let field = errors[i].attribute;
      let msg = errors[i].options.message ? errors[i].options.message : errors[i].error;
      errObj[field] = msg;
    }
  }
  return errObj;
};

validate.validators.truthy = function(value, options, key, attributes) {
  if (!value) {
    return options.message || "This field is required";
  }
  return;
}

validate.validators.isVin = function(value, options, key, attributes) {
  var vin = value.toUpperCase();
  if(vin.length !== 17) {
    return options.message || "Please enter a valid VIN";
  }
  // begin WAU, WUA, TRU,
  if(! /^(WAU|WUA|TRU)/.test(vin) ) {
    return options.message || "Please enter a valid VIN";
  }
  // followed by ZZZ
  if(! /^...(ZZZ)/.test(vin) ) {
    return options.message || "Please enter a valid VIN";
  }
  return;
};

validate.validators.isPhoneNumber = function(value, options, key, attributes) {
  if(!value || value.length < 2) {
    return options.message || "Please enter a valid phone number";
  }
  let p = parsePhoneNumber(value, 'AU');
  if (!p.isPossible()) {
    return options.message || "Please enter a valid phone number";
  }
  return;
};

validate.validators.isMobileNumber = function(value, options, key, attributes) {
  if(!value || value.length < 10) {
    return options.message || "Please enter a mobile number";
  }
  if(! /^04.*$/.test(value) ) {
    return options.message || "Please enter a valid mobile number";
  }
  let p = parsePhoneNumber(value, 'AU');
  if (!p.isValid()) {
    return options.message || "Please enter a valid mobile number";
  }
  return;
}

validate.validators.isPassword = function(value, options, key, attributes) {
  // used for new passwords
  if(!value || value.length < 10) {
    return options.message || "Please enter a password with at least 10 characters";
  }
  if (!/[0-9]/.test(value)) {
    return options.message || "Your password must contain at least 1 number";
  }
  return;
};

validate.validators.isPastDate = function(value, options, key, attributes) {
  if (value) {
    if (value instanceof Date) {
      let today = new Date();
      if (value.getTime() >= today.getTime()) {
        return options.message || "Please enter a valid date";
      }
    } else {
      return options.message || "Please enter a valid date";
    }
  }
  return;
}

validate.validators.stripeCardNumber = function (value, options) {
  let isValid = !!Stripe ? Stripe.card.validateCardNumber(value.replace(/ /g, '')) : true;
  if (!isValid) {
    return options.message || "Please enter a valid card number";
  }
  return;
}

validate.validators.stripeCVC = function (value, options) {
  let isValid = !!Stripe ? Stripe.card.validateCVC(value.replace(/ /g, '')) : true;
  if (!isValid) {
    return options.message || "Please enter a valid CVC";
  }
  return;
}


const defaultSchema = {
  requiredField: {
    presence: {
      message: "This field is required",
      allowEmpty: false
    }
  },
  firstName: {
    presence: { message: "Please enter your first name", allowEmpty: false }
  },
  lastName: {
    presence: { message: "Please enter your last name", allowEmpty: false }
  },
  email: {
    presence: { message: "Please enter your email address", allowEmpty: false },
    email: {message: "Please enter a valid email address"}
  },
  mobile: {
    presence: { message: "Please enter your mobile number", allowEmpty: false },
    format: {
      pattern: /^04.*$/,
      message: "Please enter a valid mobile number"
    }
  },
  phone: {
    presence: { message: "Please enter your phone number", allowEmpty: false },
    isPhoneNumber: { message: "Please enter a valid phone number" }
  },
  postcode: {
    presence: { message: "Please enter your postcode", allowEmpty: false },
    format: {
      pattern: /^\d{4}$/,
      message: "Please enter a valid postcode"
    }
  },
  vin: {
    presence: { message: "Please enter your VIN", allowEmpty: false },
    isVin: { message: "Please enter a valid VIN" }
    },
  km: {
    presence: { message: "Please enter your Kilometres", allowEmpty: false },
    format: {
      pattern: /^[0-9]+$/,
      message: "Please enter a valid number of Kilometres"
    }
  }
}

// not technically validation, but it's related so it lives here for now
function formatMobile(value) {
  let _v = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
  let zip = _v.substring(0,4);
  let middle = _v.substring(4,7);
  let last = _v.substring(7,10);
  if(_v.length > 7) {
    return `${zip} ${middle} ${last}`;
  }
  else if(_v.length > 4) {
    return `${zip} ${middle}`;
  }
  else if(_v.length > 0) {
    return `${zip}`;
  }
  else {
    return '';
  }
}

function formatNumber(value) {
    return value.replace(/\D/g, '');
}

export {
  validate,
  defaultSchema,
  formatMobile,
  formatNumber
};
