import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M20.5,21.5 L20.5,14.5 L15.5,14.5 L15.5,21.5 C15.5,22.6 16.4,23.5 17.5,23.5 L18.5,23.5 C19.6,23.5 20.5,22.6 20.5,21.5 Z"/>
          <path d="M19.5 14.5L19.5 7.5 20.5 6.5 20.5 3.5 19.5 0.5 16.5 0.5 15.5 3.5 15.5 6.5 16.5 7.5 16.5 14.5"/>
          <path d="M4.5,21 L4.5,9.5 L1.5,6.5 L1.5,2.5 L3.5,0.5 L4.5,0.5 L4.5,3.5 L5.5,4.5 L8.5,4.5 L9.5,3.5 L9.5,0.5 L10.5,0.5 L12.5,2.5 L12.5,6.5 L9.5,9.5 L9.5,21 C9.5,22.1 8.5,23.5 7.3,23.5 L6.7,23.5 C5.5,23.5 4.5,22.2 4.5,21 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M39.5,29 L39.5,26.5 L30.5,26.5 L30.5,29 L31.5,31 L31.5,36.5 L30.5,38.5 L30.5,43.5 C30.5,44.6 31.4,45.5 32.5,45.5 L37.5,45.5 C38.6,45.5 39.5,44.6 39.5,43.5 L39.5,38.5 L38.5,36.5 L38.5,31 L39.5,29 Z"/>
        <path d="M36.4 26.4L36.5 14.5 39.5 11.5 39.5 7 37 2.5 33 2.5 30.5 7 30.5 11.5 33.5 14.5 33.5 26.4"/>
        <path d="M12.5,42.5 L12.5,17.5 L7.5,12.5 L7.5,6.5 L11.5,2.5 L12.5,3.5 L12.5,7.5 L13.5,8.5 L18.5,8.5 L19.5,7.5 L19.5,3.5 L20.5,2.5 L24.5,6.5 L24.5,12.5 L19.5,17.5 L19.5,42.5 C19.5,44.2 18.2,45.5 16.5,45.5 L15.5,45.5 C13.8,45.5 12.5,44.2 12.5,42.5 Z"/>
      </g>
    </svg>
  );
};
