import React, { createRef, useState } from 'react';
import { GOOGLE_API_KEY } from '../../../../constants';
import Button from '../../../../audi-ui-components/Button';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const WrappedAddressDistance = ({ eventLocationRadius, eventLocationLat, eventLocationLng, addressCallback, parentValidated, profileAddress, allowWatchOnly }) => {
    const countryFilter = ['au'];
    const typesFilter = ['address'];
    const locCbd = { lat: eventLocationLat, lng: eventLocationLng };
    const minDistance = (eventLocationRadius > 0) ? eventLocationRadius : 25;

    const [distance, setDistance] = useState();
    const [locAddress, setLocAddress] = useState();
    const [addressType, setAddressType] = useState();
    const [addressValid, setAddressValid] = useState(false);
    const [addressIsPoBox, setAddressIsPoBox] = useState(false);
    const [formattedAddress, setFormattedAddress] = useState();
    const [showSelectMessage, setShowSelectMessage] = useState(false);

    var autoAddress;
    var autocompleteInput = createRef();

    //triggered by callback on google api include
    const initMap = () => {
        autoAddress = new google.maps.places.Autocomplete(autocompleteInput.current);
        autoAddress.setComponentRestrictions({ country: countryFilter });
        autoAddress.setTypes(typesFilter)
        new google.maps.event.addListener(autoAddress, 'place_changed', updateAddress);
    }

    //triggered by update event in autoaddress
    const updateAddress = () => {

        //get new location
        var place = autoAddress.getPlace();
        if (place) {
            setFormattedAddress(place.formatted_address);

            var newLoc;
            if (place.geometry) {
                newLoc = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng()
                };
                setLocAddress(newLoc);
            } else {
                setLocAddress(null);
            }

            let checkAddressValid = (place.types !== undefined);

            //result type
            if (checkAddressValid) {
                var types = place.types.join();
                setAddressType(types);
                checkAddressValid = !place.types.includes('route');
                setAddressValid(checkAddressValid);
            } else {
                setAddressType(null);
                setAddressValid(false);
            }

            //get distance
            let checkDistance = 0;
            if (newLoc !== undefined) {
                checkDistance = haversineDistance(locCbd, newLoc)
                setDistance(checkDistance);
            }

            addressCallback(
                checkAddressValid,
                place.formatted_address,
                checkDistance > minDistance
            );
        }
    }

    const haversineDistance = (loc1, loc2) => { //I give accurate results for distances < 1000km
        var R = 6371; // Radius of the Earth in km
        var rlat1 = loc1.lat * (Math.PI / 180); // Convert degrees to radians
        var rlat2 = loc2.lat * (Math.PI / 180); // Convert degrees to radians
        var difflat = rlat2 - rlat1; // Radian difference (latitudes)
        var difflon = (loc2.lng - loc1.lng) * (Math.PI / 180); // Radian difference (longitudes)

        var d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat / 2) * Math.sin(difflat / 2) + Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)));
        return d;
    }

    const onAddressChange = (e) => {
        checkPoBox(e.target.value);

        setShowSelectMessage(true);
    }

    const checkPoBox = (address) => {
        var toCheck = address.toUpperCase().replace(/\./g, '').replace(/ /g, '');
        setAddressIsPoBox(
            toCheck.substr(0, 5) == 'POBOX'
        );
    }

    const clearAddress = (e) => {
        setFormattedAddress(null);
        setAddressValid(false);
        setAddressIsPoBox(false);
        setShowSelectMessage(false);
        autocompleteInput.current.value = '';

        addressCallback(
            false,
            '',
            false
        );
    }

    const useMyAddress = (e) => {
        setFormattedAddress(null);
        setAddressValid(false);
        checkPoBox(profileAddress);
        setShowSelectMessage(true);
        addressCallback(
            false,
            '',
            false
        );

        autocompleteInput.current.value = profileAddress;
        setTimeout(func, 200);
        function func() {
            document.getElementById("address").focus();
        }

    }

    //onload
    React.useEffect(() => {
        initMap();
    }, []);

    return (
        <>
            <div>Enter the delivery address: </div>

            <input ref={autocompleteInput} name="address" id="address" type="text" size="50" onChange={onAddressChange} disabled={addressValid} />

            {addressValid &&
                <Button
                    buttonType="text"
                    label="change"
                    onClick={clearAddress}
                    className="ml-1 py-0"
                />
            }

            {!addressValid &&
                <Button
                    buttonType="text"
                    label="use my address"
                    onClick={useMyAddress}
                    className="ml-1 py-0"
                />
            }

            {((showSelectMessage || parentValidated) && !addressValid && !addressIsPoBox) &&
                <div className="aui-color-text-red">Please select a full mailing address from the list.</div>
            }

            {((showSelectMessage || parentValidated) && !addressValid && addressIsPoBox) &&
                <div className="aui-color-text-red">Please enter an address that is not a PO Box.</div>
            }

            {(distance > minDistance && addressValid && allowWatchOnly) &&
                <div className="aui-color-text-red">Unfortunately you are outside our delivery zone. You may still register to watch the Virtual Event, and you wont be deducted any Audi Experience credits to watch.</div>
            }
            {(distance > minDistance && addressValid && !allowWatchOnly) &&
                <div className="aui-color-text-red">Unfortunately your address is outside of the delivery zone and you are unable to book this event.</div>
            }

            {(distance <= minDistance && addressValid) &&
                <div className="aui-color-text-green">You are inside the delivery zone.</div>
            }

        </>
    );
}

const AddressDistance = (props) => {
  return (
    <Wrapper apiKey={GOOGLE_API_KEY} libraries={["places"]} render={(status) => {
      if (status === Status.FAILURE) { return (<div>Maps API failed to load</div>); }
      return null;
    }}>
      <WrappedAddressDistance {...props} />
    </Wrapper>
  )
}

export default AddressDistance;
