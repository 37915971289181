import React, { useState } from 'react';
import { connect } from 'react-redux';
import ProgressIndicator from './ProgressIndicator';

import FormGuest from '../forms/FormGuest';
import FormGuestPayment from '../forms/FormGuestPayment';


/** Handles adding a Guest
 *
 * swagger: POST /1/experience/bookings/{id}/attendee
 *
 * postData {
 *  "firstName": "string",
 *  "lastName": "string",
 *  "email": "string",
 *  "chargeToken": "string"
 * }
 *
*/
const AddGuest = ({ event, goToSummary, setRemoveGuestSuccess }) => {

  const [step, setStep] = useState(1);
  const [addGuestValues, setAddGuestValues] = useState({
    firstName: null,
    lastName: null,
    email: null,
    understand: false,
    norefund: false
  });

  const addGuestSubmitHandler = (values, formikBag) => {
    setAddGuestValues({...values});
  }

  // Back to showing the Summary.
  const onConfirmClick = () => {
    setRemoveGuestSuccess(false);
    goToSummary();
  }

  // Cancel out of adding a guest
  const onBackToSummaryClick = () => {
    setRemoveGuestSuccess(false);
    goToSummary();
  }

  // Return to a step via the step number buttons
  const stepClickHandler = (step) => { setStep(+step); }

  return (
    event &&
    <div className="position-relative">
      {+step < 3 && <ProgressIndicator
        totalSteps={3}
        currentStep={step}
        onStepClick={stepClickHandler} />}
      {+step === 1 &&
        <FormGuest
          addGuestValues={addGuestValues}
          onSubmit={addGuestSubmitHandler}
          onBackToSummaryClick={onBackToSummaryClick}
          setStep={setStep} />
      }
      {+step === 2 &&
        <FormGuestPayment
          onConfirmClick={onConfirmClick}
          addGuestValues={addGuestValues}
          onBackToSummaryClick={onBackToSummaryClick}
          setStep={setStep} />
      }
    </div>

  );
};

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    profile: state.profile,
  };
};

export default connect(mapStateToProps)(AddGuest);
