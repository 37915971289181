import React, { useState } from 'react';
import { connect } from 'react-redux';

import ProgressIndicator from '../components/ProgressIndicator';
import FormRegister from './FormRegister';
import FormDonate from './FormDonate';
import FormPayment from './FormPayment';
import FormConfirmation from './FormConfirmation';

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    profile: state.profile,
  };
};

const FormRegistration = ({
  event,
  profile,
  onModifyGuestClick,
  selectedSession,
  isWaitlistBooking,
  waitlistSessionIdx,
  editBookingSteps,
  setEditBookingStep,
  setDonation,
  setDonateError
}) => {

  const [step, setStep] = useState(1);

  const [registerFormValues, setRegisterFormValues] = useState({
    eventId: event.id,
    hasGuest: isWaitlistBooking && event.waitlist.hasGuest ? 'true' : 'false',
    guestFirstName: '',
    guestLastName: '',
    guestEmail: '',
    dietaryRequirements: '',
    understand: false,
    norefund: false,
    deliveryAddress: '',
    watchOnly: false,
    donation: null
  });

  const { allowsGuest, booking } = event;

    const handleSubmit = (values) => {
        let o = Object.assign({}, registerFormValues, values);
        setRegisterFormValues(o);
        setStep(step + 1);
    }

  const stepClickHandler = (step) => { setStep(+step); }

  return (
    <div className="position-relative">
      {
        +step < 4 && <ProgressIndicator
          totalSteps={4}
          currentStep={step}
          onStepClick={stepClickHandler} />
      }
      {
        +step === 1 && <FormRegister
          registerFormValues={registerFormValues}
          onSubmitHandler={handleSubmit}
          selectedSession={selectedSession}
          isWaitlistBooking={isWaitlistBooking} />
      }
      {
          +step === 2 && <FormDonate
            onSubmit={handleSubmit}
            profileData={profile.data} />
      }
      {
        +step === 3 && <FormPayment
          selectedSession={selectedSession}
          onConfirmClick={() => { setStep(4); }}
          onBackToRegistrationClick={() => { setStep(1); }}
          registerFormValues={registerFormValues}
          isWaitlistBooking={isWaitlistBooking}
          profileData={profile.data}
          setDonation={setDonation}
          setDonateError={setDonateError} />
      }
      {
        +step === 4 && <FormConfirmation
          allowsGuest={allowsGuest}
          booking={booking}
          selectedSession={selectedSession}
          editBookingSteps={editBookingSteps}
          setEditBookingStep={setEditBookingStep} />
      }
    </div>
  );
};

export default connect(mapStateToProps)(FormRegistration);
