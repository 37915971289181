import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M19.5,7 L19.5,22.5 L3.5,22.5 L3.5,2.5 L15,2.5 L19.5,7 Z M14.5,2.5 L14.5,7.5 L19.5,7.5" stroke="currentColor"/>
          <path d="M9.72746155,19.2099998 L9.72746155,17.9009998 C8.59446155,17.8789998 7.68146155,17.7029998 7.09846155,17.5049998 L7.09846155,16.6579998 C7.67046155,16.8339998 8.85846155,17.0429998 9.82646155,17.0429998 C11.4104616,17.0429998 12.3564616,16.5809998 12.3564616,15.9539998 C12.3564616,15.2939998 11.7074616,14.9639998 10.1784616,14.5899998 C8.00046155,14.0509998 7.19746155,13.5009998 7.19746155,12.3459998 C7.19746155,11.3889998 8.01146155,10.6409998 9.72746155,10.4759998 L9.72746155,9.19999981 L10.6184615,9.19999981 L10.6184615,10.4319998 C11.6304615,10.4429998 12.4884616,10.6079998 13.0494616,10.8389998 L13.0494616,11.6969998 C12.4444615,11.4769998 11.5314615,11.2899998 10.5304616,11.2899998 C9.07846155,11.2899998 8.31946155,11.7189998 8.31946155,12.3349998 C8.31946155,12.9949998 8.93546155,13.3249998 10.4974616,13.6989998 C12.5654616,14.1939998 13.4784616,14.7879998 13.4784616,15.9429998 C13.4784616,16.9109998 12.4444615,17.7139998 10.6184615,17.8789998 L10.6184615,19.2099998 L9.72746155,19.2099998 Z" fill="currentColor"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M41.5,13.5 L41.5,45.5 L7.5,45.5 L7.5,2.5 L30.5,2.5 L41.5,13.5 Z M30.5,2.5 L30.5,13.5 L41.5,13.5" stroke="currentColor"/>
        <path d="M17.1486282,22.3499998 L17.1486282,20.5649998 C15.6036282,20.5349998 14.3586282,20.2949998 13.5636282,20.0249998 L13.5636282,18.8699998 C14.3436282,19.1099998 15.9636282,19.3949998 17.2836282,19.3949998 C19.4436282,19.3949998 20.7336282,18.7649998 20.7336282,17.9099998 C20.7336282,17.0099998 19.8486282,16.5599998 17.7636282,16.0499998 C14.7936282,15.3149998 13.6986282,14.5649998 13.6986282,12.9899998 C13.6986282,11.6849998 14.8086282,10.6649998 17.1486282,10.4399998 L17.1486282,8.69999981 L18.3636282,8.69999981 L18.3636282,10.3799998 C19.7436282,10.3949998 20.9136282,10.6199998 21.6786282,10.9349998 L21.6786282,12.1049998 C20.8536282,11.8049998 19.6086282,11.5499998 18.2436282,11.5499998 C16.2636282,11.5499998 15.2286282,12.1349998 15.2286282,12.9749998 C15.2286282,13.8749998 16.0686282,14.3249998 18.1986282,14.8349998 C21.0186282,15.5099998 22.2636282,16.3199998 22.2636282,17.8949998 C22.2636282,19.2149998 20.8536282,20.3099998 18.3636282,20.5349998 L18.3636282,22.3499998 L17.1486282,22.3499998 Z" fill="currentColor"/>
        <path d="M17,37.5 L29,37.5 M13,37.5 L15,37.5 M17,31.5 L35,31.5 M13,31.5 L15,31.5" stroke="currentColor"/>
      </g>
    </svg>
  );
};
