// this form is also used on the vehicle page in a modal

import React from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import Button from '../../../audi-ui-components/Button';
import IconMyDealer from '../../../audi-ui-components/icons/MyDealer';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';
import _find from 'lodash/find';
import _filter from 'lodash/filter';
import { FIELD_SELECT, FIELD_DEALER_SELECT, OPTS_STATES } from '../../../constants';

import { connect } from 'react-redux';
import { updateProfile, getPreferredServiceLocation } from '../../../redux/Profile';
import { fetchDealers } from '../../../redux/Dealers';
const mapStateToProps = state => {
    return {
        isAuthed: state.profile.isAuthed,
        profileServiceLocation: state.profile.preferredServiceLocation,
        profileState: state.profile.data ? state.profile.data.homeState : null,
        isLoadingProfile: state.profile.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (formData, cb, errCb) => { dispatch(updateProfile(formData, cb, errCb)); },
        getPreferredServiceLocation: (id) => { dispatch(getPreferredServiceLocation(id)); },
        fetchDealers: () => { dispatch(fetchDealers()); }
    }
}

class PreferredServiceDealerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showForm: Boolean(!props.profileServiceLocation || props.alwaysShowForm),
            isUpdating: false,
            isFetching: false,
            isSaved: false,
            error: false,
            locationOpts: []
        }
    }

    componentDidMount() {
        if (this.props.profileState) {
            this.getLocationOpts(this.props.profileState);
        }
    }

    componentDidUpdate(prevProps) {
      if (!this.props.alwaysShowForm && !prevProps.profileServiceLocation && this.props.profileServiceLocation && this.props.profileServiceLocation.id) {
        this.setState({showForm: false});
      }
    }

    handleSubmit = (values, formikBag) => {
        this.setState({ isUpdating: true, isSaved: false, error: false });

        this.props.updateProfile(
            {preferredServiceLocation: values.preferredServiceLocation},
            () => {
                this.props.getPreferredServiceLocation(values.preferredServiceLocation);
                formikBag.resetForm({ values });
                this.setState({ isUpdating: false, isSaved: true });
                gtmPush("myAudi", "preferredDealerUpdateSuccess", this.props.gtmEventLabel);
                if (typeof this.props.onCancel === 'function') {
                    this.props.onCancel();
                }
            },
            (error) => {
                this.setState({ isUpdating: false });
                if (error.body && error.body.modelState) {
                    let errors = {};
                    for (let key in error.body.modelState) {
                        if (!Object.hasOwnProperty(key)) {
                            errors[key] = error.body.modelState[key][0];
                        }
                    }
                    formikBag.setStatus(errors);
                }
            }
        );
    }

    handleShowDealerForm = () => {
        this.setState({ showForm: true });
        if (this.props.profileState) {
            this.getLocationOpts(this.props.profileState);
        }
    }

    handleCancel = () => {
        this.setState({ isSaved: false, error: false });
        if (!this.props.alwaysShowForm) {
            this.setState({ showForm: false, locationOpts: [] });
        }
        if (typeof this.props.onCancel === 'function') {
            this.props.onCancel();
        }
    }

    getLocationOpts = (state) => {
        this.setState({isFetching: true});
        request(
            `${process.env.RAZZLE_API}/1/services/locations?$filter=type eq 'Service' and state eq '${state}'`
        ).then((data) => {
            this.setState({locationOpts: data.map(loc => { return {label: `${loc.dealerName} - ${loc.name}`, value: loc.id}; }), isFetching: false});
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        const { profileState, profileServiceLocation } = this.props;
        var loading = this.props.isLoadingProfile || this.state.isFetching || this.state.isUpdating;
        return (
            <div className="loading-wrapper">
                <div className={classnames("py-7", this.props.className)} style={this.props.style || {}}>

                    {loading &&
                        <LoadingOverlay type={"overlay"} bgColor={this.props.loadingBgColour} />
                    }

                    <div className="row">
                        <div className="col-12 col-medium-2 d-flex justify-content-center justify-content-medium-end icon-wrapper pr-4">
                            <IconMyDealer large />
                        </div>
                        <div className="col-12 col-medium-8 px-6 px-medium-0">
                            <h2 className="aui-headline-4 mb-5 headline-icon">
                                Preferred Service Centre
                            </h2>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-medium-8 offset-medium-2 px-6 px-medium-0">
                            {!this.state.showForm && <div>
                                <p className="">Your preferred service centre is <b>{profileServiceLocation ? profileServiceLocation.dealerName : `...`}</b></p>
                                <p><Button buttonType="text" label="Change" onClick={this.handleShowDealerForm} /></p>
                            </div>}

                            {this.state.showForm && <div>
                                <p className="mb-3">Please select your preferred Audi Service Centre.</p>
                                <Formik onSubmit={this.handleSubmit} initialValues={{ preferredServiceLocation: "", dealerState: profileState }}>
                                    {(formikBag) => (
                                        <Form>
                                            <FormField
                                                name="dealerState"
                                                fieldType={FIELD_SELECT}
                                                label="State"
                                                options={OPTS_STATES}
                                                onChange={(v) => { this.getLocationOpts(v); formikBag.setFieldValue('preferredServiceLocation', ''); }}
                                                formikBag={formikBag}
                                            />
                                            <FormField
                                                name="preferredServiceLocation"
                                                fieldType={FIELD_SELECT}
                                                label="Preferred Service Centre"
                                                options={this.state.locationOpts}
                                                formikBag={formikBag}
                                                disabled={!formikBag.values.dealerState}
                                            />

                                            <p style={{ textAlign: 'right' }}>
                                                {(this.state.isSaved && !formikBag.dirty) &&
                                                    <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                                                }
                                                {this.state.error &&
                                                    <span className="aui-color-text-red px-3"><IconCaution small /> Not saved</span>
                                                }
                                                {(!this.props.alwaysShowForm && profileServiceLocation) &&
                                                    <Button buttonType="secondary" label="Cancel" onClick={this.handleCancel} />
                                                }
                                                &nbsp;
                                                <Button buttonType="primary" label="Submit" type="submit" />
                                            </p>
                                        </Form>
                                    )}
                                </Formik>
                            </div>}

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferredServiceDealerForm);
