import React from 'react';
import { Formik, Form } from 'formik';
import { validate } from '../../../../lib/validation';

import Button from '../../../../audi-ui-components/Button';
import FormField from '../../../../components/FormField';
import IconForward from '../../../../audi-ui-components/icons/Forward';

/**
 * Step 1 of AddGuest
 */
const FormGuest = ({ addGuestValues, onSubmit, setStep, onBackToSummaryClick }) => {

  const handleSubmit = (values, formikBag) => {
    onSubmit(values, formikBag);
    formikBag.setSubmitting(false);
    setStep(2);
  }

  const validateForm = (values) => {
    let validation = validate(values, {
      firstName: {
        presence: {
          message: "Please enter your guest's first name",
          allowEmpty: false
        },
      },
      lastName: {
        presence: {
          message: "Please enter your guest's last name",
          allowEmpty: false
        },
      },
      email: {
        presence: {
          message: "Please enter an email address for your guest",
          allowEmpty: false
        },
      },
    }, { format: "firstError", fullMessages: true });

    return validation;
  }

  return (
    <>
      <h2 className="aui-headline-3 mt-4 mb-7"><b>Add a guest</b></h2>
      <Formik
        initialValues={addGuestValues}
        validate={validateForm}
        onSubmit={handleSubmit} >
        {formikBag => (
          <Form>
            <div className="row">
              <div className="col-small-6">
                <FormField name="firstName" label="Guest first name" formikBag={formikBag} type="text" />
              </div>
              <div className="col-small-6">
                <FormField name="lastName" label="Guest last name" formikBag={formikBag} type="text" />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormField name="email" label="Guest email address" formikBag={formikBag} type="email" />
              </div>
            </div>
            <div className="row mt-7 mb-3">
              <div className="col">
                <Button
                  buttonType="primary"
                  label="Add guest"
                  icon={<IconForward small />}
                  iconPosition="right"
                  type="submit"
                  disabled={formikBag.isSubmitting} />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Button
                  buttonType="secondary"
                  label="Cancel"
                  onClick={onBackToSummaryClick}
                  disabled={formikBag.isSubmitting} />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default FormGuest;
