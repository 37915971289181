import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '../audi-ui-components/Button';
import IconEdit from '../audi-ui-components/icons/Edit';
import IconSystemAdd from '../audi-ui-components/icons/SystemAdd';
import LoadingOverlay from './LoadingOverlay';
import Crumb from './Crumb';
// import { getVehicleFallbackLarge } from '../lib/vehicleHelpers';
import { request } from '../lib/apiRequestWrapper';

const BACKGROUND = {
    morning: "AUDI11456100_Sky_Morning_1424x724.jpg",
    midday: "AUDI11456100_Sky_Midday_1424x724.jpg",
    dusk: "AUDI11456100_Sky_Dusk_1424x724.jpg",
    night: "AUDI11456100_Sky_Night_1424x724.jpg"
};

// https://303loweperth.atlassian.net/wiki/spaces/ADS/pages/2260434961/Audi+Media+Service+v2

class VehicleHero extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            mediaservice: false,
            showDetail: false,
            timeOfDay: "midday"
        }
        this.refRender = React.createRef();
        this.loadTimeout = null;
    }

    componentDidMount() {
        if (this.props.imageUrl) {
            // user image
            this.setState({loading: false});
        } else {
            // get render from cache
            fetch(
                `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
                {
                  headers: {
                    'Accept': 'application/json',
                    'Cache-Control': 'no-cache',
                    'Content-Type': 'application/json'
                  },
                  method: "POST",
                  body: JSON.stringify({vin: this.props.vin, view: "n3c01aut", mimeType: "image/png", width: 1200})
                }
            ).then(response => {
              let ok = response.status >= 200 && response.status < 300;
              let contentType = response.headers.get('Content-Type') || '';
              // return response.json().then(r => { return r; }).catch(() => { return response; });
              // json response
              if (contentType.includes('application/json')) {
                if (ok) { return response.json().catch(() => response); }
                // error
                return response.json().then((body) => {
                  return Promise.reject({
                    status: response.status,
                    body,
                  });
                });
              }
              // not json response
              return Promise.reject({
                status: response.status,
                body: response.body
              });
            }).then((res) => {
                // console.log(res);
                this.setState({mediaservice: res.imageUrl, loading: false});
            }).catch((error) => {
              this.setState({loading: false});
            });
        }
        // set time of day for render bg
        let d = new Date();
        let currentHour = d.getHours();
        if (currentHour < 6 || currentHour >= 20) {
            this.setState({timeOfDay: "night"});
        } else if (currentHour >= 6 && currentHour < 9) {
            this.setState({timeOfDay: "morning"});
        } else if (currentHour >= 18 && currentHour < 20) {
            this.setState({timeOfDay: "dusk"});
        }
    }

    componentWillUnmount() {
      if (this.loadTimeout !== null) {
        clearTimeout(this.loadTimeout);
      }
    }

    render() {
        const {outdent, imageUrl, vin, modelYear, modelName, regoNo, detail} = this.props;
        const {showDetail} = this.state;
        let bg = "";
        let isFallback = false;
        if (imageUrl) {
          // user image
          bg = imageUrl;
        } else if (this.state.mediaservice) {
          // generic background for mediaservice render
          bg = `${process.env.PUBLIC_PATH}assets/${BACKGROUND[this.state.timeOfDay]}`;
        } else {
          // fallback
          bg = `${process.env.PUBLIC_PATH}assets/fallback.jpg`;//getVehicleFallbackLarge(modelName);
          isFallback = true;
        }
        return (
            <div className={classnames("vehicle-hero hero", this.props.className, { "outdent": outdent, "visible": !this.state.loading })}>
                
                {this.state.loading && <LoadingOverlay />}

                <div className="overlay">
                    <div className="content-wrapper">
                        <h1 className="aui-headline-3 headline-margin pt-small-7"><b>{modelName}</b></h1>
                        {regoNo && regoNo !== "TBA" && <p className="">{regoNo}</p>}
                        {detail && <div className={classnames("detail mt-3", {"show": showDetail})}>
                          <button className="aui-button aui-theme-light" onClick={() => { this.setState({showDetail: !showDetail}) }}><IconSystemAdd small /></button>
                          <div className="detail__text">
                            {modelYear && <p>Model year: <b>{modelYear}</b></p>}
                            {vin && <p>VIN: <b>{vin}</b></p>}
                          </div>
                        </div>}
                        <Crumb className="mb-small-6 mt-3" />
                    </div>

                    {this.props.onClickEdit && <div className="upload-image">
                        <Button
                          onClick={this.props.onClickEdit}
                          buttonType="primary"
                          theme="light"
                          icon={<IconEdit small />}
                          label={isFallback && !this.state.loading ? "Add my own photo" : ""}
                        />

                    </div>}
                </div>

                {!imageUrl && this.state.mediaservice &&
                    <img ref={this.refRender}
                        className={classnames("render", {"visible": !this.state.loading})}
                        src={this.state.mediaservice}
                        alt="Vehicle Render"
                    />
                }

                <div className={classnames("background", {"visible": !this.state.loading})} style={{ backgroundImage: `url("${bg}")` }} />
            </div>
        );
    }
};

export default VehicleHero;
