import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M9,15.5 L3.5,15.5 M6,21.5 L4.5,21.5 C4,21.5 3.5,21.1 3.5,20.5 C3.5,20.5 3.5,20.5 3.5,20.5 L3.5,0.5 L12.5,0.5 L12.5,6.9"/>
          <path d="M7.5,23.5 L7.5,18.6 C7.5,18 7.9,17.6 8.5,17.5 L19.6,17.5 C20.2,17.5 20.6,18 20.6,18.6 L20.6,23.5 C20.5,23.5 7.5,23.5 7.5,23.5 Z"/>
          <path d="M12.5,8.5 L15.5,8.5 C16.6,8.5 17.5,9.4 17.5,10.5 L17.5,13.5 C17.5,14.6 16.6,15.5 15.5,15.5 L12.5,15.5 C11.4,15.5 10.5,14.6 10.5,13.5 L10.5,10.5 C10.5,9.4 11.4,8.5 12.5,8.5 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M19,27.5 L7.5,27.5 M12.9,39.5 L9.5,39.5 C8.4,39.5 7.5,38.6 7.5,37.5 C7.5,37.5 7.5,37.5 7.5,37.5 L7.5,2.5 L23.5,2.5 L23.5,12.9"/>
        <path d="M15.5,45.5 L15.5,33.5 C15.5,31.8 16.8,30.5 18.5,30.5 L36.5,30.5 C38.2,30.5 39.5,31.8 39.5,33.5 L39.5,45.5 L15.5,45.5 Z"/>
        <path d="M24.5,15.5 L30.5,15.5 C32.2,15.5 33.5,16.8 33.5,18.5 L33.5,24.5 C33.5,26.2 32.2,27.5 30.5,27.5 L24.5,27.5 C22.8,27.5 21.5,26.2 21.5,24.5 L21.5,18.5 C21.5,16.8 22.8,15.5 24.5,15.5 Z"/>
      </g>
    </svg>
  );
};
