import React from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import cookie from './lib/cookie';
import { Helmet } from "react-helmet";

import Header from './components/Header';
import Footer from './components/Footer';
import LoadingOverlay from './components/LoadingOverlay';
import Home from './pages/Home';
import Profile from './pages/Profile';
import Vehicles from './pages/Vehicles';
import Finance from './pages/Finance';
import Experience from './pages/Experience';
import MyBookings from './pages/Experience/MyBookings';
import Waitlist from './pages/Experience/Waitlist';
import Faq from './pages/Faq';
import ContentPage from './pages/ContentPage';
import ContactUs from './pages/ContactUs';
import LocateADealer from './pages/LocateADealer';
import Alerts from './components/Alerts';
import Maintenance from './pages/Maintenance';

import { connect } from 'react-redux';
import { loadProfile, noProfile, logout } from './redux/Profile';
import { loadPage, loadFaq } from './redux/Content';

import {
    PATH_HOME,
    PATH_PROFILE,
    PATH_VEHICLES,
    PATH_FINANCE,
    PATH_EXPERIENCE,
    PATH_EXPERIENCE_OVERVIEW,
    PATH_MY_BOOKINGS,
    PATH_FAQ,
    PATH_BENEFITS,
    PATH_RESET_PASS,
    MAINTENANCE
} from './constants';

import './styles/main.scss';

// server-side data fetching
export const ssrRoutes = [
    {
        path: PATH_EXPERIENCE_OVERVIEW,
        loadData: (dispatch, matchParams, query) => { return dispatch(loadPage('audi-experience')); }
    },
    {
        path: PATH_BENEFITS,
        loadData: (dispatch, matchParams, query) => { return dispatch(loadPage('ownership-benefits')); }
    },
    {
        path: PATH_FAQ,
        loadData: (dispatch, matchParams, query) => { return dispatch(loadFaq()); }
    },
    {
        path: PATH_HOME,
        loadData: (dispatch, matchParams, query) => { return dispatch(loadPage('homepage')); }
    }
];

const mapStateToProps = state => {
    return {
        isAuthed: state.profile.isAuthed,
        isLoading: state.profile.isLoading,
        error: state.profile.error,
        alerts: state.profile.alerts
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loadProfile: (cb, errorCb, auth) => { dispatch(loadProfile(cb, errorCb, auth)); },
        noProfile: () => { dispatch(noProfile()); }
    }
}

function PrivateRoute({ children, isAuthed, error, ...rest }) {
    let hasCookie = true;
    if (typeof window !== 'undefined') {
        let a = JSON.parse(cookie.getItem('auth'));
        if (a && a.accessToken) {
            hasCookie = true;
        } else {
            hasCookie = false;
        }
    }
    if (!hasCookie || (error && error !== null)) {
        return (<Route {...rest} render={({ location }) => (<Redirect to={{ pathname: "/login", state: { from: location } }} />)} />);
    }
    return (
        <Route {...rest}>{isAuthed ? children : <LoadingOverlay type="placeholder" />}</Route>
    );
}

class App extends React.Component {

    componentDidMount() {
        if (!this.props.isAuthed && !this.props.isLoading) {
            var a = JSON.parse(cookie.getItem('auth'));
            if (a && a.accessToken) {

                var path = location.pathname;
                var params = queryString.parse(location.search);

                //if the page is password reset and we have appropriate parameters for a password reset, then log the user out first.
                if (path == PATH_RESET_PASS && params.id && params.token) {
                    logout();
                    this.props.noProfile();
                }
                else {
                    this.props.loadProfile(null, null, a);
                }

            } else {
                this.props.noProfile();
            }
        } else {
            this.props.noProfile();
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>myAudi Australia</title>
                </Helmet>
                <Header />
                <Alerts alerts={this.props.alerts} />
                <div className="outer-wrapper aui-color-white">
                    <Switch>
                        {MAINTENANCE && <Route path="*"><Maintenance /></Route>}
                        <PrivateRoute path={PATH_PROFILE} isAuthed={this.props.isAuthed} error={this.props.error}><Profile /></PrivateRoute>
                        <PrivateRoute path={PATH_VEHICLES} isAuthed={this.props.isAuthed} error={this.props.error}><Vehicles /></PrivateRoute>
                        <PrivateRoute path={PATH_FINANCE} isAuthed={this.props.isAuthed} error={this.props.error}><Finance /></PrivateRoute>
                        <PrivateRoute path={PATH_MY_BOOKINGS} isAuthed={this.props.isAuthed} error={this.props.error}><MyBookings /></PrivateRoute>
                        <PrivateRoute path={"/w/:waitlistNumber"} isAuthed={this.props.isAuthed} error={this.props.error}><Waitlist /></PrivateRoute>
                        <Route path={PATH_EXPERIENCE}><Experience /></Route>
                        <Route path={PATH_EXPERIENCE_OVERVIEW}><ContentPage key="audi-experience" slug="audi-experience" /></Route>
                        <Route path={`${PATH_FAQ}/:category?`} render={routeProps => (<Faq {...routeProps} />)} />
                        <Route path={PATH_BENEFITS}><ContentPage key="ownership-benefits" slug="ownership-benefits" /></Route>
                        <Route path="/track"><ContentPage key="track" slug="track" /></Route>
                        <Route path="/map-updates"><ContentPage key="map-updates" slug="map-updates" /></Route>
                        <Route path="/contact-us"><ContactUs /></Route>
                        <Route path="/locate-a-dealer"><LocateADealer /></Route>
                        <Route path="*" render={routeProps => (<Home {...routeProps} />)} />
                    </Switch>
                </div>
                <Footer />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
