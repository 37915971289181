import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import _debounce from 'lodash/debounce';

const SCROLL_THRESHOLD = 20; // in px; How far to scroll, before paddles are visible.
const SCROLL_PADDING = 10; // in px;
const SCROLL_EASING = 0.2;

class NavBar extends React.Component {

  constructor(props) {
    super(props);
    this.itemsRef = [];
    this.containerRef = React.createRef();
    this.panelRef = React.createRef();
    this.indicatorRef = React.createRef();
    this._debouncedUpdate = _debounce(this.update, 400);
    this.state = {
      leftPaddle: false,
      rightPaddle: false
    }
  }

  componentDidMount() {
    setTimeout(this.update, 500);
    setTimeout(this.update, 900);
    window.addEventListener("resize", this._debouncedUpdate, false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeIndex !== this.props.activeIndex) {
      this.updateIndicatorPosition(this.props.activeIndex);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._debouncedUpdate, false);
  }

  update = () => {
    this.updateIndicatorPosition(this.props.activeIndex);
    this.updatePaddleVisibility();
  }

  scroll = (dir) => {
    let containerBounds = this.containerRef.current.getBoundingClientRect();
    let elementBounds;
    let left = 0;
    let right = this.panelRef.current.offsetWidth;
    let scroll = 0;
    if (dir < 0) {
      // Scroll left
      for (let i = this.itemsRef.length - 1; i > 0; i--) {
        let element = this.itemsRef[i];
        elementBounds = element.getBoundingClientRect();
        let elementLeft = elementBounds.left - containerBounds.left;
        if (elementLeft < left) {
          scroll = Math.ceil(elementLeft + this.panelRef.current.scrollLeft - SCROLL_PADDING);
          break;
        }
      }
    } else {
      // Scroll right
      for (let i = 0; i < this.itemsRef.length; i++) {
        let element = this.itemsRef[i];
        elementBounds = element.getBoundingClientRect();
        let elementRight = elementBounds.left - containerBounds.left + element.offsetWidth;
        if (elementRight > right) {
          scroll = Math.ceil(elementRight + this.panelRef.current.scrollLeft + SCROLL_PADDING - right);
          break;
        }
      }
    }
    this.animateScroll(scroll);
  }

  animateScroll = (scroll) => {
    this._scrollStart = this.panelRef.current.scrollLeft;
    this._scrollDelta = scroll - this._scrollStart;
    this._scrollRatio = 0;
    this._scrollActive = true;
    window.requestAnimationFrame(() => this.animateScrollTick());
  }

  animateScrollTick = () => {
    let ratio = 1 - this._scrollRatio;
    ratio *= SCROLL_EASING;
    this._scrollRatio += ratio;
    if (ratio < 0.001) {
      this._scrollRatio = 1;
    }
    this.panelRef.current.scrollLeft = this._scrollStart + this._scrollDelta * this._scrollRatio;
    if (this._scrollActive && this._scrollRatio !== 1) {
      window.requestAnimationFrame(() => this.animateScrollTick());
    } else {
      this.updatePaddleVisibility();
    }
  }

  updatePaddleVisibility = () => {
    if (this.containerRef.current !== null && this.panelRef.current !== null) {
      if (this.panelRef.current.scrollLeft - SCROLL_THRESHOLD > 0) {
        this.setState({leftPaddle: true});
      } else {
        this.setState({leftPaddle: false});
      }
      if (this.panelRef.current.scrollLeft + this.containerRef.current.offsetWidth + SCROLL_THRESHOLD < this.panelRef.current.scrollWidth) {
        this.setState({rightPaddle: true});
      } else {
        this.setState({rightPaddle: false});
      }
    }
  }

  updateIndicatorPosition = (i) => {
    if (i === null) {
      if (this.indicatorRef.current !== null) {
          this.indicatorRef.current.style.width = '0';
      }
    } else {
      if (this.containerRef.current !== null) {
        let activeTarget = this.itemsRef[`${i}`];
        let containerBounds = this.containerRef.current.getBoundingClientRect();
        let rectTarget = activeTarget.getBoundingClientRect();
        let indicatorLeft = rectTarget.left - containerBounds.left + this.panelRef.current.scrollLeft;
        let indicatorWidth = activeTarget.offsetWidth;
        this.indicatorRef.current.style.left = `${indicatorLeft}px`;
        this.indicatorRef.current.style.width = `${indicatorWidth}px`;
      }
    }
  }

  render() {
    const {actions, activeIndex, onActionClick, theme, small, navType, className, style} = this.props;
    return(
      <nav ref={this.containerRef} className={classnames(`${className}`, "aui-nav", `aui-nav--${navType}`, "is-animated", {"aui-nav--small": small, [`aui-theme-${theme}`]: theme})} style={style}>
        <div ref={this.panelRef} className="aui-nav__panel">
          <ul className="aui-nav__items">
            {actions.map((action, index) => {
              if (typeof action.enabled !== "undefined" && action.enabled === false) { return null; }
              let _active = index === activeIndex;
              let Component = action.to ? Link : action.href ? "a" : "button";
              let link = {};
              if (action.to) { link.to = action.to; }
              if (action.href) { link.href = action.href; }
              if (!action.to && !action.href) { link.type = "button"; }
              return (
                <li key={`ni_${index}`} className={classnames("aui-nav__item", action.className)}>
                  <Component ref={(el) => this.itemsRef[index] = el}
                    {...link}
                    className={classnames("aui-nav__action", action.className, {"is-active": _active, "is-disabled": action.disabled})}
                    onClick={(e) => {if (!action.disabled) { onActionClick(index, e); }}}
                    disabled={action.disabled}
                  >
                    {action.text || action.label}
                  </Component>
                </li>
              );
            })}
          </ul>
          <span ref={this.indicatorRef} className="aui-nav__indicator"></span>
        </div>
        <div className="aui-nav__paddles">
          {this.state.leftPaddle && <button type="button" className="aui-nav__paddle-left" onClick={(e) => { this.scroll(-1); e.preventDefault(); }}></button>}
          {this.state.rightPaddle && <button type="button" className="aui-nav__paddle-right" onClick={(e) => { this.scroll(1); e.preventDefault(); }}></button>}
        </div>
      </nav>
    );
  }

}

NavBar.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
    className: PropTypes.string
  })),
  activeIndex: PropTypes.number,
  onActionClick: PropTypes.func,
  small: PropTypes.bool,
  navType: PropTypes.oneOf(['bar', 'tab']),
  className: PropTypes.string,
  style: PropTypes.object,
  theme: PropTypes.oneOf(['light', 'black', 'warmsilver', 'silver', 'red']) // see styles/components/_nav-themes.scss
};

NavBar.defaultProps = {
  navType: 'bar',
  className: '',
  style: {},
  onActionClick: () => {}
}

export default NavBar;
