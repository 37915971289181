import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {PATH_VEHICLES} from '../../constants';
import VehiclesList from './VehiclesList';
import Vehicle from './Vehicle';
import VehicleRegister from './VehicleRegister';

class Vehicles extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path={PATH_VEHICLES}><VehiclesList /></Route>
        <Route path={`${PATH_VEHICLES}/register`}><VehicleRegister /></Route>
        <Route path={`${PATH_VEHICLES}/:vin`} render={routeProps => (<Vehicle vin={routeProps.match.params.vin} key={routeProps.match.params.vin} location={routeProps.location} />)} />
      </Switch>
    );
  }
}

export default Vehicles;
