import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M11.5,13.5 L18,15.5 M22.5,13.5 L20,13.5 M19.3,5.7 L17.5,7.5 M11.5,2.5 L11.5,5 M5.5,7.5 L3.7,5.7 M3,13.5 L0.5,13.5 M5.5,19.5 L3.7,21.3 C1.6,19.2 0.5,16.4 0.5,13.5 C0.5,7.4 5.4,2.5 11.5,2.5 C17.6,2.5 22.5,7.4 22.5,13.5 C22.5,16.5 21.3,19.3 19.3,21.3 L17.5,19.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M26.3,26.5 L34.8,29.6"/>
        <circle cx="23.5" cy="25.5" r="3"/>
        <path d="M37,25.5 L41.5,25.5 M33,16 L36.2,12.8 M23.5,12 L23.5,7.5 M14,16 L10.8,12.8 M5.5,25.5 L10,25.5 M14,35 L10.8,38.2 C7.4,34.8 5.5,30.2 5.5,25.5 C5.5,15.6 13.6,7.5 23.5,7.5 C33.4,7.5 41.5,15.6 41.5,25.5 C41.5,30.5 39.5,35 36.2,38.2 L33,35"/>
      </g>
    </svg>
  );
};
