import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { request } from '../../../../lib/apiRequestWrapper';
import { removeGuest } from '../../../../redux/Experience';

import Button from '../../../../audi-ui-components/Button';
import { AE_REFUND_POLICY_URL } from '../../../../constants';

const FormRemoveGuest = ({
  event,
  setEditBookingStep,
  removeGuestSuccess,
  setRemoveGuestSuccess,
  removeGuest
}) => {

  let booking = event && event.booking;
  let guest = booking && booking.attendees.find(guest => guest.isGuest);
  let index = booking && booking.attendees.findIndex(guest => guest.isGuest);

  const onSubmitHandler = async (values, formikBag) => {

    // DELETE /1/experience/attendee/{id}/cancel
    try {
      let response = await request(
        `${process.env.RAZZLE_API}/1/experience/attendee/${guest.id}/cancel`,
        {
          method: "DELETE",
        }
      );
    } catch (error) {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setErrors(errors);
      }
      return;
    }

    // Remove guest value in state, to avoid refetching the whole event/booking.
    removeGuest(index);

    formikBag.setSubmitting(false);

    setRemoveGuestSuccess(true);
    setEditBookingStep(null);
    return;
  }

  return (
    <div>
      <h2 className="aui-headline-3 mt-4 mb-7">
        <b>Remove Guest?</b>
      </h2>
      {!removeGuestSuccess &&
        <Formik
          onSubmit={onSubmitHandler}
          initialValues={{}} >
          {formikBag => (
            <Form>
              <div className="row">
                <div className="col">
                  {!removeGuestSuccess && <p>Are you sure you want to remove your guest?</p>}
                </div>
              </div>
              <div className="row my-7">
                <div className="col">
                  <Button
                    buttonType="secondary"
                    label="Yes"
                    type="submit"
                    disabled={formikBag.isSubmitting} />
                  <Button
                    buttonType="primary"
                    label="No"
                    onClick={() => { setEditBookingStep(null); }}
                    className="ml-3"
                    disabled={formikBag.isSubmitting} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <p className="small">
                    <a href={AE_REFUND_POLICY_URL} target="_blank" rel="noopener noreferrer" className="aui-textlink">
                      View refund policy
                    </a>
                  </p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    removeGuest: (index) => {
      dispatch(removeGuest(index));
    },
  };
};
const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormRemoveGuest);
