import React, { useState } from 'react';
import { connect } from 'react-redux';

import FormRegistration from './forms/FormRegistration';
import Summary from './components/Summary';
import SummaryWaitlist from './components/SummaryWaitlist';
import AddGuest from './components/AddGuest';
import FormModifyGuest from './forms/FormModifyGuest';
import FormModifyRequirements from './forms/FormModifyRequirements';
import FormCancelBooking from './forms/FormCancelBooking';
import FormRemoveGuest from './forms/FormRemoveGuest';
import FormWaitlist from './forms/FormWaitlist';

const mapStateToProps = state => {
    return {
        event: state.experience.currentEvent,
        isAuthed: state.profile.isAuthed,
        profile: state.profile.data,
    };
};


const EventRegistration = ({ event, profile, selectedSessionIdx, waitlistSessionIdx, isWaitlistBooking}) => {

    const editBookingSteps = {
        cancel: "cancel",
        addGuest: "addGuest",
        modGuest: "modGuest",
        rmGuest: "rmGuest",
        requirements: "requirements"
    };

    const [editBookingStep, setEditBookingStep] = useState(null);
    const [removeGuestSuccess, setRemoveGuestSuccess] = useState(false);
    const [donation, setDonation] = useState(null);
    const [donateError, setDonateError] = useState(false);

    if (!event) { return null; }

    if (editBookingStep === editBookingSteps.cancel) {
        return (
            <FormCancelBooking
                editBookingSteps={editBookingSteps}
                setEditBookingStep={setEditBookingStep}
                setRemoveGuestSuccess={setRemoveGuestSuccess}
            />
        );
    } else if (editBookingStep === editBookingSteps.addGuest) {
        return (
            <AddGuest
                goToSummary={() => { setEditBookingStep(null); }}
                setRemoveGuestSuccess={setRemoveGuestSuccess}
            />
        );
    } else if (editBookingStep === editBookingSteps.modGuest) {
        return (
            <FormModifyGuest
                editBookingSteps={editBookingSteps}
                setEditBookingStep={setEditBookingStep}
            />
        );
    } else if (editBookingStep === editBookingSteps.rmGuest) {
        return (
            <FormRemoveGuest
                setEditBookingStep={setEditBookingStep}
                setRemoveGuestSuccess={setRemoveGuestSuccess}
                removeGuestSuccess={removeGuestSuccess}
            />
        );
    } else if (editBookingStep === editBookingSteps.requirements) {
        return (
            <FormModifyRequirements
                goToSummary={() => { setEditBookingStep(null); }}
            />
        );
    } else if (event.hasBooked) {
        return (
            <Summary
                editBookingSteps={editBookingSteps}
                setEditBookingStep={setEditBookingStep}
                removeGuestSuccess={removeGuestSuccess}
                selectedSession={event.sessions[selectedSessionIdx]}
                donation={donation}
                donateError={donateError}
            />
        );
    } else if (event.isOnWaitlist && !isWaitlistBooking) {
        return (
            <SummaryWaitlist {...event.waitlist} eventName={event.name} />
        );
    } else if (event.sessions[selectedSessionIdx].canBook || (isWaitlistBooking && selectedSessionIdx === waitlistSessionIdx)) {
        return (
            <FormRegistration
                editBookingSteps={editBookingSteps}
                setEditBookingStep={setEditBookingStep}
                selectedSession={event.sessions[selectedSessionIdx]}
                isWaitlistBooking={isWaitlistBooking}
                waitlistSessionIdx={waitlistSessionIdx}
                setDonation={setDonation}
                setDonateError={setDonateError}
            />
        );
    } else if (event.sessions[selectedSessionIdx].allowWaitlist && event.sessions[selectedSessionIdx].canWaitlist && event.sessions[selectedSessionIdx].waitlistSpotsAvailable > 0) {
        return (
            <FormWaitlist
                session={event.sessions[selectedSessionIdx]}
                profile={profile}
                allowsGuest={event.allowsGuest}
                isGuestFree={event.isGuestFree}
                isDoubleBooking={event.isDoubleBooking}
                eventId={event.id}
                eventName={event.name}
            />
        );
    } else {
        return (
            <div>
                <h2 className="aui-headline-3 mt-4 mb-7"><b>Registration</b></h2>
                <p>{event.sessions[selectedSessionIdx].canBookMessage}</p>
            </div>
        );
    }
};

export default connect(mapStateToProps)(EventRegistration);
