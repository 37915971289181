import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { validate, formatNumber } from '../../../../lib/validation';
import { request } from '../../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../../lib/gtm';
import Button from '../../../../audi-ui-components/Button';
import FormField from '../../../../components/FormField';
import LoadingOverlay from '../../../../components/LoadingOverlay';

class SearchForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            serverError: null,
            isNotEligible: false,
            isNotEligibleComment: null,
            vinNotFound: false
        }
    }

    validateForm = (values) => {
        let schema = {
            vin: {
                isVin: { message: "Please enter a valid VIN" }
            },
            mileage: {
                presence: { message: "This field is required", allowEmpty: false }
            }
        };
        return validate(values, schema, {format: "firstError", fullMessages: false});
    }

    submitForm = (values, formikBag) => {
        this.setState({serverError: null});
        request(
            `${process.env.RAZZLE_API}/1/serviceplan/validateVIN`,
            {
                method: 'POST',
                body: JSON.stringify({vehicle: {vin: values.vin, mileage: values.mileage}}),
            }
        ).then(response => {
            // success
            gtmPush("buyServicePlan", "BspSearchForPlan", "BspStart");
            response.vehicleEligibleForRetailPlan = response.vehicleEligibleForRetailPlan === "true";
            response.vehicleEligibleForTopupPlan = response.vehicleEligibleForTopupPlan === "true";
            if (response.vehicleEligibleForRetailPlan || response.vehicleEligibleForTopupPlan) {
                response.mileage = values.mileage;
                response.vin = values.vin;
                this.props.setSearchResult(response);
            } else {
                this.setState({isNotEligible: true, isNotEligibleComment: response.vehicleEligibleForTopupPlanComments});
                formikBag.setSubmitting(false);
            }
        }).catch(error => {
            // error
            if (error.status === 404) {
                this.setState({vinNotFound: true});
            } else if (error.body && error.body.modelState) {
                let errors = {};
                for (let key in error.body.modelState) {
                    if (!Object.hasOwnProperty(key)) {
                        errors[key] = error.body.modelState[key][0];
                    }
                }
                formikBag.setErrors(errors);
            } else {
                let msg = "An error has occured";
                if (error.message) {
                    msg = error.message;
                } else if (error.body && error.body.message) {
                    msg = error.body.message;
                }
                this.setState({serverError: msg});
            }
            formikBag.setSubmitting(false);
        });

    }

    render() {
        if (this.state.isNotEligible) {
            return (
                <div className="row">
                    <div className="col-small-1"></div>
                    <div className="col-small-5 col-large-6">
                        <p className="aui-headline-4 mb-3">Your vehicle is not eligible for a service plan.</p>
                        <p className="mb-5">{this.state.isNotEligibleComment}</p>
                        <Button label="Book a service" buttonType="primary" href="https://forms.audi.com.au/book-a-service?ui=audi" onClick={() => { gtmPush("buyServicePlan", "BspBookAService", "BspChoose"); }} target="_blank" />
                    </div>
                    <div className="col-small-1"></div>
                    <div className="col-small-4 col-large-3">
                        {this.props.contact}
                    </div>
                </div>
            );
        }

        if (this.state.vinNotFound) {
            return (
                <div className="row">
                    <div className="col-small-1"></div>
                    <div className="col-small-5 col-large-6">
                        <p className="aui-headline-4 mb-3">Sorry, we couldn't find your vehicle details.</p>
                        <p className="mb-5">Please contact your preferred dealer to enquire about purchasing a service plan.</p>
                        <Button label="Locate a dealer" buttonType="primary" href="https://forms.audi.com.au/locate-a-dealer?ui=audi" target="_blank" />
                    </div>
                    <div className="col-small-1"></div>
                    <div className="col-small-4 col-large-3">
                        {this.props.contact}
                    </div>
                </div>
            );
        }
        return (
            <>
                <p className="aui-headline-4 mb-3">
                    Reassuringly comprehensive and offering great value, the Audi Service Plan guarantees that your Audi
                    receives all of its scheduled servicing needs, giving you complete peace of mind.
                </p>
                <p className="aui-headline-4">Enter your details below to choose a suitable plan.</p>
                <hr className="my-7" />
                <Formik
                    initialValues={{
                        mileage: "",
                        vin: this.props.vin
                    }}
                    validate={this.validateForm}
                    onSubmit={this.submitForm}
                >
                    {formikBag => (
                        <Form>
                            {formikBag.isSubmitting && <LoadingOverlay />}
                            <div className="row">
                                <FormField name="vin" label="VIN" formikBag={formikBag} disabled={process.env.RAZZLE_APP_ENV === "production"} wrapperClassName="col-12 col-small-6 mb-3" />
                            </div>
                            <div className="row">
                                <FormField name="mileage" label="Number of KM's" formikBag={formikBag} formatValue={formatNumber} wrapperClassName="col-12 col-small-6 mb-3" />
                            </div>
                            {this.state.serverError && <p className="my-5 aui-color-text-red">{this.state.serverError}</p>}
                            <p className="mt-7">
                                <Button
                                    buttonType="primary"
                                    label="Search for a plan"
                                    type="submit"
                                    disabled={formikBag.isSubmitting}
                                />
                            </p>
                        </Form>
                    )}
                </Formik>
            </>
        );
    }
}

export default SearchForm;
