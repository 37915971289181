import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import WordMark from './WordMark';
import { Link } from 'react-router-dom';

const Tile = (props) => {
  let LinkComponent = Link;
  let linkProps = { to: props.link };
  if (
    props.link &&
    (props.link.indexOf('http://') === 0 || props.link.indexOf('https://') === 0)
  ) {
    // external link
    LinkComponent = 'a';
    linkProps = { href: props.link, target: '_blank', rel: 'noopener noreferrer' };
  }
  let textColour = (props.backgroundImage && props.backgroundImage.textColor) ? props.backgroundImage.textColor : "light";
  return (
    <div className={classnames("tile", `aui-color-text-${textColour}`, {"overlay": props.overlay})} style={{ backgroundColor: props.backgroundColour }}>
      <LinkComponent {...linkProps}>
        <div className="tile__text">
          {props.logo && (
            <div className="tile__title">
              <WordMark name={props.logo} />
            </div>
          )}
          {!props.logo && <h4 className={`aui-headline-5 tile__title aui-color-text-${textColour}`}>{props.title}</h4>}
          {props.subTitle && <div className={`tile__subtitle aui-color-text-${textColour}`} dangerouslySetInnerHTML={{ __html: props.subTitle }} />}
        </div>
        {props.backgroundImage && (
          <div
            className="tile__img"
            style={{
              backgroundImage: `url("${props.backgroundImage.url}")`,
              backgroundPosition: `${props.backgroundImage.x} ${props.backgroundImage.y}`,
            }}></div>
        )}
      </LinkComponent>
    </div>
  );
}

Tile.propTypes = {
  backgroundColour: PropTypes.string,
  backgroundImage: PropTypes.object,
  logo: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  subTitle: PropTypes.string,
  link: PropTypes.string,
  overlay: PropTypes.bool
}

Tile.defaultProps = {
  backgroundColour: "#ffffff"
}

export default Tile;
