import React from 'react';
import moment from 'moment';

export const getEventPeriodString = (eventStartDate, eventEndDate) => {
  let startDateFormat = false;
  let startDateFormatMonth;
  let startDateFormatMonthYear;

  startDateFormat = moment(eventStartDate).isSame(eventEndDate, 'day')
    ? false
    : 'D';

  startDateFormatMonth =
    startDateFormat && !moment(eventStartDate).isSame(eventEndDate, 'month')
      ? 'D MMMM'
      : startDateFormat;

  startDateFormatMonthYear =
    startDateFormat && !moment(eventStartDate).isSame(eventEndDate, 'year')
      ? 'D MMMM YYYY'
      : startDateFormatMonth;

  return startDateFormatMonthYear
    ? moment(eventStartDate).format(startDateFormatMonthYear) +
        ' - ' +
        moment(eventEndDate).format('D MMMM YYYY')
    : moment(eventEndDate).format('D MMMM YYYY');
};

export const getSessionTimeString = (eventStartDate, eventEndDate) => {
  let times;
  let time = moment(eventStartDate).format('LT');
  if(!moment(eventStartDate).isSame(eventEndDate, 'minute')){
    times = time + ' - ' + moment(eventEndDate).format('LT');
  };
  return times || time;
}

export const getDaysToEvent = (eventStartDate) => {
  let s = moment(eventStartDate);
  let today = moment();
  return s.diff(today, 'days');
}

export const getVenueString = (venue) => {
  let venueString = "";
  if (venue) {
    venueString = `${venue.name} ${venue.address} ${venue.suburb}, ${venue.state}`;
  }
  return (venueString);
}

export const getVenueJsx = (venue, tag, className) => {
  let Tag = tag || 'span';
  return <Tag className={className || ''}>{venue.name}<br />{venue.address}<br />{venue.suburb}, {venue.state}</Tag>
}

export function formatCurrency(n) {
  const negative = n < 0;
  const abs = Math.abs(n);
  return `${negative ? '-' : ''}$${abs.toFixed(2)}`;
}

export function formatDate(n, options) {
  var defaultOptions = {
    day: false,
    month: true,
    year: true,
  };
  var o = Object.assign({}, defaultOptions, options);
  var d = moment(n);
  var format = `${o.day ? 'dddd ' : ''}Do ${o.month ? 'MMMM' : ''}${o.year ? ' Y' : ''}`;
  return d.format(format);
}

// format a cc or amex number with spaces
// returns a string
export const formatCreditCard = (value) => {
  if (value === '') { return ''; }
  let rawdata = value.replace(/\D/g,'');
  if (rawdata.length < 5) {
    return rawdata;
  }
  let formatted = '';
  let firstTwo = rawdata.substring(0,2);
  if (firstTwo === '34' || firstTwo === '37') {
    // amex
    rawdata = rawdata.substring(0,15);
    if (rawdata.length < 11) {
      formatted = rawdata.replace(/\b(\d{4})(\d{1,6})\b/, '$1 $2');
    } else {
      formatted = rawdata.replace(/\b(\d{4})(\d{6})(\d{1,5})\b/, '$1 $2 $3');
    }
  } else {
    // normal cc
    rawdata = rawdata.substring(0,16);
    let pieces = rawdata.match(/\d{1,4}/g);
    formatted = pieces ? pieces.join(' ') : '';
  }
  return formatted;
};
