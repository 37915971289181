import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M7,15.5 L12,15.5 M3,15.5 L5,15.5 M7,11.5 L16,11.5 M3,11.5 L5,11.5 M7,7.5 L21,7.5 M3,7.5 L5,7.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M16,30.5 L21,30.5 M12,30.5 L14,30.5 M16,24.5 L28,24.5 M12,24.5 L14,24.5 M16,18.5 L34,18.5 M12,18.5 L14,18.5" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
  );
};
