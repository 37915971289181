import {request} from '../lib/apiRequestWrapper';

const SET_DEALERS = "DEALERS/SET_DEALERS";
const SET_LOADING = "DEALERS/SET_LOADING";

export function fetchDealers() {
  return (dispatch, getState) => {
    dispatch({type: SET_LOADING, payload: true});
    return new Promise((resolve, reject) => {
      return request(
        `${process.env.RAZZLE_API}/2/legacy/getAllDealers?type=both`
      ).then((response) => {
        let dealers = [];
        if (response.data) {
          for (let i=0; i<response.data.length; i++) {
            let d = response.data[i];
            // add some props for easy filtering
            if (!d.state) {
              let s = d.salesLocation ? d.salesLocation.state : (d.serviceLocations && d.serviceLocations.length > 0) ? d.serviceLocations[0].state : '';
              d.state = s;
            }
            d.hasSales = Boolean(d.salesLocation);
            d.hasService = Boolean(d.serviceLocations && d.serviceLocations.length > 0);
            dealers.push(d);
          }
        }
        dispatch({type: SET_DEALERS, payload: dealers});
        resolve();
      }).catch((error) => {
        console.error(error);
        dispatch({type: SET_LOADING, payload: false});
        resolve();
      });
    });
  }
}

const defaultState = {
  isLoading: false,
  list: null
};

export function dealersReducer(state = defaultState, action) {
  switch (action.type) {

    case SET_LOADING:
    return Object.assign({}, state, {
      isLoading: action.payload
    });

    case SET_DEALERS:
    return Object.assign({}, state, {
      list: action.payload,
      isLoading: false
    });

    default:
    return state;
  }
}
