import React from "react";
import PropTypes from "prop-types";
import { saveAs } from 'file-saver';
import cookie from '../../../lib/cookie';
import Spinner from '../../../audi-ui-components/Spinner';
import {PrimaryButton} from '../../../audi-ui-components/Button';
import IconDownload from '../../../audi-ui-components/icons/Download';
import { gtmPush } from '../../../lib/gtm';

class DownloadPDFLink extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  handleDownloadClick = (event) => {
    if (!this.state.isLoading) {
      this.setState({isLoading: true});
      const that = this;
      var auth = JSON.parse(cookie.getItem('auth'));
      var fname = this.props.filename;
      var conf = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          'Cache-Control': 'no-cache, must-revalidate, private, max-age=0',
          'Authorization': `bearer ${auth.accessToken}`
        },
        responseType: 'blob'
      }
      fetch(this.props.fetchUrl, conf).then(response => {that.setState({isLoading: false}); return response.blob();}).then(blob => saveAs(blob, fname));
    }
    gtmPush("myAudiFinance", "downloadStatement", "finance");
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  render() {
    if (this.state.isLoading) {
      return (<Spinner isContinuous />);
    }
    return (
      <PrimaryButton label={this.props.label} onClick={this.handleDownloadClick} icon={<IconDownload small />} iconPosition="right" theme={this.props.theme} />
    );
  }
}

DownloadPDFLink.defaultProps = {
  label: "Download PDF",
  filename: "file.pdf",
  fetchUrl: '',
};

DownloadPDFLink.propTypes = {
  label: PropTypes.string,
  filename: PropTypes.string,
  fetchUrl: PropTypes.string,
  theme: PropTypes.string
};

export default DownloadPDFLink;
