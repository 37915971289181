import { request } from '../lib/apiRequestWrapper';
import moment from 'moment';
import { AE_ITEMS_PER_PAGE } from '../constants';
import { logout } from './Profile';

const SET_LOADING = 'EVENTS/SET_LOADING';
const SET_ERROR = 'EVENTS/SET_ERROR';
const SET_TICKETS = 'EVENTS/SET_TICKETS';
const SET_LIST = 'EVENTS/SET_LIST';
const SET_LIST_CAROUSEL = 'EVENTS/SET_LIST_CAROUSEL';
const SET_LIST_BOOKED = 'EVENTS/SET_LIST_BOOKED';
const SET_EVENT = 'EVENTS/SET_EVENT';
const UNSET_EVENT = 'EVENTS/UNSET_EVENT';
const SET_SHOW_PAST = 'EVENTS/SET_SHOW_PAST';
const SET_FILTER = 'EVENTS/SET_FILTER';
const RESET_FILTERS = 'EVENTS/RESET_FILTERS';
const UPDATE_DIETARY_REQUIREMENTS = 'EVENTS/UPDATE_DIETARY_REQUIREMENTS';

export function loadEvents(_skip) {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING, payload: true });
    dispatch({ type: SET_ERROR, payload: null });

    let showPast = getState().experience.showPast;
    let api = "currentEvents";
    if (showPast) { api = "events"; }
    let d = moment().toISOString();
    let eq = showPast ? "le" : "ge";
    let dir = showPast ? "desc" : "asc";
    let skip = _skip || 0;
    let paging = "";
    if (showPast) {
      paging = `&$top=${AE_ITEMS_PER_PAGE}&$skip=${skip}`;
    }
    let q = `?$orderby=EventEndDate ${dir}${paging}&$count=true&$filter=cast(EventEndDate,'Edm.DateTimeOffset') ${eq} ${d}`;

    return request(
        `${process.env.RAZZLE_API}/2/experience/${api}${q}`
    ).then(data => {
        let newList = skip > 0 ? getState().experience.list.concat(data.items) : data.items;
        dispatch({type: SET_LIST, payload: newList, count: data.count});
    }).catch(error => {
        console.error(error);
        if (error.status === 401 || error.status === "401") {
            return dispatch(logout(error));
        }
        dispatch({ type: SET_LOADING, payload: false });
    });

  };
}

export function loadBookedEvents() {
    return (dispatch, getState) => {
        dispatch({ type: SET_LOADING, payload: true });
        let d = moment().toISOString();
        let q = `?$orderby=EventEndDate asc&$count=true&$filter=cast(EventEndDate,'Edm.DateTimeOffset') ge ${d} and (hasBooked eq true or isOnWaitlist eq true)`;
        return request(
            `${process.env.RAZZLE_API}/2/experience/events${q}`
        ).then(data => {
            dispatch({type: SET_LIST_BOOKED, payload: data.items});
        }).catch(error => {
            console.error(error);
            if (error.status === 401 || error.status === "401") {
                return dispatch(logout(error));
            }
            dispatch({ type: SET_LOADING, payload: false });
        });
    }
}

export function loadEventsCarousel() {
  return (dispatch, getState) => {
    let d = moment().toISOString();
    let q = `?pagesize=5&$filter=cast(EventEndDate,'Edm.DateTimeOffset') ge ${d} and isFeatured eq true`;

    return request(
      `${process.env.RAZZLE_API}/2/experience/currentEvents${q}`
    ).then(data => {
        dispatch({ type: SET_LIST_CAROUSEL, payload: data.items });
      }
    ).catch(error => {
        console.error(error);
      }
    );

  }
}

export function loadTickets(auth) {
  return (dispatch, getState) => {

    return request(
      `${process.env.RAZZLE_API}/1/experience/events/ticketsRemaining`, {}, auth
    ).then(data => {
        dispatch({ type: SET_TICKETS, payload: data });
      }
    ).catch(error => {
        console.error(error);
      }
    );

  };
}

export function setFilter(name, value) {
  return (dispatch, getState) => {
    dispatch({ type: SET_FILTER, name, value });
  };
}

export function setTypeFilter(slug, value) {
  return (dispatch, getState) => {
    var i = getState().experience.filterType;
    if (value) {
      i.push(slug);
    } else {
      var idx = i.indexOf(slug);
      if (idx > -1) {
        i.splice(idx, 1);
      }
    }
    dispatch({ type: SET_FILTER, name: "filterType", value: i });
  }
}

export function setInterestFilter(slug, value) {
  return (dispatch, getState) => {
    var i = getState().experience.filterInterest;
    if (value) {
      i.push(slug);
    } else {
      var idx = i.indexOf(slug);
      if (idx > -1) {
        i.splice(idx, 1);
      }
    }
    dispatch({ type: SET_FILTER, name: "filterInterest", value: i });
  }
}

export function setShowPast(value) {
  return (dispatch, getState) => {
  dispatch({ type: SET_SHOW_PAST, payload: value});
    dispatch(loadEvents());
  }
}

export function resetFilters() {
  return (dispatch, getState) => {
    dispatch({ type: RESET_FILTERS });
  }
}

export function loadEvent(id, cb) {
  return async (dispatch, getState) => {
    dispatch({ type: SET_LOADING, payload: true });

    let data, cmsData;

    try {
      data = await request(`${process.env.RAZZLE_API}/2/experience/events/${id}`);
      if (data) {
        dispatch({ type: SET_EVENT, payload: data });
        dispatch({ type: SET_LOADING, payload: false });
        if (typeof cb === "function") { cb(data); }
      }

    } catch(error) {
      console.error('catch', error);
      dispatch({ type: SET_EVENT, payload: {error} });
      dispatch({ type: SET_LOADING, payload: false });
    }

  };
}

export function loadEventByWaitlistId(waitlistId, cb, errorCb) {
    return (dispatch, getState) => {
        return request(
            `${process.env.RAZZLE_API}/1/experience/events/waitlist/${waitlistId}`
        ).then(data => {
            //
            return request(
                `${process.env.RAZZLE_API}/2/cms/experiences/events/${data.id}`
            ).then(cmsData => {
                const combinedEventData = { ...data, ...cmsData };
                if (data) {
                    dispatch({ type: SET_EVENT, payload: combinedEventData });
                    dispatch({ type: SET_LOADING, payload: false });
                    if (typeof cb === "function") { cb(combinedEventData.id); }
                }
            }).catch(error => {
                console.error(error);
                if (typeof errorCb === "function") { errorCb(error); }
            });
            //
        }).catch(error => {
            console.error(error);
            if (typeof errorCb === "function") { errorCb(error); }
        });
    }
}

export function addBooking(booking) {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    currentEvent.booking = booking;
    currentEvent.hasBooked = true;

    // Reduce the booked session spotsAvailable by number of attendees
    let bookedSession = currentEvent.sessions.find(session => booking.session.id === session.id );
    let bookedSessionIndex = currentEvent.sessions.findIndex(session => booking.session.id === session.id );

    bookedSession.spotsAvailable = +bookedSession.spotsAvailable - booking.attendees.length;
    currentEvent.sessions[bookedSessionIndex] = bookedSession;

    dispatch({ type: SET_EVENT, payload: { ...currentEvent } });
    // dispatch(loadTickets());
  }
}

export function removeBooking() {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    let bookedSessionIndex = currentEvent.sessions.findIndex(session => currentEvent.booking.session.id === session.id);

    if ((bookedSessionIndex || bookedSessionIndex === 0) && bookedSessionIndex !== -1) {
      // Increase the booked session spotsAvailable by number of attendees
      let bookedSession = {...currentEvent.sessions[bookedSessionIndex]};
      bookedSession.spotsAvailable = +bookedSession.spotsAvailable + currentEvent.booking.attendees.length;
      bookedSession.canBook = true;
      currentEvent.sessions[bookedSessionIndex] = bookedSession;

      // Remove booking
      currentEvent.booking = null;
      currentEvent.hasBooked = false;

      dispatch({ type: SET_EVENT, payload: { ...currentEvent} });
    } else {
      // data problem, reload event
      dispatch(loadEvent(currentEvent.id));
    }

    // dispatch(loadTickets());
  }
}

export function addWaitlist(waitlist) {
    return (dispatch, getState) => {
        let currentEvent = getState().experience.currentEvent;
        let sessionIdx = currentEvent.sessions.findIndex(s => waitlist.session.id === s.id );
        if (sessionIdx && sessionIdx !== -1) {
            currentEvent.waitlist = waitlist;
            currentEvent.isOnWaitlist = true;
            let numAttendees = waitlist.hasGuest ? 2 : 1;
            currentEvent.sessions[sessionIdx].waitlistSpotsAvailable -= numAttendees;
            dispatch({ type: SET_EVENT, payload: { ...currentEvent } });
        } else {
            // data problem, reload event
            dispatch(loadEvent(currentEvent.id));
        }
    }
}

export function removeWaitlist(id, successCb, errorCb) {
    return (dispatch, getState) => {
        return request(
          `${process.env.RAZZLE_API}/1/experience/waitlists/${id}/cancel`,
          {
            method: 'DELETE'
          }
        ).then(() => {
          // success
          let currentEvent = getState().experience.currentEvent;
          let waitlist = currentEvent.waitlist;
          let sessionIdx = false;
          if (waitlist && waitlist.session) {
              sessionIdx = currentEvent.sessions.findIndex(s => waitlist.session.id === s.id);
          }
          if (sessionIdx && sessionIdx !== -1) {
              currentEvent.waitlist = null;
              currentEvent.isOnWaitlist = false;
              currentEvent.canWaitlist = true;
              let numAttendees = waitlist.hasGuest ? 2 : 1;
              currentEvent.sessions[sessionIdx].waitlistSpotsAvailable += numAttendees;
              dispatch({ type: SET_EVENT, payload: { ...currentEvent } });
          } else {
              // data problem, reload event
              dispatch(loadEvent(currentEvent.id));
          }
          if (typeof successCb === "function") {
              successCb();
          }
        }).catch((error) => {
            if (typeof errorCb === "function") {
                errorCb(error);
            }
        });

    }
}

export function unloadEvent() {
  return (dispatch, getState) => {
    dispatch({ type: UNSET_EVENT });
  }
}

export function updateDietaryRequirements(value) {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    let { booking } = currentEvent;
    booking.dietaryRequirements = value;
    currentEvent.booking = booking;
    dispatch({ type: SET_EVENT, payload: currentEvent });
  }
}

export function updateGuest(index, values) {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    let { booking } = currentEvent;
    booking.attendees[index].firstName = values.firstName;
    booking.attendees[index].lastName = values.lastName;
    booking.attendees[index].email = values.email;
    booking.attendees[index].isGuest = true;
    currentEvent.booking = booking;
    dispatch({ type: SET_EVENT, payload: currentEvent });
  }
}

export function addGuest(values) {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    let { booking } = currentEvent;
    booking.attendees.push({...values});
    currentEvent.booking = booking;
    dispatch({ type: SET_EVENT, payload: currentEvent });
    // dispatch(loadTickets());
  }
}

export function removeGuest(index) {
  return (dispatch, getState) => {
    let currentEvent = getState().experience.currentEvent;
    let { booking } = currentEvent;
    booking.attendees.splice(index, 1);
    currentEvent.booking = booking;
    dispatch({ type: SET_EVENT, payload: currentEvent });
    // dispatch(loadTickets());
  }
}

const defaultState = {
  isLoading: false,
  error: null,
  list: null,
  listCount: null,
  listCarousel: null,
  listBooked: null,
  isGridLayout: true,
  showPast: false,
  filterType: ["event", "offer", "virtual"],
  filterLocation: "all",
  filterInterest: [
    "culture",
    "film",
    "sport",
    "motorsport",
    "food",
    "fashion",
    "lifestyle"
  ],
  currentEvent: null,
  tickets: null
};

export function expReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload,
      });

    case SET_ERROR:
      return Object.assign({}, state, {
        error: action.payload,
      });

    case SET_TICKETS:
      return Object.assign({}, state, {
        tickets: action.payload
      });

    case SET_LIST:
      return Object.assign({}, state, {
        list: action.payload,
        listCount: action.count,
        isLoading: false,
      });

    case SET_LIST_BOOKED:
    return Object.assign({}, state, {
        listBooked: action.payload,
        isLoading: false
    });

    case SET_LIST_CAROUSEL:
      return Object.assign({}, state, {
        listCarousel: action.payload,
      });

    case SET_EVENT:
      return {...state, currentEvent: action.payload };

    case UNSET_EVENT:
      return {...state, currentEvent: null };

      case SET_FILTER:
      return Object.assign({}, state, {
        [action.name]: action.value,
      });

    case RESET_FILTERS:
      return Object.assign({}, state, {
        showPast: defaultState.showPast,
        filterType: defaultState.filterType,
        filterLocation: defaultState.filterLocation,
        filterInterest: defaultState.filterInterest
      });

    case SET_SHOW_PAST:
      return Object.assign({}, state, {
        showPast: action.payload
      });

    case UPDATE_DIETARY_REQUIREMENTS:
      return { ...state, currentEvent: action.payload }

    default:
      return state;
  }
}
