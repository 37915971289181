import React from 'react';

function VINHelp() {
  return (
    <div className="vin-help pt-3">

      <p className="mb-7">A Vehicle Identification Number (VIN) is a unique code that identifies a vehicle. A VIN comprises 17 letters and numbers. Your VIN can be found in the following locations:</p>

      <div className="row">
        <div className="col-auto">
          <svg viewBox="0 0 80 80" width="60" height="60">
            <g id="engine_bay">
              <path style={{fill:"#BB0A30"}} d="M76,21.5C76,29,62.5,46,62.5,46S49,29,49,21.5S55,8,62.5,8S76,14,76,21.5z"/>
              <path style={{fill:"none", stroke:"currentColor"}} d="M19.4,65.3h-0.7c-3.2,0-5.8-2.6-5.8-5.8l-4.1-0.3c-1.7-0.1-3-1.7-3.2-3.3 c-0.1-1.7,0.5-5.1,0.9-6.6C7,47.7,7.9,46,9.6,45.6c1.1-0.3,2.2-0.5,3.3-0.7c3.1-0.5,9.2-4.6,13.3-6.1c2.1-0.8,6.2-1,10.2-1 c3.8,0,7.5,0.3,9.2,1c1.5,0.6,4.4,3,8.7,7.2c5.1,0.7,8.7,1.4,10.7,2c3.1,1,5.1,3.1,5.6,4.6c0.3,1,0.4,1.9,0.4,3.9V57 c0,1.4-1.1,2.6-2.6,2.6h-4.7c0,3.2-2.6,5.8-5.8,5.8h-0.7c-3.2,0-5.8-2.6-5.8-5.8H25.2C25.2,62.7,22.6,65.3,19.4,65.3z M70.3,38.8 c-1.1-1.2-1.6-1.8-4.3-1.8c-1.6,0-3.9,1.9-7.1,5.3c-0.1,0.1-0.6,0.7-1.5,1.6l-0.2,0.4"/>
            </g>
          </svg>
        </div>
        <div className="col" style={{lineHeight: 'normal'}}>
          <p className="aui-headline-5 py-1">Engine Bay</p>
          <p className="pb-3">The VIN will be printed on a silver sticker or plate.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <svg viewBox="0 0 80 80" width="60" height="60">
            <g id="windscreen">
              <path style={{fill:"none", stroke:"currentColor"}} d="M19.4,65.3h-0.7c-3.2,0-5.8-2.6-5.8-5.8l-4.1-0.3c-1.7-0.1-3-1.7-3.2-3.3 c-0.1-1.7,0.5-5.1,0.9-6.6C7,47.7,7.9,46,9.6,45.6c1.1-0.3,2.2-0.5,3.3-0.7c3.1-0.5,9.2-4.6,13.3-6.1c2.1-0.8,6.2-1,10.2-1 c3.8,0,7.5,0.3,9.2,1c1.5,0.6,4.4,3,8.7,7.2c5.1,0.7,8.7,1.4,10.7,2c3.1,1,5.1,3.1,5.6,4.6c0.3,1,0.4,1.9,0.4,3.9V57 c0,1.4-1.1,2.6-2.6,2.6h-4.7c0,3.2-2.6,5.8-5.8,5.8h-0.7c-3.2,0-5.8-2.6-5.8-5.8H25.2C25.2,62.7,22.6,65.3,19.4,65.3z"/>
              <path style={{fill:"#BB0A30"}} d="M66,20.5C66,28,52.5,45,52.5,45S39,28,39,20.5S45,7,52.5,7S66,13,66,20.5z"/>
            </g>
          </svg>
        </div>
        <div className="col" style={{lineHeight: 'normal'}}>
          <p className="aui-headline-5 py-1">Windscreen</p>
          <p className="pb-3">The VIN is displayed on the lower, passenger’s side of the windscreen exterior.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <svg viewBox="0 0 80 80" width="60" height="60">
            <g id="rego_papers">
              <path style={{fill:"none", stroke:"currentColor"}} d="M58.6,39h1.1c1.8,0,3.3,1.5,3.3,3.3v25.2c-27.8,0-42.1,0-42.8,0c-1.1,0-2.2-1.3-2.2-2.7 c0.1,1.6,1.4,2.6,2.7,2.5l37.9-3.6V39c0-2.4-2-4.4-4.4-4.4l0,0 M18,39l32.1-8.7c1.8-0.5,3.6,0.6,4,2.3c0.1,0.3,0.1,0.6,0.1,0.9 v18.7L45.4,61l-24.7,5.9c-1.2,0.3-2.4-0.4-2.6-1.6c0-0.2-0.1-0.3-0.1-0.5V39z M45.4,61v-8.8h8.8"/>
              <path style={{fill:"#BB0A30"}} d="M49,24.5C49,32,35.5,49,35.5,49S22,32,22,24.5S28,11,35.5,11S49,17,49,24.5z"/>
            </g>
          </svg>
        </div>
        <div className="col" style={{lineHeight: 'normal'}}>
          <p className="aui-headline-5 py-1">Registration Papers</p>
          <p className="pb-3">The VIN will be printed on your vehicle registration papers.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <svg viewBox="0 0 80 80" width="60" height="60">
            <g id="service_schedule">
              <g>
                <circle style={{fill:"none", stroke:"currentColor"}} cx="40.5" cy="47" r="3.5"/>
                <path style={{fill:"none", stroke:"currentColor"}} d="M40.5,50.5v5V50.5z M44,46.5h7.5H44z M37,46.5h-7.5H37z M34.1,55.5H47 c2.8-1.9,4.5-5.1,4.6-8.5c-0.1-5.9-5.1-10.6-11-10.5c-5.9-0.1-10.9,4.6-11,10.5C29.5,50.4,31.2,53.6,34.1,55.5z"/>
                <path style={{fill:"none", stroke:"currentColor"}} d="M22,64.5h4.9H22z M22,33.5h4.9H22z M53.5,70.5h-29v-43h29c1.7,0,3,1.3,3,3l0,0v37 C56.5,69.2,55.2,70.5,53.5,70.5L53.5,70.5z"/>
              </g>
              <path style={{fill:"#BB0A30"}} d="M60,18.5C60,26,46.5,43,46.5,43S33,26,33,18.5S39,5,46.5,5S60,11,60,18.5z"/>
            </g>
          </svg>
        </div>
        <div className="col" style={{lineHeight: 'normal'}}>
          <p className="aui-headline-5 py-1">Audi Service Schedule</p>
          <p className="pb-3">The VIN can be found on the inside cover of your Audi Service Schedule booklet.</p>
        </div>
      </div>

      <div className="row">
        <div className="col-auto">
          <svg viewBox="0 0 80 80" width="60" height="60">
            <g id="roadside_assist_card">
              <g>
                <path style={{fill:"none", stroke:"currentColor"}} d="M24,61.5h20 M24,56.5h28 M23.5,36.5v-5.8c0-2.3,1.9-4.1,4.2-4.2h3.8 c2.3,0,4.1,1.9,4,4.2v14.9c0,2.2-1.7,4-3.9,4l0,0c-2.2,0-4-1.8-4.1-4V31"/>
                <path style={{fill:"none", stroke:"currentColor"}} d="M62.5,38.5c0-1.1-0.9-2-2-2h-43v31c0,1.1,0.9,2,2,2h43V38.5z"/>
              </g>
              <path style={{fill:"#BB0A30"}} d="M65,20.5C65,28,51.5,45,51.5,45S38,28,38,20.5S44,7,51.5,7S65,13,65,20.5z"/>
            </g>
          </svg>
        </div>
        <div className="col" style={{lineHeight: 'normal'}}>
          <p className="aui-headline-5 py-1">Audi A+ Roadside Assistance Card</p>
          <p className="pb-3">The VIN is printed on your Audi A+ Roadside Assistance card.</p>
        </div>
      </div>
    </div>
  );
}

export default VINHelp;
