export const gtmPush = (category, action, label, data) => {
  if (dataLayer) {
    let e = {
      event: category,
      eventAction: action,
      eventLabel: label
    };
    if (data) {
      e.data = data;
    }
    dataLayer.push(e);
  }
};
