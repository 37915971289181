import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { validate, defaultSchema, formatMobile } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import IconForward from '../../../audi-ui-components/icons/Forward';
import TradeinTerms from '../components/TradeinTerms';
import { FIELD_DEALER_SELECT, FIELD_SWITCH } from '../../../constants';
import _find from 'lodash/find';
import { gtmPush } from '../../../lib/gtm';
import TradeInEstimateSubform from './TradeInEstimateSubform';

const TradeInForm = ({ profile, vin, modelNameSD, dealers }) => {

  const [serverError, setServerError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [showTradeTerms, setShowTradeTerms] = useState(false);
  const [rbData, setRbData] = useState(null);

  useEffect(() => {
    if (vin) {
      request(
        `${process.env.RAZZLE_API}/1/services/vehicle/${vin}`,
        {
          method: 'POST'
        }
      ).then(response => {
        // success
        // console.log(response);
        if (Array.isArray(response)) {
          setRbData(response[0]); // assuming first item is the best match
        } else {
          setRbData(response);
        }
      }).catch(error => {
        console.error(error);
      });
    }
  }, [vin]);

  const handleSubmit = (values, formikBag) => {
    setServerError(null);
    values.modelName = modelNameSD;
    request(
      `${process.env.RAZZLE_API}/1/request/tradeIn`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    ).then(response => {
      // success
      formikBag.setSubmitting(false);
      setSubmitted(true);
      gtmPush("form", "submitSuccess", "tradeInEnquiry", values);
    }).catch(error => {
      // error
      formikBag.setSubmitting(false);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setStatus(errors);
      } else if (error.message) {
        setServerError(error.message);
      } else if (error.body && error.body.message) {
        setServerError(error.body.message);
      }
    });
  }

  const validateForm = (values) => {
    let validation = validate(values, {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      emailAddress: defaultSchema.email,
      postcode: defaultSchema.postcode,
      mobilePhone: defaultSchema.mobile,
      km: {
        presence: { message: "Please enter your Kilometres", allowEmpty: false }
      },
      dealerId: {
        presence: { message: "Please select a dealer", allowEmpty: false }
      }
    }, { format: "firstError", fullMessages: true });
    if (values.tradeIn) {
      let tradeValidation = validate(values, {
        tradeMake: {
          presence: {
            message: "This field is required",
            allowEmpty: false
          }
        },
        tradeYear: {
          presence: {
            message: "This field is required",
            allowEmpty: false
          }
        },
        tradeHasFinance: {
          presence: {
            message: "This field is required",
            allowEmpty: false
          }
        },
      }, {format: "firstError", fullMessages: true});
      validation = Object.assign({}, validation, tradeValidation);
    }
    return validation;
  }

  const dealer = _find(dealers, {name: profile.preferredDealer});

  if (submitted) {
    return (
      <div className="modal-form py-7">
        <h2 className="aui-headline-4 mb-7">Thank you</h2>
        <p>Your selected dealer will be in touch with you shortly.</p>
      </div>
    );
  }

  return (
    <div className="modal-form py-7">
      <h2 className="aui-headline-4 mb-7">Vehicle Trade-in Enquiry</h2>
      <Formik
        initialValues={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          emailAddress: profile.email,
          mobilePhone: profile.mobile,
          postcode: profile.homePostcode,
          km: "",
          vin: vin,
          dealerId: dealer ? dealer.dealerId : "",
          tradeMake: "",
          tradeYear: "",
          tradeHasFinance: ""
        }}
        validate={validateForm}
        onSubmit={handleSubmit}>
        {formikBag => (
          <Form>
            <FormField name="vin" label="VIN" formikBag={formikBag} disabled={true} />
            <FormField name="km" label="Kilometres" formikBag={formikBag} />

            {rbData && rbData.tradeinmin && <div className="mb-4">
              <p>The trade-in estimate for your {rbData.year} {rbData.make} {rbData.family} could fall somewhere between<sup>*</sup></p>
              <div className="rb-estimate">
                ${rbData.tradeinmin}
                <span className="rb-estimate-arrow" />
                ${rbData.tradeinmax}
              </div>
            </div>}

            <div className="mb-5 pt-2">
              <FormField
                name="tradeIn"
                rightLabel="Would you like an estimate to trade another car?"
                formikBag={formikBag}
                fieldType={FIELD_SWITCH}
                wrapperClassName="mb-4"
              />
              {formikBag.values.tradeIn &&
                <TradeInEstimateSubform formikBag={formikBag} />
              }
            </div>

            <p className="my-5 pt-3">If you would like an Audi dealer to provide a formal estimate, please check your details are correct and submit an enquiry.</p>

            <FormField name="dealerId" label="Dealer" fieldType={FIELD_DEALER_SELECT} dealers={dealers} valueProp="dealerId" formikBag={formikBag} />
            <FormField name="firstName" label="First name" formikBag={formikBag} />
            <FormField name="lastName" label="Last name" formikBag={formikBag} />
            <FormField name="emailAddress" label="Email address" formikBag={formikBag} type="email" />
            <FormField name="mobilePhone" label="Mobile phone" formikBag={formikBag} formatValue={formatMobile} />
            <FormField name="postcode" label="Postcode" formikBag={formikBag} />
            <br />
            {serverError && <p className="mb-5 aui-color-text-red">{serverError}</p>}
            <p className="mt-4">
              <Button
                buttonType="primary"
                label="Submit"
                icon={<IconForward small />}
                iconPosition="right"
                type="submit"
                disabled={formikBag.isSubmitting} />
            </p>
          </Form>
        )}
      </Formik>

      <p className="disclaimer mt-4">
          <sup>*</sup> This online valuation is a guide only and is based on the information provided and the average quality of similar vehicles.
          Upon inspection of your vehicle by an Audi dealer the price could be higher or lower.
          This is impacted by several factors including; options fitted, condition, service history, odometer reading, damage and wear and tear.
          Please contact your local dealer to receive a formal estimate on your trade-in.
      </p>
      <p className=""><Button label="Terms & conditions" buttonType="text" onClick={() => { setShowTradeTerms(!showTradeTerms) }} /></p>
      {showTradeTerms && <TradeinTerms />}

    </div>
  );
}

export default TradeInForm;
