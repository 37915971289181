import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import { validate } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';
import { loginSchema } from '../schema';

import {
  PATH_LOGIN,
  PATH_REQUEST_CONFIRMAION,
  PATH_FORGOT_PASS
} from '../../../constants';

/*
 * This form will be reached via an email link
 * which will contain 'verificationCode' in the query string
 */

class ResetPasswordForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: props.initialStep || 1,
      isSubmitting: false,
      submitStatus: null,
      isResent: false,
      showEmailError: false,
      serverError: false
    }
  }

  submitForm = (values, formikBag) => {
    this.setState({isSubmitting: true});
    request(
      `${process.env.RAZZLE_API}/2/account/password/reset`,
      {
        method: 'POST',
        body: JSON.stringify({verificationCode: values.verificationCode, username: values.email, password: values.newPassword})
      }
    ).then((response) => {
      this.setState({step: 2, submitStatus: 200, isSubmitting: false});
      gtmPush("myAudi", "passwordResetSuccess");
    }).catch((error) => {
      console.log(error);
      let msg = "There was a problem resetting your password";
      if (error.body?.message) {
        msg = error.body.message;
      }
      this.setState({serverError: msg, isSubmitting: false});
      if (error.body?.modelState) {
        formikBag.setErrors(error.body.modelState);
      }
    });
  }

  validateForm = (values) => {
    var schema = {
      email: loginSchema['email'],
      newPassword: {
        isPassword: true
      },
      confirmPassword: {
        equality: {
          attribute: "newPassword",
          message: "Password does not match"
        }
      },
      verificationCode: {
        presence: {
          message: "Verification code is required",
          allowEmpty: false
        }
      }
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }
  
  resend = (values, formikBag) => {
    if (!values.email || formikBag.errors.email) {
      this.setState({showEmailError: true});
      formikBag.validateForm();
      return false;
    }
    this.setState({isSubmitting: true, serverError: null});
    request(
      `${process.env.RAZZLE_API}/2/account/password/request`,
      {
        method: 'POST',
        body: JSON.stringify({email: values.email})
      }
    ).then((response) => {
      this.setState({isResent: true, isSubmitting: false});
      gtmPush("myAudi", "passwordResetSubmit", values.email);
    }).catch((error) => {
      this.setState({serverError: "An error has occured", isSubmitting: false});
    });
  }

  render() {
    const { initialValues } = this.props;
    const { step, submitStatus } = this.state;
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{newPassword: "", confirmPassword: "", email: initialValues?.email || ""}}>
        {(formikBag) => (
          <Form>

            {this.state.isSubmitting && <LoadingOverlay />}

            <p className="aui-headline-4 mb-3"><b>Reset your password</b></p>

            {(step === 1 || step === 3) && <>
              {step === 3 && <p className="mb-3">
                The authentication process has changed and you need to reset your password.
              </p>}
              <p className="mb-5">An email has been sent to {initialValues?.email || "you"} with a verification code.</p>
              
              {this.state.isResent && <p className="aui-color-text-green mb-5">The verification code has been resent.</p>}
              {(this.state.showEmailError || !initialValues.email) && <FormField name="email" label="Email" formikBag={formikBag} showErrors={this.state.showEmailError} onChange={this.props.onChangeEmail} />}
              <FormField name="verificationCode" label="Verification code" formikBag={formikBag} />
              <FormField name="newPassword" type="password" label="New password" autoComplete="new-password" formikBag={formikBag} />
              <FormField name="confirmPassword" type="password" label="Confirm password" autoComplete="new-password" formikBag={formikBag} />
              <p className="mb-5">
                <Button label="Resend code" buttonType="text" onClick={() => { this.resend(formikBag.values, formikBag); }} />
              </p>
              <Button label="Reset Password" type="submit" buttonType="primary" />
              {this.state.serverError && <p className="server-error py-5">{this.state.serverError}</p>}
            </>}

            {step === 2 && submitStatus === 200 && <>
              <p className="mb-5">Your password has been reset.</p>
              <Button label="Login" buttonType="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 2); }} />
            </>}

          </Form>
        )}
      </Formik>
    );
  }
}

ResetPasswordForm.propTypes = {
  goTo: PropTypes.func,
  id: PropTypes.string, // from query string
  token: PropTypes.string // from query string
}

export default ResetPasswordForm;
