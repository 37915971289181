import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Notification extends React.Component {

  constructor(props) {
    super(props);
    this.noteRef = React.createRef();
    this.state = {
      open: false,
      height: 0
    };
    this._closeTimeout = null;
  }

  componentDidMount() {
    window.addEventListener("resize", this.setHeight);
    this.setHeight();
    setTimeout(() => { this.setState({open: true}); }, 50);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setHeight);
    if (this._closeTimeout !== null) {
      clearTimeout(this._closeTimeout);
    }
  }

  setHeight = () => {
    let height = this.noteRef.current.clientHeight;
    this.setState({ height });
  }

  handleClose = () => {
    this.setState({open: false});
    if (typeof this.props.onClose === 'function') {
      let id = this.props.id || this.props.idx;
      this._closeTimeout = setTimeout(() => { this.props.onClose(id); this._closeTimeout = null; }, 800);
    }
  }

  render() {
    let _style = {};
    if (this.state.height > 0) {
      _style = {height: `${this.state.height}px`};
    }
    let Buttons = this.props.buttons || null;
    return (
      <div className={classnames('aui-notification', this.props.className, {'is-open': this.state.open, 'is-closed': !this.state.open})} style={_style}>
        <div className="aui-notification__content" ref={this.noteRef}>
          {this.props.content}
          {this.props.buttons && <Buttons close={this.handleClose} />}
        </div>
        <button type="button" className="aui-notification__close" onClick={this.handleClose}></button>
      </div>
    );
  }
}
Notification.defaultProps = {
  className: "aui-color-white aui-color-text-dark"
};

class Notifications extends React.Component {
  render() {
    return (
      <div className={classnames('aui-notification-container', this.props.className)}>
        {this.props.list && this.props.list.map((item, i) => {
          return (<Notification key={i} idx={i} {...item} />);
        })}
      </div>
    );
  }
}

export default Notifications;
