import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import Disclaimer from '../../../components/Disclaimer';
import LoadingOverlay from '../../../components/LoadingOverlay';
import FormField from '../../../components/FormField';
import Button from '../../../audi-ui-components/Button';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import { validate, formatMobile } from '../../../lib/validation';
import { loginSchema } from '../schema';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';

import {
  LABEL_OPTIN,
  FIELD_CHECKBOX,
  PATH_CONFIRM_EMAIL
} from '../../../constants';

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: props.initialStep || 1,
            isSubmitting: false,
            submitAttempted: false,
            serverError: false,
            confirmationResent: false,
            confirmationResendError: false,
            isFetchingSFUser: false,
            sfUser: false
        }
    }

    componentDidMount() {
        const {query} = this.props;
        if (typeof query.sfid !== "undefined") {
            this.setState({isFetchingSFUser: true});
            request(
                `${process.env.RAZZLE_API}/1/account/profile/${query.sfid}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/plain',
                        'Accept': 'application/json',
                        'Cache-Control': 'no-cache, must-revalidate, private, max-age=0'
                    }
                }
            ).then((response) => {
                this.setState({sfUser: response, isFetchingSFUser: false});
            }).catch((error) => {
                this.setState({isFetchingSFUser: false});
            });
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
      if (this.state.serverError && prevState.serverError != this.state.serverError) {
          document.querySelector('.server-error').scrollIntoView({
              behavior: 'smooth'
          });
      }
    }

    submitForm = (values, formikBag) => {
      this.setState({ serverError: false, isSubmitting: true });
      let payload = {...values, optIn: !!values.optIn, optOut: !values.optIn}; //console.log(payload);
      request(
        `${process.env.RAZZLE_API}/2/account/register`,
        {
          method: 'POST',
          body: JSON.stringify(payload)
        }
      ).then((response) => {
        // this.setState({step: 2, isSubmitting: false});
        gtmPush("myAudi", "registerSubmit", values.email);
        this.props.goTo(PATH_CONFIRM_EMAIL);
      }).catch((error) => {
        this.setState({ isSubmitting: false });
        this.handleServerError(error, formikBag);
      });
    }

    handleServerError = (error, formikBag) => {
      let msg = "An error has occured";
      if (error.body && error.body.modelState) {
        formikBag.setErrors(error.body.modelState);
        msg = false;
      } else if (error.body && error.body.message) {
        msg = error.body.message;
      }
      this.setState({isSubmitting: false, serverError: msg});
    }

    validateForm = (values) => {
      return validate(values, loginSchema, {format: "firstError", fullMessages: false});
    }
    
    resendConfirmationEmail = (email) => {
      this.setState({confirmationResent: false, confirmationResendError: false, isSubmitting: true});
      request(
        `${process.env.RAZZLE_API}/2/account/email/request`,
        {
          method: 'POST',
          body: JSON.stringify({email})
        }
      ).then((response) => {
        this.setState({confirmationResent: true, isSubmitting: false});
      }).catch((error) => {
        // TODO: handle this
        this.setState({confirmationResendError: true, isSubmitting: false});
      });
    }

  render() {
    const { step, sfUser } = this.state;
    let vals = this.props.initialValues;
    if (sfUser) {
        vals = {
            email: sfUser.email,
            firstName: sfUser.firstName,
            lastName: sfUser.lastName,
            mobile: sfUser.mobile,
            postcode: sfUser.postcode,
            password: "",
            confirmPassword: ""
        };
    }
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={vals} enableReinitialize>
        {(formikBag) => (
          <Form>

            {(this.state.isSubmitting || this.state.isFetchingSFUser) && <LoadingOverlay />}

            <p className="aui-headline-4 mb-3"><b>Register your account</b></p>

            {step === 1 && <div>
              <FormField name="email"
                label="Email"
                autoComplete="off"
                formikBag={formikBag}
                onChange={this.props.onChangeEmail}
                description="Tip: Please use the same email you used when purchasing your vehicle*"
              />
              <FormField name="firstName" label="First name" autoComplete="off" formikBag={formikBag} />
              <FormField name="lastName" label="Last name" autoComplete="off" formikBag={formikBag} />
              <FormField name="mobile" label="Mobile phone" autoComplete="off" formatValue={formatMobile} formikBag={formikBag} />
              <FormField name="postcode" label="Postcode" autoComplete="off" formikBag={formikBag} />
              <FormField name="password" type="password" label="Password" autoComplete="new-password" formikBag={formikBag} />
              <FormField name="confirmPassword" type="password" label="Confirm password" autoComplete="new-password" formikBag={formikBag} />
              <FormField name="optIn" fieldType={FIELD_CHECKBOX} label={LABEL_OPTIN} formikBag={formikBag} wrapperClassName="mb-5 py-3" />
              <div className="mb-7">
                <Button label="Register" type="submit" buttonType="primary" />
                {this.state.serverError && <p className="server-error py-3">{this.state.serverError}</p>}
              </div>
              <p className="aui-small py-3 mb-5">
                *In order to link your vehicle to your myAudi Australia account and verify your eligibility for all Audi Experience has to offer, please enter the same email used when purchasing your vehicle.
                Audi Experience membership is complimentary for Audi owners during their vehicle’s manufacturer warranty period (3 years for vehicles with an original new car warranty start date that occurred prior to 1 January 2022 or 5 years for vehicles with an original new car warranty start date that occurred on or after 1 January 2022), excluding those used for commercial hire, or as part of a fleet.</p>
              <Disclaimer />
            </div>}

            {step === 2 && <div>
              <p className="mb-3"><b>Thanks, you're almost done.</b></p>
              <p className="mb-3">We've sent an email to {formikBag.values.email}.</p>
              <p className="mb-7">Please check your inbox and follow the instructions to complete your registration. If you don't receive an email within 10 minutes, please check the email you have entered for sign up to ensure it is correct.</p>
              <p className="mb-3"><Button type="button" onClick={() => { this.resendConfirmationEmail(formikBag.values.email); }} variant="secondary">Resend</Button></p>
              {this.state.confirmationResent && <p className="aui-color-text-green"><IconSelect small style={{verticalAlign: "middle"}} /> Email resent</p>}
              {this.state.confirmationResendError && <p className="aui-color-text-red"><IconCaution small style={{verticalAlign: "middle"}} /> There was a problem sending the email.</p>}
            </div>}

          </Form>
        )}
      </Formik>
    );
  }
}

RegisterForm.propTypes = {
  onChangeEmail: PropTypes.func,
  initialValues: PropTypes.object,
  initialStep: PropTypes.number,
}

export default RegisterForm;
