import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Radio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            isFocused: false
        };
    }

    componentDidMount() {
        // prevent browser autofill by rendering input after component mounts
        this.setState({mounted: true});
    }

  handleChange = (e) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e.target.value, e);
    }
  }

  handleFocus = (e) => {
    this.setState({isFocused: true});
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  handleBlur = (e) => {
    this.setState({isFocused: false});
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  render() {
    const {name, label, value, checked, disabled, isInvalid, invalidMessage, onChange, theme} = this.props;
    return(
      <label style={this.props.style} className={classnames(
        "aui-radio",
        `${this.props.className}`,
        {
          "is-checked": !!checked,
          "is-focused": this.state.isFocused,
          "is-disabled": disabled,
          "is-invalid": isInvalid,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        {this.state.mounted && <input className="aui-radio__input" name={name} type="radio" value={value} checked={!!checked} disabled={disabled} onChange={this.handleChange} />}
        {label && <span className="aui-radio__label">{label}</span>}
        <span className="aui-radio__box"><span className="aui-radio__tick"></span></span>
        {isInvalid && invalidMessage && <span className="aui-radio__error">{invalidMessage}</span>}
      </label>
    );
  }

}

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_radio-themes.scss
};

Radio.defaultProps = {
  value: false,
  style: {},
  className: ''
}

export default Radio;
