import { request } from '../lib/apiRequestWrapper';
import moment from 'moment';
import { SF_DATE_FORMAT, JELLYBEAN_MAP } from '../constants';

export const SET_LIST = 'VEHICLES/SET_LIST';
export const SET_MERCH = 'VEHICLES/SET_MERCH';
const SET_VEHICLE = 'VEHICLES/SET_VEHICLE';
const SET_LOADING = 'VEHICLES/SET_LOADING';
const SET_MAKES = "TRADEIN/SET_MAKES";
const SET_FAMILIES = "TRADEIN/SET_FAMILIES";

const vehicleDateProps = [
    "purchaseDate",
    "warrantyStartDate", "warrantyEndDate",
    "ownershipStartDate", "ownershipEndDate",
    "aaExtendedWarrantyStartDate", "aaExtendedWarrantyEndDate",
    "aapWarrantyStartDate", "aapWarrantyEndDate",
    "extWarrantyCoverStartDate", "extWarrantyCoverEndDate"
];
const servicePlanDateProps = [
    "planStartDate", "planEndDate",
    "topUpPlanStartDate", "topUpPlanEndDate"
];
const roadsidePlanDateProps = ["issueDate", "policyStartDate", "policyEndDate"];

export function loadVehicles(auth) {
  return (dispatch, getState) => {
    dispatch({ type: SET_LOADING, payload: true });

    return request(`${process.env.RAZZLE_API}/1/account/vehicles`, {}, auth)
      .then(data => {
        let vehicleData = {};
        for (let i = 0; i < data.length; i++) {
            let vehicle = data[i];
            for (let v=0; v<vehicleDateProps.length; v++) {
                let prop = vehicleDateProps[v];
                if (vehicle[prop]) {
                    vehicle[prop] = moment(vehicle[prop], SF_DATE_FORMAT).format("YYYY-MM-DD");
                }
            }
            let servicePlan = vehicle.servicePlan;
            for (let s=0; s<servicePlanDateProps.length; s++) {
                let prop = servicePlanDateProps[s];
                if (servicePlan[prop]) {
                    servicePlan[prop] = moment(servicePlan[prop], SF_DATE_FORMAT).format("YYYY-MM-DD");
                }
            }
            vehicle.servicePlan = servicePlan;
            vehicleData[vehicle.vin] = vehicle;
        }
        // console.log(vehicleData);
        dispatch({ type: SET_LIST, payload: vehicleData });
        dispatch({ type: SET_LOADING, payload: false });
        
        // get jellybeans
        for (let i = 0; i < data.length; i++) {
          let vin = data[i].vin;
          let vehicle = vehicleData[vin];
          dispatch({type: SET_VEHICLE, payload: vehicle, vin: vin});
          fetch(
              `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
              {
                headers: {
                  'Accept': 'application/json',
                  'Cache-Control': 'no-cache',
                  'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({vin: vin, view: "n3c14aut", mimeType: "image/png", width: 200})
              }
          ).then(response => {
            let ok = response.status >= 200 && response.status < 300;
            let contentType = response.headers.get('Content-Type') || '';
            // return response.json().then(r => { return r; }).catch(() => { return response; });
            // json response
            if (contentType.includes('application/json')) {
              if (ok) { return response.json().catch(() => response); }
              // error
              return response.json().then((body) => {
                return Promise.reject({
                  status: response.status,
                  body,
                });
              });
            }
            // not json response
            return Promise.reject({
              status: response.status,
              body: response.body
            });
          }).then((result) => {
              // console.log(result);
              vehicle.jellybean = result.imageUrl;
              dispatch({type: SET_VEHICLE, payload: vehicle, vin: vin});
          }).catch((error) => {
            // placeholder jellybean
            let jKeys = Object.keys(JELLYBEAN_MAP);
            let name = vehicle.modelNameSD.toLowerCase();
            let filename = false;
            for (let i=0; i<jKeys.length; i++) {
              let jKey = jKeys[i];
              let jStrings = JELLYBEAN_MAP[jKey];
              for (let s=0; s<jStrings.length; s++) {
                let jString = jStrings[s];
                if (name.indexOf(jString) > -1) {
                  filename = jKey;
                  break;
                }
              }
              if (filename) { break; }
            }
            if (filename) {
              vehicle.jellybean = `${process.env.PUBLIC_PATH}assets/jellybeans/${filename}.png`;
              dispatch({type: SET_VEHICLE, payload: vehicle, vin: vin});
            }
          });
        }
        // end get jellybeans
      }).catch(error => {
        console.error(error);
        dispatch({ type: SET_LOADING, payload: false });
      });
  };
}

export function setVehicle(vehicle) {
    return {type: SET_VEHICLE, payload: vehicle, vin: vehicle.vin};
}

export function getShopItems(vin) {
  return (dispatch, getState) => {

    return request(
      `${process.env.RAZZLE_API}/1/account/vehicles/${vin}/merchandise`
    ).then(response => {
      var products = response.data.products.edges;
      var filteredProducts = [];
      var usedTitles = [];
      if (products && products.length) {
        for (let i=0; i<products.length; i++) {
          let p = products[i].node;
          if (products.length < 4 || usedTitles.indexOf(p.title) === -1) {
            filteredProducts.push(p);
            usedTitles.push(p.title);
          }
          if (filteredProducts.length >= 3) {
            break;
          }
        }
      }
      dispatch({ type: SET_MERCH, payload: filteredProducts, vin: vin });
    }).catch(error => {
      console.error(error);
    });

  }
}

export function rbGetMakes() {
  return (dispatch, getState) => {
    request(
      `${process.env.RAZZLE_API}/1/services/vehicle/makes`,
      {
        method: "POST"
      }
    ).then((res) => {
      let makes = res.map(m => { return {label: m.description, value: m.makecode, latestyear: m.latestyear}});
      dispatch({type: SET_MAKES, payload: makes});
    }).catch((error) => {
      console.error(error);
    });
  };
}

export function rbGetFamilies(makecode) {
  return (dispatch, getState) => {
    request(
      `${process.env.RAZZLE_API}/1/services/vehicle/${makecode}/families`,
      {
        method: "POST"
      }
    ).then((res) => {
      let fams = res.map(m => { return {label: m.description, value: m.familycode, latestyear: m.latestyear}});
      dispatch({type: SET_FAMILIES, payload: fams, makecode: makecode});
    }).catch((error) => {
      console.error(error);
    });
  };
}

const defaultState = {
  isLoading: false,
  vehicleByVin: {},
  merchandiseByVin: {},
  rbMakes: null,
  rbFamilies: {}
};

export function vehiclesReducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LOADING:
      return Object.assign({}, state, {
        isLoading: action.payload,
      });

    case SET_LIST:
      return Object.assign({}, state, {
        vehicleByVin: action.payload,
      });

    case SET_VEHICLE:
      return Object.assign({}, state, {
          vehicleByVin: Object.assign({}, state.vehicleByVin, {
              [action.vin]: action.payload
          })
      });

    case SET_MERCH:
      let m = Object.assign({}, state.merchandiseByVin, {[action.vin]: action.payload});
      return Object.assign({}, state, {
        merchandiseByVin: m,
      });

    case SET_MAKES:
      return Object.assign({}, state, {
        rbMakes: action.payload
      });

    case SET_FAMILIES:
      return Object.assign({}, state, {
        rbFamilies: Object.assign({}, state.rbFamilies, {
          [action.makecode]: action.payload
        })
      });

    default:
      return state;
  }
}
