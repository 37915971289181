import React, { useState, useEffect, useRef } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import cn from 'classnames';
import WordMark from '../WordMark';
import IconUser from '../../audi-ui-components/icons/User';
import IconLogin from '../../audi-ui-components/icons/Login';
import IconArrowUp from "../../audi-ui-components/icons/ArrowUp";
import MainNavList from "./MainNavList";
import JellybeanLink from "./JellybeanLink";
import {
  PATH_HOME,
  PATH_LOGIN,
  PATH_REGISTER,
  PATH_PROFILE,
  PATH_VEHICLES,
  PATH_FINANCE,
  MAINTENANCE
} from '../../constants';

import { connect } from 'react-redux';
import { logout } from '../../redux/Profile';
const mapStateToProps = state => {
  return {
    isAuthed: state.profile.isAuthed,
    isLoadingProfile: state.profile.isLoading,
    firstName: state.profile.data ? state.profile.data.firstName : '',
    vehicleByVin: state.vehicles.vehicleByVin,
    vins: state.vehicles.vehicleByVin ? Object.keys(state.vehicles.vehicleByVin) : [],
    // hasFinance: Boolean(
    //   state.profile.isAuthed && state.profile.data && state.profile.data.vwfsId
    // ),
    // preferredServiceLocation: state.profile.preferredServiceLocation,
    // serviceId: state.profile.preferredServiceLocation ? state.profile.preferredServiceLocation.id : null,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    logout: () => { dispatch(logout()); },
  };
};

const Header = ({
  isAuthed,
  isLoadingProfile,
  firstName,
  vehicleByVin,
  vins,
  location,
  logout
}) => {

  const [mainOpen, setMainOpen] = useState(false);
  const [mainOpening, setMainOpening] = useState(false);
  const [mainClosing, setMainClosing] = useState(false);
  const toggleMainNav = () => {
    if (typeof window !== "undefined") {
      if (mainOpen) {
        // document.body.classList.remove("mobile-nav-open");
        setMainClosing(true);
        setTimeout(()=>{ setMainClosing(false); }, 500);
      } else {
        // document.body.classList.add("mobile-nav-open");
        setMainOpening(true);
        setTimeout(()=>{ setMainOpening(false); }, 500);
      }
    }
    setMainOpen(!mainOpen);
    setUserOpen(false);
  };

  const [userOpen, setUserOpen] = useState(false);
  const toggleUserNav = () => {
    setUserOpen(!userOpen);
    setMainOpen(false);
  };
  
  const mainNavRef = useRef(null);
  const userNavRef = useRef(null);
  const handleClickOutside = (e) => {
    if (mainOpen && !mainNavRef.current?.contains(e.target)) {
      setMainOpen(false);
    }
    if (userOpen && !userNavRef.current?.contains(e.target)) {
      setUserOpen(false);
    }
  }
  useEffect(() => {
    if (mainOpen || userOpen) {
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [mainOpen, userOpen]);
  
  useEffect(() => {
    setUserOpen(false);
    setMainOpen(false);
  }, [location]);

  return (
    <header className={cn("header", {"main-open": mainOpen, "user-open": userOpen})}>
      <div className="outer-wrapper aui-color-white">
        <div className="header-inner content-wrapper">
          
          <div className="row flex-nowrap">

            {!MAINTENANCE && <div className="col-auto d-large-none nav-toggle-wrapper">
              <button type="button" className={cn('nav-toggle', { open: mainOpen, opening: mainOpening, closing: mainClosing })} onClick={toggleMainNav}>
                <span className="line"></span><span className="line"></span><span className="line"></span>
                <span className="visually-hidden">toggle navigation</span>
              </button>
            </div>}

            <div className="col col-large-auto title-wrapper">
              <Link to={PATH_HOME} className="logo ms-2 ms-xsmall-3 ms-medium-0">
                <WordMark name="myAudi" />
                <span className="visually-hidden">myAudi Australia</span>
              </Link>
            </div>
            
            <div className="col d-none d-large-flex main-nav-desktop">
              <MainNavList />
            </div>

            {!isLoadingProfile && !isAuthed && !MAINTENANCE && (
              <div className="col-auto col-small col-medium-auto login-wrapper">
                <NavLink to={{ pathname: PATH_LOGIN, state: { from: location } }} className="aui-button aui-button--primary">
                  <span className="aui-button__text">Login</span>
                </NavLink>
                <NavLink to={{ pathname: PATH_REGISTER, state: { from: location } }} className="aui-button aui-button--secondary">
                  <span className="aui-button__text">Join</span>
                </NavLink>
              </div>
            )}

            {!isLoadingProfile && isAuthed && !MAINTENANCE && (
              <div className="col-auto col-small col-medium-auto user-wrapper">
                <button type="button" onClick={toggleUserNav} className={cn("user-btn aui-button", {"open": userOpen})}>
                  <span className="aui-button__text-icon"><span className="aui-button__text">{firstName.length < 10 && <span className="d-none d-xlarge-inline">Welcome back, </span>}{firstName}</span><IconArrowUp small /></span>
                </button>
                {vins.length > 0 && vins.slice(0,2).map((vin) => {
                  let vehicle = vehicleByVin[vin];
                  return (
                    <JellybeanLink key={vin} vin={vin} imgUrl={vehicle.jellybean} modelName={vehicle.modelNameSD} />
                  );
                })}
              </div>
            )}

          </div>
          
        </div>
      </div>
      
      <div className={cn('main-nav-wrapper d-large-none', {open: mainOpen})}>
        <nav ref={mainNavRef} className={cn('main-nav__panel', {open: mainOpen})}>
          <div className="main-nav__header-back"></div>
          <MainNavList />
        </nav>
        <div className={cn('main-nav__overlay', {open: mainOpen})}></div>
      </div>
      
      <div className={cn('user-nav-wrapper', {open: userOpen})}>
        <nav ref={userNavRef} className={cn('user-nav__panel', {open: userOpen})}>
          <div className="">
            <ul className="user-navigation">
              <li>
                <Link to={PATH_PROFILE}>Profile</Link>
              </li>
              <li className="user-nav__vehicles">
                {vins.length > 0 && vins.slice(0,4).map((vin) => {
                  let vehicle = vehicleByVin[vin];
                  return (
                    <Link key={vin} className="nav-vehicle aui-button" to={`${PATH_VEHICLES}/${vin}`}>
                      <span>Audi {vehicle.modelNameSD} {vehicle.modelYear}</span>
                      <span className="rego">{vehicle.regoNo}</span>
                      {vehicle.jellybean && <img src={vehicle.jellybean} alt="" />}
                    </Link>
                  );
                })}
                {vins.length >= 3 && <Link to={PATH_VEHICLES}>See all vehicles</Link>}
                <Link to={`${PATH_VEHICLES}/register`} className="d-block aui-button text-end">+ Add a vehicle</Link>
              </li>
              <li>
                <Link to={PATH_FINANCE}>Audi Financial Services</Link>
              </li>
              <li>
                <button type="button" onClick={() => { toggleUserNav(); logout(); }}>
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </nav>
        <div className={cn('user-nav__overlay', {open: userOpen})}></div>
      </div>
      
    </header>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
