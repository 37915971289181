import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getVehicleFallbackThumb } from '../../../lib/vehicleHelpers';
import { request } from '../../../lib/apiRequestWrapper';
import { PATH_VEHICLES, MEDIASERVICE } from '../../../constants';

const VehicleListItem = ({ vin, imageUrl, modelNameSD, modelYear, regoNo }) => {
  
  const [img, setImg] = useState(imageUrl ? imageUrl : getVehicleFallbackThumb(modelNameSD));
  
  useEffect(() => {
    if (!imageUrl) {
      request(
          `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
          {
            method: "POST",
            body: JSON.stringify({vin: vin, view: "myaun1t", mimeType: "image/jpg", width: 440})
          }
      ).then((res) => {
          // console.log(res);
          setImg(res.imageUrl);
      }).catch((error) => {
        //
      });
    }
  }, [vin]);
  
  return (
    <div className="col-12 col-xsmall-6 col-medium-4">
      <Link className="vehicle" to={`${PATH_VEHICLES}/${vin}`} style={{backgroundImage: `url("${img}")`}}>
        <div className="vehicle-text p-5">
          <p className="aui-headline-5">
            <b>{modelNameSD} {modelYear}</b>
          </p>
          <p className="">
            {regoNo}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default VehicleListItem;
