import React from 'react';
import {connect} from 'react-redux';
import { Formik, Form } from 'formik';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import {
  updateVWFSPreferences,
} from '../../../redux/Profile';
import { gtmPush } from '../../../lib/gtm';
import {FIELD_SWITCH} from '../../../constants';

const mapStateToProps = state => {
  return {
    profile: state.profile.data,
    isAuthed: state.profile.isAuthed,
    isLoadingProfile: state.profile.isLoading,
    vwfsProfile: state.profile.vwfsData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    updateVWFSPreferences: (formData, cb, errorCb) => {dispatch(updateVWFSPreferences(formData, cb, errorCb));},
  }
}

class PreferenceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            error: false
        }
    }

    handleSubmit = (values, formikBag) => {
        const {profile} = this.props;
        this.setState({ isSaved: false, error: false, isUpdating: true });
        this.props.updateVWFSPreferences(
            values,
            () => {
                formikBag.resetForm({ values });
                this.setState({ isSaved: true, isUpdating: false });
                gtmPush(
                  "form",
                  "submitSuccess",
                  "preferenceFinanceMyAudi",
                  Object.assign({}, values, {email: profile.email, sfid: profile.id})
                );
            },
            (error) => {
                if (error.body && error.body.modelState) {
                    let errors = {};
                    for (let key in error.body.modelState) {
                        if (!Object.hasOwnProperty(key)) {
                            errors[key] = error.body.modelState[key][0];
                        }
                    }
                    formikBag.setStatus(errors);
                }
                this.setState({ error: true, isUpdating: false });
            }
        );
    }

    render() {
        if (!this.props.vwfsProfile) {
            return (
                <div className={this.props.className}>
                </div>
            );
        }
        return (
            <div className="loading-wrapper">
                {(this.props.isLoadingProfile || this.state.isUpdating) &&
                    <LoadingOverlay type="overlay" bgColour={this.props.loadingBgColour} />
                }

                <Formik onSubmit={this.handleSubmit} initialValues={this.props.vwfsProfile.preferences || {}}>
                    {(formikBag) => (
                        <Form className={this.props.className}>
                            <FormField
                                name="vwfsEmailOptIn"
                                fieldType={FIELD_SWITCH}
                                rightLabel={(<b>Audi Finance marketing emails</b>)}
                                formikBag={formikBag}
                            />
                            <FormField
                                name="vwfsPostalOptIn"
                                fieldType={FIELD_SWITCH}
                                rightLabel={(<b>Audi Finance marketing mails</b>)}
                                formikBag={formikBag}
                            />
                            <Button buttonType="primary" label="Update Finance preferences" type="submit" disabled={!formikBag.dirty} />
                            {(this.state.isSaved && !formikBag.dirty) &&
                                <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                            }
                            {this.state.error &&
                                <span className="aui-color-text-red px-3"><IconCaution small /> Not saved</span>
                            }
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceForm);
