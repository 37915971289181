import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M19.1,11 C19.1,11 17.3,13.1 15,13.1 C15,20.6 19,22.9 19,22.9 C19,22.9 22.9,20.2 22.9,13.1 C20.8,13.1 19.1,11 19.1,11 Z"/>
          <path d="M13.5,10.5 L10.5,10.5 C9.4,10.5 8.5,9.6 8.5,8.5 L8.5,5.5 C8.5,4.4 9.4,3.5 10.5,3.5 L13.5,3.5 C14.6,3.5 15.5,4.4 15.5,5.5 L15.5,8.5 C15.5,9.6 14.6,10.5 13.5,10.5 Z"/>
          <path d="M15,21.5 L5.5,21.5 L5.5,15 C5.5,15 5.4,13.5 6.7,13.5 L12,13.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M38.5,20 C38.5,20 35.5,23.8 31.5,23.8 C31.5,37 38.5,41 38.5,41 C38.5,41 45.5,36.3 45.5,23.8 C41.6,23.8 38.5,20 38.5,20 Z"/>
        <path d="M27.5,20.5 L20.5,20.5 C18.9,20.5 17.5,19.1 17.5,17.5 L17.5,10.5 C17.5,8.9 18.9,7.5 20.5,7.5 L27.5,7.5 C29.1,7.5 30.5,8.9 30.5,10.5 L30.5,17.5 C30.5,19.1 29.1,20.5 27.5,20.5 Z"/>
        <path d="M33,40.5 L11.5,40.5 L11.5,27.2 C11.5,27.2 11.7,24.4 15,24.4 L27,24.4"/>
      </g>
    </svg>
  );
};
