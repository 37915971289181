import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M6,19.5 L19,19.5"/>
          <path d="M17,11 L12.5,15.5 L8,11 M12.5,4 C12.5,11.6666667 12.5,15.5 12.5,15.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M11,38.5 L38,38.5"/>
        <path d="M24.5,9 L24.5,31.5 M33.5,22.5 L24.5,31.5 L15.5,22.5"/>
      </g>
    </svg>
  );
};
