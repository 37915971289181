import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path stroke="currentColor" d="M19.5 20L19.5 22.5 3.5 22.5 3.5 2.5 15 2.5 19.5 7 19.5 10"/>
          <path stroke="currentColor" d="M14.5 2.5L14.5 7.5 19.5 7.5"/>
          <path d="M6.028,18 L6.028,12.208 L8.316,12.208 C10.1,12.208 10.74,12.952 10.74,14.008 C10.74,15.064 10.06,15.84 8.276,15.84 L6.852,15.84 L6.852,18 L6.028,18 Z M8.14,12.88 L6.852,12.88 L6.852,15.168 L8.14,15.168 C9.476,15.168 9.9,14.752 9.9,14.008 C9.9,13.264 9.476,12.88 8.14,12.88 Z M12.164,18 L12.164,12.208 L14.468,12.208 C16.5,12.208 17.636,13.336 17.636,15.104 C17.636,16.808 16.452,18 14.468,18 L12.164,18 Z M14.524,12.88 L12.988,12.88 L12.988,17.328 L14.524,17.328 C16.044,17.328 16.796,16.4 16.796,15.104 C16.796,13.808 16.044,12.88 14.524,12.88 Z M19.18,18 L20.004,18 L20.004,15.536 L23.012,15.536 L23.012,14.864 L20.004,14.864 L20.004,12.88 L23.516,12.88 L23.516,12.208 L19.18,12.208 L19.18,18 Z" fill="currentColor"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M12.75,40 L12.75,32.036 L15.896,32.036 C18.349,32.036 19.229,33.059 19.229,34.511 C19.229,35.963 18.294,37.03 15.841,37.03 L13.883,37.03 L13.883,40 L12.75,40 Z M15.654,32.96 L13.883,32.96 L13.883,36.106 L15.654,36.106 C17.491,36.106 18.074,35.534 18.074,34.511 C18.074,33.488 17.491,32.96 15.654,32.96 Z M21.6100769,40 L21.6100769,32.036 L24.7780769,32.036 C27.5720769,32.036 29.1340769,33.587 29.1340769,36.018 C29.1340769,38.361 27.5060769,40 24.7780769,40 L21.6100769,40 Z M24.8550769,32.96 L22.7430769,32.96 L22.7430769,39.076 L24.8550769,39.076 C26.9450769,39.076 27.9790769,37.8 27.9790769,36.018 C27.9790769,34.236 26.9450769,32.96 24.8550769,32.96 Z M31.6801538,40 L31.6801538,32.036 L37.6421538,32.036 L37.6421538,32.96 L32.8131538,32.96 L32.8131538,35.688 L36.9491538,35.688 L36.9491538,36.612 L32.8131538,36.612 L32.8131538,40 L31.6801538,40 Z" fill="currentColor"/>
        <path stroke="currentColor" d="M30.5 2.5L30.5 13.5 41.5 13.5"/>
        <path stroke="currentColor" d="M41.5 13.5L41.5 45.5 7.5 45.5 7.5 2.5 30.5 2.5z"/>
      </g>
    </svg>
  );
};
