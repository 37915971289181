export function formatMobile(value) {
  let _v = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
  let zip = _v.substring(0,4);
  let middle = _v.substring(4,7);
  let last = _v.substring(7,10);
  if(_v.length > 7) {
    return `${zip} ${middle} ${last}`;
  }
  else if(_v.length > 4) {
    return `${zip} ${middle}`;
  }
  else if(_v.length > 0) {
    return `${zip}`;
  }
  else {
    return '';
  }
}

export function formatNumber(value) {
    return value.replace(/\D/g, '');
}

// format a cc or amex number with spaces
// returns a string
export function formatCreditCard(value) {
    if (value === '') { return ''; }
    let rawdata = value.replace(/\D/g,'');
    if (rawdata.length < 5) {
        return rawdata;
    }
    let formatted = '';
    let firstTwo = rawdata.substring(0,2);
    if (firstTwo === '34' || firstTwo === '37') {
        // amex
        rawdata = rawdata.substring(0,15);
        if (rawdata.length < 11) {
            formatted = rawdata.replace(/\b(\d{4})(\d{1,6})\b/, '$1 $2');
        } else {
            formatted = rawdata.replace(/\b(\d{4})(\d{6})(\d{1,5})\b/, '$1 $2 $3');
        }
    } else {
        // normal cc
        rawdata = rawdata.substring(0,16);
        let pieces = rawdata.match(/\d{1,4}/g);
        formatted = pieces ? pieces.join(' ') : '';
    }
    return formatted;
};

export function formatCCExpire(string) {
    return string.replace(
        /[^0-9]/g, '' // To allow only numbers
    ).replace(
        /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
        /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
}
