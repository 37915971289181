import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from '../audi-ui-components/Checkbox';
import Fieldset from '../audi-ui-components/Fieldset';
import _pull from 'lodash/pull';

const CheckboxGroup = ({ name, label, value, delimeter, maxLength, onChange, options, ...rest }) => {

    return (
        <Fieldset title={label} {...rest}
            fields={options.map(opt => {
                let checks = value || [];
                if (value && delimeter) {
                  checks = value.split(delimeter);
                }
                let optValue = opt.value || opt;
                let optLabel = opt.label || opt;
                let isChecked = checks.indexOf(optValue) > -1;
                return (
                  <Checkbox
                      name={name}
                      label={optLabel}
                      value={optValue}
                      checked={isChecked}
                      onChange={() => {
                        let newValue = checks;
                        if (isChecked) {
                            _pull(newValue, optValue);
                        } else {
                          if (!maxLength || maxLength > checks.length) {
                            newValue.push(optValue);
                          }
                        }
                        if (delimeter) {
                            newValue = newValue.join(delimeter);
                        }
                        onChange(newValue);
                      }}
                      
                  />
              );
          })}
          fieldType="radios" />
    );
}

export default CheckboxGroup;
