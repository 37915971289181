import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import Button from '../../audi-ui-components/Button';
import FormField from '../../components/FormField';
import Media from '../../components/Media';
import { validate, defaultSchema } from '../../lib/validation';
import {
  obfuscatePhone,
  formatRegistrationCode,
} from './helpers';
import { request } from '../../lib/apiRequestWrapper';
import cookie from '../../lib/cookie';
import { gtmPush } from '../../lib/gtm';
import { loadProfile } from '../../redux/Profile';
import Crumb from '../../components/Crumb';

import { PATH_FINANCE } from '../../constants';

const mapStateToProps = state => {
  return {
  };
};

const mapDispatchToProps = dispatch => {
  return {
    loadProfile: (cb, errorCb, auth) => {dispatch(loadProfile(cb, errorCb, auth));},
  }
}

class FinanceRegisterPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      requestData: {},
      isLoading: false,
      errorMessage: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleRequestSubmit = (values, formikBag) => {
    this.setState({isLoading: true, errorMessage: null});
    let auth = JSON.parse(cookie.getItem('auth'));
    return request(
      `${process.env.RAZZLE_API}/1/vwfs/registration/new/create`,
      {
        method: "POST",
        body: JSON.stringify(values),
      },
      auth
    ).then((response) => {
      this.setState({isLoading: false, requestData: values, step: 2});
      gtmPush("myAudi", "financeVerificationSent", "finance");
    }).catch((error) => {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setStatus(errors);
      }
      this.setState({isLoading: false, errorMessage: error.message});
    });
  }

  handleVerifySubmit = (values, formikBag) => {
    this.setState({isLoading: true, errorMessage: null});
    let _data = Object.assign({}, values, this.state.requestData);
    let auth = JSON.parse(cookie.getItem('auth'));
    return request(
      `${process.env.RAZZLE_API}/1/vwfs/registration/new/confirm`,
      {
        method: "POST",
        body: JSON.stringify(_data),
      },
      auth
    ).then(() => {
      gtmPush("myAudi", "financeLinkSuccess", "finance");
      this.props.loadProfile(); // this should trigger a redirect
      // TODO: test
    }).catch((error) => {
      console.error(error);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setStatus(errors);
      }
      this.setState({isLoading: false, errorMessage: error.message});
    });
  }

  validateRequest = (values) => {
    let schema = {
      registrationCode: {...defaultSchema.requiredField},
      mobilePhone: {
        ...defaultSchema.requiredField,
        ...defaultSchema.mobile
      }
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }

  validateVerify = (values) => {
    let schema = {
      oneTimePassword: {...defaultSchema.requiredField}
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }

  render() {
    return (
      <div className="page-wrapper">
        <header className="finance__header mb-7">
          <Media type="image" url={`${process.env.PUBLIC_PATH}assets/1920x1080_InlineMediaGallery_AQ8_D_181008.jpg`} x="50%" y="50%" altText="Hero Image" />
          <div className="content-wrapper">
            <Crumb />
            <div className="finance__header-inner"><p className="aui-headline-3 indent py-7">Welcome to Audi Financial Services</p></div>
          </div>
        </header>

        <div className="content-wrapper pb-7">

          {(!this.props.location.query || !this.props.location.query.regnum) && <>
            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-small-10 col-medium-6">
                <p className="aui-headline-4 mb-7"><b>Linking your Audi Financial Services and myAudi Australia accounts.</b></p>
              </div>
            </div>
            <div className="row">
              <div className="col-small-1"></div>
              <div className="col-small-6">
                <p className="">
                  Before accessing your Audi Financial Services account, we need to confirm your identity.
                  To do this, you will need your contract number and registration code, which were sent in your welcome letter.
                  Please also have your mobile phone with you, to assist with the verification process.
                  Please contact Customer Service at Audi Financial Services if you do not have access to your registered mobile phone.
                </p>
              </div>
              <div className="col-small-1"></div>
              <div className="col-small-3">
                <div className="section sectionQuote">
                  <aside className="quote">
                    <div className="quote-inner">
                      <p>
                        Customer Service: <a href="tel:1300734567"><b style={{whiteSpace:'nowrap'}}>1300 734 567</b></a>
                        <br />Opening Hours: <b style={{whiteSpace:'nowrap'}}>Mon-Fri (9am-5pm)</b>
                      </p>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </>}

          {this.props.location.query && this.props.location.query.regnum && <p className="indent mb-7">
            You should have received your registration code to your registered mobile phone number. Please enter it below to access your Audi Financial Services account.
          </p>}

          <hr className="indent my-7" />

          {this.state.step === 1 && <div className="row">
            <div className="col-small-1"></div>
            <div className="col-small-6">
              <div className="finance__loader-wrapper">
                <Formik validate={this.validateRequest} onSubmit={this.handleRequestSubmit} initialValues={{registrationCode: "", mobilePhone: ""}}>
                  {(formikBag) => (
                    <Form className="">
                      <FormField name="registrationCode"
                        label="Registration code"
                        formatValue={formatRegistrationCode}
                        formikBag={formikBag}
                        description="This is a 14 character mix of numbers and letters which can be found in the top right corner of your welcome letter, or via text message if you requested one."
                      />
                      <FormField name="mobilePhone"
                        label="Mobile number"
                        formikBag={formikBag}
                        description="This is the mobile number you registered when applying for finance which can be found on your contract."
                      />
                      <br />
                      {this.state.errorMessage && <p className="finance__error">{this.state.errorMessage}</p>}
                      <Button label="Send verification code" buttonType="primary" type="submit" />
                    </Form>
                  )}
                </Formik>
              </div>
              <br /><br />
              <p>Don{`'`}t have your Registration code? <Link to={PATH_FINANCE + "/request-registration-code"} className="aui-textlink">Request a new one</Link>.</p>
            </div>
          </div>}

          {this.state.step === 2 && <div className="row">
            <div className="col-small-1"></div>
            <div className="col-small-6">
              <p className="aui-headline-4 mb-7">One last thing!</p>
              <p className="mb-7">
                You are one step away from being able to access your Audi Financial Services account.
                Simply enter the verification code sent to {this.state.requestData.hasOwnProperty('mobilePhone') && obfuscatePhone(this.state.requestData.mobilePhone)}. Please allow up to 2 minutes to receive the verification code.
              </p>
              <Formik validate={this.validateVerify} onSubmit={this.handleVerifySubmit} initialValues={{oneTimePassword: ""}}>
                {(formikBag) => (
                  <Form className="login-form">
                    <FormField name="oneTimePassword" label="Verification code" formikBag={formikBag} />
                    <br />
                    {this.state.errorMessage && <p className="finance__error">{this.state.errorMessage}</p>}
                    <Button label="Submit verification code" type="submit" />
                  </Form>
                )}
              </Formik>
            </div>
          </div>}

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FinanceRegisterPage);
