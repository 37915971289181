import React from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import FormField from '../../../components/FormField';
import Button from '../../../audi-ui-components/Button';
import { validate } from '../../../lib/validation';
import { gtmPush } from '../../../lib/gtm';
import { deactivateProfile } from '../../../redux/Profile';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { deactivateSchema } from '../schema';
import { logout } from '../../../redux/Profile';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';

const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        deactivateProfile: (formData, cb, errCb) => { dispatch(deactivateProfile(formData, cb, errCb)); },
        logout: () => { dispatch(logout()) }
    }
}

class DeactivateForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            errorMessage: false
        }
    }

    validateForm = (values) => {
        return validate(values, deactivateSchema, { format: "firstError", fullMessages: false });
    }

    handleSubmit = (values, formikBag) => {
        this.setState({ isUpdating: true, isSaved: false, errorMessage: false });

        this.props.deactivateProfile(
            values,
            () => {
                formikBag.resetForm({});
                this.setState({ isUpdating: false, isSaved: true });
                gtmPush("myAudi", "deactivateAccountSuccess", "profile");
                this.props.logout();
            },
            (error) => {
                if (error.status == 401) {
                    this.setState({ isUpdating: false, errorMessage: 'Incorrect Password.' });
                } else {
                    this.setState({ isUpdating: false, errorMessage: "Not saved" });
                }
            }
        );
    }

    render() {

        return (
            <Formik validate={this.validateForm}
                onSubmit={this.handleSubmit}
                initialValues={{ password: '' }}>

                {(formikBag) => (
                    <Form>


                        {this.state.isUpdating && <LoadingOverlay bgColour={this.props.loadingBgColour} />}

                        <p className="aui-headline-4 mb-3"><b>Deactivate your account.</b></p>
                        <p className="warning-text">Are you sure you want to deactivate your account?</p>


                        {true &&
                            <p>By deactivating your account, you will no longer be able to see, book or modify future events. However, you can always re-register for myAudi Australia and access Audi Experience if your car is still within a factory warranty from Audi.</p>
                        }

                        {   //i am to be used post AE eligibility update
                            false &&
                            <p>By deactivating your account, you will no longer be able to see, book or modify future events. However, you can always re-register for myAudi Australia and access Audi Experience if your car is still within a factory warranty or extended warranty from Audi.</p>
                        }

                        <FormField name="password" label="Password" type="password" formikBag={formikBag} />

                        <Button label="Deactivate now" type="submit" buttonType="primary" />

                        {(this.state.isSaved && !formikBag.dirty) &&
                            <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                        }
                        {this.state.errorMessage &&
                            <span className="aui-color-text-red px-3"><IconCaution small /> {this.state.errorMessage}</span>
                        }
                    </Form>
                )}
            </Formik>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DeactivateForm);
