import React, { useState } from 'react';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';

import { connect } from 'react-redux';
import { disableMFA } from '../../../redux/Profile';
const mapStateToProps = state => {
    return {}
}
const mapDispatchToProps = dispatch => {
    return {
        disableMFA: (cb, errCb) => {dispatch(disableMFA(cb, errCb));}
    }
}

const MFADisableForm = ({ disableMFA, onSuccess }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <div className="loading-wrapper">
      {loading && <LoadingOverlay type="overlay" />}
      <p className="aui-headline-4 mb-5">Disable 2FA</p>
      <p className="mb-5">Are you sure you want to disable 2FA? We strongly recommend to keep it enabled for extra security.</p>
      {error && <p className="mb-5 aui-color-text-red">An error has occured</p>}
      <p className="text-end"><Button variant="primary" onClick={()=>{
        setLoading(true);
        setError(null);
        console.log("test1");
        disableMFA(
          ()=>{onSuccess(); setLoading(false);},
          (err)=>{setError(err); setLoading(false);}
        );
      }}>Yes, disable 2FA</Button></p>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MFADisableForm);
