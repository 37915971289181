import React from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { updateProfile } from '../../../redux/Profile';
import { validate, formatMobile } from '../../../lib/validation';
import { gtmPush } from '../../../lib/gtm';
import { profileSchema } from '../schema';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        isAuthed: state.profile.isAuthed,
        profile: state.profile.data,
        isLoadingProfile: state.profile.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (formData, cb, errCb) => {dispatch(updateProfile(formData, cb, errCb));},
    }
}

class ProfileForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            error: false
        }
    }

    handleSubmit = (values, formikBag) => {
        this.setState({ isSaved: false, error: false, isUpdating: true });
        this.props.updateProfile(
            values,
            () => {
                formikBag.resetForm({ values });
                this.setState({ isSaved: true, isUpdating: false });
                gtmPush("myAudi", "profileUpdateSuccess", "profile");
                if (typeof this.props.cb === 'function') {
                  this.props.cb();
                }
            },
            (error) => {
                if (error.body && error.body.modelState) {
                    let errors = {};
                    for (let key in error.body.modelState) {
                        if (!Object.hasOwnProperty(key)) {
                            errors[key] = error.body.modelState[key][0];
                        }
                    }
                    formikBag.setStatus(errors);
                    this.setState({ error: true, isUpdating: false });
                }
            }
        );
    }

    validateProfile = (values) => {
        return validate(values, profileSchema, { format: "firstError", fullMessages: false });
    }
    validatePhone = (values) => {
        return validate(values, {mobile: profileSchema.mobile}, { format: "firstError", fullMessages: false });
    }

    render() {
        const { profile, theme, phoneOnly, onCancel } = this.props;
        var user = false;
        if (profile) {
            // initial values for Formik
            if (phoneOnly) {
                user = { mobile: profile.mobile };
            } else {
                user = {
                    email: profile.email,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                    mobile: profile.mobile,
                };
            }
        }
        return (
            <div className="loading-wrapper">
                {(this.props.isLoadingProfile || this.state.isUpdating) &&
                    <LoadingOverlay type={user ? "overlay" : "placeholder"} bgColour={this.props.loadingBgColour} />
                }

                {user && <Formik validate={phoneOnly ? this.validatePhone : this.validateProfile} onSubmit={this.handleSubmit} initialValues={user} validateOnMount>
                    {(formikBag) => (
                        <div>
                            {!phoneOnly && <>
                            <FormField name="firstName" label="First name" autoComplete="off" theme={theme} formikBag={formikBag} />
                            <FormField name="lastName" label="Last name" autoComplete="off" theme={theme} formikBag={formikBag} />
                            <FormField name="email" label="Email" autoComplete="off" theme={theme} formikBag={formikBag} />
                            </>}
                            <FormField name="mobile" label="Mobile phone" autoComplete="off" theme={theme} formatValue={formatMobile} formikBag={formikBag} className="profile-mobile-field" />
                            <Button buttonType="primary" label="Update" type="button" onClick={formikBag.handleSubmit} theme={theme} disabled={!formikBag.dirty || this.state.isUpdating} />
                            {typeof onCancel === "function" && <Button label="Cancel" buttonType="secondary" className="ml-2" onClick={onCancel} />}
                            {(this.state.isSaved && !formikBag.dirty) &&
                                <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                            }
                            {this.state.error &&
                                <span className="aui-color-text-red px-3"><IconCaution small /> Not saved</span>
                            }
                        </div>
                    )}
                </Formik>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
