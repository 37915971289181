import React from 'react';
import EditableSection from '../../../components/EditableSection';
import LocationEditSection from './LocationEditSection';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { VWFS_CUSTOMER_ROLES } from '../../../constants';

import IconMainUser from '../../../audi-ui-components/icons/MainUser';

class TabCustomers extends React.Component {
  renderCustomer = customer => {
    const {
      updateCustomerData,
      updateCustomerPhone,
      userRole,
      vwfsId,
    } = this.props;
    var canEdit = userRole === 'P' || vwfsId === customer.identifierId;
    return (
      <div className="col-12 col-medium-6 pt-6" key={customer.identifierId}>
        <div className="row">
          <div className="col-xsmall-2 py-0 py-xsmall-7 pr-4 text-center text-xsmall-right">
            <IconMainUser />
          </div>
          <div className="col-12 col-xsmall-8 px-6 px-xsmall-0">
            <section className="finance__customer py-7 mb-7 ">
              <h4 className="aui-headline-3 mb-5">
                {`${VWFS_CUSTOMER_ROLES[customer.customerRole]}`}:{' '}
                <b>{`${customer.firstName ? customer.firstName : '-'} ${
                  customer.lastName ? customer.lastName : '-'
                }`}</b>
              </h4>
              {customer.location &&
                customer.location.map(location => (
                  <div className="mb-7" key={location.identifierId}>
                    <LocationEditSection
                      isEditable={canEdit}
                      location={location}
                      onSubmit={updateCustomerData}
                      closeOnSuccess
                      customerNumber={customer.identifierId}
                      loadingBgColour="gray90"
                      key={`${location.identifierId}_${location.identifierType}`}
                    />
                  </div>
                ))}
              <EditableSection
                title="Phone"
                subheading
                isEditable={canEdit}
                fields={[
                  {
                    label: 'Mobile phone',
                    name: 'mobilePhone',
                    v: customer.contact ? customer.contact.mobilePhone : '',
                  },
                  {
                    label: 'Home phone',
                    name: 'homePhone',
                    v: customer.contact ? customer.contact.homePhone : '',
                  },
                  {
                    label: 'Business phone',
                    name: 'businessPhone',
                    v: customer.contact ? customer.contact.businessPhone : '',
                  },
                ]}
                schema={{
                  mobilePhone: {
                    presence: {
                      message: "This field is required",
                      allowEmpty: false
                    },
                    format: {
                      pattern: /^04.*$/,
                      message: "Please enter a valid mobile number"
                    }
                  },
                  homePhone: {isPhoneNumber: true},
                  businessPhone: {isPhoneNumber: true}
                }}
                hiddenFields={{
                  customerNumber: customer.identifierId,
                  identifierId: customer.contact
                    ? customer.contact.identifierId
                    : null,
                  identifierType: customer.contact
                    ? customer.contact.identifierType
                    : null,
                }}
                onSubmit={updateCustomerPhone}
                loadingBgColour="gray90"
                closeOnSuccess
              />
            </section>
          </div>
          <div className="col-xsmall-2"></div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <article className="finance__tab-customers  aui-color-gray90">
        {this.props.isLoadingCustomers && !this.props.customers.length && <LoadingOverlay type="placeholder" bgColour="gray90" />}

        <div className="container-fluid px-small-0">
          <div className="row">
            {this.props.customers &&
              this.props.customers.map(customer =>
                this.renderCustomer(customer)
              )}
          </div>
        </div>
      </article>
    );
  }
}

export default TabCustomers;
