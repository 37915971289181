import React from 'react';
import classnames from 'classnames';
import {connect} from 'react-redux';
import { Formik, Form } from 'formik';
import Button from '../../../audi-ui-components/Button';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import FormField from '../../../components/FormField';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { updateProfile } from '../../../redux/Profile';
import { validate } from '../../../lib/validation';
import { gtmPush } from '../../../lib/gtm';
import { OPTS_STATES, FIELD_SELECT } from '../../../constants';
import {homeAddressSchema} from '../schema';

const mapStateToProps = state => {
    return {
        isAuthed: state.profile.isAuthed,
        profile: state.profile.data,
        isLoadingProfile: state.profile.isLoading,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateProfile: (formData, cb, errCb) => {dispatch(updateProfile(formData, cb, errCb));},
    }
}

class HomeAddressForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            error: false
        }
    }

    handleSubmit = (values, formikBag) => {
        this.setState({ isSaved: false, isUpdating: true, error: false });
        this.props.updateProfile(
            values,
            () => {
                formikBag.resetForm({values});
                this.setState({ isSaved: true, isUpdating: false });
                gtmPush("myAudi", "addressUpdateSuccess", "profile");
                if (typeof this.props.cb === 'function') {
                  this.props.cb();
                }
            },
            (error) => {
                if (error.body && error.body.modelState) {
                    let errors = {};
                    for (let key in error.body.modelState) {
                        if (!Object.hasOwnProperty(key)) {
                            errors[key] = error.body.modelState[key][0];
                        }
                    }
                    formikBag.setStatus(errors);
                    this.setState({ error: true, isUpdating: false });
                }
            }
        );
    }

    validateHomeAddress = (values) => {
        return validate(values, homeAddressSchema, {format: "firstError", fullMessages: false});
    }

    render() {
        const {profile, onCancel} = this.props;
        var address = null;
        if (profile) {
            // initial values for Formik
            address = {
                homeAddress: profile.homeAddress,
                homeSuburb: profile.homeSuburb,
                homeState: profile.homeState,
                homePostcode: profile.homePostcode,
                homeCountry: profile.homeCountry
            };
        }
        return (
            <div className="loading-wrapper">
                {(this.props.isLoadingProfile || this.state.isUpdating) &&
                    <LoadingOverlay type={address ? "overlay" : "placeholder"} bgColour={this.props.loadingBgColour} />
                }

                {address && <Formik validate={this.validateHomeAddress} onSubmit={this.handleSubmit} initialValues={address}>
                    {(formikBag) => (
                        <div>
                            <FormField name="homeAddress" label="Street Address" theme={this.props.theme} formikBag={formikBag} />
                            <FormField name="homeSuburb" label="Suburb" theme={this.props.theme} formikBag={formikBag} />
                            <div className="row mx-n5">
                                <div className="col-8 px-5">
                                    <FormField name="homeState" label="State" fieldType={FIELD_SELECT} options={OPTS_STATES} theme={this.props.theme} formikBag={formikBag} />
                                </div>
                                <div className="col-4 px-5">
                                    <FormField name="homePostcode" label="Postcode" theme={this.props.theme} formikBag={formikBag} />
                                </div>
                            </div>
                            <Button buttonType="primary" label="Update" type="button" onClick={formikBag.handleSubmit} theme={this.props.theme} disabled={!formikBag.dirty || this.state.isUpdating} />
                            {typeof onCancel === "function" && <Button label="Cancel" buttonType="secondary" className="ml-2" onClick={onCancel} />}
                            {(this.state.isSaved && !formikBag.dirty) &&
                                <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                            }
                            {this.state.error &&
                                <span className="aui-color-text-red px-3"><IconCaution small /> Not saved</span>
                            }
                        </div>
                    )}
                </Formik>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeAddressForm);
