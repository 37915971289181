import React from 'react';
import classnames from 'classnames';
import { Formik, Form } from 'formik';
import IconSelect from '../../../audi-ui-components/icons/Select';
import IconCaution from '../../../audi-ui-components/icons/Caution';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import Checkbox from '../../../audi-ui-components/Checkbox';
import LoadingOverlay from '../../../components/LoadingOverlay';
import { updatePreferences } from '../../../redux/Profile';
import { gtmPush } from '../../../lib/gtm';
import { FIELD_SWITCH } from '../../../constants';

import { connect } from 'react-redux';
const mapStateToProps = state => {
    return {
        profile: state.profile.data,
        isLoadingProfile: state.profile.isLoading,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        updatePreferences: (formData, cb, errCb) => {dispatch(updatePreferences(formData, cb, errCb));},
    }
}

const optInLabels = {
  "pcProductOptIn": (<span><b>Vehicle Information</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Relevant new and existing Audi models and vehicle innovations.</span></span>),
  "pcNewsOptIn": (<span><b>Audi news</b> (Quarterly)<br /><span style={{fontSize: "85%"}}>The latest general news from Audi.</span></span>),
  "pcOffersOptIn": (<span><b>Offers</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Notification of special offers on Audi products.</span></span>),
  "pcMagazineOptIn": (<span><b>Audi Magazine</b> (Monthly)<br /><span style={{fontSize: "85%"}}>Highlights on Audi{`'`}s lifestyle, culture, sport and ambassador activities.</span></span>),
  "pcExperienceOptIn": (<span><b>Audi Experience</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Audi loyalty program benefits and invitations.</span></span>),
  "pcFoundationOptIn": (<span><b>Audi Foundation</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Audi{`'`}s community and charitable projects.</span></span>),
  "pcEventsOptIn": (<span><b>Audi Events</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Invitations to events including the Audi driving experience and product launches.</span></span>),
  "pcSurveyOptIn": (<span><b>Audi Surveys</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Tell us how we can enhance your experience with Audi products and programs.</span></span>),
  "pcFinancialServiceOptIn": (<span><b>Financial services</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Tailored finance solutions that suit your budget and your new Audi.</span></span>),
  "pcStoreOptIn": (<span><b>Audi Store</b> (Occasional)<br /><span style={{fontSize: "85%"}}>Offers, products, and accessories from Audi Store.</span></span>),
  "pcsmsOptIn": (<span><b>SMS me</b> - <span style={{fontSize: "85%"}}>Timely reminders for relevant events.</span></span>)
};

const PreferenceOption = ({ name, formikBag }) => {
  return (
    <FormField
        name={name}
        fieldType={FIELD_SWITCH}
        rightLabel={optInLabels[name]}
        formikBag={formikBag}
        style={{ lineHeight: 'normal' }}
    />
  );
}

class PreferenceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isUpdating: false,
            isSaved: false,
            error: false
        }
    }

    handleSubmit = (values, formikBag) => {
        const {profile} = this.props;
        this.setState({ isSaved: false, error: false, isUpdating: true });
        this.props.updatePreferences(
            values,
            () => {
                formikBag.resetForm({ values });
                this.setState({ isSaved: true, isUpdating: false });
                gtmPush(
                  "form",
                  "submitSuccess",
                  "preferenceCentreMyAudi",
                  Object.assign({}, values, {email: profile.email, sfid: profile.id})
                );
            },
            (error) => {
                if (error.body && error.body.modelState) {
                    let errors = {};
                    for (let key in error.body.modelState) {
                        if (!Object.hasOwnProperty(key)) {
                            errors[key] = error.body.modelState[key][0];
                        }
                    }
                    formikBag.setStatus(errors);
                }
                this.setState({ error: true, isUpdating: false });
            }
        );
    }
    
    handleToggleAll = (values, setValues) => {
      let newValue = true;
      if (values.pcProductOptIn && values.pcNewsOptIn && values.pcOffersOptIn && values.pcMagazineOptIn && values.pcExperienceOptIn && values.pcFoundationOptIn && values.pcEventsOptIn && values.pcSurveyOptIn && values.pcStoreOptIn) {
        // all are currently checked
        newValue = false;
      }
      setValues(Object.assign({}, values, {
        pcProductOptIn: newValue,
        pcNewsOptIn: newValue,
        pcOffersOptIn: newValue,
        pcMagazineOptIn: newValue,
        pcExperienceOptIn: newValue,
        pcFoundationOptIn: newValue,
        pcEventsOptIn: newValue,
        pcSurveyOptIn: newValue,
        pcFinancialServiceOptIn: newValue,
        pcsmsOptIn: newValue,
        pcStoreOptIn: newValue
      }));
    }

    render() {
        const { profile } = this.props;
        var optIn = false;
        var pcopts = Object.keys(optInLabels);
        if (profile) {
            // initial values for Formik
            optIn = {};
            for (let i = 0; i < pcopts.length; i++) {
                optIn[pcopts[i]] = !!profile[pcopts[i]];
            }
        }
        return (
            <div className="loading-wrapper">
                {(this.props.isLoadingProfile || this.state.isUpdating) &&
                    <LoadingOverlay type={optIn ? "overlay" : "placeholder"} bgColour={this.props.loadingBgColour} />
                }

                {optIn && <Formik onSubmit={this.handleSubmit} initialValues={optIn}>
                    {(formikBag) => (
                        <Form className="mb-7">
                            <h5 className="aui-headline-5 mb-3">Audi Lifestyle</h5>
                            <PreferenceOption name="pcMagazineOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcExperienceOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcFoundationOptIn" formikBag={formikBag} />
                            <h5 className="aui-headline-5 mb-3">Audi Vehicles</h5>
                            <PreferenceOption name="pcProductOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcEventsOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcNewsOptIn" formikBag={formikBag} />
                            <h5 className="aui-headline-5 mb-3">Your Audi</h5>
                            <PreferenceOption name="pcStoreOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcOffersOptIn" formikBag={formikBag} />
                            <PreferenceOption name="pcFinancialServiceOptIn" formikBag={formikBag} />
                            <h5 className="aui-headline-5 mb-3">Audi Feedback</h5>
                            <PreferenceOption name="pcSurveyOptIn" formikBag={formikBag} />
                            <hr />
                            <p className="aui-headline-5 my-5">Preferred contact method</p>
                            <PreferenceOption name="pcsmsOptIn" formikBag={formikBag} />
                            <hr />
                            <p className="my-5">
                            <Checkbox name="all"
                              label={<i>Check/uncheck all</i>}
                              checked={formikBag.values.pcProductOptIn && formikBag.values.pcNewsOptIn && formikBag.values.pcOffersOptIn && formikBag.values.pcMagazineOptIn && formikBag.values.pcExperienceOptIn && formikBag.values.pcFoundationOptIn && formikBag.values.pcEventsOptIn && formikBag.values.pcSurveyOptIn && formikBag.values.pcStoreOptIn}
                              value=""
                              onChange={() => { this.handleToggleAll(formikBag.values, formikBag.setValues); }}
                            />
                            </p>
                            <Button buttonType="primary" label="Update opt-in preferences" type="submit" disabled={!formikBag.dirty || this.state.isUpdating} />
                            {(this.state.isSaved && !formikBag.dirty) &&
                                <span className="aui-color-text-green px-3"><IconSelect small /> Saved</span>
                            }
                            {this.state.error &&
                                <span className="aui-color-text-red px-3"><IconCaution small /> Not saved</span>
                            }
                        </Form>
                    )}
                </Formik>}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceForm);
