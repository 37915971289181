import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M15.4,18.5 C18.9,18.9 21.4,20 21.4,21 C21.4,22.4 16.8,23.5 11.3,23.5 C5.8,23.5 1.3,22.4 1.3,21 C1.3,20 3.8,18.9 7.5,18.5"/>
          <path d="M11.5,20 C11.5,20 5.7,11.7 5.7,8.5 C5.6,5.2 8.3,2.4 11.5,2.3 C14.8,2.4 17.5,5.3 17.3,8.5 C17.3,11.7 11.5,20 11.5,20 Z"/>
          <path d="M11.5,6 C12.9,6 14,7.1 14,8.5 C14,9.9 12.9,11 11.5,11 C10.1,11 9,9.9 9,8.5 C9,7.1 10.1,6 11.5,6 Z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M32.5,18.5 C32.5,12.4 27.6,7.5 21.5,7.5 C15.4,7.5 10.5,12.4 10.5,18.5 C10.5,24.6 21.5,39.8 21.5,39.8 C21.5,39.8 32.5,24.6 32.5,18.5 Z"/>
        <circle cx="21.5" cy="18.5" r="5.5"/>
        <path d="M27.5,36.5 C35,37.1 40.4,38.9 40.4,40.9 C40.4,43.4 31.9,45.5 21.5,45.5 C11.1,45.5 2.6,43.5 2.6,40.9 C2.6,38.9 8,37.1 15.5,36.5"/>
      </g>
    </svg>
  );
};
