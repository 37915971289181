import React from 'react';
import PropTypes from 'prop-types';
import LoadingOverlay from '../../../components/LoadingOverlay';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import IconForward from '../../../audi-ui-components/icons/Forward';
import { Formik, Form } from 'formik';
import { validate } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import { gtmPush } from '../../../lib/gtm';

import {
  PATH_LOGIN,
  PATH_REQUEST_CONFIRMAION
} from '../../../constants';

/*
 * This form will be reached via an email link
 * which will contain 'id' and 'token' in the query string
 */

class EmailConfirmation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      isResending: false,
      isResent: false,
      step: 1,
      serverError: null
    }
  }
  
  submitForm = (values, formikBag) => {
    request(
      `${process.env.RAZZLE_API}/2/account/email/confirm`,
      {
        method: 'POST',
        body: JSON.stringify(values)
      }
    ).then((response) => {
      this.setState({step: 2, isSubmitting: false});
      gtmPush("myAudi", "signupSuccess");
    }).catch((error) => {
      this.setState({
        isSubmitting: false,
        serverError: "Your email could not be confirmed. This may be because the code has expired or is invalid."
      });
    });
  }
  
  resendConfirmationEmail = (email) => {
    this.setState({isResending: true, serverError: null});
    request(
      `${process.env.RAZZLE_API}/2/account/email/request`,
      {
        method: 'POST',
        body: JSON.stringify({email})
      }
    ).then((response) => {
      this.setState({isResent: true, isResending: false});
    }).catch((error) => {
      this.setState({isResending: false, serverError: "There was a problem resending the email."});
    });
  }

  render() {
    const {isSubmitting, isResending, isResent, step, serverError} = this.state;
    if (step === 2) {
      return (
        <>
          <p className="aui-headline-4 mb-3">Welcome to myAudi Australia</p>
          <p className="mb-5">
            Now that your account is registered, you can view your personal information
            and the time remaining on your New Car Warranty.
            You can also view services that have been performed on your vehicle by the Audi Dealer Network
            and stay up to date with the latest news and happenings from Audi.
          </p>
          <Button label="Login" buttonType="primary" onClick={() => { this.props.goTo(PATH_LOGIN, 1); }} />
        </>
      );
    }
    return (
      <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={this.props.initialValues}>
        {(formikBag) => (
          <Form>

            {(isSubmitting || isResending) && <LoadingOverlay />}
            
            <p className="mb-3"><b>Thanks, you're almost done.</b></p>
            <p className="mb-3">We've sent a verification code to {this.props.initialValues.email ? this.props.initialValues.email : "your email address"}. Please enter the code below.</p>
            
            <FormField name="email" label="Email" formikBag={formikBag} showErrors={formikBag.submitCount > 0} onChange={this.props.onChangeEmail} />
            <FormField name="verificationCode" label="Verification code" formikBag={formikBag} showErrors={formikBag.submitCount > 0} />
            
            {serverError && <>
              <p className="aui-color-text-red mb-3">{serverError}</p>
              <Button variant="text" onClick={()=>{ this.resendConfirmationEmail(formikBag.values.email); }} disabled={!formikBag.values.email}>Request a new confirmation code</Button>
            </>}
            
            {isResent && <p className="aui-color-text-green mb-3">An email with a new verification code has been sent</p>}
            
            <div className="form-field submit pt-3">
              <Button label="Next" type="submit" buttonType="primary" icon={<IconForward small />} iconPosition="right" />
            </div>
            
          </Form>
        )}
      </Formik>
    );
  }
}

export default EmailConfirmation;
