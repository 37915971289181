import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M6 12.2L10.3 16.4 18.4 6.1" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M12.5 25.5L20.7 33.7 35.2 14.4" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
  );
};
