import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Media from './Media';
import Tile from './Tile';
import Carousel from 'react-bootstrap/Carousel';

const MediaWrapper = (props) => {
  const { content, width } = props;
  if (content.length === 1) {
    return <Media {...content[0]} />;
  }
  if (content.length === 2) {
    return (
      <div className="row">
        {content.map(item => (
          <div key={item.id} className="col-small-6 pb-3">
            <Media {...item} />
          </div>
        ))}
      </div>
    );
  }
  if (content.length === 3) {
    if (width > 6) {
      return (
        <div className="row">
          {content.map(item => (
            <div key={item.id} className="col-small-4 pb-3">
              <Media {...item} />
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="row">
          <div className="col-small-12 pb-3">
            <Media {...content[0]} />
          </div>
          <div className="col-small-6 pb-3">
            <Media {...content[1]} />
          </div>
          <div className="col-small-6">
            <Media {...content[2]} />
          </div>
        </div>
      );
    }
  }
  if (content.length === 4) {
    return (
      <div className="row">
        <div className="col-12 ppb-3">
          <Media {...content[0]} />
        </div>
        <div className="col-small-4 pb-3">
          <Media {...content[1]} />
        </div>
        <div className="col-small-4 pb-3">
          <Media {...content[2]} />
        </div>
        <div className="col-small-4">
          <Media {...content[3]} />
        </div>
      </div>
    );
  }
  if (content.length > 4) {
    return (
      <Carousel interval={10000}>
        {content.map(item => (
          <Carousel.Item key={item.id}>
            <Media {...item} />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  }
};

const Section = (props) => {

  const { name, content } = props;
  const left = props.left && name === 'sectionText';
  const right = props.right && name === 'sectionText';
  var width = parseInt(props.width);
  if (left) {
    width -= 1;
  }
  if (right) {
    width -= 1;
  }
  
  return (
    <>
      {left && <div className="col-small-1"></div>}
      <div className={`col-small-${width}`}>
        <div
          className={classnames('section', props.className, name, {
            'left': left,
            'right': right,
            'not-left': !left,
            'not-right': !right
          })}>
          {name === 'sectionMedia' && <MediaWrapper {...props} />}
          {name === 'sectionTile' && <Tile {...content[0]} />}
          {name === 'sectionTileHome' && <Tile {...content[0]} overlay />}
          {name === 'sectionQuote' && (
            <aside className="quote">
              <div
                className="quote-inner cms-content"
                dangerouslySetInnerHTML={{ __html: content }}></div>
            </aside>
          )}
          {name === 'sectionText' && (
            <div
              className="cms-content"
              dangerouslySetInnerHTML={{ __html: content }}></div>
          )}
        </div>
      </div>
      {right && <div className="col-small-1"></div>}
    </>
  );
}

Section.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  content: PropTypes.any,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Section.defaultProps = {
  width: 12,
  backgroundColour: '#ffffff',
};

export default Section;
