import moment from 'moment';

export const formatDollar = (num) => { // convert number to dollar
  if (typeof num === 'undefined' || num === null || num === "") {
    return " ";
  }
  if (typeof num === 'string' && num.indexOf('$') === 0) {
    return num;
  }
  var _num = parseFloat(num);
  var p = _num.toFixed(2).split(".");
  var s = _num < 0 ? "-$" : "$";
  return s + p[0].split("").reverse().reduce(function(acc, _num, i, orig) {
    return  _num=="-" ? acc : _num + (i && !(i % 3) ? "," : "") + acc;
  }, "") + "." + p[1];
};

export const unFormatDollar = (currency) => { // convert dollar to number
  return Number(currency.replace(/[^0-9.-]+/g,""));
}

export const formatNumWithSuffix = (num) => {
  if (typeof num === 'undefined' || num === null || num === "") {
    return " ";
  }
  var _num = parseInt(num);
  return[`${_num}th`,`${_num}st`,`${_num}nd`,`${_num}rd`][(_num=~~(_num<0?-_num:_num)%100)>10&&_num<14||(_num%=10)>3?0:_num];
}

export const formatDayOfMonth = (num) => {
  if (typeof num === 'undefined' || num === null || num === "") {
    return " ";
  }
  return formatNumWithSuffix(num) + ' day of the month';
};

export const formatDate = (date) => {
  if (typeof date === 'undefined' || date === null || date === "") {
    return " ";
  }
  let _in = 'DD/MM/YYYY';
  if (date.indexOf('/') === -1) {
    _in = 'YYYYMMDD';
  }
  return moment(date, _in).format('D MMM YYYY');
};

export const obfuscatePhone = (num) => {
  if (typeof num === 'undefined' || num === null || num === "") {
    return " ";
  }
  let a = num.substring(0, 3),
  b = num.substring(3),
  c = b.replace(/[0-9]/g, 'x');
  return a + c;
};

export const formatRegistrationCode = (value) => {
  // apply to TextInput npm module
  var _value = value.replace(/[\W\s\._\-]+/g, ''); // remove special chars
  var split = 4;
  var chunk = [];
  for (var i = 0; i < _value.length; i += split) {
    chunk.push( _value.substr( i, split ) );
  }
  return chunk.join("-").toUpperCase();
}

export const formatRegoNumber = (value) => {
  // apply to TextInput npm module
  let _value = value.replace(/[^A-Za-z0-9\.\-\s]/gi, '').toUpperCase();
  return _value.substr(0, 9);
}
