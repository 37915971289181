import React from 'react';
import classnames from 'classnames';
import Tile from '../../../components/Tile';
import { PATH_EVENT, PATH_OFFER } from '../../../constants';
import { getEventPeriodString } from '../helpers';
import _findIndex from 'lodash/findIndex';

const MasonryItem = (props) => {
    const { venue, thumbnail, booking, waitlist, type } = props;
    
    const isEventOpen = () => {
        if (type.toLowerCase() === 'myaudi') {
            return props.sessions?.reduce((p, s) =>
                Date.parse(s.eventStartDate) > Date.now() && s.openForRegistration || p, false
            );
        }
        return true;
    }
    
    let dateString = type === "ryi" ? "Register your interest" : getEventPeriodString(props.eventStartDate, props.eventEndDate);
    let link = type.toLowerCase() === 'offer' ? `${PATH_OFFER}/${props.id}` : `${PATH_EVENT}/${props.id}`;
    if (props.isPromo && props.externalUrl) {
        link = props.externalUrl;
    }
    let _backgroundImage = {
        url: "",
        x: "50%",
        y: "50%"
    };
    if (thumbnail) {
        if (typeof thumbnail === 'string') {
            _backgroundImage.url = decodeURIComponent(thumbnail);
        }
        if (typeof thumbnail === 'object') {
            _backgroundImage = thumbnail;
        }
    }
    let desc = props.shortDescription;
    if (!desc && venue) {
        desc = `${venue.suburb}${venue.state ? "," : ""} ${venue.state ? venue.state : ""}`;
        if (venue.isInternational) {
            if (venue.internationalAddress) {
                desc = venue.internationalAddress;
            } else {
                desc = `${venue.state}, ${venue.country}`;
            }
        }
    }
    let name = props.name;
    let title;
    if (type.toLowerCase() === 'virtual') {
        title = <><span className="date">{dateString}</span><br />Virtual Event:<br />{name}</>;
    } else {
        title = <><span className="date">{dateString}</span><br />{name}</>;
    }
    
    let isOpen = isEventOpen();
    let isSoldout = props.sessions?.length && _findIndex(props.sessions, function (o) { return o.spotsAvailable > 0; }) === -1;
    let isWaitlistable = isOpen && isSoldout && _findIndex(props.sessions, function(o) { return o.allowWaitlist && o.canWaitlist && o.waitlistSpotsAvailable > 0}) > -1;
    
    return (
        <div className={classnames("experience-item", `rowspan-${props.rowSpan || 1} colspan-${props.colSpan || 1}`, {
          "is-booked": booking,
          "is-waitlisted": waitlist && !booking,
          "closed": !booking && !waitlist && !isOpen,
          "soldout": !booking && !waitlist && isOpen && isSoldout && !isWaitlistable,
          "can-waitlist": !booking && !waitlist && isWaitlistable
        })}>
            <div className="experience-item__inner">
                <Tile
                    backgroundColour="#ccc"
                    backgroundImage={_backgroundImage}
                    title={title}
                    subTitle={desc}
                    link={link}
                    overlay
                />
            </div>
        </div>
    );
};

export default MasonryItem;
