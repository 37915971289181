import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../audi-ui-components/Button';
import { gtmPush } from '../../../lib/gtm';
import { AUDI_SHOP_URL } from '../../../constants';

import Media from './../../../components/Media';

const RecommendedProducts = ({products}) => {
  return (
    <div className="audi-store py-7">
      <div className="row">

        <div className="col-12 col-medium-6">
          <div className="row">
            <div className="col-medium-2"></div>

            {products && products.map(product => <div className="col" key={product.handle}>
              <a className="product"
                href={product.onlineStoreUrl}
                target="_blank" rel="noopener noreferrer"
                onClick={() => { gtmPush("myAudi", "audiStoreAu", product.onlineStoreUrl); }}
              >
                <Media
                  type="image"
                  url={product.images.edges[0].node.originalSrc}
                  altText={`${product.title} image`}
                />
                <p className="product-name pt-4 pb-2">{product.title}</p>
                <div className="product-price aui-headline-6">${product.variants.edges[0].node.price}</div>
                <span className="aui-button aui-button--text"><span className="aui-button__text">Shop now</span></span>
              </a>
            </div>)}

          </div>
        </div>

        <div className="col-12 col-medium-6">
          <div className="row">
            <div className="col-1 col-medium-2"></div>
            <div className="col-10 col-medium-8 text-center text-medium-left">
              <h4 className="aui-headline-3 my-3 my-xsmall-6"><b>Recommended products from Audi Store</b></h4>
              <Button label="Shop online now"
                buttonType="primary"
                href={AUDI_SHOP_URL}
                rel="noopener noreferrer"
                target="_blank"
                onClick={() => { gtmPush("myAudi", "audiStoreAu", AUDI_SHOP_URL); }}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default RecommendedProducts;
