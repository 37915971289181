import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { request } from '../../../../lib/apiRequestWrapper';
import { removeBooking } from '../../../../redux/Experience';
import { gtmPush } from '../../../../lib/gtm';
import Button from '../../../../audi-ui-components/Button';
import LoadingOverlay from '../../../../components/LoadingOverlay';
import { EMAIL_CUSTOMER_SERVICE, PHONE_CUSTOMER_SERVICE, AE_REFUND_POLICY_URL } from '../../../../constants';

const FormCancelBooking = ({ event, editBookingSteps, setEditBookingStep, removeBooking, setRemoveGuestSuccess }) => {

  const [ cancelSuccess, setCancelSuccess ] = useState(false);

  let booking = event && event.booking;

  const onSubmitHandler = async (values, formikBag) => {

    // DELETE /1/experience/bookings/{id}/cancel
    try {
      let response = await request(
        `${process.env.RAZZLE_API}/1/experience/bookings/${booking.id}/cancel`,
        {
          method: "DELETE",
          body: JSON.stringify(values),
        }
      );

      // Update redux store to remove booking
      removeBooking(event.id);
      gtmPush("AudiExperience", "eventCancelAttendanceSuccess", event.name);
      setCancelSuccess(true);
      setRemoveGuestSuccess(false);

    } catch (error) {
      console.error(error);
      let msg = "There was a problem submitting the request";
      if (error.body && error.body.message) {
        msg = error.body.message;
      }
      formikBag.setErrors({submit: msg});
      formikBag.setSubmitting(false);
    }
  }

  return (
    <div>
      <h2 className="aui-headline-3 mt-4 mb-7">
        <b>{cancelSuccess ? 'Booking Cancelled' : 'Cancel Attendance?'}</b>
      </h2>
      {!cancelSuccess &&
        <Formik
          onSubmit={onSubmitHandler}
          initialValues={{}} >
          {formikBag => (
            <Form className="position-relative">
                {formikBag.isSubmitting && <LoadingOverlay bgColour="gray90" />}
              <div className="row">
                <div className="col">
                  {!cancelSuccess && <p>Are you sure you want to cancel your attendance?</p>}
                </div>
              </div>
              <div className="row my-7">
                <div className="col">
                  {formikBag.errors && formikBag.errors.submit && <p className="aui-color-text-red py-3">{formikBag.errors.submit}</p>}
                  <Button
                    buttonType="secondary"
                    label="Yes"
                    type="submit"
                  />
                  <Button
                    buttonType="primary"
                    label="No"
                    onClick={() => { setEditBookingStep(null); }}
                    className="ml-3"
                  />
                </div>
              </div>
               <div className="row">
                <div className="col">
                  <p className="small">
                    <a href={AE_REFUND_POLICY_URL} target="_blank" rel="noopener noreferrer" className="aui-textlink">
                      View refund policy
                    </a>
                  </p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      }
      {cancelSuccess &&
        <div>
          <p className="mb-3">Your booking has been cancelled.</p>
          <p className="mb-3">{`If you have any questions, please call us on ${PHONE_CUSTOMER_SERVICE}
          between 8am and 6pm AEST, Monday to Friday.`}</p>
          <p className="mb-3">{`Alternatively you can email us at ${EMAIL_CUSTOMER_SERVICE}.`}
          </p>
          <p className="my-7">
            <Button
              buttonType="primary"
              label="Thank you"
              onClick={() => { setEditBookingStep(null); }}
            />
          </p>
        </div>
      }
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    removeBooking: id => dispatch(removeBooking(id)),
  };
};


const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormCancelBooking);
