export const loginSchema = {
  email: {
    presence: {
      message: "Email address is required",
      allowEmpty: false
    },
    email: {message: "Please enter a valid email address"}
  },
  password: {
    presence: {
      message: "Please enter your password",
      allowEmpty: false
    }
  },
  confirmPassword: {
    presence: {
      message: "Please confirm your password",
      allowEmpty: false
    },
    equality: {
      attribute: "password",
      message: "Password does not match"
    }
  },
  firstName: {
    presence: {
      message: "Please enter your name",
      allowEmpty: false
    }
  },
  lastName: {
    presence: {
      message: "Please enter your name",
      allowEmpty: false
    }
  },
  mobile: {
    presence: {
      message: "Please enter your mobile number",
      allowEmpty: false
    },
    format: {
      pattern: /^04\d{2}[ ]\d{3}[ ]\d{3}$/,
      message: "Please enter a valid mobile number"
    }
  },
  postcode: {
    presence: {
      message: "Postcode is required",
      allowEmpty: false
    },
    format: {
      pattern: /^\d{4}$/,
      message: "Please enter a valid postcode"
    }
  },
};
