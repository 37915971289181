import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { validate, defaultSchema, formatMobile } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import { gtmPush } from '../../../lib/gtm';
import { FIELD_RADIOS, FIELD_SELECT, FIELD_DATEPICKER, FIELD_CHECKBOX } from '../../../constants';

const reqTypes = {
  SOLD: "I no longer own this vehicle",
  UPDATE: "My personal details have changed",
  WRITEOFF: "This vehicle has been written-off",
};

const ChangeOwnerForm = ({ profile, vin, regoNo }) => {

  const [serverError, setServerError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (values, formikBag) => {
    setServerError(null);
    var _data = {
      'VIN': vin,
      'Request type': values.request_type,
      'consent': values.consent
    };
    if (values.request_type === reqTypes.SOLD) {
      _data['Date of sale'] = values.sale_date;
      _data['Your details'] = {
        'First name': values.user_firstname,
        'Last name': values.user_lastname,
        'Email': values.user_email,
        'Contact number': values.user_phone,
        'Registration': values.user_registration,
        'Address': values.user_address,
        'Suburb': values.user_suburb,
        'Postcode': values.user_postcode
      }
      _data['New owner details'] = {
        'Type': values.new_owner_type,
        'Name': values.new_owner_name,
        'Last name': values.new_owner_lastname,
        'Email': values.new_owner_email,
        'Contact number': values.new_owner_phone,
        'Address': values.new_owner_address,
        'Suburb': values.new_owner_suburb,
        'Postcode': values.new_owner_postcode
      }
    }
    if (values.request_type === reqTypes.UPDATE) {
      _data['New personal details'] = {
        'First name': values.user_firstname,
        'Last name': values.user_lastname,
        'Email': values.user_email,
        'Contact number': values.user_phone,
        'Registration': values.user_registration,
        'Address': values.user_address,
        'Suburb': values.user_suburb,
        'Postcode': values.user_postcode
      }
    }
    if (values.request_type === reqTypes.WRITEOFF) {
      _data['Write-off date'] = values.write_off_date;
    }
    // console.log(_data);
    request(
      `${process.env.RAZZLE_API}/1/form`,
      {
        method: 'POST',
        body: JSON.stringify({formName: "changeownership", payload: _data}),
      }
    ).then(response => {
      // success
      formikBag.setSubmitting(false);
      setSubmitted(true);
      gtmPush("form", "submitSuccess", "changeOwnership", _data);
    }).catch(error => {
      // error
      // console.error(error);
      formikBag.setSubmitting(false);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setErrors(errors);
      }
      setServerError("Sorry, there was an error submitting the form. Please check that all fields are completed and try again.");
    });
  }

  const validateForm = (values) => {
    let validation = validate(values, {
      consent: defaultSchema.requiredField,
      request_type: {presence: { message: "Please select an option", allowEmpty: false }}
    }, { format: "firstError", fullMessages: true });

    if (values.request_type === reqTypes.SOLD) {
      validation = validate(values, {
        consent: defaultSchema.requiredField,
        request_type: {presence: { message: "Please select an option", allowEmpty: false }},
        user_firstname: defaultSchema.firstName,
        user_lastname: defaultSchema.lastName,
        user_email: defaultSchema.email,
        user_phone: defaultSchema.mobile,
        user_address:{presence: { message: "Please enter your address", allowEmpty: false }},
        user_suburb:{presence: { message: "Please enter your suburb", allowEmpty: false }},
        user_postcode: defaultSchema.postcode,
        user_registration: {presence: { message: "Please enter your registration number", allowEmpty: false }},
        new_owner_type: {presence: { message: "Please select an option", allowEmpty: false }},
        new_owner_name: {presence: { message: "Please enter the name of the new owner", allowEmpty: false }},
        new_owner_lastname: values.new_owner_type === "Private" ? {presence: { message: "Please enter the name of the new owner", allowEmpty: false }} : {},
        new_owner_postcode: values.new_owner_type === "Dealer" ? {presence: { message: "Please enter the dealer postcode", allowEmpty: false }} : {},
        sale_date: {
          presence: { message: "Please enter date of sale", allowEmpty: false },
          isPastDate: true
        },
      }, { format: "firstError", fullMessages: true });
    }

    if (values.request_type === reqTypes.UPDATE) {
      validation = validate(values, {
        consent: defaultSchema.requiredField,
        request_type: {presence: { message: "Please select an option", allowEmpty: false }},
        user_firstname: defaultSchema.firstName,
        user_lastname: defaultSchema.lastName,
        user_email: defaultSchema.email,
        user_phone: defaultSchema.mobile,
        user_postcode: defaultSchema.postcode,
        user_registration: {presence: { message: "Please enter your registration number", allowEmpty: false }},
      }, { format: "firstError", fullMessages: true });
    }

    if (values.request_type === reqTypes.WRITEOFF) {
      validation = validate(values, {
        consent: defaultSchema.requiredField,
        request_type: {presence: { message: "Please select an option", allowEmpty: false }},
        write_off_date: {
          presence: { message: "Please enter the write off date", allowEmpty: false },
          isPastDate: true
        },
      }, { format: "firstError", fullMessages: true });
    }

    return validation;
  }

  if (submitted) {
    return (
      <div className="modal-form py-7">
        <h2 className="aui-headline-4 mb-7">Thank you</h2>
        <p>We{`'`}ll be sure to update our records with the new information you{`'`}ve provided.</p>
      </div>
    );
  }

  return (
    <div className="modal-form py-7">
      <h2 className="aui-headline-4 mb-3">Vehicle Owner Information</h2>
      <p className="mb-7">If you’ve recently disposed of a vehicle, changed your personal details, or your Audi has been written-off, please let us know so we can update our records.</p>

      <p className="mb-7"><b>VIN</b>: {vin}</p>

      <Formik
        initialValues={{
          user_firstname: profile.firstName,
          user_lastname: profile.lastName,
          user_email: profile.email,
          user_phone: profile.mobile,
          user_registration: regoNo,
          user_address: profile.homeAddress,
          user_suburb: profile.homeSuburb,
          user_postcode: profile.homePostcode
        }}
        validate={validateForm}
        onSubmit={handleSubmit}>
        {formikBag => (
          <Form>
            <FormField name="request_type"
              fieldType={FIELD_RADIOS}
              label="Please select an option below"
              options={[
                {label:reqTypes.SOLD, value:reqTypes.SOLD},
                {label:reqTypes.UPDATE, value:reqTypes.UPDATE},
                {label:reqTypes.WRITEOFF, value:reqTypes.WRITEOFF}
              ]}
              formikBag={formikBag}
              wrapperClassName="mb-7"
            />

            {(formikBag.values.request_type === reqTypes.SOLD || formikBag.values.request_type === reqTypes.UPDATE) && <div className="mb-7">
              {formikBag.values.request_type === reqTypes.SOLD && <p className="aui-headline-5 mb-3">Your details</p>}
              {formikBag.values.request_type === reqTypes.UPDATE && <p className="aui-headline-5 mb-3">New details</p>}
              <div className="row">
                <div className="col-small-6">
                  <FormField name="user_firstname" label="First name" formikBag={formikBag} />
                </div>
                <div className="col-small-6">
                  <FormField name="user_lastname" label="Last name" formikBag={formikBag} />
                </div>
              </div>
              <FormField name="user_email" label="Email" formikBag={formikBag} />
              <FormField name="user_phone" label="Mobile number" formatValue={formatMobile} formikBag={formikBag} />
              <FormField name="user_registration" label="Registration No." formikBag={formikBag} />
              <FormField name="user_address" label="Address" formikBag={formikBag} />
              <div className="row">
                <div className="col-small-8">
                  <FormField name="user_suburb" label="Suburb" formikBag={formikBag} />
                </div>
                <div className="col-small-4">
                  <FormField name="user_postcode" label="Postcode" formikBag={formikBag} />
                </div>
              </div>
            </div>}

            {formikBag.values.request_type === reqTypes.SOLD && <div className="mb-7">
              <p className="aui-headline-5 mb-3">New owner</p>
              <FormField name="new_owner_type"
                fieldType={FIELD_RADIOS}
                options={[{label:"Private", value:"Private"}, {label:"Dealer", value:"Dealer"}]}
                formikBag={formikBag}
              />

              {formikBag.values.new_owner_type === "Private" && <>
                <div className="row">
                  <div className="col-small-6">
                    <FormField name="new_owner_name" label="First name" formikBag={formikBag} />
                  </div>
                  <div className="col-small-6">
                    <FormField name="new_owner_lastname" label="Last name" formikBag={formikBag} />
                  </div>
                </div>
              </>}

              {formikBag.values.new_owner_type === "Dealer" &&
                <FormField name="new_owner_name" label="Dealer name" formikBag={formikBag} />
              }

              {(formikBag.values.new_owner_type === "Private" || formikBag.values.new_owner_type === "Dealer") && <>
                <FormField name="new_owner_email" label="Email" formikBag={formikBag} />
                <FormField name="new_owner_phone" label="Mobile number" formatValue={formatMobile} formikBag={formikBag} />
                <FormField name="new_owner_address" label="Address" formikBag={formikBag} />
                <div className="row">
                  <div className="col-small-8">
                    <FormField name="new_owner_suburb" label="Suburb" formikBag={formikBag} />
                  </div>
                  <div className="col-small-4">
                    <FormField name="new_owner_postcode" label="Postcode" formikBag={formikBag} />
                  </div>
                </div>
                <FormField name="sale_date" fieldType={FIELD_DATEPICKER} label="Date of sale (dd/mm/yyyy)" formikBag={formikBag} />
              </>}
            </div>}

            {formikBag.values.request_type === reqTypes.WRITEOFF && <div>
              <FormField name="write_off_date" fieldType={FIELD_DATEPICKER} label="Write-off date (dd/mm/yyyy)" formikBag={formikBag} />
            </div>}

            <br />

            <FormField name="consent"
              fieldType={FIELD_CHECKBOX}
              label="I consent to providing my personal information for the purposes of updating vehicle ownership details"
              formikBag={formikBag}
              wrapperClassName="my-7"
            />

            <br />
            {serverError && <p className="mb-5 aui-color-text-red">{serverError}</p>}
            <p className="mt-4">
              <Button
                buttonType="primary"
                label="Submit"
                iconPosition="right"
                type="submit"
                disabled={formikBag.isSubmitting} />
            </p>

          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ChangeOwnerForm;
