import React from 'react';
import { connect } from 'react-redux';
import AddToCalendar from './AddToCalendar';
import { AE_REFUND_POLICY_URL } from '../../../../constants';
import { gtmPush } from '../../../../lib/gtm';
import Button from '../../../../audi-ui-components/Button';
import IconEdit from '../../../../audi-ui-components/icons/Edit';

const Summary = ({
  event,
  selectedSession,
  profile,
  removeGuestSuccess,
  editBookingSteps,
  setEditBookingStep,
  donation,
  donateError
}) => {

  let booking = event && event.booking ? event.booking : {};

  const {
    attendees,
    canModify,
    canRefund,
    modifyCutOffDays,
    refundCutOffDays,
    dietaryRequirements,
  } = booking;

  const cancelMessage = () => {
    let totalCost = event.price - event.rebate;
    let cancelMessage = "";
    let termsLink = <a href={AE_REFUND_POLICY_URL} target="_blank" rel="noopener" className="aui-textlink">terms and conditions</a>;
    let dayS = refundCutOffDays > 1 ? 's' : '';
    if (totalCost > 0) {
      if (event.nonRefundable) {
        // non-refundable event
        cancelMessage = <span>You may still cancel your attendance. However, as this is a non-refundable event, you won’t be entitled to a refund. Please see the event {termsLink}, and thank you for your continued Audi Experience membership.</span>
      } else if (!canRefund) {
        // cannot refund
        cancelMessage = <span>You may still cancel your attendance. However, as the deadline has expired, you won’t be entitled to a refund. Please see the event {termsLink}, and thank you for your continued Audi Experience membership.</span>
      } else {
        // can refund
        if (refundCutOffDays <= 1) {
          cancelMessage = `You have less than 1 day to cancel your attendance and receive a refund of your payment.`;
        } else {
          cancelMessage = `You have ${refundCutOffDays} day${dayS} to cancel your attendance and receive a refund of your payment.`;
        }
      }
    } else {
      // free event
      <span>You may still cancel your attendance. Please see the event {termsLink}, and thank you for your continued Audi Experience membership.</span>
    }
    return cancelMessage;
  }

  const modifyMessage = () => {
    let modifyMessage = `You have ${modifyCutOffDays} day${modifyCutOffDays > 1 ? 's' : ''} remaining to modify your details.`;
    if (canModify && modifyCutOffDays <= 1) {
      modifyMessage = 'You have less than 1 day to modify your details.';
    } else if (!canModify) {
      modifyMessage = <span>Unfortunately, you can no longer modify or cancel your attendance as the deadline has expired. If you need to discuss this further, please call <a className="aui-textlink" href="tel:1800502834">1800 502 834.</a> between 8am and 6pm AEST, Monday to Friday.</span>;
    }
    return modifyMessage;
  }

  return (
    <>
      <h2 className="aui-headline-3 mt-4 mb-7"><b>You are attending this event</b></h2>

      <div className="my-7">
        <AddToCalendar event={event} selectedSession={selectedSession} />
        {` `}
        {canModify &&
          <Button
            buttonType="secondary"
            label="Cancel attendance"
            onClick={() => { gtmPush("AudiExperience", "eventCancelAttendanceClick", event.name); setEditBookingStep(editBookingSteps.cancel); }}
          />
        }
      </div>

      <div className="mt-3 mb-7 d-inline-block">
        {canModify && <p className="mb-2">{cancelMessage()}</p>}
        <p>{modifyMessage()}</p>
      </div>

      <div className="editable">
        <header className="d-flex justify-content-between">
          <h2 className='aui-headline-4 title'>
            <b>Guests</b>
          </h2>
          {canModify && attendees && attendees.length > 1 &&
            <button
              className="aui-button edit-btn edit"
              onClick={() => { setEditBookingStep(editBookingSteps.modGuest); }}
            >
              <IconEdit small /> <span className="edit-btn__text">Edit</span>
            </button>
          }
          {canModify && attendees &&
            attendees.length === 1 &&
            event.allowsGuest &&
            (selectedSession !== undefined || selectedSession !== null) &&
            (selectedSession.spotsAvailable > 0 || event.isDoubleBooking) &&
            <button
              className="aui-button edit-btn edit"
              onClick={() => { setEditBookingStep(editBookingSteps.addGuest); }}
            >
              <IconEdit small /> <span className="edit-btn__text">Edit</span>
            </button>
          }
        </header>
        <div key={profile.firstName} className="field">
          Member: {profile.firstName} {profile.lastName}
        </div>
        {attendees && attendees.map((guest, index) => {
          return guest.isGuest && <div key={guest.firstName} className="field">
          Guest: {guest.firstName} {guest.lastName}
        </div>})}
        {removeGuestSuccess && <div>Guest was successfuly removed.</div>}
      </div>
      <header className="d-flex justify-content-between">
        <h2 className='aui-headline-4 title'>
          <b>Special Requirements</b>
        </h2>
        {canModify && attendees && attendees.length > 0 &&
          <button
            className="aui-button edit-btn edit"
            onClick={() => { setEditBookingStep(editBookingSteps.requirements); }}
          >
            <IconEdit small /> <span className="edit-btn__text">Edit</span>
          </button>
        }
      </header>
      <div className="field">{dietaryRequirements}</div>
      <hr className="my-7" />

      {donateError && <p className="aui-color-text-red py-5">There was a problem processing your donation. If you would still like to donate, please visit <a href="https://audifoundation.com.au/donate" target="_blank" rel="noreferrer" className="aui-textlink">Audi Foundation</a></p>}
      {donation && donation > 0 && <p className="py-5">Your donation of ${donation} to Audi Foundaton was successful.</p>}

      {canRefund && <p className="small">
        <a href={AE_REFUND_POLICY_URL} target="_blank" rel="noopener noreferrer" className="aui-textlink">
          View refund policy
        </a>
      </p>}
    </>
  );

}

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isAuthed: state.profile.isAuthed,
    isLoadingProfile: state.profile.isLoading,
    profile: state.profile.data,
  };
};

export default connect(mapStateToProps)(Summary);
