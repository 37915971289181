import React from 'react';
import { connect } from 'react-redux';
import { request } from '../../lib/apiRequestWrapper';
import { PATH_FINANCE } from '../../constants';
import VehicleHero from '../../components/VehicleHero';
import NavBar from '../../audi-ui-components/NavBar';
import LoadingOverlay from '../../components/LoadingOverlay';
import MobileVerificationPopup from './components/MobileVerificationPopup';
import TabDashboard from './components/TabDashboard';
import TabCustomers from './components/TabCustomers';
import TabStatements from './components/TabStatements';
import TabEnquiries from './components/TabEnquiries';
import TabContact from './components/TabContact';
import { formatDollar, unFormatDollar } from './helpers';
import { gtmPush } from '../../lib/gtm';
import _findIndex from 'lodash/findIndex';

const contractNav = [
  {
    slug: 'dashboard',
    label: 'Finance',
  },
  {
    slug: 'customers',
    label: 'Customers',
  },
  {
    slug: 'statements',
    label: 'Statements',
  },
  {
    slug: 'enquiries',
    label: 'Enquiries & requests',
  },
  {
    slug: 'contact',
    label: 'Contact Us',
  },
];

function mapStateToProps(state) {
  return {
    contactInfo: state.finance.contactInfo,
    vwfsId: state.profile.data.vwfsId,
    profileData: state.profile ? state.profile.data : {},
  };
}

class Contract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: {},
      customers: [],
      statements: [],
      enquiries: {},
      userRole: null,
      isLoadingContract: false,
      isLoadingCustomers: false,
      isLoadingStatements: false,
      isLoadingEnquiries: false,
      verifyModal: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { match, vwfsId } = this.props;
    if (match.params.contractId) {
      this.setState({ isLoadingContract: true });
      // load contract data
      request(
        `${process.env.RAZZLE_API}/1/vwfs/contract/${match.params.contractId}`
      )
        .then(contractData => {
          this.setState({
            contract: contractData,
            isLoadingContract: false,
            isLoadingCustomers: true,
            isLoadingStatements: true,
          });
          // load customer data
          request(
            `${process.env.RAZZLE_API}/1/vwfs/contract/${match.params.contractId}/customers`
          ).then(customerData => {
            let role = null;
            for (let i = 0; i < customerData.length; i++) {
              if (
                vwfsId.toString() === customerData[i].identifierId.toString()
              ) {
                role = customerData[i].customerRole;
                break;
              }
            }
            this.setState({
              customers: customerData,
              userRole: role,
              isLoadingCustomers: false,
            });
          });
          // load statements
          request(
            `${process.env.RAZZLE_API}/1/vwfs/contract/${match.params.contractId}/statements`
          ).then(data => {
            this.setState({ statements: data, isLoadingStatements: false });
          });
          // load enquiries
          request(
            `${process.env.RAZZLE_API}/1/vwfs/contract/${match.params.contractId}/enquiry`,
            {
              method: 'POST',
              body: JSON.stringify({
                enquiryType: 'TPAUTH',
                customerId: vwfsId,
              }),
            }
          ).then(response => {
            this.setState({
              enquiries: { tpauth: response },
              isLoadingEnquiries: false,
            });
          });
        })
        .catch(error => {
          // TODO: handle error
          console.error(error);
        });
    }
  }

  updateContractData = (values, successCb, errorCb) => {
    const { contract } = this.state;
    let path = '';
    let _patch = {};
    let gtmAction = "";
    if (values.hasOwnProperty('registrationNumber')) {
      path = 'registrationNumber';
      gtmAction = "registrationNumberUpdateSuccess";
      let asset = contract.assets[0];
      asset.registrationNumber = values.registrationNumber;
      _patch = {assets: [asset]};
    }
    if (values.hasOwnProperty('bsb')) {
      path = 'directdebit';
      gtmAction = "ddAccountUpdateSuccess";
      _patch = {
        directdebit: values
      };
    }
    request(
      `${process.env.RAZZLE_API}/1/vwfs/contract/${contract.contractNumber}/${path}`,
      {
        method: "PATCH",
        body: JSON.stringify(values)
      }
    ).then(res => {
      var _contract = Object.assign({}, contract, _patch);
      this.setState({contract: _contract});
      gtmPush("myAudiFinance", gtmAction, "finance");
      if (successCb) { successCb(res); }
    }).catch(err => {
      if (errorCb) { errorCb(err); }
    });
  };

  updateCustomerData = (values, successCb, errorCb) => {
    const { contract } = this.state;
    let path = '';
    let gtmAction = "";
    let _values = values;
    if (values.identifierType === 'PHONE') {
      path = 'contact';
      gtmAction = "phoneUpdateSuccess";
    }
    if (values.hasOwnProperty('monikerId')) {
      path = 'location'; _values = [values];
      if (values.identifier === "MAIL") {
        gtmAction = "mailingAddressUpdateSuccess";
      } else {
        gtmAction = "residentialAddressUpdateSuccess";
      }
    }
    request(
      `${process.env.RAZZLE_API}/1/vwfs/contract/${contract.contractNumber}/customer/${values.customerNumber}/${path}`,
      {
        method: "PATCH",
        body: JSON.stringify(_values)
      }
    ).then(res => {
      // reload customer data
      request(
        `${process.env.RAZZLE_API}/1/vwfs/contract/${contract.contractNumber}/customers`
      ).then(customerData => {
        this.setState({ customers: customerData });
        gtmPush("myAudiFinance", gtmAction, "finance");
        if (successCb) { successCb(res); }
      }).catch(err => {
        if (errorCb) { errorCb(err); }
      });
      //
    }).catch(err => {
      if (errorCb) { errorCb(err); }
    });
  };

  updateCustomerPhone = (values, successCb, errorCb) => {
    this.updateCustomerData(
      values,
      (res) => { if (successCb) { successCb(res); } },
      (err) => {
        if (err.status === 499 || err.status === '499') {
          let popup = (
            <MobileVerificationPopup
              formData={values}
              verify={this.submitMobileVerification}
              onCancel={() => { errorCb({message: 'Verification was cancelled.'}); }}
              successCallback={successCb}
              errorCallback={errorCb}
              close={() => { this.setState({verifyModal: null}); }}
            />
          );
          this.setState({verifyModal: popup});
        } else {
          if (errorCb) { errorCb(err); }
        }
      }
    );
  };

  submitMobileVerification = (values, successCb, errorCb) => {
    const { contract } = this.state;
    request(
      `${process.env.RAZZLE_API}/1/vwfs/contract/${contract.contractNumber}/customer/${values.customerNumber}/contact`,
      {
        method: "PATCH",
        body: JSON.stringify(values)
      }
    ).then(res => {
      // reload customer data
      request(
        `${process.env.RAZZLE_API}/1/vwfs/contract/${contract.contractNumber}/customers`
      ).then(customerData => {
        this.setState({ customers: customerData });
        if (successCb) { successCb(res); }
      }).catch(err => {
        if (errorCb) { errorCb(err); }
      });
      //
    }).catch(err => {
      if (errorCb) { errorCb(err); }
    });
  }

  handleTabClick = idx => {
    const { match } = this.props;
    let t = contractNav[idx].slug;
    this.props.history.push(`${PATH_FINANCE}/${match.params.contractId}/${t}`);
  };

  render() {
    const { contract, isLoadingContract } = this.state;
    if (isLoadingContract) {
      return (
        <div className="page-wrapper">
          <LoadingOverlay type="placeholder">
            Loading your finance contract
          </LoadingOverlay>
        </div>
      );
    }
    const { vwfsId, match } = this.props;
    const activeTabName = match.params.activeTab || contractNav[0].slug;
    const activeTabIndex = match.params.activeTab ? _findIndex(contractNav, {slug: activeTabName}) : 0;
    const contractId = match.params.contractId;
    const isContractLoaded = contract.hasOwnProperty('startDate');
    const totalPaid = isContractLoaded
      ? unFormatDollar(contract.financedAmount) -
        unFormatDollar(contract.currentBalance)
      : null;

    return (
      <div className="page-wrapper finance-contract">
        {contract.assets &&
          <VehicleHero
            vin={contract.assets[0].vinChasis}
            modelName={contract.assets[0].make + ' ' + contract.assets[0].model}
            regoNo={contract.assets[0].registrationNumber}
          />
        }
        <div className="content-wrapper py-5">
        <div className="finance__contract-summary aui-color-gray90 p-7 mt-3 mb-7">
          <div className="row">
            <div className="col-small-4 py-small-5">
              <label className="aui-headline-6">
                {contract.payments &&
                  contract.payments.hasOwnProperty('paymentFrequency') &&
                  contract.payments.paymentFrequency}{' '}
                  Repayments
              </label>
              <span className="value aui-headline-4 mt-6">
                {contract.payments &&
                  contract.payments.hasOwnProperty('nextPaymentAmount') &&
                  formatDollar(contract.payments.nextPaymentAmount)}{' '}
              </span>
            </div>
            <div className="col-small-4 py-small-5">
              <label className="aui-headline-6">Total repayments</label>
              <span className="value aui-headline-4 mt-6">
                {isContractLoaded && formatDollar(totalPaid)}{' '}
              </span>
            </div>
            <div className="col-small-4 py-small-5">
              <label className="aui-headline-6">Remaining balance</label>
              <span className="value aui-headline-4 mt-6">
                {isContractLoaded && formatDollar(contract.currentBalance)}{' '}
              </span>
            </div>
          </div>
        </div>

        <div className="finance__contract-navigation pt-5">
          <NavBar
            actions={contractNav}
            activeIndex={activeTabIndex}
            onActionClick={this.handleTabClick}
            navType="tab"
          />
        </div>

        {activeTabName === 'dashboard' && (
          <TabDashboard
            vwfsId={vwfsId}
            contractId={contractId}
            contract={contract}
            userRole={this.state.userRole}
            totalPaid={totalPaid}
            updateContractData={this.updateContractData}
            isLoadingContract={this.state.isLoadingContract}
          />
        )}

        {activeTabName === 'customers' && (
          <TabCustomers
            customers={this.state.customers}
            vwfsId={vwfsId}
            userRole={this.state.userRole}
            updateCustomerData={this.updateCustomerData}
            updateCustomerPhone={this.updateCustomerPhone}
            isLoadingCustomers={this.state.isLoadingCustomers}
          />
        )}

        {activeTabName === 'statements' && (
          <TabStatements
            contractId={contractId}
            statements={this.state.statements}
            isLoadingStatements={this.state.isLoadingStatements}
          />
        )}

        {activeTabName === 'enquiries' && (
          <TabEnquiries
            contractId={contractId}
            vwfsId={vwfsId}
            enquiryLinks={this.state.enquiries}
            isLoadingCustomers={this.state.isLoadingCustomers}
            isLoadingEnquiries={this.state.isLoadingEnquiries}
          />
        )}

        {activeTabName === 'contact' && <TabContact />}
        </div>

        {this.state.verifyModal && this.state.verifyModal}
      </div>
    );
  }
}

export default connect(mapStateToProps)(Contract);
