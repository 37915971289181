import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { validate, defaultSchema, formatMobile } from '../../../lib/validation';
import { request } from '../../../lib/apiRequestWrapper';
import Button from '../../../audi-ui-components/Button';
import FormField from '../../../components/FormField';
import IconForward from '../../../audi-ui-components/icons/Forward';
import { FIELD_DEALER_SELECT } from '../../../constants';
import _find from 'lodash/find';
import { gtmPush } from '../../../lib/gtm';

const ExtWarrantyForm = ({ profile, vin, modelNameSD, dealers }) => {

  const [serverError, setServerError] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (values, formikBag) => {
    setServerError(null);
    values.modelName = modelNameSD;
    request(
      `${process.env.RAZZLE_API}/1/request/extendedWarranty`,
      {
        method: 'POST',
        body: JSON.stringify(values),
      }
    ).then(response => {
      // success
      formikBag.setSubmitting(false);
      setSubmitted(true);
      gtmPush("form", "submitSuccess", "extWarrantyEnquiry", values);
    }).catch(error => {
      // error
      formikBag.setSubmitting(false);
      if (error.body && error.body.modelState) {
        let errors = {};
        for (let key in error.body.modelState) {
          if (!Object.hasOwnProperty(key)) {
            errors[key] = error.body.modelState[key][0];
          }
        }
        formikBag.setStatus(errors);
      } else if (error.message) {
        setServerError(error.message);
      } else if (error.body && error.body.message) {
        setServerError(error.body.message);
      }
    });
  }

  const validateForm = (values) => {
    let validation = validate(values, {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      emailAddress: defaultSchema.email,
      postcode: defaultSchema.postcode,
      mobilePhone: defaultSchema.mobile,
      km: defaultSchema.km,
      dealerId: {
        presence: { message: "Please select a dealer", allowEmpty: false }
      }
    }, { format: "firstError", fullMessages: true });
    return validation;
  }

  const dealer = _find(dealers, {name: profile.preferredDealer});

  if (submitted) {
    return (
      <div className="modal-form py-7">
        <h2 className="aui-headline-4 mb-7">Thank you</h2>
        <p>Your selected dealer will be in touch with you shortly.</p>
      </div>
    );
  }

  return (
    <div className="modal-form py-7">
      <h2 className="aui-headline-4 mb-7">Enquire about Extended Warranty</h2>
      <Formik
        initialValues={{
          firstName: profile.firstName,
          lastName: profile.lastName,
          emailAddress: profile.email,
          mobilePhone: profile.mobile,
          postcode: profile.homePostcode,
          km: "",
          vin: vin,
          dealerId: dealer ? dealer.dealerId : ""
        }}
        validate={validateForm}
        onSubmit={handleSubmit}>
        {formikBag => (
          <Form>
            <FormField name="firstName" label="First name" formikBag={formikBag} />
            <FormField name="lastName" label="Last name" formikBag={formikBag} />
            <FormField name="emailAddress" label="Email address" formikBag={formikBag} type="email" />
            <FormField name="mobilePhone" label="Mobile phone" formikBag={formikBag} formatValue={formatMobile} />
            <FormField name="postcode" label="Postcode" formikBag={formikBag} />
            <FormField name="km" label="Kilometres" formikBag={formikBag} />
            <FormField name="vin" label="VIN" formikBag={formikBag} disabled={true} />
            <FormField name="dealerId" label="Dealer" fieldType={FIELD_DEALER_SELECT} dealers={dealers} valueProp="dealerId" formikBag={formikBag} />
            <br />
            {serverError && <p className="mb-5 aui-color-text-red">{serverError}</p>}
            <p className="mt-4">
              <Button
                buttonType="primary"
                label="Enquire about Extended Warranty"
                icon={<IconForward small />}
                iconPosition="right"
                type="submit"
                disabled={formikBag.isSubmitting} />
            </p>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default ExtWarrantyForm;
