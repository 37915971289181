import React from 'react';
import Media from './../../../components/Media';
import { gtmPush } from '../../../lib/gtm';
import Button from '../../../audi-ui-components/Button';

const SoldVehicle = ({ showActiveModal }) => {
  return (
    <div className="sold-vehicle" style={{height:'100%'}}>
      <Media
        type="image"
        url={`${process.env.PUBLIC_PATH}assets/1920x1080_InlineMediaGallery_AQ8_D_181008.jpg`}
        altText="Sold Tile Background Image"
        useFullImg={false}
        className="position-relative">
        <div className="overlay d-flex flex-column justify-content-end px-3 px-small-6 py-6 aui-color-text-light">
          <div className="aui-headline-5 mb-3"><b>Sold your Audi?</b></div>
          <p className="disclaimer">
            <b>
              Please let us know if you've sold your Audi so we can refresh your profile.
            </b>
          </p>
          <div>
            <Button
              buttonType="primary"
              theme="light"
              label="Change of ownership"
              isStretched={false}
              className="mt-3"
              onClick={() => { showActiveModal('change-ownership'); gtmPush("myAudi", "changeOwnership", "vehicle"); }}
            />
          </div>
        </div>
      </Media>
    </div>
  );
};

export default SoldVehicle;
