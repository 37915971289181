import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path strokeLinejoin="round" d="M8.5 21.2999992L0.5 17 0.5 1 8.5 4.8z"/>
          <path d="M11.5,11.6 C11.5,9.5 13.2,7.7 15.3,7.7 C15.4,7.7 15.4,7.7 15.5,7.7 C17.7,7.7 19.5,9.5 19.5,11.7 C19.5,13.9 15.5,19.8 15.5,19.8 C15.5,19.8 11.6,14.1 11.5,11.8 C11.5,11.7 11.5,11.7 11.5,11.6 Z"/>
          <path d="M11.7,19.6 L8.5,21.3 L8.5,4.8 L15.5,1 L22.5,5.2 L22.5,21.2 L19.5,19.5 M15.5,5.2 L15.5,1" strokeLinejoin="round"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M31.4,27.7000008 C31.4575067,23.8024773 34.6,20.5 38.4,20.6 C42.3,20.6 45.5,23.8 45.6,27.7 C45.6,31.6 38.5,41 38.5,41 C38.5,41 31.3424933,31.5975242 31.4,27.7000008 Z"/>
        <path d="M38.5,24.4 C40.2,24.4 41.6,25.7 41.6,27.4 C41.6,29.1 40.3,30.5 38.6,30.5 C38.6,30.5 38.5,30.5 38.5,30.5 C36.8,30.5 35.4,29.1 35.4,27.4 C35.4,25.8 36.8,24.4 38.5,24.4 Z"/>
        <path strokeLinejoin="round" d="M31.4 37L26.5 34.5 26.5 7 38.5 12.5 38.5 18"/>
        <path d="M26.5,7 L14.5,12.5" strokeLinecap="round"/>
        <path d="M26.5,34.5 L14.5,41" strokeLinecap="round"/>
        <path strokeLinecap="round" strokeLinejoin="round" d="M2.5 34.5L2.5 7 14.5 12.5 14.5 41z"/>
      </g>
    </svg>
  );
};
