import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Checkbox from './Checkbox';
import _pull from 'lodash/pull';

function findInCollection(array, key, value) {
    var o;
    array.some(function iter(a) {
        if (a[key] === value) {
            o = a;
            return true;
        }
        return Array.isArray(a.options) && a.options.some(iter);
    });
    return o;
}

class Dropdown extends React.Component {

    constructor(props) {
        super(props);
        this.elementRef = React.createRef();
        this.state = {
            isActive: false,
            isFocused: false
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, false);
    }

    handleSelect = (event, selectedValue) => {
        event.preventDefault(); event.stopPropagation();
        const {value, multiple, onChange} = this.props;
        if (multiple) {
            // multi-value select
            let newValue = [...value] || [];
            if (newValue.indexOf(selectedValue) === -1) {
                newValue.push(selectedValue);
            } else {
                _pull(newValue, selectedValue);
            }
            onChange(newValue, event);
        } else {
            // single value select
            onChange(selectedValue, event);
            this.setState({isActive: false});
        }
    }

    handleClick = (e) => {
        e.preventDefault(); e.stopPropagation();
        this.setState({isActive: !this.state.isActive});
    }

    handleClickOutside = (e) => {
        if (this.elementRef && !this.elementRef.current.contains(e.target)) {
            this.setState({isActive: false});
        }
    }

    handleFocus = (e) => {
        this.setState({isFocused: true});
        if (typeof this.props.onFocus === 'function') {
            this.props.onFocus(e);
        }
    }

    handleBlur = (e) => {
        this.setState({isFocused: false});
        if (typeof this.props.onBlur === 'function') {
            this.props.onBlur(e);
        }
    }

    getSelectedLabel = () => {
        const {value, multiple, options} = this.props;
        if (value && value !== null) {
            if (multiple) {
                let labels = [];
                value.forEach(val => {
                    let opt = findInCollection(options, 'value', val);
                    if (opt) { labels.push(opt.label); }
                });
                return labels.length > 0 ? `(${labels.length}) ${labels.join(', ')}` : '';
            } else {
                var currentOption = findInCollection(options, 'value', value);
                if (currentOption) {
                    if (currentOption.jsxLabel) { return currentOption.jsxLabel; }
                    return currentOption.label;
                }
            }
        }
        return '';
    }

    renderListItem = (li, index, animate) => {
        let _style = {};
        if (animate) {
            _style = {transitionDelay: `${.05 * index}s`, WebkitTransitionDelay: `${.05 * index}s`};
        }
        if (li.hasOwnProperty('options')) {
            return (
                <li key={`group_${index}`} className={classnames("aui-dropdown__list-item", {"aui-dropdown__list-item--animate": animate})} style={_style}>
                    <span className="aui-dropdown__list-title">{li.label}</span>
                    <ul className="aui-dropdown__list">
                        {li.options.map((opt, i) => {
                            return (this.renderListItem(opt, i, false));
                        })}
                    </ul>
                </li>
            );
        }
        let isSelected = this.props.multiple ? this.props.value.indexOf(li.value) !== -1 : li.value === this.props.value;
        return (
            <li key={`li_${index}`} className={classnames("aui-dropdown__list-item", {"aui-dropdown__list-item--animate": animate})} style={_style} onClick={(e) => {this.handleSelect(e, li.value);}}>
                {this.props.multiple && <div className="aui-dropdown__option">
                    <Checkbox isDropdownOption label={li.jsxLabel || li.label} value={li.value} checked={isSelected} disabled={li.disabled} />
                </div>}
                {!this.props.multiple && <button type="button" className="aui-dropdown__option">{li.jsxLabel || li.label}</button>}
            </li>
        );
    }

    renderMessage = () => {
        const {isValid, isInvalid, validMessage, invalidMessage, description} = this.props;
        if (isValid && validMessage) {
            return (<span className="aui-dropdown__valid">{validMessage}</span>);
        }
        if (isInvalid && invalidMessage) {
            return (<span className="aui-dropdown__error">{invalidMessage}</span>);
        }
        if (description) {
            return (<span className="aui-dropdown__description">{description}</span>);
        }
        return false;
    }

    render() {
        const {value, disabled, multiple, isValid, isInvalid, theme} = this.props;
        const {isFocused, isActive} = this.state;
        const currentLabel = this.getSelectedLabel();
        const isDirty = multiple ? value && value.length > 0 : value || value === 0;
        return(
            <div ref={this.elementRef} onFocus={this.handleFocus} onBlur={this.handleBlur} onClick={this.handleClick} style={this.props.style} className={classnames(
                "aui-dropdown",
                "aui-dropdown--floating-label",
                `${this.props.className}`,
                {
                    "aui-dropdown--multiple": multiple,
                    "is-focused": isFocused,
                    "is-dirty": isDirty,
                    "is-disabled": disabled,
                    "is-invalid": isInvalid,
                    "is-active": isActive,
                    [`aui-theme-${theme}`]: theme
                }
            )}>
                <div className="aui-dropdown__field">
                    {typeof currentLabel === 'string' && <input className="aui-dropdown__input" type="text" id={this.props.name} readOnly value={currentLabel} />}
                    {typeof currentLabel === 'object' && <div className="aui-dropdown__input" id={this.props.name}>{currentLabel}</div>}
                    <label className="aui-dropdown__label" htmlFor={this.props.name}>{this.props.label}</label>
                    <span className="aui-dropdown__focus-line"></span>
                    <div className="aui-dropdown__panel">
                        <ul className="aui-dropdown__list">
                            {this.props.options.map((item, i) => {
                                return (this.renderListItem(item, i, true));
                            })}
                        </ul>
                    </div>
                </div>
                {this.renderMessage()}
            </div>
        );
    }
}

Dropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    options: PropTypes.array,
    isValid: PropTypes.bool,
    isInvalid: PropTypes.bool,
    validMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
    style: PropTypes.object,
    className: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'black', 'warmsilver', 'silver']) // see styles/components/_dropdown-themes.scss
};

Dropdown.defaultProps = {
    style: {},
    className: ''
}

export default Dropdown;

/*
 * options example:
 * [
 *  {
 *    label: 'A1',
 *    options: [
 *      { label: 'A1 Sportback', value: 'a1sportback' }
 *      { label: 'A1 Sedan', value: 'a1sedan' }
 *    ]
 *  },
 *  { label: 'A3', jsxLabel: (<span>A3</span>), value: 'a3' }
 * ]
 *
 */
