import React, { useState } from 'react';
import Button from '../../../../audi-ui-components/Button';
import TextField from '../../../../audi-ui-components/TextField';
import IconForward from '../../../../audi-ui-components/icons/Forward';
import ProfileForm from '../../../Profile/forms/ProfileForm';

const FormDonate = ({onSubmit, profileData}) => {

    const [amount, setAmount] = useState(null);
    const [useTextfield, setUseTextfield] = useState(false);

    const onClickAmount = (amt) => {
        if (amount === amt && !useTextfield) {
            setAmount(null);
        } else {
            setAmount(amt);
        }
        setUseTextfield(false);
    }

    const onChangeAmount = (amt) => {
        amt = amt.replace(/\D/g,'');
        setAmount(amt);
        setUseTextfield(true);
    }

    const profileMissingInfo = Boolean(!profileData.firstName || !profileData.lastName || !profileData.email || !profileData.mobile);

    return (
        <>
            <h2 className="aui-headline-3 mt-4 mb-7"><b>Make a donation to the Audi Foundation</b></h2>
            <p className="mb-5">The <a href="https://audifoundation.com.au" target="_blank" rel="noreferrer" className="aui-textlink">Audi Foundation</a> supports leading Australian charities with a focus on advancing medical technology, education, and positive mental health outcomes.</p>

            <p className="mb-5 aui-headline-5">Make a donation (optional)</p>
            <div className="row mb-2 donate-buttons">
                <div className="col-3">
                    <Button
                        buttonType={amount === 5 && !useTextfield ? "primary" : "secondary"}
                        className={amount === 5 && !useTextfield ? "red" : ""}
                        label="$5"
                        onClick={() => { onClickAmount(5); }}
                    />
                </div>
                <div className="col-3">
                    <Button
                        buttonType={amount === 10 && !useTextfield ? "primary" : "secondary"}
                        className={amount === 10 && !useTextfield ? "red" : ""}
                        label="$10"
                        onClick={() => { onClickAmount(10); }}
                    />
                </div>
                <div className="col-3">
                    <Button
                        buttonType={amount === 25 && !useTextfield ? "primary" : "secondary"}
                        className={amount === 25 && !useTextfield ? "red" : ""}
                        label="$25"
                        onClick={() => { onClickAmount(25); }}
                    />
                </div>
                <div className="col-3">
                    <Button
                        buttonType={amount === 50 && !useTextfield ? "primary" : "secondary"}
                        className={amount === 50 && !useTextfield ? "red" : ""}
                        label="$50"
                        onClick={() => { onClickAmount(50); }}
                    />
                </div>
            </div>

            <div className="mb-5 pb-5">
                <TextField
                    value={useTextfield ? amount : ""}
                    label="Or enter your own amount"
                    floatingLabel={false}
                    onChange={onChangeAmount}
                    icon={<b className="audiicon">$</b>}
                />
            </div>

            {amount > 0 && profileMissingInfo && <div className="mb-5">
                <p>Please complete your profile to make a donation.</p>
                <ProfileForm loadingBgColour="gray90" />
            </div>}

            <Button
                buttonType="primary"
                label="Payment"
                icon={<IconForward small />}
                iconPosition="right"
                onClick={() => { onSubmit({donation: amount}); }}
                disabled={(amount > 0 && amount < 5) || (amount > 0 && profileMissingInfo)}
            />

            {(amount && amount > 0 && amount < 5) && <p className="my-4 aui-color-text-red">Minimum donation amount is $5.</p>}
        </>
    );
}

export default FormDonate;
