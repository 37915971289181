import React, { useState } from 'react';
import SearchForm from './SearchForm';
import PurchaseForm from './PurchaseForm';
import Button from '../../../../audi-ui-components/Button';
import { gtmPush } from '../../../../lib/gtm';
import { AUDI_BOOK_SERVICE_URL, FEATURE } from '../../../../constants';

const contact = (
    <div className="section sectionQuote">
        <aside className="quote py-0">
            <div className="quote-inner">
                <p>
                    Service Team: <a href="tel:1300734567"><b style={{whiteSpace:'nowrap'}}>1300 734 567</b></a>
                    <br />Opening Hours: <b style={{whiteSpace:'nowrap'}}>Mon-Fri (9am-5pm)</b>
                </p>
            </div>
        </aside>
    </div>
);

class BuyServicePlan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchResult: null,
            purchaseResult: null,
        }
    }

    componentDidMount() {
        if (typeof window !== 'undefined') {
            if (window.Stripe) {
                window.Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_KEY_SP);
            } else {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://js.stripe.com/v2/";
                script.onload = function () {
                    window.Stripe.setPublishableKey(process.env.RAZZLE_STRIPE_KEY_SP);
                };
                document.getElementsByTagName("head")[0].appendChild(script);
            }
        }
        gtmPush("buyServicePlan", "BspLoaded", "BspStart");
    }

    setSearchResult = (r) => {
        this.setState({searchResult: r});
        document.getElementsByClassName("aui-modal is-active")[0].scrollTop = 0;
    }

    setPurchaseResult = (r) => {
        this.setState({purchaseResult: r});
        document.getElementsByClassName("aui-modal is-active")[0].scrollTop = 0;
    }

    render() {
        const {profile, vehicle, preferredServiceLocation} = this.props;
        const {submitted, review, searchResult, purchaseResult} = this.state;

        if (!FEATURE.buyServicePlan) {
            return (
                <div className="buy-service-plan py-7">
                    <p className="aui-headline-4 mb-3">Sorry, this feature is currently unavailable.</p>
                    <p>If you would like to enquire about service plans now, you can</p>
                    <ul>
                        <li>Email us at <a className="aui-textlink" href="mailto:customerassistance@audi-info.com.au">customerassistance@audi-info.com.au</a></li>
                        <li>Call us on <a className="aui-textlink" href="tel:1300557405">1300 557 405</a></li>
                    </ul>
                </div>
            );
        }

        if (purchaseResult) {
            return (
                <div className="buy-service-plan py-7">
                    <div className="row">
                        <div className="col-small-1"></div>
                        <div className="col-small-5 col-large-6">
                            <p className="aui-headline-4 mb-3">Thank you. Your order has been confirmed.</p>
                            <p className="mb-3"><b>Order number: {purchaseResult}</b></p>
                            <p className="mb-5">You will receive two email confirmations for your order shortly, one containing your invoice and the second with your service plan certificate.</p>
                            {preferredServiceLocation && <p className="mb-5">
                                {preferredServiceLocation.webkey &&
                                    <Button
                                        href={`${AUDI_BOOK_SERVICE_URL}${preferredServiceLocation.webkey}`}
                                        rel="noopener noreferrer" target="_blank"
                                        onClick={() => { gtmPush("buyServicePlan", "BspBookAService", "BspThanks"); }}
                                        buttonType="primary"
                                        label={`Book a service at ${preferredServiceLocation.dealerName}`}
                                    />
                                }
                                {(!preferredServiceLocation.webkey && preferredServiceLocation.phone) && <>Call {preferredServiceLocation.dealerName} on <a href={`tel:${preferredServiceLocation.phone.replace(/\D/g, '')}`} className="aui-textlink" onClick={() => { gtmPush("buyServicePlan", "BspBookAService", "BspThanks"); }}><b>{preferredServiceLocation.phone}</b></a> to book a service</>}
                            </p>}
                            {!preferredServiceLocation && <p className="mb-5">
                                <Button label="Book a service" buttonType="primary" href="https://forms.audi.com.au/book-a-service" rel="noopener noreferrer" target="_blank" onClick={() => { gtmPush("buyServicePlan", "BspBookAService", "BspThanks"); }} />
                            </p>}
                        </div>
                        <div className="col-small-1"></div>
                        <div className="col-small-4 col-large-3">
                            {contact}
                        </div>
                    </div>
                </div>
            );
        }

        if (searchResult && searchResult.mileage) {
            return (
                <div className="buy-service-plan p-7">
                    <PurchaseForm
                        vin={searchResult.vin}
                        rego={vehicle.regoNo}
                        mileage={searchResult.mileage}
                        model={searchResult.model}
                        availablePlans={searchResult.plans}
                        onSubmitSuccess={this.setPurchaseResult}
                        profile={this.props.profile}
                    />
                </div>
            );
        }

        return (
            <div className="buy-service-plan p-7">
                <SearchForm
                    vin={vehicle.vin}
                    contact={contact}
                    setSearchResult={this.setSearchResult}
                />
            </div>
        );
    }
}

export default BuyServicePlan;
