import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import LoadingOverlay from '../../components/LoadingOverlay';
import HeroSection from '../../components/HeroSection';
import VehicleListItem from './components/VehicleListItem';

const VehiclesList = ({ vehicleByVin, isLoading, firstName }) => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const vins = vehicleByVin ? Object.keys(vehicleByVin) : [];
    var titleName = "Your";
    if (firstName) {
      let last = firstName.slice(-1).toLowerCase();
      if (last === "s") {
        titleName = `${firstName}'`;
      } else {
        titleName = `${firstName}'s`;
      }
    }

    return (
        <div className="page-wrapper vehicles">
            <HeroSection
                content={[{ type: "image", url: `${process.env.PUBLIC_PATH}assets/1024-ARSQ3_D_191010_R1.png`, x: "50%", y: "50%" }]}
                className="mb-2"
                imgWide
            >
                <h1 className="aui-headline-3 headline-margin mb-small-6 pt-small-7 aui-color-text-light">
                    <b>{`${titleName} Vehicles`}</b>
                </h1>
            </HeroSection>
            {isLoading && <LoadingOverlay type="placeholder" />}
            <div className="vehicles-list mt-5">
                <div className="content-wrapper">
                    <div className="row">
                        {vins.map(v => {
                          let vehicle = vehicleByVin[v];
                          return (
                            <VehicleListItem key={vehicle.regoNo} {...vehicle} />
                          );
                        })}
                    </div>
                </div>
            </div>

            <Helmet>
                <title>Vehicle Listing | myAudi Australia</title>
            </Helmet>
        </div>
    );
};

const mapStateToProps = state => {
  return {
    vehicleByVin: state.vehicles.vehicleByVin,
    isLoading: state.profile.isLoading || state.vehicles.isLoading,
    firstName: state.profile.data ? state.profile.data.firstName : '',
  };
};

export default connect(mapStateToProps)(VehiclesList);
