import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { loadEvent, unloadEvent } from '../../redux/Experience';
import LoadingOverlay from '../../components/LoadingOverlay';
import HeroSection from '../../components/HeroSection';
import Crumb from '../../components/Crumb';
import { Link } from 'react-router-dom';
import { getEventPeriodString } from './helpers';
import ResizeObserver from 'resize-observer-polyfill';
import { PATH_EXPERIENCE, PATH_LOGIN, BREAKPOINTS } from '../../constants';

const mapStateToProps = state => {
  return {
    event: state.experience.currentEvent,
    isLoading: state.experience.isLoading,
    isAuthed: state.profile.isAuthed,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    loadEvent: id => dispatch(loadEvent(id)),
    unloadEvent: () => dispatch(unloadEvent()),
  };
};

class OfferDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      halfHero: false
    };
    this.refContent = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.loadEvent(this.props.match.params.id);
    this.layoutUpdate();
    // check for resize
    this.resizeObserver = new ResizeObserver(this.layoutUpdate);
    this.resizeObserver.observe(this.refContent.current);
  }

  componentWillUnmount() {
    this.props.unloadEvent();
    this.resizeObserver.unobserve(this.refContent.current);
  }

  layoutUpdate = () => {
    let ww = window.innerWidth;
    if (ww >= BREAKPOINTS.small) {
      this.setState({halfHero: true});
    } else {
      this.setState({halfHero: false});
    }
  }

  render() {
    const {event, isLoading, isAuthed} = this.props;
    const dateString = event && getEventPeriodString(event.eventStartDate, event.eventEndDate);
    var heroContent = [];
    if (event) {
      if (event.heroImages && event.heroImages.length) {
        heroContent = event.heroImages;
      } else if (event.heroImage) {
        heroContent = [{
          id: 'hero',
          url: event.heroImage.url,
          type: 'image',
          x: event.heroImage.x,
          y: event.heroImage.y,
          textColor: event.heroImage.textColor
        }];
      }
    }
    return (
      <div className="page-wrapper offer">
        <div className="content-wrapper pb-small-5">
          {isLoading && <LoadingOverlay type="placeholder" />}
          <div className="row mb-5">
            <div className="col-12 col-small-6">
              {heroContent.length > 0 &&
                <HeroSection
                  content={heroContent}
                  defaultType="image"
                  fullheight={this.state.halfHero}
                  outdent={!this.state.halfHero}
                  gradient={!this.state.halfHero}
                  imgWide={!this.state.halfHero}
                >
                  <div className="pt-small-7 row d-small-none">
                    <div className="col">
                      <h2 className="aui-headline-6 mb-3">
                        {dateString}
                      </h2>
                      <h1 className="aui-headline-3 mb-small-6 ">
                        <b>{event && event.name}</b>
                      </h1>
                    </div>
                  </div>
                </HeroSection>
              }
            </div>

            <div className="col-12 col-small-6">
              <div className="pl-small-6 pl-large-7">
                {!isLoading && event && <Crumb className="mb-4" />}

                <section ref={this.refContent} className="event-content pb-3">
                  {event && this.state.halfHero && <>
                    <h2 className="aui-headline-6 mb-3">{dateString}</h2>
                    <h1 className="aui-headline-3 mb-6 "><b>{event.name}</b></h1>
                  </>}
                  <div className="cms-content">
                    {event && event.eventDetails && <div className="cms-content mb-7" dangerouslySetInnerHTML={{ __html: event.eventDetails }} />}
                    {event && event.termsAndConditionDetails && <div className="cms-content aui-small" dangerouslySetInnerHTML={{ __html: event.termsAndConditionDetails }} />}
                  </div>
                </section>
              </div>
            </div>
          </div>

          <section className="aui-color-gray95 p-7 outdent-below-small">
            {isAuthed && event && <div className="cms-content" dangerouslySetInnerHTML={{ __html: event.redeem }} />}
            {!isAuthed && <div className="text-center">
              <Link to={{ pathname: PATH_LOGIN, state: { from: this.props.location } }} className="aui-button aui-button--primary">
                <span className="aui-button__text">Login to redeem this offer</span>
              </Link>
            </div>}
          </section>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OfferDetail);
