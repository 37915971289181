import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Fieldset extends React.Component {

  render() {
    const {title, fields, fieldType, isInvalid, invalidMessage, theme} = this.props;
    let typeClass = `aui-fieldset--${fieldType}`;
    return(
      <fieldset style={this.props.style} className={classnames(
        "aui-fieldset",
        `${this.props.className}`,
        {
          [typeClass]: fieldType && fieldType !== null,
          "is-invalid": isInvalid,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        <legend className="aui-fieldset__legend aui-fieldset__legend">{title}</legend>
        <div className="aui-fieldset__fields">
          {fields.map((field, i) => <div key={`field_${i}`} className="aui-fieldset__field">{field}</div>)}
        </div>
        {isInvalid && invalidMessage && <span className="aui-fieldset__error">{invalidMessage}</span>}
      </fieldset>
    );
  }

}

Fieldset.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  fields: PropTypes.array,
  fieldType: PropTypes.oneOf(['textfields', 'selects', 'radios']),
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_fieldset-themes.scss
};

Fieldset.defaultProps = {
  style: {},
  className: ''
}

export default Fieldset;
