import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M7,18.5 L17,18.5 M7,15.5 L17,15.5 M7,12.5 L17,12.5 M14.5,2.5 L14.5,7.5 L19.5,7.5"/>
          <path d="M19.5 7.5L19.5 21.5 4.5 21.5 4.5 2.5 14.5 2.5z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M12,38.5 L35,38.5 M12,32.5 L35,32.5 M12,26.5 L35,26.5 M29.5,2.5 L29.5,13.5 L40.5,13.5"/>
        <path d="M40.5 13.5L40.5 45.5 6.5 45.5 6.5 2.5 29.5 2.5z"/>
      </g>
    </svg>
  );
};
