import React, {useCallback} from 'react';
import classnames from 'classnames';
import {useDropzone} from 'react-dropzone';
import IconDoc from '../audi-ui-components/icons/Documents';
import IconPdf from '../audi-ui-components/icons/DocumentPdf';

const UploadField = ({onChange, multiple, accept, dragText, dragActiveText, isInvalid, invalidMessage}) => {

  const onDrop = useCallback(acceptedFiles => {
    if (typeof onChange === 'function') {
      onChange(acceptedFiles);
    }
  }, []);

  const {getRootProps, getInputProps, isDragActive, acceptedFiles} = useDropzone({onDrop, accept});

  const files = acceptedFiles.map(file => {
    let ext = file.path.split('.').pop().toLowerCase();
    if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif') {
      return (
        <li className="file" key={file.path}>
           <img src={URL.createObjectURL(file)} />
        </li>
      );
    } else if (ext === 'pdf') {
      return (
        <li className="file" key={file.path}>
           <IconPdf large />
           <span>{file.name}</span>
        </li>
      );
    } else {
      return (
        <li className="file" key={file.path}>
           <IconDoc large />
           <span>{file.name}</span>
        </li>
      );
    }

  });

  return (
    <div className={classnames("form-field upload mb-5", {"single": !multiple, "multiple": multiple, "dragging": isDragActive, "is-invalid": isInvalid})}>
      <div {...getRootProps({className: "dropzone aui-color-gray95 p-7"})}>
        <input {...getInputProps()} />
        <div className="drag-text">
          {isDragActive ?
            <p>{dragActiveText}</p> :
            <p>{dragText}</p>
          }
        </div>
        <ul className="file-list">{files}</ul>
      </div>
      {isInvalid && invalidMessage && <p className="aui-color-text-red mt-3">{invalidMessage}</p>}
    </div>
  );
};

UploadField.defaultProps = {
  accept: "",
  dragText: "Drag 'n' drop files here, or click to select files",
  dragActiveText: "Drop the files here ..."
}

export default UploadField;
