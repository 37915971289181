import React from 'react';
import { Link } from 'react-router-dom';
import Notifications from '../audi-ui-components/Notifications';
import Button from '../audi-ui-components/Button';
import { PATH_PROFILE, PATH_VEHICLES, PATH_EVENT } from '../constants';
import { request } from '../lib/apiRequestWrapper';
import { gtmPush } from '../lib/gtm';

const Alerts = ({alerts}) => {

    const hideAlert = async (id) => {
        try {
            let response = await request(
                `${process.env.RAZZLE_API}/1/useralerts/${id}/hide`,
                {
                    method: "POST",
                }
            );
        } catch (error) {
            console.error(error);
        }
    }

    let list = [];

    if (alerts && alerts.length) {
        for (let a=0; a<alerts.length; a++) {
            let item = alerts[a];
            let itemContent = (item.message && item.message.content) ? item.message.content : typeof item.message === "string" ? item.message : "";
            if (item.code === 'event') {
                list.push({
                    content: (<>
                        <div dangerouslySetInnerHTML={{ __html: itemContent }} className="mb-3" />
                        <p><Link to={`${PATH_EVENT}/${item.message.id}`} className="aui-button aui-button--secondary"><span className="aui-button__text">View booking</span></Link></p>
                    </>)
                });
            } else if (item.code ==="waitlist") {
                list.push({
                    content: (<div dangerouslySetInnerHTML={{ __html: itemContent }} className="mb-3" />),
                    buttons: ({close}) => { return (
                        <Link to={`${PATH_EVENT}/${item.message.id}`} onClick={close} className="aui-button aui-button--primary">
                            <span className="aui-button__text">Book now</span>
                        </Link>
                    ); }
                });
            } else if (item.code === "offer") {
                list.push({
                    content: (<>
                        <div dangerouslySetInnerHTML={{ __html: itemContent }} className="mb-3" />
                        {item.message && item.message.link && <p><a href={item.message.link} target="_blank" rel="noopener noreferrer" className="aui-button aui-button--secondary"><span className="aui-button__text">View details</span></a></p>}
                    </>)
                });
            } else if (item.code === 'extendedWarranty') {
                list.push({
                    content: (<div dangerouslySetInnerHTML={{ __html: itemContent }} className="mb-3" />),
                    buttons: ({close}) => { return (
                        <p>
                            <Link to={{pathname: `${PATH_VEHICLES}/${item.message.vin}`, state: {showModal: "enquire-warranty"}}} className="aui-button aui-button--primary mb-3">
                                <span className="aui-button__text">Yes please</span>
                            </Link>
                            <Button label="No thanks" onClick={() => { close(); hideAlert(item.userAlertId); }} buttonType="secondary" />
                        </p>
                    ); }
                });
            } else if (item.code === 'recall') {
                list.push({
                    className: "aui-color-red aui-color-text-light",
                    content: (<>
                        <p className="aui-headline-4 mb-3">Recall Alert</p>
                        <p className="">VIN: <b>{item.message.vin}</b></p>
                        {item.message.recalls.map((r, i) => <div key={i} className="mt-5">
                            <p className="aui-headline-6 mb-3">{r.title}</p>
                            <p className="aui-headline-5 mb-3"><b>{r.recallStatus.description}</b></p>
                            <p>
                                <Button label="Download result"
                                    buttonType="secondary"
                                    theme="light"
                                    onClick={() => { gtmPush("myAudi", "recallAlertDownloadResult", item.message.vin); }}
                                    href={r.downloadResults}
                                    target="_blank" rel="noopener noreferrer"
                                />
                            </p>
                        </div>)}
                    </>)
                });
            } else if (item.code === 'preference') {
                list.push({
                    content: (<>
                        <p className="mb-3">It looks like we haven’t seen you in a while. Check to see if we still have your correct details.</p>
                        <p><Link to={PATH_PROFILE} className="aui-button aui-button--secondary"><span className="aui-button__text">View my profile</span></Link></p>
                    </>)
                });
            } else {
              if (itemContent && itemContent !== "") {
                list.push({content: (<div dangerouslySetInnerHTML={{ __html: itemContent }} />)});
              }
            }
        }
    }

    return (
        <Notifications list={list} />
    );
}

export default Alerts;
