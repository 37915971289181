import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { PATH_FINANCE, MEDIASERVICE } from '../../../constants';
import { formatDollar } from '../helpers';
import Button from '../../../audi-ui-components/Button';
import Media from '../../../components/Media';

const FinanceContractPreview = ({ contract }) => {
    const {
        contractTypeDesc,
        contractStatusDesc,
        contractNumber,
        payments,
    } = contract;
    const { make, model, registrationNumber, vinChasis } = contract.assets[0];
    
    const [vinImg, setVinImg] = useState(null);
    useEffect(() => {
      fetch(
          `${process.env.RAZZLE_MEDIASERVICE}/MediaServiceCache/vinimageurl`,
          {
            headers: {
              'Accept': 'application/json',
              'Cache-Control': 'no-cache',
              'Content-Type': 'application/json'
            },
            method: "POST",
            body: JSON.stringify({vin: vinChasis, view: "n3c01aut", mimeType: "image/png", width: 1200})
          }
      ).then(response => {
        let ok = response.status >= 200 && response.status < 300;
        let contentType = response.headers.get('Content-Type') || '';
        // return response.json().then(r => { return r; }).catch(() => { return response; });
        // json response
        if (contentType.includes('application/json')) {
          if (ok) { return response.json().catch(() => response); }
          // error
          return response.json().then((body) => {
            return Promise.reject({
              status: response.status,
              body,
            });
          });
        }
        // not json response
        return Promise.reject({
          status: response.status,
          body: response.body
        });
      }).then((res) => {
          // console.log(res);
          setVinImg(res.imageUrl);
      }).catch((error) => {
        // this.setState({loading: false});
      });
    })
    
    return (
        <Link to={PATH_FINANCE + '/' + contractNumber} className="finance__contract-preview mb-3">
            <Media type="image" url={vinImg} altText="Vehicle Preview" />
            <div className="aui-color-gray95 px-5 py-3">
                <header className="py-3">
                    <h2 className="aui-headline-3 pb-6">{make} {model}</h2>
                    <h3 className="aui-headline-6 pb-4">
                        {registrationNumber}{' '}
                        {registrationNumber && contractTypeDesc && ' - '}
                        {contractTypeDesc}
                    </h3>
                </header>
                <p className="aui-headline-6">
                    <small> Status: {contractStatusDesc}</small>
                </p>
                <p className="aui-headline-6">
                    <small> Contract number: {contractNumber}</small>
                </p>
                <p className="aui-headline-6">
                    <small>
                        {payments.paymentFrequency} repayment:{' '}
                        {formatDollar(payments.nextPaymentAmount)}
                    </small>
                </p>
                <p>
                    <Button label="View details" buttonType="primary" isStretched className="my-4" />
                </p>
            </div>
        </Link>
    );
};

export default FinanceContractPreview;
